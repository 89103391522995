import React from 'react';
import { useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { BgImage, ClassContainer } from './styles';

const RemoteControl: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Remote Control')}</h1>
      <p>
        {t(
          'The remote control robot works in the same way you are already familiar with operating a toy car or an air conditioner.',
        )}
      </p>
      <p>
        {t(
          'You can build your own custom robot using LEGO pieces and then control its actions through a small remote control. This combination of physical construction and remote control provides an easy way to send commands to your robot.',
        )}
      </p>
      <p>
        {t(
          "To get started, you'll need to assemble the robot following the instructions provided by the LEGO Mindstorms set. The kit includes specific pieces designed to fit together perfectly, allowing you to create a wide variety of robots with different shapes and functionalities.",
        )}
      </p>
      <p>
        {t(
          'The remote control is used to send commands to the robot and has buttons that control basic movements such as forward, backward, left, and right. It communicates wirelessly with the controller, allowing you to control the robot from a certain distance.',
        )}
      </p>
      <p>
        {t(
          'However, the true power of the LEGO Mindstorms remote control robot is unlocked when you start programming it.',
        )}
      </p>
      <p>
        {t(
          'For example, you can create a program that makes the robot move in a straight line for a certain distance, stop, turn at a specific angle, and continue its movement. This programming can be done to respond to commands from the remote control or even to interact with sensors, such as a distance sensor, which allows the robot to avoid obstacles.',
        )}
      </p>
      <p>
        {t(
          'It is important to highlight that the LEGO Mindstorms remote control robot is also compatible with other more advanced programming languages, such as Python, allowing more experienced users to explore even more complex possibilities.',
        )}
      </p>
      <p>
        {t(
          'You can access a pre-built version of the code in the Applications menu of your control block by accessing the IR Control program.',
        )}
      </p>
      <BgImage>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/menu3.png"
          alt="code"
        />
      </BgImage>
    </ClassContainer>
  );
};

export default RemoteControl;
