import React from 'react';

import { Container } from './styles';

interface CardProps {
  className?: string;
  cardColor?: string;
  textColor?: string;
}

const Card: React.FC<CardProps> = ({
  className = '',
  cardColor,
  textColor,
  children,
}) => {
  return (
    <Container
      className={className}
      cardColor={cardColor}
      textColor={textColor}
    >
      {children}
    </Container>
  );
};

export default Card;
