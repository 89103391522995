import { darken, saturate, shade } from 'polished';
import styled, { css } from 'styled-components';

interface CardProps {
  color?: string;
  itemId: string;
}

interface ButtonProps {
  color?: string;
}

export const Screen = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  overflow-x: hidden;
  padding-top: 4rem;
  padding-left: 4rem;

  & > h1 {
    margin-bottom: 3.5rem;

    color: #051227;

    font-size: 2rem;
    font-weight: 500;
  }

  .react-horizontal-scrolling-menu--item {
    margin-right: 3rem;
  }

  div + div {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  div + div::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1224px) {
    padding: 3rem 2rem;
  }

  @media (max-width: 800px) {
    padding: 2rem 1.5rem;
  }
`;

export const CourseCard = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 360px;
  height: 500px;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px; */

  padding: 3rem;
  color: #fff;
  border-radius: 1.5rem;

  transition: 0.25s ease-in-out;

  &:hover {
    /* transform: scale(1.1); */
    ${props =>
      props.color &&
      css`
        background: ${darken(0.15, props.color)};
      `}
  }

  ${props =>
    props.color &&
    css`
      background: ${props.color};
    `}

  & + & {
    margin-left: 3rem;
  }

  h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 3rem;

    @media (max-width: 1224px) {
      margin-top: 2rem;
      margin-bottom: 1.5rem;
    }
  }

  @media only screen and (max-width: 1500px) {
    padding: 1.5rem;
    width: 230px;
    height: 320px;

    h2 {
      font-size: 1.6rem;
    }
  }

  @media (max-width: 1224px) {
    width: 100%;
    height: auto;
  }
`;

export const DetailContainer = styled.div<ButtonProps>`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 1;
  width: 100%;

  div {
    display: flex;
    padding: 0.5rem;
    border-radius: 1rem;
    ${props =>
      props.color &&
      css`
        background: ${darken(0.15, props.color)};
      `}
  }
`;

export const GoButton = styled.div<ButtonProps>`
  display: flex;
  background: #fff;
  padding: 0.75rem;
  border-radius: 1rem;
  cursor: pointer;
  svg {
    color: ${props => (props.color ? `${props.color}` : '#000')};
  }
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  div {
    margin-left: 0 !important;
  }

  div + div {
    margin-top: 3rem;
  }

  @media (max-width: 1224px) {
    align-items: center;
  }
`;

export const BackButton = styled.div`
  display: inline-flex;
  margin-bottom: 0.5rem;
  cursor: pointer;
  color: #051227;

  &:hover {
    color: ${shade(0.3, '#051227')};
  }
`;
