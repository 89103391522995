import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { TbEngine } from 'react-icons/tb';
import { ClassContainer } from './styles';
import { useToast } from '../../../../../../hooks/toast';
import Cards from './dataPPE';
import CardsModal from '../../../../../../components/CardsModal';

const WhatIsLEGO: React.FC = () => {
  const { t } = useTranslation();

  const { addToast } = useToast();

  const [showNextButton, setShowNextButton] = useState(false);

  useEffect(() => {
    if (showNextButton === true) {
      addToast({
        type: 'success',
        label: t("Now we're talking!"),
        description: t("Click on the 'next' button to continue!"),
      });
    }
  }, [addToast, showNextButton, t]);

  return (
    <ClassContainer showNextButton={showNextButton}>
      <h1>{t('PPE')}</h1>
      <h2>{t('What is PPE?')}</h2>
      <p>
        {t(
          'PPE stands for Personal Protective Equipment, which means any device or individual product used by workers with the purpose of protecting them from risks that could threaten their safety and health, once all collective protection measures are not sufficient for protection against accidents or even serious diseases.',
        )}
      </p>
      <h2>{t('What is the importance of PPE?')}</h2>
      <p>
        {t(
          'In robotics, we prioritize the use of PPE for the safety of team members. It is extremely important that everyone uses PPE when in contact with the robot or tools, especially those in the mechanics area who have greater contact with the robot. Here is a list of mandatory PPE in mechanics.',
        )}
      </p>
      <p className="modalStartText">{t('Click the button below to start:')}</p>
      <CardsModal
        Icon={TbEngine}
        bgColor="#6cd0e0"
        propBorderColor="#c6eef5"
        setShowNextButton={setShowNextButton}
        cards={Cards}
      />
    </ClassContainer>
  );
};

export default WhatIsLEGO;
