import React, { useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../hooks/authentication';

import dataCourses from '../../data/dataCourses';

import {
  Screen,
  Container,
  Course,
  Courses,
  Levels,
  Level,
  CreateAssignment,
} from './styles';
import api from '../../services/api';

interface Course {
  id: string;
  name: string;
  area_modules: Module[];
}

interface Module {
  id: string;
  area: {
    name: string;
  };
  module_levels: Level[];
}

interface Level {
  id: string;
  name: string;
}

interface TeachParams {
  course_name: string;
}

const Teach: React.FC = () => {
  const { params } = useRouteMatch<TeachParams>();

  const [modules, setModules] = useState<Module[]>([] as Module[]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [levels, setLevels] = useState<Level[]>([] as Level[]);

  const { user } = useAuthentication();
  const { t } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    async function loadModules(): Promise<void> {
      if (
        !user.permissions.some(permission =>
          permission.name.includes(
            params.course_name.replaceAll(' ', '-').toLowerCase(),
          ),
        )
      ) {
        history.push('/dashboard');
      }

      if (user.permissions.length > 0) {
        const dataCourse = dataCourses.find(
          dc => dc.name === params.course_name,
        );

        dataCourse?.modules.forEach(async module => {
          if (
            user.permissions.some(permission =>
              permission.name.includes(module.name.toLowerCase()),
            )
          ) {
            const dbModule: Module = await (
              await api.get(`/courses/module/area-name/${module.name}`)
            ).data;

            setModules(oldState => [...oldState, dbModule]);
          }
        });
      }
    }

    loadModules();
  }, [history, params.course_name, user.permissions]);

  useEffect(() => {
    modules.length > 0 && setLevels(modules[activeIndex].module_levels);
  }, [activeIndex, modules]);

  return (
    <Screen>
      <Container>
        <h2>{t('Teach')}</h2>
        <h3>{t('Create new assignments')}</h3>

        <Courses>
          {modules.map((module, index) => (
            <Course
              key={module.id}
              onClick={() => {
                setActiveIndex(index);
                setLevels(module.module_levels);
              }}
              active={index === activeIndex}
            >
              {t(module.area.name)}
            </Course>
          ))}
        </Courses>
        <Levels>
          {levels.map((level, index) => (
            <Level key={level.id}>
              <div>
                <p>{t(level.name)}</p>
              </div>
              <CreateAssignment to={`/teach/create-assignment/${level.id}`}>
                <p>{t('Create Task')}</p>
              </CreateAssignment>
            </Level>
          ))}
        </Levels>
      </Container>
    </Screen>
  );
};

export default Teach;
