import { AiOutlineFileSearch } from 'react-icons/ai';
import {
  HiOutlineCamera,
  HiOutlineCog,
  HiOutlineLightBulb,
  HiOutlineLightningBolt,
  HiOutlineMail,
  HiOutlinePlay,
  HiOutlineTerminal,
} from 'react-icons/hi';
import { IoPeopleOutline } from 'react-icons/io5';
import { MdOutlineCable } from 'react-icons/md';
import { RiMoneyDollarCircleLine, RiOrganizationChart } from 'react-icons/ri';
import { TbEngine, TbMedal, TbTargetArrow, TbTools } from 'react-icons/tb';
import LEGO from '../assets/Illustrations/LEGO.svg';
import PREP from '../assets/Illustrations/PREP.svg';
import FRC from '../assets/Illustrations/FRC.svg';
import BRICK from '../assets/Illustrations/Brick.svg';
import ABROAD from '../assets/Illustrations/abroad.svg';
import FRCLIB from '../assets/Illustrations/FRCLib.svg';

const Courses = [
  {
    name: 'LEGO Robotics',
    image: LEGO,
    libraryImage: BRICK,
    modules: [
      {
        name: 'Getting Started',
        color: '#6cd0e0',
        icon: HiOutlinePlay,
      },
      {
        name: 'Components',
        color: '#ffb129',
        icon: RiOrganizationChart,
      },
      {
        name: 'Programming',
        color: '#d7607e',
        icon: HiOutlineTerminal,
      },
    ],
  },
  {
    name: 'Estudar Fora',
    image: PREP,
    libraryImage: ABROAD,
    modules: [
      {
        name: 'College Research',
        color: '#d7607e',
        icon: AiOutlineFileSearch,
      },
      {
        name: 'Scholarships',
        color: '#ffb129',
        icon: RiMoneyDollarCircleLine,
      },
      {
        name: 'Letters',
        color: '#ac98ef',
        icon: HiOutlineMail,
      },
      {
        name: 'Parents',
        color: '#6cd0e0',
        icon: IoPeopleOutline,
      },
    ],
  },
  {
    name: 'FIRST Robotics',
    image: FRC,
    libraryImage: FRCLIB,
    modules: [
      {
        name: 'Strategy',
        color: '#046582',
        icon: TbTargetArrow,
      },
      {
        name: 'Mechanics',
        color: '#6cd0e0',
        icon: TbEngine,
      },
      {
        name: 'Electric',
        color: '#ffb129',
        icon: HiOutlineLightningBolt,
      },
      {
        name: 'Programming',
        color: '#d7607e',
        icon: HiOutlineTerminal,
      },
      {
        name: 'Awards',
        color: '#E08F62',
        icon: TbMedal,
      },
      {
        name: 'Communication',
        color: '#92BA92',
        icon: HiOutlineCamera,
      },
      {
        name: 'CAD',
        color: '#ac98ef',
        icon: TbTools,
      },
    ],
  },
];

export default Courses;
