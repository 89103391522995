import React from 'react';

import Account from '../../components/TabPanel/Tabs/Account';
import Profile from '../../components/TabPanel/Tabs/Profile';

const Tabs = [
  {
    name: 'Profile',
    component: <Profile />,
  },
  {
    name: 'Account',
    component: <Account />,
  },
];

export default { Tabs };
