import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SettingsTabs from '../../data/DataSettingsTabs';

import { Tabs, TabItem } from './styles';

const TabPanel: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const { t } = useTranslation();

  return (
    <>
      <Tabs>
        {SettingsTabs.Tabs.map((tab, index) => {
          return (
            <TabItem
              key={tab.name}
              active={index === activeIndex}
              onClick={() => setActiveIndex(index)}
            >
              {t(tab.name)}
            </TabItem>
          );
        })}
      </Tabs>
      {SettingsTabs.Tabs[activeIndex].component}
    </>
  );
};

export default TabPanel;
