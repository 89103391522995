import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { CodeBlock } from '@atlaskit/code';
import { AtlaskitThemeProvider } from '@atlaskit/theme';

import { BgImage, ClassContainer } from './styles';

const RobotSystems: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Robot Systems')}</h1>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>{t('Chassis')}</h2>
      <p>
        {t(
          "The purpose of the chassis is to provide mobility to the robot, allowing it to move across different areas of the field and complete the game challenge. The chassis defines the robot's ability to navigate obstacles and maneuver during matches, also demonstrating how much the robot can interact with others on the field. In addition to being durable, it should possess characteristics such as speed and agility while remaining simple and robust.",
        )}
      </p>
      <h3 style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('Types of Chassis')}
      </h3>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('TANK (Skid Steer)')}
      </p>
      <p>
        {t(
          'This chassis is the most commonly used in FRC (FIRST Robotics Competition). The right and left sides of the robot are driven independently, allowing it to move in all directions. It is simple, cost-effective, easy to program, and accessible to the drivers. It is somewhat less agile compared to other types; however, this is balanced by its effectiveness in other areas and its speed. This is a great chassis for beginners in FRC, as it is simple and requires minimal maintenance.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/mech/tank1.png"
          alt="Tank"
        />
      </BgImage>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('SWERVE')}
      </p>
      <p>
        {t(
          'This chassis uses independently motorized wheels that can be turned in any direction. Each module (a wheel with 2 motors) moves independently, with one motor used for traction and the other for steering the wheel. Its programming is more complex, and its maintenance requires careful attention and delicacy, along with a structure to protect the wheels. It is typically used by experienced teams or those with greater support.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/mech/SWERVE.png"
          alt="SWERVE"
        />
      </BgImage>
      <p style={{ color: '#051227', alignSelf: 'center', fontWeight: 'bold' }}>
        {t('Model from Team 1710')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('MECANUM')}
      </p>
      <p>
        {t(
          "This is a variation of the Tank chassis that uses special wheels called 'mecanum' (omni) wheels. These wheels have small diagonal rollers around their circumference, allowing the robot to move in all directions. Each wheel has a special combination for each direction. Although this model is an improved version of the Tank chassis, it is expensive to design and more challenging to program and operate. It also provides little thrust and adds significant weight to the robot, making it not the most recommended choice, as a collision can displace the robot.",
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/mech/MECANUM.png"
          alt="MECANUM"
        />
      </BgImage>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('Lifting Systems')}
      </h2>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('Elevator')}
      </p>
      <p>
        {t(
          'The objective of this subsystem is to raise and lower another subsystem of the robot. They can be built in various ways, with the most common method being the use of belts or ropes on profiles that slide against each other to perform the movement. Additionally, elevators are typically fast and compact.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/mech/elevador.png"
          alt="Elevator"
        />
      </BgImage>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('Arm')}
      </p>
      <p>
        {t(
          "Robotic arms typically operate with belts and a gear, with the gear fixed to the structure that needs to perform the movement. Arms are generally used to lift objects, reaching outside the robot's frame perimeter and scoring over opponents. They can be simple and reliable when properly designed. However, they raise the center of gravity and tend to be slower, more fragile, and heavier, requiring counterweights in certain situations.",
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/mech/arm.png"
          alt="arm"
        />
      </BgImage>
      <p style={{ color: '#051227', alignSelf: 'center', fontWeight: 'bold' }}>
        {t('Model from Team 3467')}
      </p>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('Collectors (Intake)')}
      </h2>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('Grippers')}
      </p>
      <p>
        {t(
          'Grippers are collectors that use two or more surfaces to grab a game piece and place it in predetermined locations. They are simple to design and maintain but more complex to operate. Since they require precision, they usually incorporate more mechanisms to move them, as they are slow compared to other systems.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/mech/claw.png"
          alt="arm"
        />
      </BgImage>
      <p style={{ color: '#051227', alignSelf: 'center', fontWeight: 'bold' }}>
        {t('Photo from Team 971')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('Wheels/Rollers')}
      </p>
      <p>
        {t(
          'These collectors are the most commonly used due to their efficiency and ease of assembly. They typically consist of two or three contact surfaces on the game piece, one for support during movement and collection, and the others for moving the piece inside the robot or ejecting it. The movement is achieved with wheels or rollers pressing the sides or top of the piece, allowing them to pick up multiple pieces at once.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/mech/rolling-intake.png"
          alt="rolling-intake"
        />
      </BgImage>
      <p style={{ color: '#051227', alignSelf: 'center', fontWeight: 'bold' }}>
        {t('Photo from Team 3847')}
      </p>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>{t('Climbs')}</h2>
      <p>
        {t(
          'Climbing is the mechanical system that lifts the entire robot to achieve a game objective where climbing, scaling, or hanging is required.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('Climbing with Rope')}
      </p>
      <p>
        {t(
          'This system consists of a rope with a hook at the end that is raised to a bar, and once the hook is attached, the rope begins to be wound by 2 motors, lifting the robot.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/mech/rope-climb.png"
          alt="rope-climb"
        />
      </BgImage>
      <p style={{ color: '#051227', alignSelf: 'center', fontWeight: 'bold' }}>
        {t('Photo from Team 558')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('Climbing with "Lifting Arms"')}
      </p>
      <p>
        {t(
          'In this type of climb, arms are used to lift the robot. In an example of use, the arm extends to the required location and is retracted using motors.',
        )}
      </p>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>{t('Shooter')}</h2>
      <p>
        {t(
          "This system is used to shoot game pieces to high places and typically in large quantities. It operates with surfaces with wheels, rollers, or ropes that rotate and press the game piece to 'shoot' it. These systems are efficient when well designed but are complex as they need to be precise and fast.",
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/mech/shooter.png"
          alt="shooter"
        />
      </BgImage>
      <p style={{ color: '#051227', alignSelf: 'center', fontWeight: 'bold' }}>
        {t('Model from Team 5940')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('Shooter with Piston')}
      </p>
      <p>
        {t(
          'This system is typically used to shoot non-cylindrical pieces and in not very high places. It is not as efficient as it is usually slow, lacks precision, and requires the assistance of wheels and rollers to facilitate the shot.',
        )}
      </p>
    </ClassContainer>
  );
};

export default RobotSystems;
