import React, { useCallback } from 'react';
import ScratchBlocks from 'scratchblocks-react';
import scratchblocks from 'scratchblocks';
import es from 'scratchblocks/locales/es.json';
import pt from 'scratchblocks/locales/pt-br.json';

import { useTranslation } from 'react-i18next';
import { EditContainer, ScratchBlocksContainer } from './styles';
import { useToast } from '../../../../../../../hooks/toast';

scratchblocks.loadLanguages({ es, pt });

interface TabsProps {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
}

const Events: React.FC<TabsProps> = ({ code, setCode }) => {
  const { addToast } = useToast();

  const { t } = useTranslation();

  const setNewText = useCallback(
    (text: string) => {
      if (!code.includes(text) && code.lastIndexOf('if <') < 50) {
        if (
          code.includes('if <') &&
          (!code.includes('is color') || !code.includes('set status light'))
        ) {
          addToast({
            type: 'error',
            label: t('Not Yet'),
            description: t('Complete the first “if-then” statement'),
          });
        } else {
          setCode(prevState =>
            prevState.length > 0 ? `${prevState}\n${text}` : `${text}`,
          );
        }
      }
    },
    [addToast, code, setCode, t],
  );

  return (
    <EditContainer>
      <ScratchBlocksContainer
        blockStyle="scratch3"
        languages={['en', 'es', 'pt']}
        onClick={() => setNewText('if <> then\nend')}
      >
        {`
              ${t('if <> then\nend')}
            `}
      </ScratchBlocksContainer>
    </EditContainer>
  );
};

export default Events;
