import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { BgImage, ClassContainer } from './styles';

const WhatIsLEGO: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Motor Controllers')}</h1>
      <p>
        {t(
          'In the last topic, we learned a bit about the essential components for a robot to function. However, this next topic can be separated to provide a better explanation.',
        )}
      </p>
      <p>
        {t(
          "Controllers are a programmer's best friend, as they are the items that communicate with the robot's motors. Without controllers, the motors could only run at full power or be turned off. But with controllers, we can regulate the speed and functions of a motor.",
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/talonsrx.png"
          alt="TalonSRX"
        />
      </BgImage>
      <p style={{ color: '#051227', alignSelf: 'center', fontWeight: 'bold' }}>
        {t('TalonSRX - Vex Robotics')}
      </p>
      <p>
        {t(
          'On one side of the controller, wires are connected to the PDP, and on the other side, they are connected to a motor.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/cim.png"
          alt="TalonSRX"
        />
      </BgImage>
      <p style={{ color: '#051227', alignSelf: 'center', fontWeight: 'bold' }}>
        {t('CIM Motor')}
      </p>
      <p>
        {t(
          'There are different controllers available in the FIRST market. Nowadays, the most sophisticated ones come with extra wires to facilitate communication with the RoboRIO.',
        )}
      </p>
      <p>
        {t(
          'The green and yellow wires are called CAN wires. They act as a communication bridge between the controllers. We need these wires so that the controllers can communicate with the RoboRIO, rather than connecting each controller wire directly to the RoboRIO.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src={`https://${t('elec-diagram-1')}`}
          alt="Controller Diagram"
          style={{ width: '60%' }}
        />
      </BgImage>
      <p style={{ color: '#051227', alignSelf: 'center', fontWeight: 'bold' }}>
        {t('Controller Diagram')}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src={`https://${t('elec-diagram-2')}`}
          alt="Electric Diagram"
          style={{ width: '60%' }}
        />
      </BgImage>
      <p style={{ color: '#051227', alignSelf: 'center', fontWeight: 'bold' }}>
        {t('Electrical Diagram')}
      </p>
    </ClassContainer>
  );
};

export default WhatIsLEGO;
