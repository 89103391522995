interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/components/ex-beam.png',
    title: 'Frame Beam',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/components/t-beam.png',
    title: 'T Beam',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/components/angled-beams.png',
    title: 'Angle Beams',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/components/straight-beams.png',
    title: 'Straight Beams',
    text: '',
  },
];

export default Cards;
