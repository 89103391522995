interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'sound/b6.png',
    title: 'Change Volume',
    text:
      'This block changes the volume of the robot. It has one parameter indicating the volume as a percentage.',
  },
  {
    image: 'sound/b5.png',
    title: 'Stop Sounds',
    text: 'This block stops all sounds that are being played on the robot.',
  },
  {
    image: 'sound/b4.png',
    title: 'Play Beep',
    text:
      'This block plays a continuous beep according to the note you specify. It has one parameter indicating the pitch of the beep you want to play.',
  },
  {
    image: 'sound/b3.png',
    title: 'Play Beep',
    text:
      'This block plays a beep according to the note you specify. It has two parameters: the first one indicating the pitch of the beep you want to play, and the second one specifying the duration in seconds for how long the sound will be played.',
  },
  {
    image: 'sound/b2.png',
    title: 'Play Sound',
    text:
      'This block provides various audio options for you to use, and it will be played only once. It has one parameter indicating which sound you want to play.',
  },
  {
    image: 'sound/b1.png',
    title: 'Play Sound',
    text:
      'This block provides various audio options for you to use in your projects until the action defined by you in the following blocks is completed. It has one parameter indicating which sound you want to play.',
  },
];

export default Cards;
