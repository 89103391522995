import { VscRadioTower } from 'react-icons/vsc';
import { HiOutlineDownload } from 'react-icons/hi';
import { IoMdPower } from 'react-icons/io';
import { RiWebcamLine } from 'react-icons/ri';

const dataInterfaces = [
  {
    id: 'power',
    shape: 'interfaceBox',
    type: 'cable',
    icon: IoMdPower,
    name: 'Power',
  },
  {
    id: 'update',
    shape: 'interfaceBox',
    type: 'cable',
    icon: HiOutlineDownload,
    name: 'Update',
  },
  {
    id: 'camera',
    shape: 'interfaceBox',
    type: 'cable',
    icon: RiWebcamLine,
    name: 'Camera',
  },
  {
    id: 'radio',
    shape: 'interfaceBox',
    type: 'cable',
    icon: VscRadioTower,
    name: 'Radio',
  },
  {
    id: 'powerPort',
    shape: 'interfaceBox',
    type: 'port',
    src: 'https://learnfirst-bucket.s3.us-east-2.amazonaws.com/input.svg',
    matches: 'power',
  },
  {
    id: 'updatePort',
    shape: 'interfaceBox',
    type: 'port',
    src: 'https://learnfirst-bucket.s3.us-east-2.amazonaws.com/usb-device.svg',
    matches: 'update',
  },
  // {
  //   id: 'sdPort',
  //   shape: 'interfaceBox',
  //   type: 'port',
  //   matches: 'null',
  // },
  {
    id: 'webcamPort',
    shape: 'interfaceBox',
    type: 'port',
    src: 'https://learnfirst-bucket.s3.us-east-2.amazonaws.com/usbs.svg',
    matches: 'camera',
  },
  {
    id: 'radioPort',
    shape: 'interfaceBox',
    type: 'port',
    src: 'https://learnfirst-bucket.s3.us-east-2.amazonaws.com/ethernet-2.svg',
    matches: 'radio',
  },
];

export default dataInterfaces;
