/* eslint-disable prettier/prettier */
import React, { useCallback } from 'react';
import ScratchBlocks from 'scratchblocks-react';
import scratchblocks from 'scratchblocks';
import es from 'scratchblocks/locales/es.json';
import pt from 'scratchblocks/locales/pt-br.json';

import { useTranslation } from 'react-i18next';
import { EditContainer, ScratchBlocksContainer } from './styles';
import { useToast } from '../../../../../../../hooks/toast';

scratchblocks.loadLanguages({ es, pt });

interface TabsProps {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
}

const Motors: React.FC<TabsProps> = ({ code, setCode }) => {
  const { addToast } = useToast();

  const { t } = useTranslation();

  const setNewText = useCallback(
    (text: string) => {
      if (
        !code.includes('when button (')
      ) {
        addToast({
          type: 'error',
          label: t('Not Yet'),
          description: t('Add a event block first'),
        });
      }else if (code.includes('display [Eyes')){
        addToast({
          type: 'info',
          label: t('Just one block'),
          description: t('Add only one display block at a time'),
        });
      } else {
        setCode(prevState => `${prevState}\n${text}`);
      }
    },
    [addToast, code, setCode, t],
  );

  return (
    <EditContainer>
      <ScratchBlocksContainer
        blockStyle="scratch3"
        languages={['en', 'es', 'pt']}
        onClick={() =>
          setNewText('display [Eyes/Neutral v]::looks')}
      >
        {`
              ${t('display [Eyes/Neutral v]::looks')}
            `}
      </ScratchBlocksContainer>
      <ScratchBlocksContainer
        blockStyle="scratch3"
        languages={['en', 'es', 'pt']}
        onClick={() =>
          setNewText('display [Eyes/Angry v]::looks')}
      >
        {`
              ${t('display [Eyes/Angry v]::looks')}
            `}
      </ScratchBlocksContainer>
    </EditContainer>
  );
};

export default Motors;
