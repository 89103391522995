import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import { IconBaseProps } from 'react-icons';

import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import getCourseProps from '../../utils/getCourseProps';

import {
  Screen,
  Container,
  AreaCourse,
  List,
  CustomCard,
  BackButton,
} from './styles';

import api from '../../services/api';
import levelsProps from '../../data/dataCourses';
import { useAuthentication } from '../../hooks/authentication';
import { Level } from '../Dashboard/styles';

interface Progress {
  completed: boolean;
}

interface Course {
  id: string;
  name: string;
}

interface Module {
  id: string;
  area_id: string;
  course_id: string;
  color?: string;
  icon?: React.ComponentType<IconBaseProps>;
  area: {
    name: string;
  };
}

interface Assignment {
  id: string;
  title: string;
  module_level_id: string;
  level?: Level;
}

interface Class {
  id: string;
  title: string;
  module_level_id: string;
  level?: Level;
}

interface Level {
  id: string;
  name: string;
  area_module_id: string;
  area_name: string;
  color: string;
  icon: React.ComponentType<IconBaseProps>;
  progress?: number;
  assignments: Assignment[];
  classes: Class[];
}

interface LevelProgress {
  completed: boolean;
  level: Level;
}

interface LevelParams {
  module_id: string;
}

const Courses: React.FC = () => {
  const { params } = useRouteMatch<LevelParams>();

  const [module, setModule] = useState<Module>();
  const [course, setCourse] = useState<Course>();
  const [levels, setLevels] = useState<Level[]>([] as Level[]);

  const { user } = useAuthentication();
  const history = useHistory();

  const { t } = useTranslation();

  useLayoutEffect(() => {
    async function loadModule(): Promise<void> {
      const dbModule: Module = await (
        await api.get(`/courses/module/${params.module_id}`)
      ).data;

      setModule(dbModule);

      const dbCourse: Course = await (
        await api.get(`/courses/${dbModule.course_id}`)
      ).data;

      setCourse(dbCourse);

      const isEnrolled = user.enrollments.some(
        enrollment => enrollment.course_id === dbCourse.id,
      );

      if (!isEnrolled) {
        // put message like "you should enroll in a course before..."
        history.push(`/courses`);
      }
    }

    async function checkModuleProgress(): Promise<void> {
      const module_progress: Module = await (
        await api.get(
          `/learn/user-module-progress/${user.id}/${params.module_id}`,
        )
      ).data;

      if (!module_progress) {
        // put message like "you should enroll in a course before..."
        history.push(`/courses`);
      }
    }

    async function loadLevels(): Promise<void> {
      const dbLevels: Level[] = await (
        await api.get(`/courses/module/levels/${params.module_id}`)
      ).data;

      const propsLevels = await Promise.all(
        dbLevels.map(dbLevel => getCourseProps(dbLevel)),
      );

      const mappedLevels = propsLevels.filter(
        (mappedLevel): mappedLevel is Level => mappedLevel !== undefined,
      );

      setLevels(mappedLevels);
    }

    loadModule();
    checkModuleProgress();
    loadLevels();
  }, [history, params.module_id, user.enrollments, user.id]);

  const formatLevelName = useCallback((name: string) => {
    return name.replaceAll(' ', '-');
  }, []);

  const createLevelProgress = useCallback(
    async (level: Level) => {
      // get in api level progresses with user_id and module_progress_id
      // get module_progress_id with state id and search inside enrolledModuleProgresses
      const module_progress: Module = await (
        await api.get(
          `/learn/user-module-progress/${user.id}/${params.module_id}`,
        )
      ).data;

      if (!module_progress) {
        // put message like "you should enroll in a course before..."
        history.push(`/courses`);
      }

      // create this route on api
      const dbLevelProgress: LevelProgress = await (
        await api.get(`/learn/user-level-progress/${user.id}/${level.id}`)
      ).data;

      // if dbLevelProgress is true, just history push, if not, create one

      if (dbLevelProgress) {
        history.push(
          `/courses/${
            course ? formatLevelName(course?.name) : 'course'
          }/${formatLevelName(level.area_name)}/${formatLevelName(level.name)}`,
        );
      }

      if (!dbLevelProgress) {
        await api.post('/learn/level-progress', {
          module_progress_id: module_progress.id,
          module_level_id: level.id,
          user_id: user.id,
        });

        history.push(
          `/courses/${
            course ? formatLevelName(course?.name) : 'course'
          }/${formatLevelName(level.area_name)}/${formatLevelName(level.name)}`,
        );
      }
    },
    [course, formatLevelName, history, params.module_id, user.id],
  );

  return (
    <Screen>
      <Container>
        <BackButton onClick={() => history.goBack()}>
          <HiArrowNarrowLeft size={30} />
        </BackButton>
        <h1>{t('Levels')}</h1>
        <List>
          {levels.map(level => (
            <button
              type="button"
              key={level.id}
              onClick={() => createLevelProgress(level)}
            >
              <CustomCard textColor={level.color} cardColor="#fff">
                <level.icon size={40} />
                <h2>{t(level.name)}</h2>
              </CustomCard>
            </button>
          ))}
        </List>
      </Container>
    </Screen>
  );
};

export default Courses;
