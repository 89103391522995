interface Case {
  name: string;
  text: string[];
  link: string;
}

const Cases: Case[] = [
  {
    name: 'RED',
    text: [
      `when program starts::hat events\nif <(3 v) is color (red v)? :: sensing boolean> then\nset status light to [red v]::looks\nend`,
    ],
    link: 'https://makecode.mindstorms.com/---run?id=_X03EXtDxqfx2',
  },
  {
    name: 'GREEN',
    text: [
      `when program starts::hat events\nif <(3 v) is color (green v)? :: sensing boolean> then\nset status light to [green v]::looks\nend`,
    ],
    link: 'https://makecode.mindstorms.com/---run?id=_hi4cH3i3vVC2',
  },
  {
    name: 'RED & GREEN',
    text: [
      `when program starts::hat events\nif <(3 v) is color (red v)? :: sensing boolean> then\nset status light to [red v]::looks\nend\nif <(3 v) is color (green v)? :: sensing boolean> then\nset status light to [green v]::looks\nend`,
      `when program starts::hat events\nif <(3 v) is color (green v)? :: sensing boolean> then\nset status light to [green v]::looks\nend\nif <(3 v) is color (red v)? :: sensing boolean> then\nset status light to [red v]::looks\nend`,
    ],
    link: 'https://makecode.mindstorms.com/---run?id=_4LRW3udWoPbW',
  },
  {
    name: 'BLANK',
    text: [
      ``,
      `when program starts::hat events`,
      `when program starts::hat events\nif <> then\nend`,
      `when program starts::hat events\nif <(3 v) is color (red v)? :: sensing boolean> then\nend`,
      `when program starts::hat events\nif <(3 v) is color (green v)? :: sensing boolean> then\nend`,
    ],
    link: 'https://makecode.mindstorms.com/---run?id=_Rm4Afx74q0L8',
  },
];

export default Cases;
