/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../../services/api';
import Interactive from '../Interactive';
import SimpleAssignment from '../SimpleAssignment';

interface Answer {
  id: string;
  placeholder: string;
  correct: boolean;
}

interface Question {
  id: string;
  placeholder: string;
  answers: Answer[];
}

interface Assignment {
  id: string;
  title: string;
  instructions: string;
  module_level_id: string;
  module_level?: Level;
  questions: Question[];
  order: number;
}

interface Level {
  id: string;
  name: string;
  area_module_id: string;
  area_name: string;
  color: string;
  progress?: number;
  assignments: Assignment[];
}

const Assignment: React.FC = () => {
  const [isSimple, setIsSimple] = useState(false);
  const [fetched, setFetched] = useState(false);

  const { pathname } = useLocation();

  const reformatLevelName = useCallback((name: string) => {
    return name.replaceAll('-', ' ');
  }, []);

  useEffect(() => {
    async function loadContent(): Promise<void> {
      const contentName = pathname
        .substr(pathname.lastIndexOf('/') + 1)
        .replaceAll('-', ' ');

      const courseName = pathname.split('/')[3];
      const areaName = pathname.split('/')[4];
      const levelName = pathname.split('/')[5];

      const responseLevel: Level = await (
        await api.get(
          `/courses/levels/${reformatLevelName(courseName)}/${reformatLevelName(
            areaName,
          )}/${reformatLevelName(levelName)}`,
        )
      ).data;

      const response = await api.get(
        `/courses/content/${contentName}/${responseLevel.id}`,
      );

      if (response.data.assignment_type.type === 'quiz') {
        setIsSimple(true);
      }

      setFetched(true);
    }

    loadContent();
  }, [pathname, reformatLevelName]);

  // return isSimple ? <SimpleAssignment /> : <Interactive />;
  return !fetched ? <></> : isSimple ? <SimpleAssignment /> : <Interactive />;
};

export default Assignment;
