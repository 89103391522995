interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/ircontrol.png',
    title: 'IR Remote',
    text:
      'It allows users to control their robots using an infrared (IR) remote control. Similar to controlling your television with a remote control, when a button is pressed, a signal is sent to the robot, which interprets it and performs the corresponding action. This control can make the robot move in different directions, activate sensors, or perform other tasks.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/ev3.png',
    title: 'EV3',
    text:
      'Similar to the Spike Hub, the EV3 component is a central piece of LEGO robots! It is a small, brick-shaped device that connects to various sensors, motors, and other LEGO elements.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/spike-hub.png',
    title: 'SPIKE Hub',
    text:
      "The Spike Prime Hub serves as the central control unit of the Spike Prime system. It acts as the robot's brain, allowing it to think, move, and perform different tasks. This component interprets the instructions sent by you through programming and makes the other pieces, such as motors and sensors, execute their functions. It has ports to connect the other components to it, buttons to add functions while it is performing a task, and lights that can be used to display important information.",
  },
];

export default Cards;
