import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../hooks/authentication';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import Button from '../Button';
import { Container } from './styles';

interface FileProps {
  avatar?: File;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AvtEditor: React.FC<FileProps> = ({ avatar, setIsOpen }) => {
  const [scale, setScale] = useState(1);

  const editor = useRef<AvatarEditor>(null);

  const { t } = useTranslation();

  const { updateUser } = useAuthentication();
  const { addToast } = useToast();

  const handleScale = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const scaleParse = parseFloat(e.target.value);
    setScale(scaleParse);
  }, []);

  return (
    <Container>
      <AvatarEditor
        ref={editor}
        image={
          avatar ||
          'https://i.pinimg.com/222x/57/70/f0/5770f01a32c3c53e90ecda61483ccb08.jpg'
        }
        width={200}
        height={200}
        border={20}
        scale={scale}
        borderRadius={100}
      />
      <input
        name="scale"
        type="range"
        onChange={handleScale}
        min="1"
        max="2"
        step="0.01"
        defaultValue="1"
        className="slider"
      />
      <Button
        onClick={() => {
          if (editor.current) {
            editor.current.getImageScaledToCanvas().toBlob(blob => {
              if (blob) {
                const maxSize = 1024 * 1024;

                const file = new File([blob], `profilePicture.png`, {
                  type: 'image/png',
                });

                if (file.size > maxSize) {
                  addToast({
                    type: 'error',
                    label: t('Max image size: 1MB'),
                  });
                } else {
                  const formData = new FormData();

                  formData.append('avatar', file);

                  api.put('/profile', formData).then(response => {
                    updateUser(response.data);

                    addToast({
                      type: 'success',
                      label: t('Avatar updated!'),
                    });

                    setIsOpen(false);
                  });
                }
              }
            });
          }
        }}
      >
        {t('Save')}
      </Button>
    </Container>
  );
};

export default AvtEditor;
