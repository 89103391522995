import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { ClassContainer } from './styles';

const WhatIsLEGO: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Introduction')}</h1>
      <p>
        {t(
          "Within the broad spectrum of robotics fields, electrics serves as the bridge between programming and mechanics, enabling the robot to move and perform tasks. In other words, without electrics, the programming lacks the energy needed to drive the robot's mechanisms. For this reason, it's crucial to establish a secure and stable electrical system, always striving to maintain component organization.",
        )}
      </p>
      <p>
        {t(
          "Our goal is to understand the various electrical components of the robot and learn how to organize them. The challenging part is that we can't always apply the same standard to all robots, so we need to be creative to make the system organized and aesthetically pleasing.",
        )}
      </p>
    </ClassContainer>
  );
};

export default WhatIsLEGO;
