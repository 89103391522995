import styled from 'styled-components';

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100%;
  max-height: 100%;

  padding: 5rem;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;

  & > svg {
    margin-bottom: 1.5rem;
    width: 80%;
  }

  h1 {
    margin-bottom: 1rem;

    font-size: 2.5rem;

    color: #051227;
    font-weight: 600;
  }

  p {
    font-size: 1.2rem;
  }

  a {
    text-decoration: none;

    button {
      display: flex;
      align-items: center;
      width: auto;
      padding: 0.5rem 1.5rem;
    }
  }
`;
