import React, { useState } from 'react';

import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { FaChevronRight } from 'react-icons/fa';

import { useMediaQuery } from 'react-responsive';
import Arrow from '..';

const ArrowRight: React.FC = () => {
  const mobileOrTablet = useMediaQuery({ query: '(max-width: 1224px)' });

  const {
    isLastItemVisible,
    scrollNext,
    visibleItemsWithoutSeparators,
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible,
  );
  React.useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()}>
      <FaChevronRight size={mobileOrTablet ? 20 : 40} />
    </Arrow>
  );
};

export default ArrowRight;
