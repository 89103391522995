import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useLayoutEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import api from '../services/api';

import { useToast } from './toast';

interface ModuleProgress {
  id: string;
  enrollment_id: string;
  user_id: string;
  area_module_id: string;
  completed: boolean;
}

interface Enrollment {
  id: string;
  user_id: string;
  course_id: string;
  module_progresses: ModuleProgress[];
}

interface Role {
  id: string;
  name: string;
}

interface Flag {
  id: string;
  name: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
  goal: number;
  flags: Flag[];
  roles: Role[];
  permissions: Role[];
  enrollments: Enrollment[];
}

interface AuthenticationState {
  token: string;
  user: User;
}

interface SignInCredencials {
  email: string;
  password: string;
}

interface AuthenticationContextData {
  user: User;
  signIn(credencials: SignInCredencials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

// {} as AuthenticationContext - to remove error and pass inicial value with no data/null
const AuthenticationContext = createContext<AuthenticationContextData>(
  {} as AuthenticationContextData,
);

const AuthenticationProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const { t } = useTranslation();

  const [data, setData] = useState<AuthenticationState>(() => {
    const token = localStorage.getItem('@LearnFirst:token');
    const user = localStorage.getItem('@LearnFirst:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthenticationState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('sessions', {
      email,
      password,
    });

    const { token, user } = response.data;

    localStorage.setItem('@LearnFirst:token', token);
    localStorage.setItem('@LearnFirst:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@LearnFirst:token');
    localStorage.removeItem('@LearnFirst:user');

    setData({} as AuthenticationState);
  }, []);

  const updateUser = useCallback(
    (updatedUser: User) => {
      localStorage.setItem('@LearnFirst:user', JSON.stringify(updatedUser));

      setData({
        token: data.token,
        user: updatedUser,
      });
    },
    [data.token],
  );

  useLayoutEffect(() => {
    api.interceptors.response.use(
      response => response,
      err => {
        const url = new URL(err.config.url, err.config.baseURL);

        if (err.response.status === 401 && url.pathname !== '/sessions') {
          const token = localStorage.getItem('@LearnFirst:token');
          const user = localStorage.getItem('@LearnFirst:user');

          if (token && user) {
            addToast({
              type: 'error',
              label: t('Your session has expired'),
              description: t('Please, sign in again.'),
            });

            signOut();
          }
        }

        return Promise.reject(err);
      },
    );
  }, [addToast, signOut, t]);

  return (
    <AuthenticationContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

function useAuthentication(): AuthenticationContextData {
  const context = useContext(AuthenticationContext);

  return context;
}

export { AuthenticationProvider, useAuthentication };
