import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';

import { IconBaseProps } from 'react-icons';

import { useTranslation } from 'react-i18next';
import {
  HiArrowNarrowLeft,
  HiChevronDoubleLeft,
  HiOutlineInboxIn,
} from 'react-icons/hi';
import { useMediaQuery } from 'react-responsive';
import api from '../../services/api';
import { useAuthentication } from '../../hooks/authentication';
import dataCourses from '../../data/dataCourses';

import {
  Screen,
  CourseCard,
  GoButton,
  DetailContainer,
  MobileContainer,
  BackButton,
} from './styles';
import ArrowLeft from '../../components/ArrowLibrary/ArrowLeft';
import ArrowRight from '../../components/ArrowLibrary/ArrowRight';

interface Course {
  id: string;
  name: string;
}

interface Module {
  id: string;
  area_id: string;
  course_id: string;
  color?: string;
  icon?: React.ComponentType<IconBaseProps>;
  area: {
    name: string;
  };
}

interface ModuleParams {
  course_id: string;
}

const Modules: React.FC = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const { params } = useRouteMatch<ModuleParams>();

  const [course, setCourse] = useState<Course>();
  const [modules, setModules] = useState<Module[]>([] as Module[]);

  const { user, updateUser } = useAuthentication();
  const history = useHistory();

  const { t } = useTranslation();

  useLayoutEffect(() => {
    async function loadCourse(): Promise<void> {
      const dbCourse: Course = await (
        await api.get(`/courses/${params.course_id}`)
      ).data;

      setCourse(dbCourse);

      const isEnrolled = user.enrollments.some(
        enrollment => enrollment.course_id === dbCourse.id,
      );

      if (!isEnrolled) {
        // put message like "you should enroll in a course before..."
        history.push(`/courses`);
      }
    }

    async function loadModules(): Promise<void> {
      const dbModules: Module[] = await (
        await api.get(`/courses/course/modules/${params.course_id}`)
      ).data;

      const frontModules = dataCourses.find(data => data.name === course?.name)
        ?.modules;

      const mappedModules = dbModules.map(dm => {
        const matchModule = frontModules?.find(md => md.name === dm.area.name);
        return matchModule
          ? { ...dm, color: matchModule.color, icon: matchModule.icon }
          : dm;
      });

      setModules(mappedModules);
    }

    loadCourse();
    loadModules();
  }, [course?.id, course?.name, history, params.course_id, user.enrollments]);

  // info: creates module_progress
  const createModuleProgress = useCallback(
    async module_id => {
      const module_progress: Module = await (
        await api.get(`/learn/user-module-progress/${user.id}/${module_id}`)
      ).data;

      if (module_progress) {
        history.push(`/levels/${module_id}`);
      } else {
        await api.post('/learn/module-progress', {
          enrollment_id: user.enrollments.find(
            enrollment => enrollment.course_id === course?.id,
          )?.id,
          area_module_id: module_id,
          user_id: user.id,
        });

        history.push(`/levels/${module_id}`);
      }
    },
    [course?.id, history, user],
  );

  return (
    <Screen>
      <BackButton onClick={() => history.goBack()}>
        <HiArrowNarrowLeft size={30} />
      </BackButton>
      <h1>{t('Modules')}</h1>
      {!isTabletOrMobile ? (
        <ScrollMenu LeftArrow={ArrowLeft} RightArrow={ArrowRight}>
          {modules.map(module => (
            <CourseCard itemId={module.id} key={module.id} color={module.color}>
              <DetailContainer color={module.color}>
                {module.icon && (
                  <div>
                    <module.icon size={window.innerWidth >= 1501 ? 70 : 60} />
                  </div>
                )}
              </DetailContainer>
              <h2>{t(module.area.name)}</h2>
              <GoButton
                color={module.color}
                onClick={() => createModuleProgress(module.id)}
              >
                <HiOutlineInboxIn size={window.innerWidth >= 1501 ? 30 : 20} />
              </GoButton>
            </CourseCard>
          ))}
        </ScrollMenu>
      ) : (
        <MobileContainer>
          {modules.map(module => (
            <CourseCard itemId={module.id} key={module.id} color={module.color}>
              <DetailContainer color={module.color}>
                {module.icon && (
                  <div>
                    <module.icon size={window.innerWidth >= 1501 ? 70 : 60} />
                  </div>
                )}
              </DetailContainer>
              <h2>{t(module.area.name)}</h2>
              <GoButton
                color={module.color}
                onClick={() => createModuleProgress(module.id)}
              >
                <HiOutlineInboxIn size={window.innerWidth >= 1501 ? 30 : 20} />
              </GoButton>
            </CourseCard>
          ))}
        </MobileContainer>
      )}
    </Screen>
  );
};

export default Modules;
