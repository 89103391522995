import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  span {
    position: absolute;
    bottom: calc(100% + 12px);

    width: 160px;
    padding: 8px;
    border-radius: 4px;

    background: #ff9000;
    color: #312e38;

    font-size: 14px;
    font-weight: 500;

    opacity: 0;
    transition: opacity 0.3s ease-in;
    visibility: hidden;

    @media (min-width: 800px) {
      left: 50%;
      transform: translateX(-50%);

      &::before {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);

        border-style: solid;
        border-color: #ff9000 transparent;
        border-width: 6px 6px 0 6px;

        content: '';
      }
    }

    @media (max-width: 800px) {
      right: 0;
    }
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`;
