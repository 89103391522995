import styled, { css } from 'styled-components';

interface PortProps {
  active: boolean;
  correct: boolean;
  verifyMode: boolean;
}

interface CategoryProps {
  active: boolean;
}

interface selectModeProps {
  $on: boolean;
}

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  max-height: 100%;
  background: #fff;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  width: 100%;
  height: 100%;
`;

export const WiresContainer = styled.div`
  display: flex;

  margin-bottom: 2rem;
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  button {
    margin-top: 2rem;

    @media (max-width: 600px) {
      margin-top: 1rem;
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d62d1;
  padding: 1rem;
  color: #ffffff;
`;

export const ItemsContainer = styled.div`
  display: flex;
  align-items: center;
  background: #1d62d1;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  color: #fff;
  padding: 0 3rem;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0.5rem;
    margin: 0 0.75rem;

    p {
      font-size: 0.75rem;
      color: #e6e6e6;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    @media (max-width: 600px) {
      padding: 1rem 0;
    }
  }

  @media (max-width: 600px) {
    padding: 0 0.5rem;
  }
`;

export const Unselect = styled.div`
  background: #fff;
  border-radius: 2rem;
  color: #1d62d1;
  cursor: pointer;
`;

export const Category = styled.div<CategoryProps>`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  ${props =>
    props.active &&
    css`
      color: #f6ae2d;
    `}
`;

export const RoboRIO = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #858b8f;
  border-radius: 2rem;
  padding: 1rem;
  width: 500px;
  height: 500px;
  position: relative;

  .logo {
    width: 80px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  @media (max-width: 1000px) {
    width: 350px;
    height: 350px;
    max-width: 95%;
  }

  @media (max-width: 1400px) {
    width: 400px;
    height: 400px;
  }
`;

export const Ports = styled.div`
  display: flex;

  .input {
    width: 40px;
    height: auto;
    margin-right: 1rem;
    align-self: center;
  }
`;

export const CenterPorts = styled.div<selectModeProps>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: #858b8f;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 1rem;
  padding: 1rem;

  > div + div {
    margin-left: 1rem;
  }

  div {
    transition: all 0.3s ease-in-out;
    border: 3px solid ${props => (props.$on ? '#1d62d1' : 'transparent')};
    ${props =>
      props.$on &&
      css`
        cursor: pointer;
      `};
  }

  #powerPort {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 0.25rem;

    img {
      width: 30px;
      height: auto;
    }
  }

  #updatePort {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: #cfcfcf;
    padding: 0.25rem;
    border-radius: 0.25rem;

    img {
      width: 40px;
      height: auto;
    }
  }

  #sdPort {
    height: 100%;
    width: 15px;
    border-radius: 0.25rem;
    background: #333333;
  }

  #webcamPort {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 0.25rem;

    img {
      width: 40px;
      height: auto;
    }
  }

  #radioPort {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: #cfcfcf;
    padding: 1rem 0.25rem 0.25rem;
    border-radius: 0.25rem;

    img {
      width: 40px;
      height: auto;
    }
  }
`;

export const Port = styled.div<PortProps>`
  ${props =>
    props.active &&
    css`
      border-color: #f6ae2d !important;
    `}

  ${props =>
    props.verifyMode &&
    props.correct &&
    css`
      border-color: #75c375 !important;
    `}

  ${props =>
    props.verifyMode &&
    !props.correct &&
    css`
      border-color: #c53030 !important;
    `}
`;
