import React, { useEffect, useState } from 'react';

import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { IconBaseProps } from 'react-icons';

import { useTranslation } from 'react-i18next';
import { HiOutlineHeart, HiOutlineLogin } from 'react-icons/hi';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import api from '../../services/api';
import dataCategories from '../../data/dataCategories';

import ArrowRight from '../../components/ArrowLibrary/ArrowRight';
import ArrowLeft from '../../components/ArrowLibrary/ArrowLeft';

import {
  Screen,
  FavoritesContainer,
  CategoryContainer,
  MobileContainer,
} from './styles';
import { useAuthentication } from '../../hooks/authentication';

interface Category {
  id: string;
  name: string;
  icon: React.ComponentType<IconBaseProps>;
}

interface CategoryParams {
  course_id: string;
}

const Library: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' });

  const [categories, setCategories] = useState<Category[]>([] as Category[]);
  const [activeIndex, setActiveIndex] = useState(0);

  const { t } = useTranslation();
  const { params } = useRouteMatch<CategoryParams>();
  const { user } = useAuthentication();
  const history = useHistory();

  useEffect(() => {
    async function loadCategories(): Promise<void> {
      const response = await api.get(`/library/categories/${params.course_id}`);

      if (response.data.length === 0) {
        history.push(`/library`);
      }

      const mappedCategories: Category[] = await Promise.all(
        response.data.map(async (category: Category) => {
          const dataFind = dataCategories.find(
            data => data.name === category.name,
          );

          return {
            ...category,
            icon: dataFind?.icon,
          };
        }),
      );

      setCategories(mappedCategories);
    }

    loadCategories();
  }, [history, params.course_id]);

  return (
    <Screen>
      {user ? (
        <Link to="/library/favorites">
          <FavoritesContainer>
            <HiOutlineHeart size={24} />
          </FavoritesContainer>
        </Link>
      ) : (
        <Link to="/">
          <FavoritesContainer>
            <HiOutlineLogin size={24} />
          </FavoritesContainer>
        </Link>
      )}
      {isMobile ? (
        <MobileContainer>
          {categories.map((category, index) => (
            <CategoryContainer
              to={`/library/category/${category.id}`}
              itemId={category.id}
              active={index === activeIndex}
              onMouseEnter={() => setActiveIndex(index)}
              key={category.id}
            >
              <category.icon size={80} />
              <p>{t(category.name)}</p>
            </CategoryContainer>
          ))}
        </MobileContainer>
      ) : (
        <ScrollMenu LeftArrow={ArrowLeft} RightArrow={ArrowRight}>
          {categories.map((category, index) => (
            <CategoryContainer
              to={`/library/category/${category.id}`}
              itemId={category.id}
              active={index === activeIndex}
              onMouseEnter={() => setActiveIndex(index)}
              key={category.id}
            >
              <category.icon size={80} />
              <p>{t(category.name)}</p>
            </CategoryContainer>
          ))}
        </ScrollMenu>
      )}
    </Screen>
  );
};

export default Library;
