import {
  HiOutlineCog,
  HiOutlineLibrary,
  HiOutlineViewGrid,
  HiOutlineAcademicCap,
  HiOutlineIdentification,
  HiOutlinePencilAlt,
} from 'react-icons/hi';

const SidebarItems = [
  {
    name: 'Dashboard',
    mainRoute: '/dashboard',
    routes: ['/dashboard', '/notifications', 'achievements'],
    icon: HiOutlineViewGrid,
  },
  {
    name: 'Library',
    mainRoute: '/library',
    routes: ['/library', '/library/category/', '/library/favorites'],
    icon: HiOutlineLibrary,
  },
  {
    name: 'Courses',
    mainRoute: '/courses',
    routes: [
      '/course',
      '/courses',
      '/levels',
      '/courses/levels/Mechanics/A1',
      '/courses/levels/Mechanics/A2',
      '/courses/levels/Electric/A1',
      '/courses/Programming/B2/Basic-Limelight-programming',
      '/finished-level',
    ],
    icon: HiOutlineAcademicCap,
  },
  {
    name: 'Teach',
    mainRoute: '/teach',
    routes: ['/teach', '/teach/level'],
    icon: HiOutlinePencilAlt,
    role: 'teacher',
  },
  {
    name: 'Profile',
    mainRoute: '/profile',
    routes: ['/profile'],
    icon: HiOutlineIdentification,
    mobile: true,
  },
  {
    name: 'Settings',
    mainRoute: '/settings',
    routes: ['/settings'],
    icon: HiOutlineCog,
  },
];

export default SidebarItems;
