const Tabs = [
  {
    name: 'Events',
  },
  {
    name: 'Motors',
  },
];

export default { Tabs };
