import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    outline: 0;
  }

  body {
    background: #fff;
    color: #6f7380;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;

    #root {
      display: flex;

      @media (max-width: 800px) {
        flex-direction: column-reverse;
      }
    }
  }

  html, body, #root {
    height: 100%;
  }

  body, input, button {
    /* font-family: 'Inter', sans-serif; */
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }
`;
