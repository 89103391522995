import styled, { css } from 'styled-components';

import { shade } from 'polished';
import Card from '../../components/Card';

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  width: 100vw;
  height: 100%;

  padding: 4rem;

  overflow-y: scroll;

  @media (max-width: 1224px) {
    padding: 3rem 2rem;
  }

  @media (max-width: 800px) {
    padding: 2rem 1.5rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  width: 100%;

  & > h1 {
    margin-bottom: 3.5rem;

    color: #051227;

    font-size: 2rem;
    font-weight: 500;
  }
`;

export const AreaCourse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & + & {
    margin-top: 3rem;
  }

  h2 {
    margin-bottom: 2rem;
  }
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;

  button {
    margin-top: 2rem;
    margin-right: 2rem;
    text-decoration: none;
    background: none;
    border: 0;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;

    button {
      margin: 0 !important;
    }

    button + button {
      margin-top: 2rem !important;
    }
  }
`;

interface CustomCardProps {
  textColor?: string;
}

export const CustomCard = styled(Card)<CustomCardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 200px;
  height: 250px;
  border: 2px solid;

  ${props =>
    props.textColor &&
    css`
      color: ${props.textColor};
    `}

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  svg {
    margin-top: 1rem;
    margin-bottom: 2rem;

    @media (max-width: 800px) {
      margin-top: 0;
    }
  }

  h1 {
    margin-bottom: 0;
  }

  &:hover {
    border: 2px solid;

    transform: scale(1.05);
  }

  ${props =>
    props.textColor &&
    css`
      &:hover {
        background: ${props.textColor};

        > * {
          color: #fff;
        }
      }
    `}

  @media (max-width: 800px) {
    width: 100%;
    height: auto;
  }
`;

export const BackButton = styled.div`
  display: inline-flex;
  margin-bottom: 0.5rem;
  cursor: pointer;
  color: #051227;

  &:hover {
    color: ${shade(0.3, '#051227')};
  }
`;
