import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isErrored: boolean;
  gridIndex: number;
}

export const OptionsContainer = styled.div``;

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  grid-row-start: ${props => `${props.gridIndex + 1}`};
  grid-row-end: ${props => `${props.gridIndex + 2}`};
  grid-column-start: 1;
  grid-column-end: 2;

  input {
    -webkit-appearance: none;
    position: absolute;
  }

  label {
    display: flex;
    align-items: center;
    transition: 0.2s ease-in-out;
    cursor: pointer;

    svg {
    }
  }

  input:checked + label {
    color: #75c375;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-right: 0.5rem;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
