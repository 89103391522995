import React, { useEffect } from 'react';

import { FaCheckCircle, FaInfoCircle } from 'react-icons/fa';
import { CgClose } from 'react-icons/cg';
import { BsFillExclamationDiamondFill } from 'react-icons/bs';
import { ToastMessage, useToast } from '../../../hooks/toast';

import { Container, IconContainer } from './styles';

interface ToastProps {
  message: ToastMessage;
  style: object;
}

const icons = {
  info: <FaInfoCircle size={24} />,
  error: <BsFillExclamationDiamondFill size={24} />,
  success: <FaCheckCircle size={24} />,
};

const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 3000);

    // Cancel timer if toast has been removed
    return () => {
      clearTimeout(timer);
    };
  }, [message.id, removeToast]);

  return (
    <Container
      type={message.type}
      /* hasDescription={Number(!!message.description)} */
      style={style}
    >
      <IconContainer>{icons[message.type || 'info']}</IconContainer>
      <div>
        <strong>{message.label}</strong>
        {message.description && <p>{message.description}</p>}
      </div>

      <button onClick={() => removeToast(message.id)} type="button">
        <CgClose size={24} />
      </button>
    </Container>
  );
};

export default Toast;
