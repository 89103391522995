import React from 'react';
import { useTranslation } from 'react-i18next';

import TabPanel from '../../components/TabPanel';

import { Screen, Container } from './styles';

const Settings: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Screen>
      <Container>
        <h1>{t('Settings')}</h1>
        <TabPanel />
      </Container>
    </Screen>
  );
};

export default Settings;
