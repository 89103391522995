import styled, { css } from 'styled-components';

interface AnswerProps {
  checked: boolean;
  incorrect?: boolean;
}

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-between; */

  width: 100vw;
  height: 100%;
  max-height: 100%;
  background: #fff;

  padding: 5rem;

  padding: 5rem 5rem 0;
  overflow-y: scroll;

  @media (max-width: 1224px) {
    padding: 5rem 3rem;
  }

  @media (max-width: 800px) {
    padding: 2rem 1.5rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 60%;

  ::-webkit-scrollbar {
    display: none;
  }

  button {
    margin-bottom: 2rem;
  }

  @media (max-width: 1500px) {
    width: 90%;
  }

  @media (max-width: 1224px) {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  @media (max-width: 1224px) {
    width: 100%;

    button {
      width: 100% !important;
    }
  }
`;

export const AssignmentHeader = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 2rem;

  h1 {
    color: #051227;
    margin-bottom: 1rem;
  }
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
  background: #f1f3f6;
  border-radius: 1.5rem;
  padding: 2rem 3rem;

  h2 {
    font-size: 1.5rem;
    color: #1e223c;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  @media (max-width: 1224px) {
    padding: 2rem 1.5rem;
  }
`;

export const Answer = styled.div<AnswerProps>`
  display: grid;
  grid-template: 1fr / 5% auto;
  padding: 1rem;
  border-radius: 0.5rem;
  background: #fff;
  color: #1e223c;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;

  &:hover {
    background: #d2d3d8;
  }

  svg {
    grid-area: 1 / 1 / 2 / 2;
    align-self: center;
  }

  & + & {
    margin-top: 1rem;
  }

  ${props =>
    props.checked &&
    css`
      background-color: #75c375;
      color: #fff;

      &:hover {
        background: #5e9c5e;
      }
    `}

  ${props =>
    props.incorrect &&
    css`
      background-color: #c53030;
      color: #fff;

      &:hover {
        background: #9e2626;
      }
    `}

    @media (max-width: 1224px) {
    padding: 1rem 0.5rem;
    grid-template: 1fr / 10% auto;
  }
`;

export const AnswerContent = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  align-items: center;
`;

export const QuestionImage = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  width: 30%;
  align-self: center;

  img {
    position: relative;
    width: 100%;
    height: auto;
  }

  @media (max-width: 800px) {
    width: 60%;
  }
`;

export const AnswerImage = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 80px;
  margin-right: 2rem;

  img {
    max-width: 100%;
    height: 100%;
  }

  @media (max-width: 1224px) {
    height: 40px;
    margin-right: 0.75rem;
  }
`;
