import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HiCheckCircle, HiXCircle } from 'react-icons/hi';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../components/Button';
import { useAuthentication } from '../../hooks/authentication';
import api from '../../services/api';

import {
  Screen,
  Container,
  Answer,
  QuestionContainer,
  AssignmentHeader,
  AnswerImage,
  QuestionImage,
  AnswerContent,
  ButtonContainer,
} from './styles';

interface Level {
  id: string;
  name: string;
  area_module_id: string;
  area_name: string;
  color: string;
  progress?: number;
  assignments: Assignment[];
}

interface Answer {
  id: string;
  placeholder: string;
  image?: string;
  correct: boolean;
}

interface Question {
  id: string;
  placeholder: string;
  image?: string;
  answers: Answer[];
}

interface Assignment {
  id: string;
  title: string;
  instructions: string;
  module_level_id: string;
  module_level?: Level;
  questions: Question[];
  order: number;
}

interface QuestionData {
  question: Question;
  answer: Answer;
}

interface StateProps {
  content: Assignment;
  data: QuestionData[];
  nextContent: string;
}

interface AssignmentProgress {
  id: string;
  completed: boolean;
}

interface LevelProgress {
  id: string;
  completed: boolean;
  level: Level;
}

const AssignmentReview: React.FC = () => {
  const { user } = useAuthentication();
  const history = useHistory();

  const { state } = useLocation<StateProps>();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const reformatLevelName = useCallback((name: string) => {
    return name.replaceAll('-', ' ');
  }, []);

  useEffect(() => {
    async function loadAssignment(): Promise<void> {
      // check if user has level progress (if not - shouldn't be here)
      const courseName = pathname.split('/')[3];
      const areaName = pathname.split('/')[4];
      const levelName = pathname.split('/')[5];

      const responseLevel: Level = await (
        await api.get(
          `/courses/levels/${reformatLevelName(courseName)}/${reformatLevelName(
            areaName,
          )}/${reformatLevelName(levelName)}`,
        )
      ).data;

      const dbLevelProgress: LevelProgress = await (
        await api.get(
          `/learn/user-level-progress/${user.id}/${responseLevel?.id}`,
        )
      ).data;

      if (!dbLevelProgress) {
        // put message like "you should enroll in a course before..."
        history.push(`/levels/${responseLevel?.area_module_id}`);
      } else {
        const contentName = pathname
          .substr(pathname.lastIndexOf('/') + 1)
          .replaceAll('-', ' ');

        const dbAssignment: Assignment = await (
          await api.get(`/courses/content/${contentName}/${responseLevel.id}`)
        ).data;

        const dbAssignmentProgress: AssignmentProgress = await (
          await api.get(
            `/learn/user-content-progress/${user.id}/${dbAssignment.id}`,
          )
        ).data;
        if (!dbAssignmentProgress) {
          history.push(`/levels/${responseLevel?.area_module_id}`);
        }
      }
    }

    loadAssignment();
  }, [history, pathname, reformatLevelName, user.id]);

  const isIncorrect = useCallback(
    (answer: Answer, index: number) => {
      const incorrect =
        answer.placeholder === state.data[index].answer.placeholder &&
        !answer.correct;

      return incorrect;
    },
    [state?.data],
  );

  const formatLevelName = useCallback((name: string) => {
    return name.replaceAll(' ', '-');
  }, []);

  const handleNextContent = useCallback(async () => {
    const dbAssignmentProgress: AssignmentProgress = await (
      await api.get(
        `/learn/user-content-progress/${user.id}/${state.content.id}`,
      )
    ).data;

    if (!dbAssignmentProgress.completed) {
      await api.put('/courses/assignment/complete', {
        assignment_id: state.content?.id,
        user_id: user.id,
      });
    }

    const isLevelFinished = await (
      await api.get(
        `/courses/level-status/${state.content?.module_level_id}/${user.id}`,
      )
    ).data;

    if (isLevelFinished) {
      history.push('/finished-level');
    } else if (state.nextContent) {
      history.push(state.nextContent);
    } else {
      const courseName = pathname.split('/')[3];
      const areaName = pathname.split('/')[4];
      const levelName = pathname.split('/')[5];

      history.push(
        `/courses/${formatLevelName(courseName)}/${areaName}/${formatLevelName(
          levelName,
        )}`,
      );
    }
  }, [
    formatLevelName,
    history,
    pathname,
    state.content.id,
    state.content?.module_level_id,
    state.nextContent,
    user.id,
  ]);

  if (!state) {
    history.push('/courses');
    return null;
  }

  return (
    <Screen>
      <Container>
        <AssignmentHeader>
          {state.content && (
            <>
              <h1>{t(state.content.title)}</h1>
              <h3>{t(state.content.instructions)}</h3>
            </>
          )}
        </AssignmentHeader>
        {state.content ? (
          state.content.questions.map((question, index) => (
            <QuestionContainer key={question.id}>
              <h2>{t(question.placeholder)}</h2>
              {question.image && (
                <QuestionImage>
                  <img src={`https://${t(question.image)}`} alt="img" />
                </QuestionImage>
              )}
              {question.answers.map(answer => (
                <Answer
                  checked={answer.correct}
                  incorrect={isIncorrect(answer, index)}
                  key={answer.id}
                >
                  {answer.correct && <HiCheckCircle size={24} />}
                  {isIncorrect(answer, index) && <HiXCircle size={24} />}
                  <AnswerContent>
                    {answer.image && (
                      <AnswerImage>
                        <img src={`https://${t(answer.image)}`} alt="img" />
                      </AnswerImage>
                    )}
                    {answer.placeholder && <p>{t(answer.placeholder)}</p>}
                  </AnswerContent>
                </Answer>
              ))}
            </QuestionContainer>
          ))
        ) : (
          <h1>{t('Hi! You need to take an assignment to get here!')}</h1>
        )}

        {state.data.every(dt => dt.answer.correct) ? (
          <ButtonContainer>
            <Button
              onClick={handleNextContent}
              style={{
                marginTop: '0.5rem',
                width: 'auto',
                padding: '0.5rem 1rem',
              }}
            >
              {t('Next')}
            </Button>
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            <Button
              onClick={() => history.goBack()}
              style={{
                marginTop: '0.5rem',
                width: 'auto',
                padding: '0.5rem 1rem',
              }}
            >
              {t('Try Again')}
            </Button>
          </ButtonContainer>
        )}
      </Container>
    </Screen>
  );
};

export default AssignmentReview;
