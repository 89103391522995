import styled, { css } from 'styled-components';
import Class from '../../../../../Class';

interface ContainerProps {
  showNextButton?: boolean;
}

export const ClassContainer = styled(Class)<ContainerProps>`
  & > h1,
  h2,
  & > p {
    margin-bottom: 2rem;
  }
  position: relative;

  h1:first-child {
    margin-bottom: 3rem;

    color: #051227;

    font-size: 2rem;
    font-weight: 600;
  }

  .modalStartText {
    font-weight: 600;
    color: #051227;
    margin-bottom: 80px;
    align-self: center;
  }

  ${props =>
    !props.showNextButton &&
    css`
      .nextClassButton {
        visibility: hidden;
      }
    `}

  ${props =>
    props.showNextButton &&
    css`
      .modalStartText {
        visibility: hidden;
      }
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > h1,
  h2,
  & > p {
    margin-bottom: 2rem;
  }
  position: relative;

  h1:first-child {
    margin-bottom: 3rem;

    color: #051227;

    font-size: 2rem;
    font-weight: 600;
  }

  .modalStartText {
    font-weight: 600;
    color: #051227;
    margin-bottom: 9rem;
  }

  .lf-player-container {
    /* align-self: center; */
  }

  .item {
  }
`;
