import ScratchBlocks from 'scratchblocks-react';
import styled from 'styled-components';

export const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > :not(:first-child) {
    margin-top: 1rem;
  }

  h4 {
    color: #051227;
  }

  @media only screen and (max-width: 1500px) {
    overflow-y: auto;
    height: 100%;
  }

  @media (max-width: 1500px) {
    & > :not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  & > div {
    cursor: pointer;
  }
`;

export const ScratchBlocksContainer = styled(ScratchBlocks)`
  svg {
    width: 100%;
  }
`;
