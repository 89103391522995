interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'events/b9.png',
    title: 'Timer',
    text:
      'With this block, when the timer reaches the specified time, the code will be executed. It has a parameter indicating the time you want the robot to wait before starting.',
  },
  {
    image: 'events/b8.png',
    title: 'Receive Message',
    text:
      'With this block, when you receive a message, the code will be executed. It has a parameter indicating the text you want to receive in the message.',
  },
  {
    image: 'events/b7.png',
    title: 'Send Message',
    text:
      'This block can be used to send a message to the robot itself. This tool is typically used to inform the robot that a certain action has occurred. It has a parameter indicating the text you want to send in the message.',
  },
  {
    image: 'events/b6.png',
    title: 'Customized When',
    text:
      'With this block, you can create your own condition for the code to be executed, by adding the blocks in hexagon format inside this event block.',
  },
  {
    image: 'events/b5.png',
    title: 'Button Pressed',
    text:
      'With this block, as soon as the button is pressed or released, the code will be executed. It has two parameters: the first one is the button to be triggered, and the second one is the chosen condition.',
  },
  {
    image: 'events/b4.png',
    title: 'Measure Distance',
    text:
      'With this block, as soon as the distance between the presence sensor (ultrasonic) and an object is less than, greater than, or equal to the chosen threshold, the code will be executed. It has four parameters: the first one is the port to which the sensor is connected, the second one is the chosen condition, the third one is the desired measurement, and the fourth one is the unit (centimeters or inches).',
  },
  {
    image: 'events/b3.png',
    title: 'On Pressing',
    text:
      'With this block, as soon as the touch sensor button is pressed or released, the code will be executed. It has two parameters: the first one is the port to which the sensor is connected, and the second one is the action to perform when the event occurs.',
  },
  {
    image: 'events/b2.png',
    title: 'Determining Colors',
    text:
      'With this block, as soon as the color sensor detects the specified color, the code will be executed. It has two parameters: the first one is the port to which the sensor is connected, and the second one is the chosen color.',
  },
  {
    image: 'events/b1.png',
    title: 'Starting Program',
    text:
      'With this block, your code will be started as soon as it is selected from the file list on your robot.',
  },
];

export default Cards;
