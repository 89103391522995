/* eslint-disable import/no-duplicates */
import React, { useEffect, useMemo, useState } from 'react';
import { parseISO, formatDistance } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

import api from '../../services/api';
import { useAuthentication } from '../../hooks/authentication';

import {
  Screen,
  Container,
  Header,
  Scroll,
  Achievement,
  AchievementIcon,
  AchievementContent,
  HeaderContent,
} from './styles';

interface Achievement {
  id: string;
  area_id: string;
  achievement_type_id: string;
  name: string;
  achievement_id: string;
  badge_url: string;
  description: string;
  unlocked: string;
}

interface UserAchievement {
  user_id: string;
  achievement_id: string;
  created_at: string;
}

const Achievements: React.FC = () => {
  const [achievements, setAchievements] = useState<Achievement[]>(
    [] as Achievement[],
  );

  const { user } = useAuthentication();

  const { t, i18n } = useTranslation();

  const locales: { [key: string]: Locale } = useMemo(() => {
    return {
      'pt-BR': ptBR,
      'en-US': enUS,
    };
  }, []);

  useEffect(() => {
    async function loadAchievements(): Promise<void> {
      const response = await api.get(`/courses/achievements/${user.id}`);

      const mappedAchievements: Achievement[] = await Promise.all(
        response.data.map(async (loadedUserAward: UserAchievement) => {
          const achievement: Achievement = (
            await api.get(
              `/courses/achievement/${loadedUserAward.achievement_id}`,
            )
          ).data;

          return {
            ...achievement,
            unlocked: formatDistance(
              parseISO(loadedUserAward.created_at),
              new Date(),
              { addSuffix: true, locale: locales[i18n.language] },
            ),
            badge_url: achievement.badge_url,
          };
        }),
      );

      setAchievements(mappedAchievements);
    }

    loadAchievements();
  }, [i18n.language, locales, user.id]);

  return (
    <Screen>
      <Container>
        <Header>
          <HeaderContent>
            <h2>{t('Achievements')}</h2>
          </HeaderContent>
        </Header>
        <Scroll>
          {achievements.length > 0 ? (
            achievements
              .slice()
              .reverse()
              .map(achievement => (
                <Achievement key={achievement.id}>
                  <AchievementIcon>
                    <img src={achievement.badge_url} alt="" />
                  </AchievementIcon>
                  <AchievementContent>
                    <p>{t(achievement.name)}</p>
                    <time>{t(achievement.description)}</time>
                  </AchievementContent>
                  <time>{achievement.unlocked}</time>
                </Achievement>
              ))
          ) : (
            <p style={{ marginTop: '2rem' }}>
              {t('You do not have any achievements at the moment')}
            </p>
          )}
        </Scroll>
      </Container>
    </Screen>
  );
};

export default Achievements;
