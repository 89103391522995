import React from 'react';

import { AuthenticationProvider } from './authentication';
import { ToastProvider } from './toast';

const AppProvider: React.FC = ({ children }) => (
  <ToastProvider>
    <AuthenticationProvider>{children}</AuthenticationProvider>
  </ToastProvider>
);

export default AppProvider;
