interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'screen/b6.png',
    title: 'Change Light',
    text:
      'With this block, you can change the color of the light displayed by the robot.',
  },
  {
    image: 'screen/b5.png',
    title: 'Clear Display',
    text:
      'With this block, you can delete everything that is currently displayed on the screen.',
  },
  {
    image: 'screen/b4.png',
    title: 'Write on Screen',
    text:
      'With this block, you can write a text message on the screen, specifying more detailed position and changing the font used. It has four parameters: the first one specifying the text, the second and third parameters indicating the position, and the fourth parameter defining the font style.',
  },
  {
    image: 'screen/b3.png',
    title: 'Write on Screen',
    text:
      'With this block, you can write a text message on the screen. It has two parameters: the first one specifying the text you want to display, and the second one indicating the position where you want to display the text on the screen.',
  },
  {
    image: 'screen/b2.png',
    title: 'Expressions',
    text:
      'With this block, you can define an expression or object without a time limit.',
  },
  {
    image: 'screen/b1.png',
    title: 'Timed Expressions',
    text:
      'This block provides various expressions and objects for you to use in your projects. It has two parameters: the first one indicating what you want to display on the screen, and the second one specifying the duration in seconds for how long this image will appear on the screen.',
  },
];

export default Cards;
