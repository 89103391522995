import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { BgImage, ClassContainer } from './styles';

const WireIdentification: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Basic Maintenance')}</h1>
      <p>
        {t(
          "After the entire assembly process, it's time to stop and review it, which means it's time to learn about circuit prevention and maintenance.",
        )}
      </p>
      <p>
        {t(
          'Before connecting the battery, check if all connections have been made with the correct polarity. Ideally, someone who did not participate in the assembly process should verify the wiring and ensure that all connections are correct.',
        )}
      </p>
      <ul style={{ marginBottom: '2rem' }}>
        <li>
          {t(
            'Start with the battery and check if the red wire is connected to the positive terminal.',
          )}
        </li>
        <li>
          {t(
            'Verify that the red wire goes through the main breaker and to the + terminal of the PDP, and also, that the black wire goes directly to the - terminal.',
          )}
        </li>
        <li>
          {t(
            'For each motor controller, make sure the red wire goes from the red PDP terminal to the red wire on the motor (not the white M+ wire).',
          )}
        </li>
        <li>
          {t(
            'For each device at the end of the PDP, ensure that the red wire connects to the red terminal on the PDP and to the red terminal on the component.',
          )}
        </li>
        <li>
          {t(
            'Make sure the orange cable of the Passive PoE is connected directly to the radio, not the roboRIO! It should be connected to the roboRIO using an additional Ethernet cable.',
          )}
        </li>
      </ul>
      <p>
        {t(
          'It is recommended to put the robot on blocks so that the wheels are off the ground before the next step. This will prevent any unexpected movement from becoming dangerous.',
        )}
      </p>
      <h2>{t('Wire Management')}</h2>
      <p>
        {t(
          "Now might be a good time to securely fasten the wires. Group the wires together and secure them with zip ties. This will help keep the robot's wiring neater and more organized (remember the goal: organization and functionality).",
        )}
      </p>
      <p>
        {t(
          "And the long-awaited moment has finally arrived! Connect the battery; Power on the robot by moving the lever on top of the circuit breaker; If the lights flash, everything is probably okay. From this point on, it's all about programming, and we need to let the programmers work their magic with the code!",
        )}
      </p>
    </ClassContainer>
  );
};

export default WireIdentification;
