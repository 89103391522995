import styled, { css } from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { lighten } from 'polished';

interface NotificationProps {
  hasNotRead?: boolean;
  unread?: boolean;
  visible?: boolean;
}

export const Container = styled.div`
  position: relative;
  z-index: 9999;
`;

export const Bell = styled.button<NotificationProps>`
  background: none;
  border: 0;
  position: relative;

  ${props =>
    props.hasNotRead &&
    css`
      &::after {
        position: absolute;
        right: 0px;
        top: 0;
        width: 10px;
        height: 10px;
        background: #f6ae2d;
        content: '';
        border-radius: 50%;
      }
    `}
`;

export const NotificationList = styled.div<NotificationProps>`
  position: absolute;
  width: 400px;
  right: calc(0% + 1px);
  top: calc(20% + 30px);
  background: #fff;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  border-radius: 0.5rem;
  display: ${props => (props.visible ? 'flex' : 'none')};
  flex-direction: column;

  @media (min-width: 1225px) {
    right: calc(0% + 1px);
  }

  @media (max-width: 1224px) {
    left: calc(0% + 1px);
    width: 85vw;
  }
`;

export const NotificationsHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 3.2px -3.2px;
  padding: 1rem 1.2rem;
  margin-bottom: 0.5rem;

  p {
    font-weight: 600;
  }

  button {
    background: transparent;
    border: 0;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    font-weight: 500;

    color: #387bd1;

    &:hover {
      color: #3854c2;
    }
  }
`;

export const NotificationsFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem 1.2rem;
  border-top: 1px solid #e8eaed;
  margin-top: 0.5rem;

  a {
    background: transparent;
    border: 0;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    font-weight: 500;

    color: #387bd1;

    &:hover {
      color: #3854c2;
    }
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  /* max-height: 260px; */
`;

export const Notification = styled.div<NotificationProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0.5rem;
  padding: 1rem 0.5rem;
  transition: 0.2s ease-in-out;

  &:hover {
    background: #f6f6f6;
    border-radius: 0.5rem;
    border-color: transparent;
  }

  &:hover + & {
    border-color: transparent;
  }

  /* button {
    font-size: 12px;
    border: 0;
    background: none;
    color: ${lighten(0.2, '#7159c1')};

  } */

  & + div {
    border-top: 2px solid #f0f1f5;
  }
`;

export const NotificationIcon = styled.div<NotificationProps>`
  position: relative;
  min-width: 50px;
  min-height: 50px;
  border-radius: 1rem;
  background: #e8e8e8;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 40px;
    height: auto;
  }

  ${props =>
    props.unread &&
    css`
      &::before {
        content: '';
        position: absolute;
        left: -3px;
        top: 0;
        width: 12px;
        height: 12px;
        background: #387bd1;
        border: 3px solid #fff;
        border-radius: 50%;
      }
    `}
`;

export const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  p {
    font-size: 0.95rem;
    font-weight: 500;
  }

  time {
    font-size: 0.9rem;
    opacity: 0.8;
    margin-bottom: 5px;
  }
`;
