import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface CourseProps {
  active: boolean;
}

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  width: 100vw;
  height: 100%;
  max-height: 100%;

  padding: 5rem;

  overflow-y: scroll;

  @media (max-width: 1224px) {
    padding: 5rem 3rem;
  }

  @media (max-width: 800px) {
    padding: 2rem 1.5rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #2e2e2e;
  }

  h3 {
    margin-bottom: 3rem;
  }
`;

export const Courses = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 3rem;

  div + div {
    margin-left: 1rem;
  }
`;

export const Course = styled.div<CourseProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: 2px solid;
  border-color: #f0f1f5;
  border-radius: 1rem;
  transition: all 0.2s ease-in-out;
  font-weight: 500;
  cursor: pointer;

  ${props =>
    props.active &&
    css`
      border-color: #1d62d1;
      color: #1d62d1;
    `}
`;

export const Levels = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 3rem;

  > div + div {
    margin-left: 1rem;
  }

  @media (max-width: 800px) {
    > div + div {
      margin-left: 0;
      margin-top: 1rem;
    }

    flex-direction: column;
  }
`;

export const Level = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.2s ease-in-out;
  font-weight: 500;

  div:first-child {
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 1.5rem 1.5rem 0 0;
    border-left: 2px solid;
    border-top: 2px solid;
    border-right: 2px solid;
    border-color: #f0f1f5;
    padding: 1.5rem 1rem;

    p {
      font-size: 1rem;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const CreateAssignment = styled(Link)`
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 0 0 1.5rem 1.5rem;
  border: 2px solid;
  border-color: #1d62d1;
  padding: 0.5rem 1rem;
  background: #1d62d1;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    background: #113b7d;
    border-color: #113b7d;
  }
`;
