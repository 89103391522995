/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import { useDrag } from '@use-gesture/react';
import { useSprings, animated, to as interpolate } from '@react-spring/web';
import { useTranslation } from 'react-i18next';
import { CardText, Container } from './styles';

interface CardInfo {
  image: string;
  title: string;
  text: string;
}

interface StackProps {
  cards: CardInfo[];
  bgColor: string;
  invert?: boolean;
}

const CardStack: React.FC<StackProps> = ({ cards, invert, bgColor }) => {
  const { t } = useTranslation();

  // These two are just helpers, they curate spring data, values that are later being interpolated into css
  const to = (i: number) => ({
    x: 0,
    y: i * -4,
    scale: 1,
    rot: -10 + Math.random() * 20,
    delay: i * 100,
  });
  const from = (_i: number) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });
  // This is being used down there in the view, it interpolates rotation and scale into a css transform
  const trans = (r: number, s: number) =>
    `rotateX(30deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`;

  const [gone] = useState(() => new Set());
  const [lastCard, setLastCard] = useState(cards.length - 1);

  const [propss, api] = useSprings(cards.length, i => ({
    ...to(i),
    from: from(i),
  })); // Create a bunch of springs using the helpers above

  const bind = useDrag(
    ({ args: [index], down, movement: [mx], direction: [xDir], velocity }) => {
      const trigger = velocity[0] > 0.3;
      const dir = xDir < 0 ? -1 : 1; // Direction should either point left or right
      if (!down && trigger) {
        gone.add(index);
      } // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
      api.start(i => {
        if (index !== i) return {}; // We're only interested in changing spring-data for the current spring
        const isGone = gone.has(index);
        if (gone.size !== cards.length) {
          setLastCard(cards.findLastIndex(el => !gone.has(cards.indexOf(el))));
        }
        const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0; // When a card is gone it flys out left or right, otherwise goes back to zero
        const rot = mx / 100 + (isGone ? dir * 10 : 0); // How much the card tilts, flicking it harder makes it rotate faster
        const scale = down ? 1.3 : 1; // Active cards lift up a bit
        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
        };
      });
      if (!down && gone.size === cards.length)
        setTimeout(() => {
          gone.clear();
          setLastCard(cards.length - 1);
          api.start(i => to(i));
        }, 600);
    },
  );

  return (
    <Container bgColor={bgColor} invert={invert}>
      {propss.map(({ x, y, rot, scale }, i) => (
        <animated.div className="cardContainer" key={i} style={{ x, y }}>
          {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
          <animated.div
            {...bind(i)}
            style={{
              transform: interpolate([rot, scale], trans),
              backgroundImage: `url(https://${t(cards[i].image)})`,
            }}
          />
        </animated.div>
      ))}
      <CardText>
        <h1 style={{ marginBottom: '1rem' }}>{t(cards[lastCard].title)}</h1>
        <p>{t(cards[lastCard].text)}</p>
      </CardText>
    </Container>
  );
};

export default CardStack;
