interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/medium-spike.png',
    title: 'Medium Motor (SPIKE)',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/large-spike.png',
    title: 'Large Motor (SPIKE)',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/medium-motor.png',
    title: 'Medium Motor',
    text:
      'Smaller and more precise, the medium motors are ideal for tasks that require more control, such as precision movements or more complex actions. Medium motors are often used to move arms and other movable parts of the robot, such as claws or hooks, for example.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/large-motor.png',
    title: 'Large Motor',
    text:
      'Large motors are stronger, have more power and torque, which means they can move heavier objects more easily. They are typically connected to the wheels of the robot to make the entire structure move. They are also more suitable for building larger robots that require more power to function properly.',
  },
];

export default Cards;
