interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/fuse.png',
    title: 'Fuses',
    text:
      'Fuses are used with the Power Distribution Panel (PDP) to limit the current to branch circuits. 20A, 30A, and 40A fuses are used, depending on the amount of power consumed by the component connected to the power channel.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/rsl.png',
    title: 'Robot Signal Light',
    text:
      'The Robot Signal Light (RSL) is mandatory during FRC competitions and serves as a signal to indicate whether the robot is connected to the control system, in teleoperated mode, and more.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/batery.png',
    title: 'Battery',
    text:
      "The robot operates thanks to a 12-volt battery, from which power is distributed to all systems through the PDP. To give you an idea of its power, it's the same type of battery used in Jet Skis.",
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mcb.png',
    title: 'Main Circuit Breaker',
    text:
      "Every robot has an on/off switch. This is a simple component, but it's also the primary safeguard that prevents the robot from experiencing excessive electrical current due to a short circuit.",
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/pcm.png',
    title: 'Pneumatics Control Module',
    text:
      'The PCM (Pneumatic Control Module) is a component that the mechanical team loves. It connects all the components of the pneumatic system to execute piston movements in the programming.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/radio.png',
    title: 'Radio',
    text:
      'The radio is essential for the robot to move without any wires between the robot and the computer (wireless). It communicates directly with the RoboRIO through one of its Ethernet ports.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/vrm.png',
    title: 'Voltage Regulator Module',
    text:
      "The VRM (Voltage Regulator Module) is used to regulate the maximum voltage for some special components. Technically, an FRC robot runs on 12V voltage when connected to a battery. However, there are components like the radio (which we'll see shortly) that require only 5V. If you supply 12V voltage to the radio, something unfortunate will happen...",
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/pdp.png',
    title: 'Power Distribution Panel',
    text:
      "The PDP (Power Distribution Panel) is the second most important component of the robot, and most components are connected to it. It's second in importance because it's straightforward; it only distributes power to its partners.",
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/roborio.png',
    title: 'RoboRIO',
    text:
      'We begin with the brain of our robot, the RoboRIO. It is the primary system that brings the robot to life on the field. All the components that will be introduced will be connected directly (or indirectly) to the main board.',
  },
];

export default Cards;
