interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/components/gear-36.png',
    title: 'Gear - 36 teeth',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/components/gear-28.png',
    title: 'Gear - 28 teeth',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/components/gear-20.png',
    title: 'Gear - 20 teeth',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/components/gear-12.png',
    title: 'Gear - 12 teeth',
    text: '',
  },
];

export default Cards;
