const Tabs = [
  {
    name: 'Control',
  },
  {
    name: 'Sensors',
  },
  {
    name: 'Display',
  },
];

export default { Tabs };
