import React from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineViewGridAdd } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';

import { Screen, Container } from './styles';

import { ReactComponent as FinishedLevelImg } from '../../assets/Illustrations/FinishedLevel.svg';

const FinishedLevel: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Screen>
      <Container>
        <FinishedLevelImg />
        <h1>{t('Level finished!')}</h1>
        <h3>{t('Congratz!')}</h3>
        <Link to="/dashboard" style={{ marginTop: '0.5rem' }}>
          <Button>
            {t('Back to dashboard')}
            <HiOutlineViewGridAdd size={20} style={{ marginLeft: '0.5rem' }} />
          </Button>
        </Link>
      </Container>
    </Screen>
  );
};

export default FinishedLevel;
