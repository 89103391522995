import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CodeTabs from './tabs';

import { Tabs, TabItem } from './styles';
import Control from './Tabs/Control';
import Sensors from './Tabs/Sensors';
import Display from './Tabs/Display';

interface TabsProps {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
}

const TabPanel: React.FC<TabsProps> = ({ code, setCode }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const { t } = useTranslation();

  return (
    <>
      <Tabs>
        {CodeTabs.Tabs.map((tab, index) => {
          return (
            <TabItem
              key={tab.name}
              active={index === activeIndex}
              onClick={() => setActiveIndex(index)}
            >
              {t(tab.name)}
            </TabItem>
          );
        })}
      </Tabs>
      {(() => {
        switch (activeIndex) {
          case 0:
            return <Control code={code} setCode={setCode} />;
          case 1:
            return <Sensors code={code} setCode={setCode} />;
          case 2:
            return <Display code={code} setCode={setCode} />;
          default:
            return null;
        }
      })()}
    </>
  );
};

export default TabPanel;
