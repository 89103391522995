import { shade } from 'polished';
import styled, { css } from 'styled-components';

import Button from '../../../Button';

interface LabelProps {
  hasPhoto: boolean;
}

export const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  position: relative;

  h4 {
    color: #051227;
  }

  @media only screen and (max-width: 1500px) {
    overflow-y: auto;
    height: 100%;
  }

  /* @media (max-width: 1500px) {
    width: 70%;
  } */

  @media (max-width: 1224px) {
    width: 60%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
`;

export const Avatar = styled.div<LabelProps>`
  position: relative;

  margin-bottom: 1rem;

  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }

  label {
    ${props =>
      props.hasPhoto &&
      css`
        position: absolute;
        right: 0;
        bottom: 0;
      `}
    align-items: center;
    justify-content: center;

    display: flex;
    width: 48px;
    height: 48px;
    border: 0;
    border-radius: 25%;

    background: #1d62d1;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    input {
      display: none;
    }

    svg {
      width: 22px;
      height: 22px;
      color: #fff;
    }

    &:hover {
      background: ${shade(0.2, '#1d62d1')};
    }
  }
`;

export const UpdateButton = styled(Button)`
  margin-top: 1rem;
  width: auto;
  height: auto;
  padding: 0.5rem 1rem;
`;
