import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import api from '../../services/api';
import { useAuthentication } from '../../hooks/authentication';

import {
  Container,
  CourseImage,
  CourseTitle,
  CoursesDiv,
  Screen,
} from './styles';

import { ReactComponent as Brick } from '../../assets/Illustrations/Brick.svg';
import dataCourses from '../../data/dataCourses';
import { useToast } from '../../hooks/toast';

interface Course {
  id: string;
  libraryImage?: string;
  name: string;
}

const Courses: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([] as Course[]);

  const history = useHistory();

  const { addToast } = useToast();

  const { t } = useTranslation();

  const { user } = useAuthentication();

  useLayoutEffect(() => {
    async function loadCourses(): Promise<void> {
      const dbCourses: Course[] = await (await api.get('/courses')).data;

      const mappedCourses = dbCourses.map(course => {
        const matchCourse = dataCourses.find(
          dataCourse => dataCourse.name === course.name,
        );

        return matchCourse
          ? { ...course, libraryImage: matchCourse.libraryImage }
          : course;
      });

      setCourses(mappedCourses);
    }

    loadCourses();
  }, []);

  const handleCourseSelection = useCallback(
    async (course_id, course_name) => {
      if (course_name === 'FIRST Robotics') {
        addToast({
          type: 'info',
          label: t('Course under construction'),
          description: t('Soon we will release this amazing content!'),
        });
      } else {
        history.push(`/library/course/${course_id}`);
      }
    },
    [addToast, history, t],
  );

  return (
    <Screen>
      <Container>
        <h1>{t('Library')}</h1>
        <CoursesDiv>
          {courses.slice().map(course => (
            <button
              key={course.id}
              type="button"
              onClick={() => handleCourseSelection(course.id, course.name)}
            >
              {course.libraryImage && (
                <CourseImage
                  style={{ backgroundImage: `url(${course.libraryImage})` }}
                />
              )}
              <CourseTitle>
                <h1>{t(course.name)}</h1>
              </CourseTitle>
            </button>
          ))}
        </CoursesDiv>
      </Container>
    </Screen>
  );
};

export default Courses;
