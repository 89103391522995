import React, { useRef, useCallback } from 'react';

import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { HiOutlineLogin, HiOutlineMail, HiOutlinePuzzle } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import { Screen, Container, Content, ContainerRight, Logo } from './styles';

import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { ReactComponent as SignUpGroup } from '../../assets/Illustrations/SignUp.svg';

interface ForgotPasswordFormData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (data: ForgotPasswordFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required(t('Please, provide an email'))
            .email(t('Please, provide a valid email')),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/password/forgot', {
          email: data.email,
        });

        addToast({
          type: 'success',
          label: t('Password recovery email has been sent'),
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const validationError = getValidationErrors(error);

          formRef.current?.setErrors(validationError);

          return;
        }

        addToast({
          type: 'error',
          label: t('Email does not match with your account'),
          description: t('Please, try again.'),
        });
      }
    },
    [addToast, t],
  );

  return (
    <Screen>
      <Container>
        <Content>
          <Logo>
            <div style={{ display: 'flex' }}>
              <HiOutlinePuzzle size={32} />
            </div>
            <p>LearnFirst</p>
          </Logo>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>{t('Password recovery')}</h1>

            <Input name="email" placeholder="E-mail" icon={HiOutlineMail} />

            <Button type="submit">{t('Recover password')}</Button>
          </Form>

          <Link to="/">
            <HiOutlineLogin size={18} />
            {t('Back to Log in')}
          </Link>
        </Content>
        <ContainerRight>
          <SignUpGroup />
        </ContainerRight>
      </Container>
    </Screen>
  );
};

export default ForgotPassword;
