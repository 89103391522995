import React, { useRef, useCallback, useMemo } from 'react';

import { Link, useLocation, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { FiLogIn, FiKey } from 'react-icons/fi';
import { TiArrowRepeat } from 'react-icons/ti';

import { HiOutlineLogin, HiOutlinePuzzle } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import { Screen, Container, Content, Logo, ContainerRight } from './styles';

import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { ReactComponent as SignUpGroup } from '../../assets/Illustrations/SignUp.svg';

interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
}

const ResetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const useQuery = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string().required(t('New password is required')),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password')],
            t('Passwords do not match'),
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { password, password_confirmation } = data;

        const token = useQuery.get('token');

        await api.post('/password/reset', {
          password,
          password_confirmation,
          token,
        });

        history.push('/');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const validationError = getValidationErrors(error);

          formRef.current?.setErrors(validationError);

          return;
        }

        addToast({
          type: 'error',
          label: t('Error at password reset process'),
          description: t('Please, try again'),
        });
      }
    },
    [addToast, history, t, useQuery],
  );

  return (
    <Screen>
      <Container>
        <Content>
          <Logo>
            <div style={{ display: 'flex' }}>
              <HiOutlinePuzzle size={32} />
            </div>
            <p>LearnFirst</p>
          </Logo>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>{t('Create new password')}</h1>

            <Input
              name="password"
              placeholder={t('New Password')}
              icon={FiKey}
              endIconOn={RiEyeLine}
              endIconOff={RiEyeOffLine}
            />

            <Input
              name="password_confirmation"
              placeholder={t('Confirm new Password')}
              icon={TiArrowRepeat}
              endIconOn={RiEyeLine}
              endIconOff={RiEyeOffLine}
            />

            <Button type="submit">{t('Reset my password')}</Button>
          </Form>

          <Link to="/">
            <HiOutlineLogin size={18} />
            {t('Back to Log in')}
          </Link>
        </Content>
        <ContainerRight>
          <SignUpGroup />
        </ContainerRight>
      </Container>
    </Screen>
  );
};

export default ResetPassword;
