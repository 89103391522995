import styled from 'styled-components';
import Class from '../../../../../Class';

export const ClassContainer = styled(Class)`
  & > h1,
  & > p {
    margin-bottom: 2rem;
  }

  h1:first-child {
    margin-bottom: 3rem;

    color: #051227;

    font-size: 2rem;
    font-weight: 600;
  }

  h2 {
    color: #051227;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .lf-player-container {
    align-self: center;
  }

  table {
    border-collapse: collapse;
  }

  table,
  td,
  th {
    border: 1px solid #1d62d1;
  }

  td,
  th {
    padding: 0.5rem;
  }
`;
