import React from 'react';
import { useTranslation } from 'react-i18next';

import { BgImage, ClassContainer } from './styles';

const ExploringBrick: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Exploring the SPIKE Hub')}</h1>
      <p>
        {t(
          'The SPIKE Hub is similar in functionality to the EV3 main brick. However, there are some essential differences to be aware of.',
        )}
      </p>
      <h2>{t('Ports')}</h2>
      <p>
        {t(
          'At first glance, you can see six input/output ports, three on each brick side. For basic interactions between motors and sensors, you can connect a motor at the exact opposite side of the sensor.',
        )}
      </p>
      <BgImage>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/in-out-ports.png"
          alt="robot base"
        />
      </BgImage>
      <h2>{t('Light Matrix and Navigation')}</h2>
      <p>
        {t(
          'The Light Matrix is a grid of multi-colored LEDs, found on the front face of the hub. It serves as both a practical tool and a canvas for creativity, adding a dynamic visual element to our robotic projects.',
        )}
      </p>
      <p>
        {t(
          'The LEDs are arranged in rows and columns. They can display a wide spectrum of colors, and their brightness and patterns can be controlled programmatically.',
        )}
      </p>
      <BgImage>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/buttons-spike.png"
          alt="robot base"
        />
      </BgImage>
      <p>
        {t(
          "You can switch between different programs by navigating the menu with the left and right arrow buttons. If the heart symbol doesn't change, this means you have no uploaded programs yet. Once you've uploaded a program, navigate to the number of the slot you used when uploading the program. Move your robot to the test zone and press the center button on the hub. You should hear a clicking sound and your program will now be executed.",
        )}
      </p>
      <p>
        {t(
          'You can also use the Bluetooth button on the top right corner of the Hub to connect your robot.',
        )}
      </p>
    </ClassContainer>
  );
};

export default ExploringBrick;
