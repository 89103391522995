import React from 'react';
import { useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { BgImage, ClassContainer } from './styles';

const LineFollower: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Line Follower')}</h1>
      <p>
        {t(
          'The line-following robot is a type of programmable robot that uses color sensors to detect a black line on a light background. It follows this black line, moving in a specific direction based on the information captured by the sensors.',
        )}
      </p>
      <Player
        autoplay
        loop
        src="https://learnfirst-bucket.s3.us-east-2.amazonaws.com/follow.json"
        style={{ height: '250px', width: '250px' }}
      >
        <Controls visible={false} />
      </Player>
      <p>
        {t(
          'This robot is equipped with one, two, or more color sensors that are placed near the base of the robot and aligned with the direction of movement. These sensors are capable of detecting changes in the color of the surface beneath the robot and, therefore, are able to follow a black line on a light background.',
        )}
      </p>
      <p>
        {t(
          'The robot follows the line using a code that continuously adjusts the direction of the robot to keep it aligned with the line. When the sensor detects that it is on top of the line, it sends a signal to the robot, which adjusts its motors to stay on the line. If the sensor detects that it is off the line, the robot adjusts its motors to get back on the line.',
        )}
      </p>
      <p>
        {t(
          'This type of robot is widely used in robotics competitions and industrial environments where precise navigation is required, such as in automated assembly lines.',
        )}
      </p>
      <p>
        {t(
          'One of the main advantages of the line-following robot is its simplicity and effectiveness in following specific paths with high precision. Additionally, they can be programmed to follow lines of different colors, allowing them to be used in a wide range of applications.',
        )}
      </p>
      <p>
        {t(
          'To increase the precision of the robot, the programmer can use a technique called PID (Proportional, Integral, and Derivative), which utilizes a mathematical algorithm to continuously adjust the direction and speed of the robot, enabling it to follow the line more accurately and efficiently. Additionally, the line-following robot can be equipped with other features, such as a gripper for picking up objects or sensors for detecting obstacles.',
        )}
      </p>
      <p>
        {t(
          'In this class, we will learn the basics of programming a robot with just one sensor. By using only one sensor, the robot will have the limitation of being able to make turns in only one direction. To address this issue, you can add another sensor, which will increase the precision of the robot.',
        )}
      </p>
      <p>
        {t(
          'The first step is to calibrate the color sensor. To do this, add and execute the following code snippets on your robot:',
        )}
      </p>
      <BgImage>
        <img src={`https://${t('line-follower/code1.png')}`} alt="code" />
      </BgImage>
      <p>
        {t(
          'This next snippet will set the white color for your sensor. In other words, you need to position the sensor precisely over the white surface you will be using and press the “up” button on the robot.',
        )}
      </p>
      <BgImage>
        <img src={`https://${t('line-follower/code2.png')}`} alt="code" />
      </BgImage>
      <p>
        {t(
          "Finally, this piece will set the color black for your sensor. In other words, you need to position the sensor precisely over the black line to be used and press the robot's “down” button.",
        )}
      </p>
      <BgImage>
        <img src={`https://${t('line-follower/code3.png')}`} alt="code" />
      </BgImage>
      <p>
        {t(
          'Now, with the robot properly calibrated, you can execute the following code for the robot to follow the black line:',
        )}
      </p>
      <BgImage>
        <img src={`https://${t('line-follower/code4.png')}`} alt="code" />
      </BgImage>
      <p>
        {t(
          'This code will tell the robot that “if” the intensity of reflected light is less than 50%, meaning it is on top of the black line, then it will initiate movement with a speed of 45% and 0%. This will activate the left motor, causing the robot to move forward and to the left simultaneously. Then, within the “else” block, meaning if the block is not on the black line, it will initiate movement with a speed of 0% and 45%. This will activate the right motor while the left motor remains off, causing the robot to move back to the right.',
        )}
      </p>
      <p>
        {t(
          'If the robot has trouble following the line, redo the calibration steps again.',
        )}
      </p>
    </ClassContainer>
  );
};

export default LineFollower;
