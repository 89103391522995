import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { ClassContainer } from './styles';

const WhatIsLEGO: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Introduction')}</h1>
      <p>
        {t(
          'A estratégia é uma disciplina que envolve aspectos não técnicos da robótica, desenvolvendo o uso de componentes da maneira mais eficiente o possível para a competição. Quando falamos sobre esses componentes, podemos nos referir tanto aos do robô em si, quanto as pessoas que lá estarão presentes. Podemos dizer que um time organizado e que usa o tempo de forma eficiente é essencial para competir',
        )}
      </p>
      <p>
        {t(
          'Ademais, a estratégia é focada não só em ganhar jogos da competição, mas também desenvolver competências como raciocínio lógico, matemática, entre outros. Vamos lá que tem bastante coisa pela frente.',
        )}
      </p>
    </ClassContainer>
  );
};

export default WhatIsLEGO;
