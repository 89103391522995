import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

interface ContainerProps {
  type?: 'success' | 'error' | 'info';
  // hasDescription: number;
}

const toastTypeVariation = {
  info: css`
    color: #1d62d1;

    div:first-child {
      background-color: #ebf8ff;
    }
  `,
  success: css`
    color: #75c375;

    div:first-child {
      background-color: #e9fae9;
    }
  `,
  error: css`
    color: #c53030;

    div:first-child {
      background-color: #fddede;
    }
  `,
};

export const IconContainer = styled.div`
  display: flex;
  align-items: center;

  padding: 1rem;
  border-radius: 1.5rem;
  margin-right: 1rem;
`;

export const Container = styled(animated.div)<ContainerProps>`
  display: flex;
  position: relative;
  align-items: center;

  width: 360px;
  padding: 0.5rem;
  border-radius: 2rem;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;

  background: #fff;

  & + div {
    margin-top: 6px;
  }

  ${props => toastTypeVariation[props.type || 'info']}

  div {
    p {
      margin-top: 4px;

      opacity: 0.8;

      font-size: 14px;
      line-height: 20px;
    }
  }

  button {
    display: flex;

    border: 0;
    margin-right: 0.5rem;
    margin-left: auto;
    align-items: center;

    background: transparent;
    color: #1e2a3d;
    opacity: 0.8;
  }
`;
