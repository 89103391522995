/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Controls, Player } from '@lottiefiles/react-lottie-player';

import { RiOrganizationChart } from 'react-icons/ri';
import { ClassContainer } from './styles';
import CardsModal from '../../../../../../components/CardsModal';
import { useToast } from '../../../../../../hooks/toast';

import Cards from './dataMotors';

const Motors: React.FC = () => {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [showNextButton, setShowNextButton] = useState(false);

  useEffect(() => {
    if (showNextButton === true) {
      addToast({
        type: 'success',
        label: t("Now we're talking!"),
        description: t("Click on the 'next' button to continue!"),
      });
    }
  }, [addToast, showNextButton, t]);

  return (
    <ClassContainer showNextButton={showNextButton}>
      <h1 style={{ marginBottom: '0.5rem' }}>{t('Motors')}</h1>
      {/* <h2>{t('Basic Programming:')}</h2> */}
      <Player
        autoplay
        loop
        src="https://learnfirst-bucket.s3.us-east-2.amazonaws.com/data.json"
        style={{ height: '250px', width: '250px', margin: '1rem 0' }}
      >
        <Controls visible={false} />
      </Player>
      <p>
        {t(
          "The motors are responsible for providing movement to the robot. Using them, you can control the speed, direction, and timing of the robot's actions, from simple movements like driving forward and backward to more complex actions like opening and closing arms, rotating sensors, or moving gears.",
        )}
      </p>
      <p>
        {t("Now, let's get to know the main motors used in LEGO robotics.")}
      </p>

      <p className="modalStartText">{t('Click the button below to start:')}</p>
      <CardsModal
        Icon={RiOrganizationChart}
        bgColor="#ffb129"
        propBorderColor="#f2d4a0"
        setShowNextButton={setShowNextButton}
        cards={Cards}
      />
    </ClassContainer>
  );
};

export default Motors;
