interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/components/axle-2l.png',
    title: 'Axle pin - 2',
    text: 'Used to connect two pieces with a cross-shaped joint.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/components/pin-connector-3l.png',
    title: 'Double perpendicular connector',
    text:
      'Used to connect two cross-shaped pieces with a common piece perpendicularly.',
  },
  {
    image:
      'learnfirst-bucket.s3.amazonaws.com/components/pin-connector-perpend.png',
    title: 'Simple Perpendicular Connector',
    text:
      'Used to connect a cross-shaped piece with a common piece perpendicularly.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/components/pin-connector.png',
    title: '180° Connector',
    text: 'Used to connect two pieces in a cross shape perpendicularly.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/components/pin-3l-stop.png',
    title: 'Pin w/ joint',
    text:
      'Using this piece, you can connect two beams and leave a space to connect another piece in the joint on the other side.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/components/connector-4p.png',
    title: 'Double Connector',
    text: 'Used to connect structures.',
  },
  {
    image:
      'learnfirst-bucket.s3.amazonaws.com/components/connector-perpend.png',
    title: '90° Connector ',
    text: 'Used to connect structures..',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/components/axle-3l-stop.png',
    title: 'Axle pin w/ stop - 3',
    text:
      'Used to connect components, usually for rotation systems or gears, with a stop mechanism at the end.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/components/axle-3l.png',
    title: 'Axle pin - 3',
    text: 'Used to connect components, usually for rotation systems or gears.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/components/axle-1l.png',
    title: 'Axle pin / common - 2',
    text:
      'Used to connect a piece with a common joint to a piece with a cross joint.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/components/pin-3l.png',
    title: 'Pin - 3',
    text: 'Used to connect three beams or similar components simultaneously.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/components/pin-2l.png',
    title: 'Pin - 2',
    text: 'Used to connect two beams or similar components.',
  },
];

export default Cards;
