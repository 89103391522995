/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import {
  animated,
  config,
  useChain,
  useSpring,
  useSpringRef,
  useTransition,
} from '@react-spring/web';
import { TbSwipe } from 'react-icons/tb';
import { HiX } from 'react-icons/hi';
import { IconBaseProps } from 'react-icons';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import CardStack from '../CardStack';

import { Container } from './styles';

interface CardInfo {
  image: string;
  title: string;
  text: string;
}

interface ModalProps {
  Icon: React.ComponentType<IconBaseProps>;
  bgColor: string;
  propBorderColor: string;
  setShowNextButton?: React.Dispatch<React.SetStateAction<boolean>>;
  invert?: boolean;
  cards: CardInfo[];
}

const CardsModal: React.FC<ModalProps> = ({
  Icon,
  bgColor,
  propBorderColor,
  setShowNextButton,
  invert,
  cards,
}) => {
  const bigScreens = useMediaQuery({ query: '(min-width: 1400px)' });
  const laptopQuery = useMediaQuery({ query: '(min-width: 1000px)' });
  const mobileOrTablet = useMediaQuery({ query: '(max-width: 1000px)' });

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const sizes = [
    { condition: open && bigScreens, height: '600px', width: '1000px' },
    { condition: open && laptopQuery, height: '550px', width: '800px' },
    { condition: open && mobileOrTablet, height: '600px', width: '330px' },
  ];

  const matchedSize = sizes.find(item => item.condition);

  const springApi = useSpringRef();
  const { height, width, ...rest } = useSpring({
    ref: springApi,
    config: config.stiff,
    from: { height: '80px', width: '80px', background: bgColor },
    to: {
      height: matchedSize ? matchedSize.height : '80px',
      width: matchedSize ? matchedSize.width : '80px',
      background: open ? '#f8f9fd' : bgColor,
    },
  });

  const testVec = ['1'];

  const transApi = useSpringRef();
  const transition = useTransition(open ? testVec : [], {
    ref: transApi,
    trail: 400 / testVec.length,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
  });

  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  useChain(open ? [springApi, transApi] : [transApi, springApi], [
    0,
    open ? 0.1 : 0.1,
  ]);

  return (
    <Container
      style={{ ...rest, width, height }}
      className="container"
      onClick={() => {
        setOpen(true);
      }}
      open={open}
      $propBorderColor={propBorderColor}
    >
      {!open && <Icon className="modalIcon" size={50} />}
      {transition(() => (
        <animated.div className="openModalContainer">
          {open && (
            <button
              type="button"
              onClick={event => {
                event.stopPropagation();
                setOpen(false);
                if (setShowNextButton) {
                  setShowNextButton(true);
                }
              }}
            >
              <HiX size={20} />
            </button>
          )}
          {open && (
            <p className="modalDirections">
              {t(
                'To view the next component, hold and drag the card to the side.',
              )}
              <TbSwipe size={24} />
            </p>
          )}

          {open && (
            <CardStack cards={cards} invert={invert} bgColor={bgColor} />
          )}
        </animated.div>
      ))}
    </Container>
  );
};

export default CardsModal;
