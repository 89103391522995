/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Controls, Player } from '@lottiefiles/react-lottie-player';

import { RiOrganizationChart } from 'react-icons/ri';
import { ClassContainer } from './styles';
import CardsModal from '../../../../../../components/CardsModal';
import { useToast } from '../../../../../../hooks/toast';

import Cards from './dataMotors';

const Motors: React.FC = () => {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [showNextButton, setShowNextButton] = useState(false);

  useEffect(() => {
    if (showNextButton === true) {
      addToast({
        type: 'success',
        label: t("Now we're talking!"),
        description: t("Click on the 'next' button to continue!"),
      });
    }
  }, [addToast, showNextButton, t]);

  return (
    <ClassContainer showNextButton={showNextButton}>
      <h1 style={{ marginBottom: '0.5rem' }}>{t('Motors')}</h1>
      {/* <h2>{t('Basic Programming:')}</h2> */}
      <Player
        autoplay
        loop
        src="https://learnfirst-bucket.s3.us-east-2.amazonaws.com/data.json"
        style={{ height: '250px', width: '250px', margin: '1rem 0' }}
      >
        <Controls visible={false} />
      </Player>
      <p>
        {t(
          "The 'motors' block category is one of the most important and commonly used to control the parts that bring robots to life. Motors allow robots to move by rotating wheels, moving arms, and other components.",
        )}
      </p>
      <p>
        {t(
          "The blocks in the 'motors' category include action blocks such as 'Rotate Motor' or 'Stop Motor' that allow users to control the speed, direction, and power of the motors, as well as accelerate or brake the movement.",
        )}
      </p>
      <p>
        {t(
          "Additionally, the blocks in the 'motors' category can be combined with other blocks, such as logic blocks and loops, to create more complex programs and automate specific tasks.",
        )}
      </p>

      <p className="modalStartText">{t('Click the button below to start:')}</p>
      <CardsModal
        Icon={RiOrganizationChart}
        bgColor="#d7607e"
        propBorderColor="#f296ad"
        setShowNextButton={setShowNextButton}
        invert
        cards={Cards}
      />
    </ClassContainer>
  );
};

export default Motors;
