import React from 'react';

import { Screen, Container } from './styles';

const Roborio: React.FC = () => {
  return (
    <Screen>
      <Container>
        <h1>NI roboRIO 2.0</h1>
        {/* <h2>Basic Programming:</h2> */}
        <p>
          For now, we just need to get data from the camera to your robot.
          Limelight posts targeting data to Network Tables at 100hz. The default
          update rate for NetworkTables is 10hz, but Limelight automatically
          overwrites it to allow for more frequent data transfer.
        </p>
        <p>
          To get started, we recommend reading four values from the “limelight”
          Network Table as frequently as possible. Our code samples will show
          you exactly how to do this. The offsets to your target (in degrees)
          are sent as “tx” and “ty”. These can be used to turn your robot, turn
          a turret, etc. The target’s area, sent as “ta”, may be used a rough
          indicator of distance to your target. Area is a value between 0 and
          100, where 0 means that your target’s hull area is 0% of the total
          image area, and 100 means that your target’s hull fills the entire
          image. The rotation or “skew” of your target is returned as “ts”. If
          all values are equal to zero, no targets exist.
        </p>
        <p>
          In addition, you may control certain features by setting values in
          NetworkTables. See the complete NT API here: Complete NetworkTables
          API
        </p>
      </Container>
    </Screen>
  );
};

export default Roborio;
