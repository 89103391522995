import styled from 'styled-components';

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  align-items: flex-start;
  justify-content: space-between;

  width: 100vw;
  height: 100%;
  max-height: 100%;

  padding: 4rem;

  @media (max-width: 1224px) {
    padding: 5rem 3rem;
  }

  @media (max-width: 800px) {
    padding: 2rem 1.5rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;

  h1:first-child {
    margin-bottom: 3.5rem;
    color: #051227;
  }

  @media only screen and (max-width: 1500px) {
    h1:first-child {
      margin-bottom: 2rem;
    }
  }
`;
