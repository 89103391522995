import styled from 'styled-components';

export const Screen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;

  padding: 5rem 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 40%;
  height: 100%;
  max-height: 100%;

  a {
    text-decoration: none;

    button {
      display: flex;
      align-items: center;
      width: auto;
      padding: 0.5rem 1.5rem;
    }
  }

  @media (max-width: 800px) {
    width: 80%;
  }
`;

export const SVGContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;

  h1 {
    color: #051227;
    margin-bottom: 0.75rem;
  }

  p {
    font-size: 1rem;
    text-align: center;
  }
`;
