interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'movement/b11.png',
    title: 'Start Movement',
    text:
      'This block is used to keep the motors running continuously until another block is added to stop them in your code, while also setting a speed. It has two parameters indicating the speed of each motor.',
  },
  {
    image: 'movement/b10.png',
    title: 'Start Movement',
    text:
      'This block is used to make the motors run continuously in a custom direction until another block is added to stop them in your code, while also setting a speed. It has two parameters: the first one determines the direction of movement, and the second one sets the speed of the motors.',
  },
  {
    image: 'movement/b9.png',
    title: 'Start Movement',
    text:
      'This block is used to make the motors run until they complete a certain number of rotations, degrees, or seconds with different speeds for each motor. You can set a speed of 70% on one motor and 30% on another to make the robot perform a turn, for example. It has four parameters: the first one specifies the value that will be considered until the motors stop, the second one indicates the unit that will be used, and the third and fourth parameters represent the speeds of each motor.',
  },
  {
    image: 'movement/b8.png',
    title: 'Start Movement',
    text:
      'This block is a combination of several blocks. It is used to make the motors run in a custom direction until they complete a certain number of rotations, degrees, or seconds, while also setting a speed. It has four parameters: the first one determines the direction of movement, the second one specifies the value that will be considered until the motor stops, the third one indicates the unit that will be used, and the fourth one sets the speed of the motors.',
  },
  {
    image: 'movement/b7.png',
    title: 'Set Position',
    text:
      'This block is used to determine what will happen to the motors after they are stopped. It has one parameter indicating the state of the motors when they stop.',
  },
  {
    image: 'movement/b6.png',
    title: 'Set Speed',
    text:
      'This block is used to set the speed of the motors using a percentage. It is usually not necessary to use 100% for most tasks. It has one parameter indicating the speed of the motors.',
  },
  {
    image: 'movement/b5.png',
    title: 'Stop Motors',
    text: 'This block is used to turn off the motors.',
  },
  {
    image: 'movement/b4.png',
    title: 'Start Movement',
    text:
      'This block is used to keep the motors running continuously until another block to stop them is added to your code. It has one parameter indicating the direction of movement.',
  },
  {
    image: 'movement/b3.png',
    title: 'Move in a direction',
    text:
      'This block is used to make the motors move in a custom direction until they complete a certain number of rotations, degrees, or seconds. It has three parameters: the first one determines the direction of the movement, the second one specifies the value that will be considered until the motors stop, and the third one indicates the unit that will be used.',
  },
  {
    image: 'movement/b2.png',
    title: 'Move Motors',
    text:
      'This block is used to make the motors move forward or backward until they complete a certain number of rotations, degrees, or seconds. It has three parameters: the first one determines the direction of the movement, the second one specifies the value that will be considered until the motors stop, and the third one indicates the unit that will be used.',
  },
  {
    image: 'movement/b1.png',
    title: 'Set Ports',
    text:
      'This first block is used to set which motors will be used. Typically, you would specify the two large motors that will make the robot move. It has two parameters that indicate the ports of the motors you want to use.',
  },
];

export default Cards;
