import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  HiHeart,
  HiOutlineClipboardCopy,
  HiOutlineHeart,
  HiOutlineNewspaper,
} from 'react-icons/hi';
import { Link, useRouteMatch } from 'react-router-dom';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../hooks/authentication';
import { useToast } from '../../hooks/toast';

import api from '../../services/api';

import {
  Screen,
  Container,
  ComponentsContainer,
  Component,
  DetailContainer,
  NameContainer,
  LeftContainer,
  RightContainer,
  InfoCard,
  AreaBadge,
  ActionsContainer,
  Action,
} from './styles';
import ArrowLeft from '../../components/ArrowComponents/ArrowLeft';
import ArrowRight from '../../components/ArrowComponents/ArrowRight';

interface Area {
  id: string;
  name: string;
}

interface ComponentArea {
  id: string;
  name: string;
  avatar_url: string;
  area: Area;
}

interface ComponentCategory {
  id: string;
  name: string;
  avatar_url: string;
  componentAreas: ComponentArea[];
}

interface Component {
  id: string;
  name: string;
  description: string;
  avatar_url: string;
}

interface CategoryParams {
  component_category_id: string;
}

const LibraryCategory: React.FC = () => {
  const [components, setComponents] = useState<Component[]>([] as Component[]);
  const [
    componentCategory,
    setComponentCategory,
  ] = useState<ComponentCategory>();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isSaved, setIsSaved] = useState(false);
  const [fetched, setFetched] = useState(false);

  const { t } = useTranslation();

  const { addToast } = useToast();
  const { user } = useAuthentication();
  const { params } = useRouteMatch<CategoryParams>();

  useEffect(() => {
    async function loadComponents(): Promise<void> {
      const response = await api.get(
        `/library/components/${params.component_category_id}`,
      );

      setComponents(response.data);
    }
    async function loadCategory(): Promise<void> {
      const response = await api.get(
        `/library/component-category/${params.component_category_id}`,
      );

      setComponentCategory(response.data);
      setFetched(true);
    }

    loadComponents();
    loadCategory();
  }, [params.component_category_id]);

  useEffect(() => {
    async function checkIsSaved(): Promise<void> {
      const response = await api.get(
        `/library/check-user-component/${user.id}/${components[activeIndex].id}`,
      );

      response.data ? setIsSaved(true) : setIsSaved(false);
    }
    if (user && components.length > 0) {
      checkIsSaved();
    }
  }, [activeIndex, components, user]);

  const splittedName = useMemo(() => {
    return components.length > 0
      ? t(components[activeIndex].name).toString().split(/ (.*)/)
      : '';
  }, [activeIndex, components, t]);

  const handleAddToast = useCallback(() => {
    addToast({
      type: 'success',
      label: t('Link copied!'),
    });
  }, [addToast, t]);

  const handleSaveComponent = useCallback(
    async (component_id: string) => {
      try {
        await api.post('/library/save-component', {
          user_id: user.id,
          component_id,
        });

        setIsSaved(true);

        addToast({
          type: 'success',
          label: t('Component Saved!'),
        });
      } catch (error) {
        const errorMessage = error.response.data.message as string;

        addToast({
          type: 'error',
          label: errorMessage,
          description: t('Please, try again.'),
        });
      }
    },
    [addToast, t, user?.id],
  );

  const handleUnsaveComponent = useCallback(
    async (component_id: string) => {
      try {
        await api.delete(`/library/user-component/${user.id}/${component_id}`);

        setIsSaved(false);

        addToast({
          type: 'success',
          label: t('Component Removed!'),
        });
      } catch (error) {
        const errorMessage = error.response.data.message as string;

        addToast({
          type: 'error',
          label: errorMessage,
          description: t('Please, try again.'),
        });
      }
    },
    [addToast, t, user?.id],
  );

  return (
    <Screen>
      <Container>
        {fetched && components.length > 0 && (
          <>
            <LeftContainer>
              <DetailContainer>
                <img src={components[activeIndex].avatar_url} alt="" />
                <NameContainer>
                  <h1>{splittedName[0]}</h1>
                  <h2>{splittedName[1]}</h2>
                  <ActionsContainer>
                    {/* <Action>
                      <Link
                        to={`/library/${components[activeIndex].name.replace(
                          /\s/g,
                          '-',
                        )}`}
                      >
                        <HiOutlineNewspaper size={24} />
                        <p>{t('Details')}</p>
                      </Link>
                    </Action> */}
                    {user && (
                      <>
                        {isSaved ? (
                          <Action
                            onClick={
                              () =>
                                handleUnsaveComponent(
                                  components[activeIndex].id,
                                )
                              // eslint-disable-next-line react/jsx-curly-newline
                            }
                          >
                            <HiHeart size={24} />
                            <p>{t('Unsave')}</p>
                          </Action>
                        ) : (
                          <Action
                            onClick={
                              () =>
                                handleSaveComponent(components[activeIndex].id)
                              // eslint-disable-next-line react/jsx-curly-newline
                            }
                          >
                            <HiOutlineHeart size={24} />
                            <p>{t('Save')}</p>
                          </Action>
                        )}
                      </>
                    )}
                    <CopyToClipboard
                      text={window.location.href}
                      onCopy={handleAddToast}
                    >
                      <Action>
                        <HiOutlineClipboardCopy size={24} />
                        <p>{t('Share')}</p>
                      </Action>
                    </CopyToClipboard>
                  </ActionsContainer>
                </NameContainer>
              </DetailContainer>

              <ComponentsContainer>
                <ScrollMenu LeftArrow={ArrowLeft} RightArrow={ArrowRight}>
                  {components.map((component, index) => (
                    <Component
                      itemId={component.id}
                      key={component.id}
                      onClick={() => setActiveIndex(index)}
                    >
                      <div>
                        <img src={component.avatar_url} alt="" />
                      </div>
                      <p>{t(component.name)}</p>
                    </Component>
                  ))}
                </ScrollMenu>
              </ComponentsContainer>
            </LeftContainer>
            <RightContainer>
              <InfoCard>
                {components.length > 0 && (
                  <>
                    <h3>{t('Overview')}</h3>
                    <p>{t(components[activeIndex].description)}</p>
                    {componentCategory &&
                      componentCategory.componentAreas.map(componentArea => (
                        <AreaBadge key={componentArea.id}>
                          <p>{t(componentArea.area.name)}</p>
                        </AreaBadge>
                      ))}
                  </>
                )}
              </InfoCard>
              {/* <InfoCardFooter>
                <FooterLeft>
                  <IoCartOutline size={28} />
                  <p>{t('Shop')}</p>
                </FooterLeft>
                <a href="https://www.youtube.com/" target="blank">
                  {t('Buy')}
                </a>
              </InfoCardFooter> */}
            </RightContainer>
          </>
        )}
        {fetched && components.length <= 0 && (
          <h1 style={{ color: '#051227', fontWeight: 500 }}>
            {t('No items available at the moment')}
          </h1>
        )}
      </Container>
    </Screen>
  );
};

export default LibraryCategory;
