import React from 'react';
import { useTranslation } from 'react-i18next';

import { CodeBlock } from '@atlaskit/code';

import { ClassContainer } from './styles';

const WhatIsLEGO: React.FC = () => {
  const { t } = useTranslation();

  const firstJavaCode = `class Main {\n\u0020\u0020public static void main (String [] args) {\n\u0020\u0020\u0020\u0020// Declare the age\n\u0020\u0020\u0020\u0020int age;\n\u0020\u0020\u0020\u0020age = 15;\n\n\u0020\u0020\u0020\u0020// Print the age\n\u0020\u0020\u0020\u0020System.out.println(age);\n\u0020\u0020}\n}`;

  const secondJavaCode = `class Main {\n\u0020\u0020public static void main (String [] args) {\n\u0020\u0020\u0020\u0020// Print the age\n\u0020\u0020\u0020\u0020int firstAge = 20;\n\u0020\u0020\u0020\u0020System.out.println(firstAge);\n\n\u0020\u0020\u0020\u0020// Generane an age in the next year\n\u0020\u0020\u0020\u0020int ageInNextYear;\n\u0020\u0020\u0020\u0020ageInNextYear = firstAge + 1;\n\n\u0020\u0020\u0020\u0020// Print the second age\n\u0020\u0020\u0020\u0020System.out.println(ageInNextYear);\n\u0020\u0020}\n}`;

  return (
    <ClassContainer>
      <h1>{t('Variables')}</h1>
      <p>
        {t(
          'Variables are fundamental elements in programming. They serve as "containers" to store and manipulate data in a program. Imagine them as boxes where you can temporarily store information. In this lesson, we will explore what variables are, how to declare them, assign values, and how they are used in programming.',
        )}
      </p>
      <p>
        {t(
          'For example, you can have a variable called "age" that stores the number 25. You can use this variable in calculations, compare it to other values, and even change its value throughout the program.',
        )}
      </p>
      <p>
        {t(
          "Before using a variable, you need to declare it. Declaring a variable is like giving a name to the box where you want to store something. In many programming languages, you also need to specify the data type that the variable will contain, but we'll see that shortly.",
        )}
      </p>
      <p>{t("Here's an example in Python of how to declare a variable:")}</p>
      <CodeBlock
        language="python"
        text={t(
          'age = 25  # Declares a variable named "age" and assigns the value 25 to it',
        )}
        shouldWrapLongLines
      />
      <p
        style={{
          marginTop: '2rem',
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('Assigning Values and Manipulating Variables')}
      </p>
      <p>
        {t(
          "Once you have declared a variable, you can assign values to it or modify its value throughout the program. Here's how to do it in Python:",
        )}
      </p>
      <CodeBlock
        language="python"
        text={t(
          `age = 25  # Assigns the value 25 to the variable "age"\n\nage = age + 1  # Increments the value of the variable "age" by 1\n\nname = "Alice"  # Declares a variable called "name" and assigns the text "Alice" to it`,
        )}
        shouldWrapLongLines
      />
      <p
        style={{
          marginTop: '2rem',
        }}
      >
        {t(
          'You can use variables in calculations, text combinations, conditional decisions, and much more. They provide flexibility to your code, allowing it to adapt to different situations.',
        )}
      </p>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('Data Types')}
      </h2>
      <p>
        {t(
          "Variables can be categorized into two main groups: strongly typed variables and loosely typed variables. Furthermore, each type of variable (strong or loose) can contain different data types. Let's explore these concepts in more detail.",
        )}
      </p>
      <h3 style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('Typed Variables')}
      </h3>
      <p>
        {t(
          "Typed variables, also known as strongly typed variables, are those in which the data type of the variable is explicitly declared and cannot be changed during the program's execution. This means that a typed variable can only hold values compatible with the declared type. Programming languages that use this concept are called statically typed programming languages.",
        )}
      </p>
      <p
        style={{
          marginBottom: '1rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('Examples of Types:')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('Integers (int): ')}
        </span>
        {t('Store integer numbers, such as 1, 42, -10.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('Real (float or double): ')}
        </span>
        {t('Store decimal numbers, like 3.14, -0.5, 2.0.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('Boolean (bool): ')}
        </span>
        {t('Store boolean values, which can be true or false.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('Character (char): ')}
        </span>
        {t("Store individual characters, like 'A,' 'b,' '$.'")}
      </p>
      <p>
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {'Strings (string): '}
        </span>
        {t("Store sequences of characters, like 'Hello, world!'")}
      </p>
      <p>
        {t(
          "But this division is a particularity of the programming language. The goal is to optimize memory usage. In some languages, it's not necessary to specify the data type of the variable; they are identified dynamically.",
        )}
      </p>
      <p>
        {t(
          'Each programming language has its way of "typing" variables, and there are languages that don\'t type them. However, since we could use typed languages like Java  in robotics, let\'s present an example of how to declare a typed variable and display it on your screen.',
        )}
      </p>
      <p
        style={{
          marginBottom: '1rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('Java Example')}
      </p>
      <CodeBlock language="java" text={t(firstJavaCode)} highlight="4-5,8" />
      <p
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('More complex example')}
      </p>
      <CodeBlock language="java" text={t(secondJavaCode)} />
    </ClassContainer>
  );
};

export default WhatIsLEGO;
