import styled, { css } from 'styled-components';

interface MenuProps {
  isOpen: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1400px) {
    margin-bottom: 3rem;
  }
`;

export const MenuNav = styled.div<MenuProps>`
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 1rem;
  border: 2px solid #eef0f3;
  border-radius: 1rem;

  transition: all 0.3s ease-in-out;
  cursor: pointer;

  p {
    color: #051227;
    font-weight: 500;
  }

  svg {
    color: #051227;
  }

  &:hover {
    border-color: transparent;

    border-color: #1d62d1;
  }

  ${props =>
    props.isOpen &&
    css`
      /* background-color: #e8effa; */
      border-color: #1d62d1;
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 1rem;
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 1.5rem;

  button {
    text-decoration: none;
    text-align: left;

    color: #091d3f;
    background: none;
    border: 0;

    transition: 0.2s ease-in-out;

    &:hover {
      color: #051227;
    }
  }

  button + button {
    margin-top: 1rem;
  }
`;
