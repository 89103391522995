import React, { useState } from 'react';

import { VisibilityContext } from 'react-horizontal-scrolling-menu';

import { IoIosArrowBack } from 'react-icons/io';

import Arrow from '..';

const ArrowLeft: React.FC = () => {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete,
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !initComplete || (initComplete && isFirstItemVisible),
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()}>
      <IoIosArrowBack size={40} />
    </Arrow>
  );
};

export default ArrowLeft;
