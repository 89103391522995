import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  button {
    margin: 0 !important;
  }

  .slider {
    -webkit-appearance: none;
    margin: 1.5rem 0;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: #e0e4ff;
    outline: none;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #174ea7;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #174ea7;
    cursor: pointer;
  }
`;
