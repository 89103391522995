import React, { useCallback } from 'react';
import ScratchBlocks from 'scratchblocks-react';
import scratchblocks from 'scratchblocks';
import es from 'scratchblocks/locales/es.json';
import pt from 'scratchblocks/locales/pt-br.json';

import { useTranslation } from 'react-i18next';
import { EditContainer, ScratchBlocksContainer } from './styles';
import { useToast } from '../../../../../../../hooks/toast';

scratchblocks.loadLanguages({ es, pt });

interface TabsProps {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
}

const Events: React.FC<TabsProps> = ({ code, setCode }) => {
  const { addToast } = useToast();

  const { t } = useTranslation();

  const setNewText = useCallback(
    (text: string) => {
      if (!code.includes(text)) {
        if (
          code.includes('when button (') &&
          !code.includes('start motor at (')
        ) {
          addToast({
            type: 'error',
            label: t('Not Yet'),
            description: t('Add a motor block first'),
          });
        } else if (
          code.includes('when button (') &&
          code.includes('(A v) start motor') &&
          code.includes('(D v) start motor')
        ) {
          addToast({
            type: 'error',
            label: t('Not Yet'),
            description: t('You have already added two blocks to the button'),
          });
        } else {
          setCode(prevState =>
            prevState.length > 0 ? `${prevState}\n${text}` : `${text}`,
          );
        }
      }
    },
    [addToast, code, setCode, t],
  );

  return (
    <EditContainer>
      <ScratchBlocksContainer
        blockStyle="scratch3"
        languages={['en', 'es', 'pt']}
        onClick={() => setNewText('when button (up v) pressed')}
      >
        {`
              ${t('when button (up v) pressed')}
            `}
      </ScratchBlocksContainer>
      <ScratchBlocksContainer
        blockStyle="scratch3"
        languages={['en', 'es', 'pt']}
        onClick={() => setNewText('when button (down v) pressed')}
      >
        {`
              ${t('when button (down v) pressed')}
            `}
      </ScratchBlocksContainer>
    </EditContainer>
  );
};

export default Events;
