import React, { useRef, useState, useEffect } from 'react';

import { Container } from './styles';

interface CircularProgressbarProps {
  size: number;
  inicialProgress: number;
  strokeWidth: number;
  circleBgStroke: string;
  circleProgressStroke: string;
}

const CircularProgressbar: React.FC<CircularProgressbarProps> = ({
  size,
  inicialProgress,
  strokeWidth,
  circleBgStroke,
  circleProgressStroke,
}) => {
  const center = size / 2;

  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  const [offset, setOffset] = useState(0);
  const [progress, setProgress] = useState(0);

  const circleRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setProgress(inicialProgress);
    }, 300);

    const progressOffset = ((100 - progress) / 100) * circumference;

    setOffset(progressOffset);
  }, [circumference, inicialProgress, progress]);

  return (
    <Container>
      <svg width={size} height={size}>
        <circle
          className="circleBg"
          stroke={circleBgStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className="circleProgress"
          stroke={circleProgressStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          ref={circleRef}
          strokeDashoffset={offset}
          strokeLinecap="round"
        />
      </svg>
      <p>{`${progress}%`}</p>
    </Container>
  );
};

export default CircularProgressbar;
