const Tabs = [
  {
    name: 'Events',
  },
  {
    name: 'Display',
  },
];

export default { Tabs };
