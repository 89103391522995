import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { CodeBlock } from '@atlaskit/code';
import { AtlaskitThemeProvider } from '@atlaskit/theme';

import { BgImage, ClassContainer } from './styles';

const WhatIsLEGO: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Possibilities and Applications')}</h1>
      <p>
        {t(
          'Programmers are behind nearly everything involving technology. They can venture into various areas such as:',
        )}
      </p>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('Mobile Apps')}
      </h2>
      <p>
        {t(
          'The mobile apps that are part of our daily lives are built by programmers. From social media and streaming apps to productivity and shopping apps, there is an endless array of products in this market. With programming knowledge, you can even create an app to streamline processes within your robotics team!',
        )}
      </p>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('Video Games')}
      </h2>
      <p>
        {t(
          'Games are developed by programmers who create graphics, physics, game logic, and interactivity. They range from simpler 2D games to complex 3D titles.',
        )}
      </p>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('Web Development')}
      </h2>
      <p>
        {t(
          'Web programming is one of the most popular nowadays. The difference between this type of system and others is that it is developed using web technologies such as HTML, CSS, JavaScript, and a server-side language.',
        )}
      </p>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('Artificial Intelligence (AI) and Machine Learning')}
      </h2>
      <p>
        {t(
          'Programmers can create systems that learn from data and make autonomous decisions, used in chatbots, autonomous cars, and much more.',
        )}
      </p>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>{t('Robotics')}</h2>
      <p>
        {t(
          'Lastly, but not least, we have robotics. Here, programming plays an essential role in the creation, control, and automation of robots for a wide range of actions and tasks, such as movement, object manipulation, sensor control, and more.',
        )}
      </p>
      <p>
        {t(
          'In the FRC category, it is possible to program using the following languages: LabVIEW, C++, and Java (officially, although there are teams that use others, such as C# and Python, for example).',
        )}
      </p>
      <p>
        {t(
          'Programming is the language that translates ideas into the digital world, empowering you to shape the future with possibilities as vast as your creativity. So, dive into this world and start turning your visions into reality, line by line!',
        )}
      </p>
    </ClassContainer>
  );
};

export default WhatIsLEGO;
