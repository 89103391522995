/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuthentication } from '../../hooks/authentication';

import Assignments from '../../routes/assignments';
import api from '../../services/api';

interface InteractiveProps {
  content: Assignment;
  nextContent?: string;
}

interface AssignmentProp {
  path: string;
  component: React.FC<InteractiveProps>;
}

interface Answer {
  id: string;
  placeholder: string;
  correct: boolean;
}

interface Question {
  id: string;
  placeholder: string;
  answers: Answer[];
}

interface Assignment {
  id: string;
  title: string;
  instructions: string;
  module_level_id: string;
  module_level?: Level;
  questions: Question[];
  order: number;
}

interface Level {
  id: string;
  name: string;
  area_module_id: string;
  area_name: string;
  color: string;
  progress?: number;
  assignments: Assignment[];
}

interface LevelProgress {
  id: string;
  completed: boolean;
  level: Level;
}

interface AssignmentProgress {
  id: string;
  completed: boolean;
}

const Assignment: React.FC = () => {
  const [contentProp, setContentProp] = useState<AssignmentProp>();
  const [content, setContent] = useState<Assignment>();
  const [nextContent, setNextContent] = useState<string>();

  const { pathname } = useLocation();
  const { user } = useAuthentication();
  const history = useHistory();

  const reformatLevelName = useCallback((name: string) => {
    return name.replaceAll('-', ' ');
  }, []);

  useEffect(() => {
    async function findContentProp(): Promise<void> {
      const findContent = Assignments.find(ass => ass.path === pathname);

      if (findContent) {
        setContentProp(findContent);
      }
    }

    async function loadAssignment(): Promise<void> {
      // check if user has level progress (if not - shouldn't be here)
      const courseName = pathname.split('/')[3];
      const areaName = pathname.split('/')[4];
      const levelName = pathname.split('/')[5];

      const responseLevel: Level = await (
        await api.get(
          `/courses/levels/${reformatLevelName(courseName)}/${reformatLevelName(
            areaName,
          )}/${reformatLevelName(levelName)}`,
        )
      ).data;

      const dbLevelProgress: LevelProgress = await (
        await api.get(
          `/learn/user-level-progress/${user.id}/${responseLevel?.id}`,
        )
      ).data;

      if (!dbLevelProgress) {
        // put message like "you should enroll in a course before..."
        history.push(`/levels/${responseLevel?.area_module_id}`);
      } else {
        const contentName = pathname
          .substr(pathname.lastIndexOf('/') + 1)
          .replaceAll('-', ' ');
        const dbAssignment: Assignment = await (
          await api.get(`/courses/content/${contentName}/${responseLevel.id}`)
        ).data;
        setContent(dbAssignment);
        const dbAssignmentProgress: AssignmentProgress = await (
          await api.get(
            `/learn/user-content-progress/${user.id}/${dbAssignment.id}`,
          )
        ).data;
        if (!dbAssignmentProgress) {
          await api.post('/learn/assignment-progress', {
            assignment_id: dbAssignment.id,
            level_progress_id: dbLevelProgress.id,
            user_id: user.id,
          });
        }
        const responseNext = await api.get(
          `/courses/next-content/${dbAssignment.module_level_id}/${dbAssignment.order}`,
        );
        if (responseNext.data) {
          const changePathname = pathname.replace(
            pathname.split('/')[2],
            responseNext.data.type,
          );

          const newPathname = changePathname.replace(
            pathname.substr(pathname.lastIndexOf('/') + 1),
            responseNext.data.title.replaceAll(' ', '-'),
          );

          setNextContent(newPathname);
        }
      }
    }

    findContentProp();
    loadAssignment();
  }, [history, pathname, reformatLevelName, user.id]);

  return (
    <>
      {contentProp && content && (
        <contentProp.component content={content} nextContent={nextContent} />
      )}
    </>
  );
};

export default Assignment;
