/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Controls, Player } from '@lottiefiles/react-lottie-player';

import { RiOrganizationChart } from 'react-icons/ri';
import { ClassContainer } from './styles';
import CardsModal from '../../../../../../components/CardsModal';
import { useToast } from '../../../../../../hooks/toast';

import Cards from './dataVariables';

const Variables: React.FC = () => {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [showNextButton, setShowNextButton] = useState(false);

  useEffect(() => {
    if (showNextButton === true) {
      addToast({
        type: 'success',
        label: t("Now we're talking!"),
        description: t("Click on the 'next' button to continue!"),
      });
    }
  }, [addToast, showNextButton, t]);

  return (
    <ClassContainer showNextButton={showNextButton}>
      <h1 style={{ marginBottom: '0.5rem' }}>{t('Variables')}</h1>
      {/* <h2>{t('Basic Programming:')}</h2> */}
      <Player
        autoplay
        loop
        src="https://learnfirst-bucket.s3.us-east-2.amazonaws.com/analysis.json"
        style={{ height: '150px', width: '250px', marginBottom: '1rem' }}
      >
        <Controls visible={false} />
      </Player>
      <p>
        {t(
          'This category of blocks is used to store values and data that can be used throughout the program. With these variables, you can share values between different parts of your code, maintaining or even changing those values based on robot actions and sensor information.',
        )}
      </p>
      <p>
        {t(
          "To use a variable in your program, you first need to declare it by clicking on “Create a variable”. Once you have declared the variable, you can use it in other blocks, such as in mathematical operations, logical conditions, or displaying information on the robot's screen.",
        )}
      </p>
      <p>
        {t(
          'There are also lists, a special category of variables available in Lego Mindstorms programming. They are used to store a collection of values, such as a list of numbers, a list of names, or other values.',
        )}
      </p>
      <p>
        {t(
          'When working with lists, you can add, remove, and modify elements individually or as a whole. You can also perform operations on the entire list, such as changing its order or counting the elements.',
        )}
      </p>
      <p>
        {t(
          'For example, in a fruit sorting robot, you can store a list of fruit samples and their characteristics for later classification based on this data.',
        )}
      </p>

      <p className="modalStartText">{t('Click the button below to start:')}</p>
      <CardsModal
        Icon={RiOrganizationChart}
        bgColor="#d7607e"
        propBorderColor="#f296ad"
        setShowNextButton={setShowNextButton}
        invert
        cards={Cards}
      />
    </ClassContainer>
  );
};

export default Variables;
