import {
  AiOutlineBoxPlot,
  AiOutlineCode,
  AiOutlineControl,
  AiOutlineFileSearch,
} from 'react-icons/ai';
import { VscTools } from 'react-icons/vsc';
import { BsBricks, BsFileText, BsPlug } from 'react-icons/bs';
import { CgSupport } from 'react-icons/cg';
import { BiAnalyse, BiCog, BiWind } from 'react-icons/bi';
import {
  RiCaravanLine,
  RiMoneyDollarCircleLine,
  RiNodeTree,
  RiRemoteControlLine,
  RiRouterLine,
  RiShapeLine,
  RiSignalTowerLine,
} from 'react-icons/ri';
import {
  HiOutlineChip,
  HiOutlineCog,
  HiOutlineMail,
  HiOutlineMicrophone,
  HiWifi,
} from 'react-icons/hi';
import { IoWalletOutline } from 'react-icons/io5';
import { TbEngine } from 'react-icons/tb';

const dataCategories = [
  // {
  //   name: 'Motors',
  //   icon: AiOutlineBoxPlot,
  // },
  {
    name: 'Controllers',
    icon: AiOutlineControl,
  },
  {
    name: 'Tools',
    icon: VscTools,
  },
  {
    name: 'Wheels',
    icon: CgSupport,
  },
  {
    name: 'Wires',
    icon: BsPlug,
  },
  {
    name: 'Gearboxes',
    icon: BiCog,
  },
  {
    name: 'Bolts',
    icon: BiAnalyse,
  },
  {
    name: 'Pistons',
    icon: BiWind,
  },
  {
    name: 'Control',
    icon: HiOutlineChip,
  },
  {
    name: 'Motors',
    icon: TbEngine,
  },
  {
    name: 'Sensors',
    icon: RiRouterLine,
  },
  {
    name: 'Beams',
    icon: RiShapeLine,
  },
  {
    name: 'Connectors',
    icon: RiNodeTree,
  },
  {
    name: 'Functional',
    icon: HiOutlineCog,
  },
  {
    name: 'Essays',
    icon: BsFileText,
  },
  {
    name: 'Interview',
    icon: HiOutlineMicrophone,
  },
  {
    name: 'Letters',
    icon: HiOutlineMail,
  },
  {
    name: 'Universities',
    icon: AiOutlineFileSearch,
  },
  {
    name: 'Scholarships',
    icon: RiMoneyDollarCircleLine,
  },
  {
    name: 'Costs',
    icon: IoWalletOutline,
  },
];

export default dataCategories;
