/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import ScratchBlocks from 'scratchblocks-react';
import scratchblocks from 'scratchblocks';
import es from 'scratchblocks/locales/es.json';
import pt from 'scratchblocks/locales/pt-br.json';

import { useMediaQuery } from 'react-responsive';
import { useAuthentication } from '../../../../hooks/authentication';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';

import Cases from './cases';

import {
  Screen,
  Container,
  ScratchBlocksContainer,
  BlocksContainer,
  EV3Container,
  OptionsContainer,
  ClearButton,
  RunButton,
  PathButton,
} from './styles';
import Tabs from './CodeTabs';
import Button from '../../../../components/Button';
import InstructionsModal from './InstructionsModal';

interface Level {
  id: string;
  name: string;
  area_module_id: string;
  area_name: string;
  color: string;
  progress?: number;
  assignments: Assignment[];
}

interface Assignment {
  id: string;
  title: string;
  instructions: string;
  module_level_id: string;
  module_level?: Level;
  questions: Question[];
  order: number;
}

interface Answer {
  id: string;
  placeholder: string;
  correct: boolean;
}

interface Question {
  id: string;
  placeholder: string;
  answers: Answer[];
}

interface Connection {
  catId: string;
  id: string;
  correct: boolean;
}

interface AssignmentProgress {
  id: string;
  completed: boolean;
}

interface InteractiveProps {
  content: Assignment;
  nextContent?: string;
}

scratchblocks.loadLanguages({ es, pt });

const SpecificMotors: React.FC<InteractiveProps> = ({
  content,
  nextContent,
}) => {
  const { t } = useTranslation();

  const [code, setCode] = useState(`when button (up v) pressed`);
  const [link, setLink] = useState(
    'https://makecode.mindstorms.com/---run?id=_Rm4Afx74q0L8',
  );
  const [correctCode, setCorrectCode] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);

  const screenRef = useRef<HTMLDivElement>(null);

  const { addToast } = useToast();

  const { user } = useAuthentication();
  const history = useHistory();

  const { pathname } = useLocation();

  const handleCode = useCallback(() => {
    const success = [
      `when button (up v) pressed\n(A v) start motor at (25)% speed::motion\nwhen button (down v) pressed\n(D v) start motor at (50)% speed::motion`,
      `when button (down v) pressed\n(D v) start motor at (50)% speed::motion\nwhen button (up v) pressed\n(A v) start motor at (25)% speed::motion`,
    ];
    const findCase = Cases.find(caseItem => caseItem.text.includes(code));

    if (findCase) {
      setLink(findCase.link);
    }

    if (success.includes(code)) {
      setCorrectCode(true);

      addToast({
        type: 'success',
        label: t('Amazing!'),
        description: t(
          'Test the robot and then click the “Next” button to continue!',
        ),
      });
    }
  }, [addToast, code, t]);

  const formatLevelName = useCallback((name: string) => {
    return name.replaceAll(' ', '-');
  }, []);

  const handleNextContent = useCallback(async () => {
    const dbAssignmentProgress: AssignmentProgress = await (
      await api.get(`/learn/user-content-progress/${user.id}/${content?.id}`)
    ).data;

    if (!dbAssignmentProgress.completed) {
      await api.put('/courses/assignment/complete', {
        assignment_id: content?.id,
        user_id: user.id,
      });
    }

    const isLevelFinished = await (
      await api.get(
        `/courses/level-status/${content?.module_level_id}/${user.id}`,
      )
    ).data;

    if (isLevelFinished) {
      history.push('/finished-level');
    } else if (nextContent) {
      history.push(nextContent);
    } else {
      const courseName = pathname.split('/')[3];
      const areaName = pathname.split('/')[4];
      const levelName = pathname.split('/')[5];

      history.push(
        `/courses/${courseName}/${areaName}/${formatLevelName(levelName)}`,
      );
    }
  }, [
    content?.id,
    content?.module_level_id,
    formatLevelName,
    history,
    nextContent,
    pathname,
    user.id,
  ]);

  return (
    <Screen ref={screenRef}>
      <Container>
        <InstructionsModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
        <EV3Container>
          <iframe
            src={link}
            sandbox="allow-popups allow-forms allow-scripts allow-same-origin"
            frameBorder="0"
            title="ev3"
          />
        </EV3Container>
        <BlocksContainer>
          <ScratchBlocksContainer
            blockStyle="scratch3"
            languages={['en', 'es', 'pt']}
          >
            {t(code)}
          </ScratchBlocksContainer>
          <ClearButton
            type="button"
            onClick={() => setCode('')}
            style={{ marginTop: '2rem', width: 'auto', padding: '0 1rem' }}
          >
            {t('Clear')}
          </ClearButton>
          <RunButton
            type="button"
            onClick={handleCode}
            style={{ marginTop: '2rem', width: 'auto', padding: '0 1rem' }}
          >
            {t('Run')}
          </RunButton>
        </BlocksContainer>
        <OptionsContainer>
          <Tabs code={code} setCode={setCode} />
        </OptionsContainer>
        {/* <Button
          type="button"
          onClick={() => setModalVisible(true)}
          style={{ marginTop: '2rem', width: 'auto', padding: '0 1rem' }}
        >
          {t('Abrir')}
        </Button> */}
        {correctCode ? (
          <PathButton
            type="button"
            onClick={handleNextContent}
            style={{ padding: '0.5rem 1rem', width: 'auto', height: 'auto' }}
          >
            {t('Next')}
          </PathButton>
        ) : (
          <PathButton
            type="button"
            onClick={() => {
              screenRef.current?.scroll({
                top: 0,
                behavior: 'smooth',
              });
              setModalVisible(true);
            }}
            style={{ padding: '0.5rem 1rem', width: 'auto', height: 'auto' }}
          >
            {t('Instructions')}
          </PathButton>
        )}
      </Container>
    </Screen>
  );
};

export default SpecificMotors;
