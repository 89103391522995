import React, { useRef, useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { AiOutlineFieldNumber } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import { Screen, Container, Content } from './styles';

import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import { useAuthentication } from '../../hooks/authentication';

const UserGoal: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const { updateUser } = useAuthentication();
  const history = useHistory();
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (goal: string) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          goal: Yup.number()
            .positive()
            .integer()
            .min(1)
            .required(t('Please, provide a number bigger than 0')),
        });

        await schema.validate(goal, {
          abortEarly: false,
        });

        const response = await api.put('/users/goal', goal);

        updateUser(response.data);

        history.push('/dashboard');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          return;
        }

        const errorMessage = error.response.data.message as string;

        addToast({
          type: 'error',
          label: errorMessage,
          description: t('Please, try again.'),
        });
      }
    },
    [addToast, history, t, updateUser],
  );

  return (
    <Screen>
      <Container>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>{t('Type your weekly goal of classes and tasks')}</h1>

            <Input
              name="goal"
              type="number"
              placeholder="1, 5, 10..."
              icon={AiOutlineFieldNumber}
            />

            <Button type="submit">{t('Continue')}</Button>
          </Form>
        </Content>
      </Container>
    </Screen>
  );
};

export default UserGoal;
