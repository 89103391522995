import React from 'react';
import { useTranslation } from 'react-i18next';

import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { ValueContainer, ClassContainer } from './styles';

const CoreValues: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('FRC Award List')}</h1>
      <p style={{ marginBottom: '0' }}>
        {t(
          "Much is said about the technical aspects of a team, and maybe you've heard this before, but robotics is more than just robots.",
        )}
      </p>
      <Player
        autoplay
        loop
        src="https://learnfirst-bucket.s3.amazonaws.com/frc/badge.json"
        style={{ height: '350px', width: '350px', margin: '0 0' }}
      >
        <Controls visible={false} />
      </Player>
      <p style={{ marginTop: '0' }}>
        {t(
          'Participants in FIRST Robotics Competition are encouraged to enhance their knowledge in entrepreneurship, social initiatives, and public speaking, which brings us to the topic of awards.',
        )}
      </p>
      <p>
        {t(
          "In order for all our efforts throughout the year to be showcased in the best possible way to the competition judges, we must strive to organize, write, build, and present the awards during the tournament. The awards officially start being prepared at the end of the year, when reviews of the previous year's documents are conducted. Then, new models are built based on this.",
        )}
      </p>
      <h2>Machine, Creativity, & Innovation Awards</h2>
      <h3 style={{ color: '#051227' }}>Autonomous Award</h3>
      <p>
        {t(
          "For the team that has shown consistency, confidence, and high robot performance during the autonomous actions period. The evaluation is based on the robot's ability to perceive its surroundings, self-position or possess appropriate mechanisms, as well as execute specific tasks.",
        )}
      </p>
      <h3 style={{ color: '#051227' }}>Creativity Award</h3>
      <p>
        {t('Awards creativity in design, component usage, or game strategy.')}
      </p>
      <h3 style={{ color: '#051227' }}>Excellence in engineering Award</h3>
      <p>
        {t(
          'Granted for an elegant robot feature and its entire design process.',
        )}
      </p>
      <h3 style={{ color: '#051227' }}>Industrial Design Award</h3>
      <p>
        {t(
          'Celebrates the form and functionality of an efficiently designed machine that effectively addresses the challenge posed in the given year.',
        )}
      </p>
      <h3 style={{ color: '#051227' }}>Innovation in Control Award</h3>
      <p>
        {t(
          'This award is given to the robot with an innovative control system or the application of control components (electrical, mechanical, or software) to provide original functions.',
        )}
      </p>
      <h3 style={{ color: '#051227' }}>Quality Award</h3>
      <p>
        {t(
          'Honors the robot with the best robustness in terms of design and manufacturing.',
        )}
      </p>
      <h2>Team Attribute Awards</h2>
      <h3 style={{ color: '#051227' }}>Engineering Inspiration Award</h3>
      <p>
        {t(
          'Celebrates exceptional success in promoting respect and appreciation for engineering, such as bringing new students into STEM fields and increasing the presence of robotics in the community. Typically, a "journal" is kept regarding the team\'s activities. This award secures a spot at the world championship.',
        )}
      </p>
      <h3 style={{ color: '#051227' }}>Gracious Professionalism® Award</h3>
      <p>
        {t(
          "Awards the display of FIRST's Core Values such as the ongoing Gracious Professionalism and their work both on and off the field.",
        )}
      </p>
      <h3 style={{ color: '#051227' }}>Imagery Award</h3>
      <p>
        {t(
          'In honor of Jack Kamen, father of Dean Kamen, for his dedication to the art of illustration and his commitment to FIRST. This award recognizes engineering attractiveness and exceptional visual aesthetic integration of the robot and the team.',
        )}
      </p>
      <h3 style={{ color: '#051227' }}>Judges&apos; Award</h3>
      <p>
        {t(
          "An award given directly from the judges' assessment to teams that demonstrated exceptional performance during the competition days.",
        )}
      </p>
      <h3 style={{ color: '#051227' }}>Rookie All-Star Award</h3>
      <p>
        {t(
          'The Rookie (novice) team that exemplifies the qualities of a good team while also embodying the FIRST mission to inspire students to learn more about science and technology.',
        )}
      </p>
      <h3 style={{ color: '#051227' }}>Team Spirit Award</h3>
      <p>
        {t(
          'An award given for extraordinary enthusiasm, spirit, partnerships, and teamwork, aiming towards the goals of FIRST everywhere: in school, the city, and the community.',
        )}
      </p>
      <h3 style={{ color: '#051227' }}>Team Sustainability Award</h3>
      <p>
        {t(
          'Recognizes the team that has developed environmentally-friendly practices that have a long-lasting, positive impact on their community.',
        )}
      </p>
      <h2>Submitted Awards</h2>
      <h3 style={{ color: '#051227' }}>FIRST Impact Award</h3>
      <p>
        {t(
          "The most significant non-technical award of the competition, as it recognizes a team's efforts in adhering to FIRST principles and serving as a role model for other teams. To win the Impact Award, teams can promote projects and activities in their schools and communities, such as workshops, STEM classes, lectures, and other social events, inspiring young people to pursue careers in STEM fields.",
        )}
      </p>
      <h3 style={{ color: '#051227' }}>Digital Animation Award</h3>
      <p>
        {t(
          'The art of digital animation focused on STEAM is the purpose of this award, providing a challenge related to storytelling, digital design skills, among others.',
        )}
      </p>
      <h3 style={{ color: '#051227' }}>FIRST Dean&apos;s List Award</h3>
      <p>
        {t(
          "This award, also considered one of the highest honors in the competition, is given to two students who have excelled the most in their communities, considering FIRST's principles: teamwork, leadership spirit, and the promotion of science and technology. To be eligible for the award, mentors must submit a text about these two team members, highlighting all the activities they have undertaken. The students nominated for this award will participate in an interview with the judges, where they will be asked questions about the contents mentioned in the submitted essay and their personal lives.",
        )}
      </p>
      <h3 style={{ color: '#051227' }}>Safety Animation Award</h3>
      <p>
        {t(
          'The team must develop an animation that relates safety to the themes addressed in the competition for that year.',
        )}
      </p>
      <h3 style={{ color: '#051227' }}>Woodie Flowers Finalist Award</h3>
      <p>
        {t(
          'This award is one of the most special in the competition. It honors the work of the mentor who, during their journey, has served as the best example to the people within the FIRST community, especially the students on their team, through their communication skills, leadership, and STEM knowledge. To compete for this award, the team must submit a text discussing the qualities of the nominated mentor, highlighting all the activities they have carried out. No interview is required for this award.',
        )}
      </p>
      <h2>Robot Performance Award</h2>
      <h3 style={{ color: '#051227' }}>Finalist</h3>
      <p>
        {t(
          'Received by the teams that have reached the final match of the competition (Regional).',
        )}
      </p>
      <h3 style={{ color: '#051227' }}>Winner</h3>
      <p>
        {t(
          'Lifted by those who have won the competition, earning the title of winner and qualifying to participate in the Championship, where they can compete for the same award on a global level.',
        )}
      </p>
    </ClassContainer>
  );
};

export default CoreValues;
