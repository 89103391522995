/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Controls, Player } from '@lottiefiles/react-lottie-player';

import { RiOrganizationChart } from 'react-icons/ri';
import { ClassContainer } from './styles';
import CardsModal from '../../../../../../components/CardsModal';
import { useToast } from '../../../../../../hooks/toast';

import Cards from './dataSensors';

const Sensors: React.FC = () => {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [showNextButton, setShowNextButton] = useState(false);

  useEffect(() => {
    if (showNextButton === true) {
      addToast({
        type: 'success',
        label: t("Now we're talking!"),
        description: t("Click on the 'next' button to continue!"),
      });
    }
  }, [addToast, showNextButton, t]);

  return (
    <ClassContainer showNextButton={showNextButton}>
      <h1 style={{ marginBottom: '0.5rem' }}>{t('Sensors')}</h1>
      {/* <h2>{t('Basic Programming:')}</h2> */}
      <Player
        autoplay
        loop
        src="https://learnfirst-bucket.s3.us-east-2.amazonaws.com/radio.json"
        style={{ height: '250px', width: '250px', marginBottom: '1rem' }}
      >
        <Controls visible={false} />
      </Player>
      <p>
        {t(
          'The “sensors” category of blocks allows the robot to interact with its surroundings. These blocks are used to detect and measure different aspects of the environment, such as light, sound, distance, and touch.',
        )}
      </p>
      <p>
        {t(
          'The touch sensor, for example, is used to detect when something touches the robot. This can be used to stop the robot when it collides with a wall or to initiate an action when someone presses a button.',
        )}
      </p>
      <p>
        {t('There are three different categories of sensor blocks, which are:')}
      </p>
      <h2>{t('Common Blocks:')}</h2>
      <p>
        {t(
          "These blocks, just like the ones we've seen before, are part of the sequence of instructions in your code.",
        )}
      </p>
      <h2>{t('Condition Blocks:')}</h2>
      <p>
        {t(
          'These hexagonal-shaped blocks are placed inside other blocks to determine whether a condition has been met or not.',
        )}
      </p>
      <h2>{t('Information blocks')}</h2>
      <p>{t('These blocks provide information collected by the sensors.')}</p>

      <p className="modalStartText">{t('Click the button below to start:')}</p>
      <CardsModal
        Icon={RiOrganizationChart}
        bgColor="#d7607e"
        propBorderColor="#f296ad"
        setShowNextButton={setShowNextButton}
        invert
        cards={Cards}
      />
    </ClassContainer>
  );
};

export default Sensors;
