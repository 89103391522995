import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../hooks/authentication';

import dataCourses from '../../data/dataCourses';

import { Screen, Container, Course, Courses } from './styles';
import api from '../../services/api';

interface Course {
  id: string;
  name: string;
  area_modules: Module[];
}

interface Module {
  id: string;
  area: {
    name: string;
  };
  levels: Level[];
}

interface Level {
  id: string;
  name: string;
}

const Teach: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([] as Course[]);

  const { user } = useAuthentication();
  const { t } = useTranslation();

  useEffect(() => {
    async function loadModules(): Promise<void> {
      if (user.permissions.length > 0) {
        dataCourses.forEach(async dataCourse => {
          if (
            user.permissions.some(permission =>
              permission.name.includes(
                dataCourse.name.replaceAll(' ', '-').toLowerCase(),
              ),
            )
          ) {
            const dbCourse: Course = await (
              await api.get(`/courses/name/${dataCourse.name}`)
            ).data;

            setCourses(oldState => [...oldState, dbCourse]);
          }
        });
      }
    }

    loadModules();
  }, [user.permissions]);

  return (
    <Screen>
      <Container>
        <h2>{t('Teach')}</h2>
        <h3>{t('Select a course to create your new task')}</h3>

        <Courses>
          {courses.map((course, index) => (
            <Link key={course.id} to={`/teach/course/${course.name}`}>
              <Course>{t(course.name)}</Course>
            </Link>
          ))}
        </Courses>
      </Container>
    </Screen>
  );
};

export default Teach;
