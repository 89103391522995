import styled from 'styled-components';

export const Screen = styled.div`
  height: 100%;
  max-height: 100%;
  width: 100%;
  position: relative;

  background: #e8effa;

  overflow-y: scroll;
`;

export const Container = styled.div`
  position: relative;
  display: grid;
  grid-template: 1fr / 3fr 1fr;
  height: 100%;
  padding: 6rem;

  @media only screen and (max-width: 1500px) {
    padding: 4rem;
  }

  @media (max-width: 1224px) {
    padding: 4rem 3rem;

    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media (max-width: 800px) {
    padding: 2rem 1.5rem;
  }
`;

export const LeftContainer = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (min-width: 1224px) {
    height: 100%;
    overflow: hidden;
  }

  @media only screen and (max-width: 1400px) {
    justify-content: space-evenly;
  }

  @media only screen and (min-width: 1440px) and (max-width: 1500px) {
    justify-content: space-evenly;
  }

  @media (max-width: 1224px) {
    flex-direction: column-reverse;
    width: 100%;
  }
`;

export const DetailContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 5rem;
  margin-left: 3rem;

  img {
    width: 200px;
    height: auto;
    margin-right: 5rem;
    margin-bottom: 1.5rem;
    -webkit-filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
  }

  @media (max-width: 1500px) {
    margin-bottom: 4rem;

    width: 150px;
  }

  @media (min-width: 1224px) and (max-width: 1500px) {
    img {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: 1224px) {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
    width: 100%;
    margin-bottom: 3rem;

    img {
      margin-right: 0;
      margin-bottom: 1.5rem;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;

  h1,
  h2 {
    line-height: 120%;
    color: #0c2754;
    font-size: 5rem;
  }

  h1 {
    font-weight: 400;
  }

  h2 {
    font-weight: 700;
    color: #091d3f;
  }

  @media only screen and (max-width: 1500px) {
    /* margin-top: 2rem; */

    h1,
    h2 {
      font-size: 3rem;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  margin-top: 2rem;

  * {
    color: #091d3f;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: 800px) {
    justify-content: space-between;
    width: 100%;
  }
`;

export const Action = styled.div`
  display: flex;
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: #8eb1e8;
  }

  & + & {
    margin-left: 3rem;
  }

  svg {
    margin-right: 0.5rem;
  }

  p {
    font-weight: 600;
  }

  a {
    display: flex;
  }

  @media only screen and (max-width: 1500px) {
    & + & {
      margin-left: 1rem;
    }
  }

  @media (max-width: 800px) {
    padding: 0.25rem 0;

    & + & {
      margin-left: 0;
    }
  }

  @media (max-width: 400px) {
    p {
      display: none;
    }
  }
`;

export const ComponentsContainer = styled.div`
  /* width: 80%;
  height: 100%;
  overflow-x: hidden; */

  .react-horizontal-scrolling-menu--item {
    margin-right: 1.5rem;
  }

  div + div {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  div + div::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1224px) {
    /* width: 100%;
    display: flex; */
    margin-bottom: 3rem;
  }
`;

interface ComponentProps {
  itemId: string;
}

export const Component = styled.div<ComponentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border: 3px solid #091d3f;
  border-radius: 2rem;
  padding: 1rem 1rem 2rem;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
  /* box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px; */
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  cursor: pointer;
  width: 150px;
  height: 200px;

  &:hover {
    div {
      img {
        transform: scale(1.1);
      }
    }
  }

  & + & {
    margin-left: 2rem;
  }

  div {
    display: flex;
    align-items: center;
    width: 70px;
    height: 70px;
    margin-bottom: 1.5rem;

    img {
      transition: ease-in 0.2s all;
      width: 100%;
      height: auto;

      -webkit-filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
      filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
    }
  }

  p {
    font-weight: 600;
    color: #1c2d35;
    text-align: center;
  }

  @media only screen and (max-width: 1500px) {
    padding: 0.85rem 1rem 1rem;

    img {
      width: 70px;
      height: auto;
      margin-bottom: 1rem;
    }

    p {
      font-size: 0.85rem;
    }
  }

  @media (max-width: 1224px) {
    padding: 0.75rem;

    width: 120px;
    height: 175px;
    border-radius: 1.5rem;

    & + & {
      margin-left: 1rem;
    }

    div {
      margin-bottom: 1rem;
    }
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: 1 / 2 / 2 / 3;

  @media (max-width: 1224px) {
    grid-area: 2 / 1 / 3 / 2;
    width: 100%;
    padding-bottom: 2rem;
  }
`;

export const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  /* border-radius: 2rem 2rem 0 0; */
  border-radius: 2rem;
  padding: 3rem;
  width: 100%;

  h3 {
    margin-bottom: 1.5rem;
    font-weight: 600;
    color: #1c2d35;
  }

  > p {
    margin-bottom: 1.5rem;
  }

  @media only screen and (max-width: 1500px) {
    padding: 2rem;

    h3 {
      margin-bottom: 1rem;
    }

    > p {
      font-size: 0.9rem;
    }
  }
`;

export const AreaBadge = styled.div`
  background: #e8e8e8;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;

  @media only screen and (max-width: 1500px) {
    padding: 0.25rem 0.5rem;

    p {
      font-size: 0.9rem;
    }
  }
`;

export const InfoCardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #8eb1e8;
  border-radius: 0 0 2rem 2rem;
  padding: 1.5rem 3rem;
  width: 100%;

  a {
    text-decoration: none;
    background: #091d3f;
    border-radius: 0.75rem;
    padding: 0.5rem 1rem;
    color: #fff;
    font-weight: 500;
    transition: 0.3s ease-in-out;

    &:hover {
      background: #0f3169;
    }
  }

  @media only screen and (max-width: 1500px) {
    padding: 1rem 2rem;
  }
`;

export const FooterLeft = styled.div`
  display: flex;
  align-items: center;

  * {
    color: #fff;
  }

  svg {
    margin-right: 0.5rem;
  }

  > p {
    font-weight: 500;
  }
`;
