import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import { Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  options: number[];
}

const RadioButtons: React.FC<InputProps> = ({
  name,
  options,
  icon: Icon,
  ...rest
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue(refs) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const checked = refs.find((ref: any) => ref.checked);

        return checked ? checked.value : null;
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      {options.map((option, index) => (
        <Container gridIndex={index} key={option} isErrored={!!error}>
          {error && (
            <Error title={error}>
              <FiAlertCircle color="#c53030" size={20} />
            </Error>
          )}
          <input
            // eslint-disable-next-line no-return-assign
            ref={elRef => {
              elRef && (inputRefs.current[index] = elRef);
            }}
            type="radio"
            id={`${name}-${option}`}
            name={name}
            value={option}
            defaultChecked={defaultValue === option}
            {...rest}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor={`${name}-${index}`}>
            {Icon && <Icon size={30} />}
          </label>
        </Container>
      ))}
    </>
  );
};

export default RadioButtons;
