import React from 'react';
import { useTranslation } from 'react-i18next';

import { ClassContainer } from './styles';

const WhatIsLEGO: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Understanding Algorithms')}</h1>
      <p>
        {t(
          'In order for the machine to understand your instructions, it needs a more specific language. To make this interpretation between humans and machines, programming languages were developed. But even these languages use a specific logic to be written - this is where algorithms come in.',
        )}
      </p>
      <p>
        {t(
          'The term can be understood as a sequence of reasoning, instructions, or operations to achieve a goal or task, with the requirement that the steps are finite and carried out systematically.',
        )}
      </p>
      <p>
        {t(
          "Seems complex? Don't worry, let's simplify. Some examples of algorithms we can mention are: culinary recipes, instruction manuals, mathematical functions, and even web pages like the one you are reading.",
        )}
      </p>
      <p>
        {t(
          'In the case of a culinary recipe, it includes the necessary ingredients (input data), step-by-step instructions to prepare the recipe (processing or logical instructions), and the final result (the finished dish).',
        )}
      </p>
      <p>
        {t(
          "Although you may not realize it, algorithms are part of your daily life in an intuitive and automatic way as you perform common tasks. Let's go back to the example of changing a lightbulb that we saw earlier.",
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('Start')}
      </p>
      <p
        style={{
          marginBottom: '0',
        }}
      >
        {t('Look for a new lightbulb;')}
      </p>
      <p
        style={{
          marginBottom: '0',
        }}
      >
        {t('Get a ladder;')}
      </p>
      <p
        style={{
          marginBottom: '0',
        }}
      >
        {t('Carry the ladder to the location;')}
      </p>
      <p
        style={{
          marginBottom: '0',
        }}
      >
        {t('Position the ladder;')}
      </p>
      <p
        style={{
          marginBottom: '0',
        }}
      >
        {t('Climb the steps;')}
      </p>
      <p
        style={{
          marginBottom: '0',
        }}
      >
        {t('Stop at the appropriate height;')}
      </p>
      <p
        style={{
          marginBottom: '0',
        }}
      >
        {t('Remove the burned-out lightbulb;')}
      </p>
      <p
        style={{
          marginBottom: '0',
        }}
      >
        {t('Insert the new lightbulb;')}
      </p>
      <p
        style={{
          marginBottom: '0',
        }}
      >
        {t('Get down from the ladder;')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('Turn on the switch;')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        <span style={{ color: '#051227', fontWeight: 'bold' }}>{t('If ')}</span>
        {t("(the lightbulb doesn't turn on), then:")}
      </p>
      <p
        style={{
          marginBottom: '0',
        }}
      >
        {t('Remove the new burned-out lightbulb;')}
      </p>
      <p>{t('Insert another new lightbulb;')}</p>
      <p>
        {t(
          'The way these instructions were organized is similar to how we write the code that the machine will interpret.',
        )}
      </p>
    </ClassContainer>
  );
};

export default WhatIsLEGO;
