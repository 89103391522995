interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'variables/b8.png',
    title: 'Length of the list',
    text:
      'You can use this block to retrieve the length of a list (the number of items it contains). It has one parameter indicating the list you want to query.',
  },
  {
    image: 'variables/b7.png',
    title: 'Item from the list',
    text:
      'You can use this block to retrieve an item from a list. It has two parameters: the first one is the position of the item you want to retrieve, and the second one is the list where the item is located.',
  },
  {
    image: 'variables/b6.png',
    title: 'Replace Items',
    text:
      'With this block, you can replace the text of an existing item in the list with a new text. The number “1” in the block name indicates that it will target the first element of the list. It has three parameters: the first one being the position of the item you want to change, the second one is the list where you want to make the substitution, and the third one is the new text you want to add.',
  },
  {
    image: 'variables/b5.png',
    title: 'Clear All',
    text:
      'With this block, you can remove all items from your list. It has one parameter indicating the list you want to modify.',
  },
  {
    image: 'variables/b4.png',
    title: 'Add Item',
    text:
      'With this block, you can add another text item to the list. It has two parameters: the first one is the text to be added, and the second one is the list where you want to add it.',
  },
  {
    image: 'variables/b3.png',
    title: 'List',
    text:
      'You can use this block to apply the a list of items within other parts of your code.',
  },
  {
    image: 'variables/b2.png',
    title: 'Change Values',
    text: 'With these blocks, you can change or add a value to the variable.',
  },
  {
    image: 'variables/b1.png',
    title: 'Variable',
    text:
      'You can use this block to apply the variable within other parts of your code.',
  },
];

export default Cards;
