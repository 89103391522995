import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

import Dashboard from '../pages/Dashboard';
import Library from '../pages/Library';
import LibraryCategory from '../pages/LibraryCategory';
import Settings from '../pages/Settings';
import MobileProfile from '../pages/MobileProfile';
import Courses from '../pages/Courses';
import Modules from '../pages/Modules';
import Levels from '../pages/Levels';
import LevelDetail from '../pages/Levels/LevelDetail';

import UserNotifications from '../pages/UserNotifications';
import Achievements from '../pages/Achievements';

import ContentsRoutes from './contents';
import ComponentsRoutes from './components';
import Assignments from './assignments';
import CreateAssignment from '../pages/CreateAssignment';
import Questions from '../pages/Questions';
import Teach from '../pages/Teach';
import TeachDetail from '../pages/TeachDetail';
import FinishedLevel from '../pages/FinishedLevel';
import SimpleAssignment from '../pages/SimpleAssignment';
import AssignmentReview from '../pages/AssignmentReview';
import Assignment from '../pages/Assignment';
import Favorites from '../pages/Favorites';
import LibraryCourses from '../pages/LibraryCourses';
import NotFound from '../pages/NotFound';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/signup" component={SignUp} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />

    <Route path="/dashboard" component={Dashboard} RouteNeedsSession />
    <Route
      path="/notifications"
      component={UserNotifications}
      RouteNeedsSession
    />
    <Route path="/achievements" component={Achievements} RouteNeedsSession />
    <Route path="/library" exact component={LibraryCourses} />
    <Route path="/library/course/:course_id" exact component={Library} />
    <Route path="/library/favorites" exact component={Favorites} />
    <Route
      path="/library/category/:component_category_id+"
      component={LibraryCategory}
    />
    <Route path="/courses" exact component={Courses} RouteNeedsSession />
    <Route
      path="/course/modules/:course_id+"
      exact
      component={Modules}
      RouteNeedsSession
    />
    <Route path="/levels/:module_id+" component={Levels} RouteNeedsSession />
    <Route path="/courses/:level+" component={LevelDetail} RouteNeedsSession />
    <Route path="/settings" component={Settings} RouteNeedsSession />
    <Route path="/profile" component={MobileProfile} RouteNeedsSession />

    <Route
      path="/teach"
      exact
      component={Teach}
      RouteNeedsSession
      routeRole="teacher,admin"
    />
    <Route
      path="/teach/course/:course_name+"
      component={TeachDetail}
      RouteNeedsSession
    />
    <Route
      path="/teach/create-assignment/:level_id+"
      component={CreateAssignment}
      RouteNeedsSession
      routeRole="teacher,admin"
    />
    <Route
      path="/teach/questions/:level_id+/:assignment+"
      component={Questions}
      RouteNeedsSession
      routeRole="teacher,admin"
    />
    <Route
      path="/finished-level"
      exact
      component={FinishedLevel}
      RouteNeedsSession
    />
    {ContentsRoutes.map(content => (
      <Route
        key={content.path}
        path={content.path}
        exact
        component={content.component}
        RouteNeedsSession
      />
    ))}
    {ComponentsRoutes.map(component => (
      <Route
        key={component.path}
        path={component.path}
        exact
        component={component.component}
      />
    ))}

    {/* <Route
      path="/course/assignment/:courseName/:areaName/:levelName/:content"
      component={Assignment}
      RouteNeedsSession
    /> */}
    <Route
      path="/course/assignment/:courseName/:areaName/:levelName/:content"
      component={Assignment}
      RouteNeedsSession
    />
    <Route
      path="/course/assignment-review/:courseName/:areaName/:levelName/:content"
      exact
      component={AssignmentReview}
      RouteNeedsSession
    />
    <Route path="*" component={NotFound} NotFound />
  </Switch>
);

export default Routes;
