import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import { Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  endIconOn?: React.ComponentType<IconBaseProps>;
  endIconOff?: React.ComponentType<IconBaseProps>;
}

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  endIconOn: EndIconOn,
  endIconOff: EndIconOff,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [passwordType, setPasswordType] = useState('password');

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleIconEndOn = useCallback(() => {
    setPasswordType('text');
  }, []);

  const handleIconEndOff = useCallback(() => {
    setPasswordType('password');
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error} isFocused={isFocused} isFilled={isFilled}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {Icon && <Icon size={20} />}
      </div>
      <input
        autoComplete="falseasdasd"
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        type={EndIconOn ? passwordType : 'text'}
        {...rest}
      />

      {EndIconOn && passwordType === 'password' && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EndIconOn size={20} onClick={handleIconEndOn} />
        </div>
      )}
      {EndIconOff && passwordType === 'text' && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EndIconOff size={20} onClick={handleIconEndOff} />
        </div>
      )}
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default Input;
