/* eslint-disable prettier/prettier */
import React, { useCallback } from 'react';
import ScratchBlocks from 'scratchblocks-react';
import scratchblocks from 'scratchblocks';
import es from 'scratchblocks/locales/es.json';
import pt from 'scratchblocks/locales/pt-br.json';

import { useTranslation } from 'react-i18next';
import { EditContainer, ScratchBlocksContainer } from './styles';
import { useToast } from '../../../../../../../hooks/toast';

scratchblocks.loadLanguages({ es, pt });

interface TabsProps {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
}

const Motors: React.FC<TabsProps> = ({ code, setCode }) => {
  const { addToast } = useToast();

  const { t } = useTranslation();

  const setNewText = useCallback(
    (text: string) => {
      if (!code.includes(text)) {
        if (
          !code.includes('when button (')
        ) {
          addToast({
            type: 'error',
            label: t('Not Yet'),
            description: t('Add a event block first'),
          });
        } else {
          setCode(prevState => `${prevState}\n${text}`);
        }
      }
    },
    [addToast, code, setCode, t],
  );

  return (
    <EditContainer>
      <ScratchBlocksContainer
        blockStyle="scratch3"
        languages={['en', 'es', 'pt']}
        onClick={() =>
          setNewText('(A v) start motor at (25)% speed::motion')}
      >
        {`
              ${t('(A v) start motor at (25)% speed::motion')}
            `}
      </ScratchBlocksContainer>
      <ScratchBlocksContainer
        blockStyle="scratch3"
        languages={['en', 'es', 'pt']}
        onClick={() =>
          setNewText('(D v) start motor at (50)% speed::motion')}
      >
        {`
              ${t('(D v) start motor at (50)% speed::motion')}
            `}
      </ScratchBlocksContainer>
    </EditContainer>
  );
};

export default Motors;
