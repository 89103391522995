import { IconBaseProps } from 'react-icons';
import { ImBook } from 'react-icons/im';
import api from '../services/api';
import dataCourses from '../data/dataCourses';

interface Assignment {
  id: string;
  title: string;
  module_level_id: string;
  level?: Level;
}

interface Class {
  id: string;
  title: string;
  module_level_id: string;
  level?: Level;
}

interface Level {
  id: string;
  name: string;
  area_module_id: string;
  area_name: string;
  color: string;
  icon: React.ComponentType<IconBaseProps>;
  progress?: number;
  assignments: Assignment[];
  classes: Class[];
}

interface Module {
  id: string;
  area_id: string;
  course_id: string;
  color?: string;
  icon?: React.ComponentType<IconBaseProps>;
  area: {
    name: string;
  };
}

interface Course {
  id: string;
  name: string;
}

export default async function getCourseProps(
  level: Level,
): Promise<Level | undefined> {
  const module: Module = await (
    await api.get(`/courses/module/${level.area_module_id}`)
  ).data;

  const course: Course = await (await api.get(`/courses/${module.course_id}`))
    .data;

  const frontModules = dataCourses.find(data => data.name === course?.name)
    ?.modules;

  if (frontModules) {
    const propsFound = frontModules.find(
      prop => prop.name === module.area.name,
    );

    return propsFound
      ? ({
          ...level,
          color: propsFound?.color,
          icon: propsFound?.icon,
          area_name: module.area.name,
        } as Level)
      : ({
          ...level,
          color: '#b1b1b1',
          icon: ImBook,
        } as Level);
  }

  return undefined;
}
