import Limelight from '../pages/Classes/Programming/B2/Limelight';
import CommandBase from '../pages/Classes/Programming/B2/CommandBase';
import WhatIsLEGO from '../pages/Classes/LEGO/GettingStarted/Introduction/WhatIsLEGO';
import LEGOShowcase from '../pages/Classes/LEGO/GettingStarted/Introduction/LEGOShowcase';
import Control from '../pages/Classes/LEGO/Components/Electric/Control';
import Motors from '../pages/Classes/LEGO/Components/Electric/Motors';
import Sensors from '../pages/Classes/LEGO/Components/Electric/Sensors';
import ExploringBrick from '../pages/Classes/LEGO/Components/Electric/ExploringBrick';
import ExploringSpike from '../pages/Classes/LEGO/Components/Electric/ExploringSpike';
import Beams from '../pages/Classes/LEGO/Components/Pieces/Beams';
import Connectors from '../pages/Classes/LEGO/Components/Pieces/Connectors';
import Functional from '../pages/Classes/LEGO/Components/Pieces/Functional';
import Understanding from '../pages/Classes/LEGO/Programming/Blocks/Understanding';
import MotorsBlocks from '../pages/Classes/LEGO/Programming/Blocks/Motors';
import Movement from '../pages/Classes/LEGO/Programming/Blocks/Movement';
import Screen from '../pages/Classes/LEGO/Programming/Blocks/Screen';
import Sound from '../pages/Classes/LEGO/Programming/Blocks/Sound';
import Events from '../pages/Classes/LEGO/Programming/Blocks/Events';
import ControlBlocks from '../pages/Classes/LEGO/Programming/Blocks/Control';
import SensorsBlocks from '../pages/Classes/LEGO/Programming/Blocks/Sensors';
import Operators from '../pages/Classes/LEGO/Programming/Blocks/Operators';
import Variables from '../pages/Classes/LEGO/Programming/Blocks/Variables';
import MyBlocks from '../pages/Classes/LEGO/Programming/Blocks/MyBlocks';
import RemoteControl from '../pages/Classes/LEGO/Programming/RoboticsRealLife/RemoteControl';
import DetectingObstacles from '../pages/Classes/LEGO/Programming/RoboticsRealLife/DetectingObstacles';
import LineFollower from '../pages/Classes/LEGO/Programming/RoboticsRealLife/LineFollower';
import LineFollowerWPID from '../pages/Classes/LEGO/Programming/RoboticsRealLife/LineFollowerWPID';
import EV3Simulator from '../pages/Classes/LEGO/Programming/RobotMovement/EV3Simulator';

import FRCPrinciples from '../pages/Classes/FRC/Communication/Fundamentals/Principles';
import FRCElectricIntro from '../pages/Classes/FRC/Electric/Fundamentals/Introduction';
import FRCElectricComponents from '../pages/Classes/FRC/Electric/Fundamentals/Components';
import FRCElectricMotorControllers from '../pages/Classes/FRC/Electric/Fundamentals/MotorControllers';
import FRCElectricWires from '../pages/Classes/FRC/Electric/Fundamentals/Wires';
import FRCElectricTools from '../pages/Classes/FRC/Electric/Fundamentals/Tools';
import FRCElectricAssembly from '../pages/Classes/FRC/Electric/RobotCircuit/CircuitAssembly';
import FRCElectricAssemblyII from '../pages/Classes/FRC/Electric/RobotCircuit/CircuitAssemblyII';
import FRCElectricToolHandling from '../pages/Classes/FRC/Electric/Tool&Wire/ToolHandling';
import FRCElectricCableDimensions from '../pages/Classes/FRC/Electric/Tool&Wire/CableDimensions';
import FRCElectricWireIdentification from '../pages/Classes/FRC/Electric/Tool&Wire/WireIdentification';
import FRCElectricBasicMaintenance from '../pages/Classes/FRC/Electric/Maintenance/BasicMaintenance';
import FRCStrategyIntro from '../pages/Classes/FRC/Strategy/Fundamentals/Introduction';
import FRCMechPPE from '../pages/Classes/FRC/Mechanics/Fundamentals/PPE';
import FRCMechManualTools from '../pages/Classes/FRC/Mechanics/Fundamentals/ManualTools';
import FRCMechRobotSystems from '../pages/Classes/FRC/Mechanics/Fundamentals/RobotSystems';
import FRCMechRobotMaterials from '../pages/Classes/FRC/Mechanics/Fundamentals/RobotMaterials';
import FRCProgWhatIs from '../pages/Classes/FRC/Programming/Fundamentals/WhatIsProgramming';
import FRCProgPossibilities from '../pages/Classes/FRC/Programming/Fundamentals/Possibilities&Applications';
import FRCProgUnderstanding from '../pages/Classes/FRC/Programming/Principles/Understanding';
import FRCProgVariables from '../pages/Classes/FRC/Programming/Principles/Variables';
import FRCProgOperators from '../pages/Classes/FRC/Programming/Principles/Operators';
import FRCProgConditional from '../pages/Classes/FRC/Programming/Principles/ConditionalStatements';
import FRCProgRepetition from '../pages/Classes/FRC/Programming/Principles/RepetitionStructures';
import FRCProgArrays from '../pages/Classes/FRC/Programming/Principles/Arrays';
import FRCAwardsFund from '../pages/Classes/FRC/Awards/Fundamentals/CoreValues';
import FRCAwardsAwardList from '../pages/Classes/FRC/Awards/Fundamentals/AwardList';

const Contents = [
  {
    path:
      '/course/class/LEGO-Robotics/Getting-Started/Intro-to-Lego-Robotics/What-is-Lego-Robotics',
    component: WhatIsLEGO,
  },
  {
    path:
      '/course/class/LEGO-Robotics/Getting-Started/Intro-to-Lego-Robotics/LEGO-Showcase',
    component: LEGOShowcase,
  },
  {
    path: '/course/class/LEGO-Robotics/Components/Electric/Control',
    component: Control,
  },
  {
    path: '/course/class/LEGO-Robotics/Components/Electric/Motors',
    component: Motors,
  },
  {
    path: '/course/class/LEGO-Robotics/Components/Electric/Sensors',
    component: Sensors,
  },
  {
    path:
      '/course/class/LEGO-Robotics/Components/Electric/Exploring-the-Main-Brick',
    component: ExploringBrick,
  },
  {
    path:
      '/course/class/LEGO-Robotics/Components/Electric/Exploring-the-SPIKE-Hub',
    component: ExploringSpike,
  },
  {
    path: '/course/class/LEGO-Robotics/Components/Pieces/Beams',
    component: Beams,
  },
  {
    path: '/course/class/LEGO-Robotics/Components/Pieces/Connectors',
    component: Connectors,
  },
  {
    path: '/course/class/LEGO-Robotics/Components/Pieces/Functional',
    component: Functional,
  },
  {
    path:
      '/course/class/LEGO-Robotics/Programming/Blocks/Understanding-programming-logic',
    component: Understanding,
  },
  {
    path: '/course/class/LEGO-Robotics/Programming/Blocks/Motors',
    component: MotorsBlocks,
  },
  {
    path: '/course/class/LEGO-Robotics/Programming/Blocks/Movement',
    component: Movement,
  },
  {
    path: '/course/class/LEGO-Robotics/Programming/Blocks/Display',
    component: Screen,
  },
  {
    path: '/course/class/LEGO-Robotics/Programming/Blocks/Sound',
    component: Sound,
  },
  {
    path: '/course/class/LEGO-Robotics/Programming/Blocks/Events',
    component: Events,
  },
  {
    path: '/course/class/LEGO-Robotics/Programming/Blocks/Control',
    component: ControlBlocks,
  },
  {
    path: '/course/class/LEGO-Robotics/Programming/Blocks/Sensors',
    component: SensorsBlocks,
  },
  {
    path: '/course/class/LEGO-Robotics/Programming/Blocks/Operators',
    component: Operators,
  },
  {
    path: '/course/class/LEGO-Robotics/Programming/Blocks/Variables',
    component: Variables,
  },
  {
    path: '/course/class/LEGO-Robotics/Programming/Blocks/My-Blocks',
    component: MyBlocks,
  },
  {
    path:
      '/course/class/LEGO-Robotics/Programming/Real-Life-Robots/Remote-Control',
    component: RemoteControl,
  },
  {
    path:
      '/course/class/LEGO-Robotics/Programming/Real-Life-Robots/Detecting-Obstacles',
    component: DetectingObstacles,
  },
  {
    path:
      '/course/class/LEGO-Robotics/Programming/Real-Life-Robots/Line-Follower',
    component: LineFollower,
  },
  {
    path:
      '/course/class/LEGO-Robotics/Programming/Robot-Movement/EV3-Simulator',
    component: EV3Simulator,
  },
  {
    path:
      '/course/class/LEGO-Robotics/Programming/Real-Life-Robots/Line-Follower-with-PID',
    component: LineFollowerWPID,
  },
  {
    path:
      '/course/class/FIRST-Robotics/Communication/Fundamentals/FIRST-Communication-Principles',
    component: FRCPrinciples,
  },
  {
    path: '/course/class/FIRST-Robotics/Electric/Fundamentals/Introduction',
    component: FRCElectricIntro,
  },
  {
    path: '/course/class/FIRST-Robotics/Electric/Fundamentals/Components',
    component: FRCElectricComponents,
  },
  {
    path:
      '/course/class/FIRST-Robotics/Electric/Fundamentals/Motor-Controllers',
    component: FRCElectricMotorControllers,
  },
  {
    path: '/course/class/FIRST-Robotics/Electric/Fundamentals/Wires',
    component: FRCElectricWires,
  },
  {
    path: '/course/class/FIRST-Robotics/Electric/Fundamentals/Tools',
    component: FRCElectricTools,
  },
  {
    path:
      '/course/class/FIRST-Robotics/Strategy/Fundamentals/What-is-the-strategy-about',
    component: FRCStrategyIntro,
  },
  {
    path: '/course/class/FIRST-Robotics/Mechanics/Fundamentals/PPE',
    component: FRCMechPPE,
  },
  {
    path: '/course/class/FIRST-Robotics/Mechanics/Fundamentals/Manual-Tools',
    component: FRCMechManualTools,
  },
  {
    path: '/course/class/FIRST-Robotics/Mechanics/Fundamentals/Robot-Systems',
    component: FRCMechRobotSystems,
  },
  {
    path: '/course/class/FIRST-Robotics/Mechanics/Fundamentals/Robot-Materials',
    component: FRCMechRobotMaterials,
  },
  {
    path:
      '/course/class/FIRST-Robotics/Electric/Robot-Circuit/Circuit-Assembly',
    component: FRCElectricAssembly,
  },
  {
    path:
      '/course/class/FIRST-Robotics/Electric/Robot-Circuit/Circuit-Assembly-II',
    component: FRCElectricAssemblyII,
  },
  {
    path:
      '/course/class/FIRST-Robotics/Electric/Tool-&-Wire-Essentials/Tool-Handling',
    component: FRCElectricToolHandling,
  },
  {
    path:
      '/course/class/FIRST-Robotics/Electric/Tool-&-Wire-Essentials/Cable-Dimensions',
    component: FRCElectricCableDimensions,
  },
  {
    path:
      '/course/class/FIRST-Robotics/Electric/Tool-&-Wire-Essentials/Wire-Identification',
    component: FRCElectricWireIdentification,
  },
  {
    path: '/course/class/FIRST-Robotics/Electric/Maintenance/Basic-Maintenance',
    component: FRCElectricBasicMaintenance,
  },
  {
    path:
      '/course/class/FIRST-Robotics/Programming/Fundamentals/What-is-Programming',
    component: FRCProgWhatIs,
  },
  {
    path:
      '/course/class/FIRST-Robotics/Programming/Fundamentals/Possibilities-and-Applications',
    component: FRCProgPossibilities,
  },
  {
    path:
      '/course/class/FIRST-Robotics/Programming/Coding-Principles/Understanding-Algorithms',
    component: FRCProgUnderstanding,
  },
  {
    path:
      '/course/class/FIRST-Robotics/Programming/Coding-Principles/Variables',
    component: FRCProgVariables,
  },
  {
    path:
      '/course/class/FIRST-Robotics/Programming/Coding-Principles/Operators',
    component: FRCProgOperators,
  },
  {
    path:
      '/course/class/FIRST-Robotics/Programming/Coding-Principles/Conditional-Statements',
    component: FRCProgConditional,
  },
  {
    path:
      '/course/class/FIRST-Robotics/Programming/Coding-Principles/Repetition-Structures',
    component: FRCProgRepetition,
  },
  {
    path: '/course/class/FIRST-Robotics/Programming/Coding-Principles/Arrays',
    component: FRCProgArrays,
  },
  {
    path: '/course/class/FIRST-Robotics/Awards/Fundamentals/Core-Values',
    component: FRCAwardsFund,
  },
  {
    path: '/course/class/FIRST-Robotics/Awards/Fundamentals/FRC-Award-List',
    component: FRCAwardsAwardList,
  },
];

export default Contents;
