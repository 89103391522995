import styled from 'styled-components';

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  width: 100vw;
  height: 100%;
  max-height: 100%;

  padding: 5rem;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 45%;
  height: 100%;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  & > h1,
  h2,
  & > p {
    margin-bottom: 2rem;
  }

  h1:first-child {
    margin-bottom: 3.5rem;

    color: #051227;

    font-size: 2rem;
    font-weight: 500;
  }

  pre {
    width: 80%;
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
    border-radius: 1.5rem;
    margin-bottom: 2rem;

    background: #1b1b1b;
  }
`;

export const Table = styled.div`
  position: relative;
  display: inline-flex;

  border-radius: 1rem;
  margin-bottom: 2rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableHeading = styled.div`
  display: flex;

  background: #58a4b0;

  h4 {
    padding: 1rem 2rem;

    color: #fff;
  }
`;

export const TableRow = styled.div`
  display: flex;
  flex-direction: column;

  background: #fff;

  p {
    padding: 1rem 2rem;
  }

  p + p {
    border-top: 1px solid #e8e8e8;
  }
`;
