import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { BiAddToQueue, BiShapeCircle } from 'react-icons/bi';

import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Question from '../../components/Question';

import {
  Screen,
  Container,
  Header,
  AddQuestion,
  QuestionContainer,
  CreateButton,
  HeaderTop,
} from './styles';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import Button from '../../components/Button';

interface ContentParams {
  level_id: string;
  assignment: string;
}

interface Answer {
  placeholder: string;
  correct: boolean;
  key?: string;
}

interface IQuestion {
  key: string;
  placeholder: string;
  answers: Answer[];
}

interface QuestionFormData {
  correct: string;
  question: string;
  answer: string[];
}

interface StateProps {
  params: string;
}

const Questions: React.FC = () => {
  const [visible, setVisible] = useState(true);
  const [questions, setQuestions] = useState<IQuestion[]>([] as IQuestion[]);

  const { params } = useRouteMatch<ContentParams>();

  const questionFormRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const history = useHistory();
  const { state } = useLocation<StateProps>();
  const { t } = useTranslation();

  const handleQuestionSubmit = useCallback(
    async (data: QuestionFormData) => {
      try {
        questionFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          question: Yup.string().required(t('Please, provide the question')),
          correct: Yup.string()
            .ensure()
            .required(t('Please, provide the correct answer')),
          answer: Yup.array().of(
            Yup.string().required(t('Please, provide an answer')),
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const mappedAnswers = data.answer.map((answer, index) => {
          return parseInt(data.correct, 10) === index
            ? { placeholder: answer, correct: true }
            : { placeholder: answer, correct: false };
        });

        const mappedAnswersUnique = mappedAnswers.map((answer, index) => {
          return {
            ...answer,
            key: `${answer.placeholder}-${new Date().getTime()}`,
          };
        });

        const mappedData = {
          key: `${data.question}-${new Date().getTime()}`,
          placeholder: data.question,
          answers: mappedAnswersUnique,
        };

        setQuestions(prevState => [...prevState, mappedData]);

        setVisible(!visible);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          questionFormRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          label: t('Authentication error'),
          description: t('Check your credentials'),
        });
      }
    },
    [addToast, t, visible], // maybe should update dependencies
  );

  const handleCreateAssignment = useCallback(async () => {
    try {
      const response = await api.post('/courses/module/level/assignment', {
        module_level_id: params.level_id,
        assignment_type_id: '8c5da1e0-3c80-44fa-b1db-e1993f490981',
        title: params.assignment,
        instructions: state.params,
      });

      const mappedQuestions = questions.map(({ key, ...rest }) => rest);
      mappedQuestions.forEach(question => {
        // eslint-disable-next-line no-param-reassign
        question.answers = question.answers.map(({ key, ...rest }) => rest);
      });

      await api.post('/question', {
        assignment_id: response.data.id,
        questions: mappedQuestions,
      });

      addToast({
        type: 'success',
        label: t('Assignment Created'),
      });

      history.push('/teach');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        questionFormRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        label: t('Authentication error'),
        description: t('Check your credentials'),
      });
    }
  }, [
    addToast,
    history,
    params.assignment,
    params.level_id,
    questions,
    state.params,
    t,
  ]);

  const handleRemoveQuestion = useCallback(
    index => {
      if (questions.length > 0) {
        setQuestions(prevState => prevState.filter((_, i) => i !== index));
      }
    },
    [questions.length],
  );

  return (
    <Screen>
      <Container>
        <Header>
          <HeaderTop>
            <h2>{t('Create Task')}</h2>
            <AddQuestion onClick={() => setVisible(!visible)}>
              <BiAddToQueue size={30} />
            </AddQuestion>
          </HeaderTop>
        </Header>
        {questions.length > 0 && (
          <>
            {questions.map((question, index) => (
              <QuestionContainer key={question.key}>
                <h2>{question.placeholder}</h2>
                {question.answers.map(answer => (
                  <div key={answer.key}>
                    <BiShapeCircle size={24} />
                    <p>{answer.placeholder}</p>
                  </div>
                ))}
                <Button
                  type="button"
                  onClick={() => handleRemoveQuestion(index)}
                >
                  {t('Delete')}
                </Button>
              </QuestionContainer>
            ))}
          </>
        )}
        {visible && (
          <Form
            initialData={{ answer: 'Option' }}
            ref={questionFormRef}
            onSubmit={handleQuestionSubmit}
          >
            <Question />
          </Form>
        )}
        {questions.length > 0 && (
          <CreateButton type="button" onClick={handleCreateAssignment}>
            {t('Create')}
          </CreateButton>
        )}
      </Container>
    </Screen>
  );
};

export default Questions;
