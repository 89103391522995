import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { BgImage, ClassContainer } from './styles';

const ToolHandling: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Cable Dimensions')}</h1>
      <p style={{ color: '#051227', fontWeight: 'bold' }}>
        {t(
          'What is the reason to identify the wires that go to each component and why is that so important?',
        )}
      </p>
      <p>
        {t(
          'After all the installation we\'ve seen in the previous steps, we can imagine the number of identical wires going to different places on our robot. For better handling when disassembling or performing maintenance, we can do "component identification" by placing tape on each end of the wires to identify their respective inputs.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/electric/example-electric.png"
          alt="motor-power"
        />
      </BgImage>
      <p style={{ color: '#051227', fontWeight: 'bold', alignSelf: 'center' }}>
        {t('FRC Team 27')}
      </p>
    </ClassContainer>
  );
};

export default ToolHandling;
