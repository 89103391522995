import styled from 'styled-components';

interface ArrowProps {
  disabled: boolean;
}

export const Container = styled.div`
  align-self: center;
  background: transparent;
  margin: 0 1rem;

  @media (max-width: 1224px) {
    margin: 0 0.5rem;
  }
`;

export const Button = styled.button<ArrowProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 0;

  svg {
    color: #091d3f;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }
`;
