import React from 'react';
import { useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { ClassContainer } from './styles';

const WhatIsLEGO: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('What is Lego Robotics')}</h1>
      {/* <h2>{t('Basic Programming:')}</h2> */}
      <p>
        {t(
          'When we think of robots, it is common to envision those we see in movies or within large industries.',
        )}
      </p>
      <p style={{ marginBottom: 0 }}>
        {t(
          'They are composed by different parts that work together to make them move and perform tasks, and they can be programmed to do all sorts of things, from helping us clean the house to exploring space!',
        )}
      </p>
      <Player
        autoplay
        loop
        src="https://learnfirst-bucket.s3.us-east-2.amazonaws.com/walking-robot.json"
        style={{ height: '300px', width: '300px' }}
      >
        <Controls visible={false} />
      </Player>
      <p>
        {t(
          'The limit of a robot lies in the creativity of their creator! They come in different shapes and sizes, and they can streamline tasks in ways that we cannot. And believe it or not, it is possible to use your creativity to build a complete robot using LEGO pieces!',
        )}
      </p>
      <p>
        {t(
          'With LEGO robotics sets, you can integrate plastic pieces with electrical components such as motors, controllers, and sensors, while learning concepts of design, robotics, and mechatronics. In addition to developing important skills like programming, logical thinking, and problem-solving, all while having fun building robots.',
        )}
      </p>
      <p>
        {t(
          'But LEGO robotics is not just about building cool robots. You can also participate in regional, national, and even international competitions with your knowledge and experience in this field.',
        )}
      </p>
      <p>
        {t(
          "Now let's learn a little more about how these robots are in the real world!",
        )}
      </p>
    </ClassContainer>
  );
};

export default WhatIsLEGO;
