import React, { useRef, useCallback } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { GiMailbox } from 'react-icons/gi';
import { FiKey } from 'react-icons/fi';

import { useTranslation } from 'react-i18next';
import { HiOutlineLogout, HiOutlineMail } from 'react-icons/hi';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { useAuthentication } from '../../../../hooks/authentication';
import { useToast } from '../../../../hooks/toast';
import Input from '../../../Input';

import {
  EditContainer,
  FormContainer,
  SignOutButton,
  UpdateButton,
} from './styles';

interface IUpdatePasswordFormData {
  old_password: string;
  new_password: string;
  new_password_confirmation: string;
}

const Account: React.FC = () => {
  const emailFormRef = useRef<FormHandles>(null);
  const passwordFormRef = useRef<FormHandles>(null);

  const { t } = useTranslation();

  const { user, updateUser, signOut } = useAuthentication();
  const { addToast } = useToast();

  const handleEmailSubmission = useCallback(
    async (email: string) => {
      try {
        emailFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required(t('Please, provide an email'))
            .email(t('Please, provide a valid email')),
        });

        await schema.validate(email, {
          abortEarly: false,
        });

        const response = await api.put('/account', email);

        updateUser(response.data);

        addToast({
          type: 'success',
          label: t('Email updated!'),
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          emailFormRef.current?.setErrors(errors);

          return;
        }

        const errorMessage = error.response.data.message as string;

        addToast({
          type: 'error',
          label: errorMessage,
          description: t('Please, try again.'),
        });
      }
    },
    [addToast, t, updateUser],
  );

  const handlePasswordSubmission = useCallback(
    async (data: IUpdatePasswordFormData) => {
      try {
        passwordFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          old_password: Yup.string().required(
            t('Please, provide your old password'),
          ),
          new_password: Yup.string()
            .min(6, t('Your password must have at least 6 characters'))
            .required(t('Please, provide a new password')),
          new_password_confirmation: Yup.string().required(
            t('Please, confirm your new password'),
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.put('/account', data);

        updateUser(response.data);

        addToast({
          type: 'success',
          label: t('Password updated!'),
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          passwordFormRef.current?.setErrors(errors);

          return;
        }

        const errorMessage = error.response.data.message as string;

        addToast({
          type: 'error',
          label: errorMessage,
          description: t('Please, try again.'),
        });
      }
    },
    [addToast, t, updateUser],
  );

  return (
    <EditContainer>
      <Form
        ref={emailFormRef}
        initialData={{ email: user.email }}
        onSubmit={handleEmailSubmission}
      >
        <FormContainer>
          <h4 style={{ marginBottom: '0.5rem' }}>Email</h4>
          <Input
            id="email"
            name="email"
            placeholder="Email"
            icon={HiOutlineMail}
          />
          <UpdateButton type="submit">{t('Update Email')}</UpdateButton>
        </FormContainer>
      </Form>
      <Form ref={passwordFormRef} onSubmit={handlePasswordSubmission}>
        <FormContainer>
          <h4 style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>
            {t('Password')}
          </h4>
          <Input
            name="old_password"
            placeholder={t('Old Password')}
            icon={FiKey}
            endIconOn={RiEyeLine}
            endIconOff={RiEyeOffLine}
          />
          <Input
            name="new_password"
            placeholder={t('New Password')}
            icon={FiKey}
            endIconOn={RiEyeLine}
            endIconOff={RiEyeOffLine}
          />
          <Input
            name="new_password_confirmation"
            placeholder={t('Confirm New Password')}
            icon={FiKey}
            endIconOn={RiEyeLine}
            endIconOff={RiEyeOffLine}
          />
          <UpdateButton type="submit">{t('Update Password')}</UpdateButton>
          <SignOutButton onClick={() => signOut()}>
            <HiOutlineLogout size={20} />
            {t('Sign Out')}
          </SignOutButton>
        </FormContainer>
      </Form>
    </EditContainer>
  );
};

export default Account;
