import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { TbEngine } from 'react-icons/tb';
import { ClassContainer } from './styles';
import { useToast } from '../../../../../../hooks/toast';
import Cards from './dataTools';
import CardsModal from '../../../../../../components/CardsModal';

const RobotMaterials: React.FC = () => {
  const { t } = useTranslation();

  const { addToast } = useToast();

  const [showNextButton, setShowNextButton] = useState(false);

  useEffect(() => {
    if (showNextButton === true) {
      addToast({
        type: 'success',
        label: t("Now we're talking!"),
        description: t("Click on the 'next' button to continue!"),
      });
    }
  }, [addToast, showNextButton, t]);

  return (
    <ClassContainer showNextButton={showNextButton}>
      <h1>{t('Robot Materials')}</h1>
      <Player
        autoplay
        loop
        src="https://learnfirst-bucket.s3.amazonaws.com/frc/nut.json"
        style={{ height: '250px', width: '250px', margin: '1rem 0' }}
      >
        <Controls visible={false} />
      </Player>
      <p>
        {t(
          "Now, let's learn a little more about the types and shapes of materials we can use to build our robots.",
        )}
      </p>
      <p className="modalStartText">{t('Click the button below to start:')}</p>
      <CardsModal
        Icon={TbEngine}
        bgColor="#6cd0e0"
        propBorderColor="#c6eef5"
        setShowNextButton={setShowNextButton}
        cards={Cards}
      />
    </ClassContainer>
  );
};

export default RobotMaterials;
