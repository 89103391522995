interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mechanic/rivet.png',
    title: 'Manual Riveter',
    text: 'Tool used to join metals by fixing rivets.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mechanic/level.png',
    title: 'Level',
    text:
      'Essential for the installation of any item that requires correct positioning.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mechanic/rub-hammer.png',
    title: 'Rubber Mallet',
    text:
      "Intended for applications where it is necessary to hit without 'marking' the worked piece.",
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mechanic/hammer.png',
    title: 'Hammer',
    text:
      'The most recognized and widely used model. It has a point with a wedge, which allows for nail extraction or box opening.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mechanic/rasp.png',
    title: 'File',
    text:
      'For sharpening cutting tools, removing excess material (wood, metal, plastics), roughing, and finishing.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mechanic/clamp.png',
    title: 'Clamp',
    text: 'Used to temporarily join and secure materials.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mechanic/wrench.png',
    title: 'Open-end Wrench',
    text: 'Used for tightening and loosening hexagonal nuts and bolts.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mechanic/allen.png',
    title: 'Allen Key',
    text: 'Special key for hexagonal screws.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mechanic/saw.png',
    title: 'Hacksaw',
    text: 'For cutting pipes, metal bars, and general cuts.',
  },
];

export default Cards;
