import React from 'react';

import RoboRIO from '../pages/Classes/InteractiveClasses/RoboRIO';
import SpecificMotors from '../pages/Classes/InteractiveClasses/SpecificMotors';
import ScreenUsage from '../pages/Classes/InteractiveClasses/ScreenUsage';
import DetectingColors from '../pages/Classes/InteractiveClasses/DetectingColors';

interface Answer {
  id: string;
  placeholder: string;
  correct: boolean;
}

interface Question {
  id: string;
  placeholder: string;
  answers: Answer[];
}

interface Level {
  id: string;
  name: string;
  area_module_id: string;
  area_name: string;
  color: string;
  progress?: number;
  assignments: Assignment[];
}

interface Assignment {
  id: string;
  title: string;
  instructions: string;
  module_level_id: string;
  module_level?: Level;
  questions: Question[];
  order: number;
}

interface InteractiveProps {
  content: Assignment;
  nextContent?: string;
}

interface AssignmentProp {
  path: string;
  component: React.FC<InteractiveProps>;
}

const Assignments: AssignmentProp[] = [
  {
    path:
      '/course/assignment/LEGO-Robotics/Programming/Programming-Logic/Robo-RIO-Wiring',
    component: RoboRIO,
  },
  {
    path:
      '/course/assignment/LEGO-Robotics/Programming/Robot-Movement/Specific-Motors',
    component: SpecificMotors,
  },
  {
    path:
      '/course/assignment/LEGO-Robotics/Programming/Robot-Movement/Screen-Usage',
    component: ScreenUsage,
  },
  {
    path:
      '/course/assignment/LEGO-Robotics/Programming/Robot-Movement/Detecting-Colors',
    component: DetectingColors,
  },
  {
    path:
      '/course/assignment/FIRST-Robotics/Electric/Robot-Circuit/RoboRIO-Wiring',
    component: RoboRIO,
  },
];

export default Assignments;
