import React from 'react';
import { useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { ClassContainer } from './styles';

const Understanding: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Understanding programming logic')}</h1>
      <p>
        {t(
          'To make your robot perform the actions you request, you need to create a list of instructions that will tell which steps it needs to take to complete the assigned function.',
        )}
      </p>
      <p>
        {t(
          'A great way to understand how the programming logic works is to use the simple example of changing a light bulb. Imagine you want to replace a burnt-out light bulb in your house. You could break down the process into the following steps:',
        )}
      </p>
      <p style={{ marginBottom: '0.5rem' }}>
        {t('1. Analyse the location of the light bulb')}
      </p>
      <p style={{ marginBottom: '0.5rem' }}>
        {t('2. Turn off the power of the light bulb to prevent any accidents')}
      </p>
      <p style={{ marginBottom: '0.5rem' }}>
        {t('3. Bring a ladder to the location of the burnt-out light bulb')}
      </p>
      <p style={{ marginBottom: '0.5rem' }}>
        {t('4. Climb the ladder and remove the old light bulb')}
      </p>
      <p style={{ marginBottom: '0.5rem' }}>
        {t('5. Install the new light bulb')}
      </p>
      <p style={{ marginBottom: '0.5rem' }}>{t('6. Turn the power back on')}</p>
      <p style={{ marginBottom: '0.5rem' }}>{t('7. Put away the ladder')}</p>
      <Player
        autoplay
        loop
        src="https://learnfirst-bucket.s3.us-east-2.amazonaws.com/lamp.json"
        style={{ height: '300px', width: '300px', marginBottom: '1rem' }}
      >
        <Controls visible={false} />
      </Player>
      <p>
        {t(
          "In LEGO robot programming, you will create this step-by-step process using code blocks. These blocks are organized into categories such as 'Movement', 'Sensor', 'Loop', and 'Logic'. Each code block represents a programming instruction and can be connected to other blocks to create more complex programs.",
        )}
      </p>
      <p>
        {t(
          'LEGO block programming is a visual language, which means that programs are represented graphically instead of being written in text, making it easier to create code that allows robots to be controlled autonomously or through a remote control.',
        )}
      </p>
      <p>{t('Next, we will specifically explore each category of blocks.')}</p>
    </ClassContainer>
  );
};

export default Understanding;
