import styled from 'styled-components';

import Button from '../../../Button';

export const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  position: relative;

  & > :not(:first-child) {
    margin-top: 1rem;
  }

  h4 {
    color: #051227;
  }

  @media only screen and (max-width: 1500px) {
    overflow-y: auto;
    height: 100%;
  }

  /* @media (max-width: 1500px) {
    width: 70%;
  } */

  @media (max-width: 1224px) {
    width: 60%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
`;

export const UpdateButton = styled(Button)`
  margin-top: 1rem;
  width: auto;
  height: auto;
  padding: 0.5rem 1rem;
`;

export const SignOutButton = styled(Button)`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  width: auto;
  height: auto;
  padding: 0.5rem 1rem;

  svg {
    margin-right: 0.5rem;
  }
`;
