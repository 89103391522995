import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { IconBaseProps } from 'react-icons/lib';
import { FaChalkboard } from 'react-icons/fa';
import { MdAssignment } from 'react-icons/md';

import { useTranslation } from 'react-i18next';
import { HiArrowNarrowLeft, HiOutlineClipboardList } from 'react-icons/hi';
import { BiChalkboard } from 'react-icons/bi';
import api from '../../../services/api';

import Card from '../../../components/Card';
import DropdownMenu from '../../../components/DropdownMenu';

import {
  Screen,
  Container,
  Info,
  HeaderCustomCard,
  Content,
  HeaderTopContent,
  HeaderBottomContent,
  LittleInfo,
  Bottom,
  ContentCustomCard,
  BackButton,
} from './styles';
import { useAuthentication } from '../../../hooks/authentication';

interface LevelParams {
  level: string;
}

interface LevelProgress {
  completed: boolean;
  level: Level;
}

interface Assignment {
  id: string;
  title: string;
  module_level_id: string;
  level?: Level;
  order: number;
  type?: string;
}

interface Class {
  id: string;
  title: string;
  module_level_id: string;
  level?: Level;
  order: number;
  type?: string;
}

interface Level {
  id: string;
  name: string;
  area_module_id: string;
  area_name: string;
  color: string;
  icon: React.ComponentType<IconBaseProps>;
  progress?: number;
  assignments: Assignment[];
  classes: Class[];
}

const Levels: React.FC = () => {
  const [level, setLevel] = useState<Level | null>(null);

  const { user } = useAuthentication();
  const history = useHistory();
  const { params } = useRouteMatch<LevelParams>();

  const { t } = useTranslation();

  const [courseName, areaName, levelName] = useMemo(() => {
    return params.level.split('/');
  }, [params.level]);

  const reformatLevelName = useCallback((name: string) => {
    return name.replaceAll('-', ' ');
  }, []);

  useEffect(() => {
    async function loadLevelInfo(): Promise<void> {
      const responseLevel: Level = await (
        await api.get(
          `/courses/levels/${reformatLevelName(courseName)}/${reformatLevelName(
            areaName,
          )}/${reformatLevelName(levelName)}`,
        )
      ).data;

      if (!responseLevel) {
        history.push(`/courses`);
      } else {
        const dbLevelProgress: LevelProgress = await (
          await api.get(
            `/learn/user-level-progress/${user.id}/${responseLevel?.id}`,
          )
        ).data;

        if (!dbLevelProgress) {
          // put message like "you should enroll in a course before..."
          history.push(`/levels/${responseLevel?.area_module_id}`);
        }

        setLevel(responseLevel);
      }
    }

    loadLevelInfo();
  }, [areaName, courseName, history, levelName, reformatLevelName, user.id]);

  return (
    <Screen>
      {level && (
        <Container>
          <Info>
            <HeaderCustomCard>
              <Content>
                <HeaderTopContent>
                  <BackButton onClick={() => history.goBack()}>
                    <HiArrowNarrowLeft size={30} />
                  </BackButton>
                  <h3>{`${t(reformatLevelName(areaName))}`}</h3>
                  <h1>{`${t(level.name)}`}</h1>
                </HeaderTopContent>
                <HeaderBottomContent>
                  {level.classes.length > 0 && (
                    <LittleInfo>
                      <BiChalkboard size={20} />
                      <p>
                        {t('Classes')}
                        {`: ${level.classes.length}`}
                      </p>
                    </LittleInfo>
                  )}
                  {level.assignments.length > 0 && (
                    <LittleInfo>
                      <HiOutlineClipboardList size={20} />
                      <p>
                        {t('Assignments')}
                        {`: ${level.assignments.length}`}
                      </p>
                    </LittleInfo>
                  )}
                </HeaderBottomContent>
              </Content>
            </HeaderCustomCard>

            <Bottom>
              <ContentCustomCard>
                <div>
                  {/* <h3>{`${t('Classes')}:`}</h3> */}
                  <DropdownMenu
                    courseName={courseName}
                    areaName={areaName}
                    level={level}
                  />
                </div>
              </ContentCustomCard>
            </Bottom>
          </Info>
        </Container>
      )}
    </Screen>
  );
};

export default Levels;
