/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Controls, Player } from '@lottiefiles/react-lottie-player';

import { RiOrganizationChart } from 'react-icons/ri';
import { ClassContainer, Wrapper } from './styles';
import CardsModal from '../../../../../../components/CardsModal';
import { useToast } from '../../../../../../hooks/toast';

import Cards from './dataBeams';

const Beams: React.FC = () => {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [showNextButton, setShowNextButton] = useState(false);

  useEffect(() => {
    if (showNextButton === true) {
      addToast({
        type: 'success',
        label: t("Now we're talking!"),
        description: t("Click on the 'next' button to continue!"),
      });
    }
  }, [addToast, showNextButton, t]);

  return (
    <ClassContainer showNextButton={showNextButton}>
      <h1 style={{ marginBottom: '0.5rem' }}>{t('Beams')}</h1>
      {/* <h2>{t('Basic Programming:')}</h2> */}
      <Player
        autoplay
        loop
        src="https://learnfirst-bucket.s3.us-east-2.amazonaws.com/blocks.json"
        style={{ height: '250px', width: '250px', margin: '1rem 0' }}
      >
        <Controls visible={false} />
      </Player>
      <p>
        {t(
          'Beams are one of the main types of pieces used in building LEGO robots. They are rigid plastic rectangular bars with holes, allowing them to be connected to other pieces to create the structure of your robot, providing support and stability for other parts.',
        )}
      </p>
      <p>
        {t(
          'They come in a variety of sizes, from small beams with 2 holes to larger beams with 15 holes. They can be straight or angled, allowing for the creation of more complex designs. Beams are also easy to connect to other pieces, allowing users to create custom designs without the need for tools or adhesives.',
        )}
      </p>
      <p>{t("Now, let's get to know the main beams used in LEGO robotics.")}</p>

      <p className="modalStartText">{t('Click the button below to start:')}</p>
      <CardsModal
        Icon={RiOrganizationChart}
        bgColor="#ffb129"
        propBorderColor="#f2d4a0"
        setShowNextButton={setShowNextButton}
        cards={Cards}
      />
    </ClassContainer>
  );
};

export default Beams;
