/* eslint-disable import/no-duplicates */
import React, { useEffect, useMemo, useState } from 'react';
import { parseISO, formatDistance } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import api from '../../services/api';
import { useAuthentication } from '../../hooks/authentication';

import {
  Screen,
  Container,
  Header,
  Content,
  ComponentContainer,
  HeaderContent,
  ImageIcon,
} from './styles';

interface Component {
  id: string;
  name: string;
  description: string;
  avatar_url: string;
  component_category_id: string;
}

const Favorites: React.FC = () => {
  const [components, setComponents] = useState<Component[]>([] as Component[]);

  const { user } = useAuthentication();

  const { t } = useTranslation();

  useEffect(() => {
    async function loadComponents(): Promise<void> {
      const response = await api.get(`/library/user-components/${user.id}`);

      setComponents(response.data);
    }

    loadComponents();
  }, [user.id]);

  return (
    <Screen>
      <Container>
        <Header>
          <HeaderContent>
            <h2>{t('Your Favorite Components')}</h2>
          </HeaderContent>
        </Header>
        <Content>
          {components.length > 0 ? (
            components
              .slice()
              .reverse()
              .map(component => (
                <Link
                  to={`/library/category/${component.component_category_id}`}
                >
                  <ComponentContainer key={component.id}>
                    <ImageIcon>
                      <img src={component.avatar_url} alt="" />
                    </ImageIcon>
                  </ComponentContainer>
                </Link>
              ))
          ) : (
            <p style={{ marginTop: '2rem' }}>
              {t('You do not have any favorite components')}
            </p>
          )}
        </Content>
      </Container>
    </Screen>
  );
};

export default Favorites;
