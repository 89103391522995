import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../components/Button';
import { useAuthentication } from '../../hooks/authentication';
import api from '../../services/api';

import { Screen, Container } from './styles';

interface Level {
  id: string;
  name: string;
  area_module_id: string;
  area_name: string;
  color: string;
  progress?: number;
  classes: Class[];
  order: number;
}

interface Class {
  id: string;
  title: string;
  module_level_id: string;
  level?: Level;
  order: number;
}

interface LevelProgress {
  id: string;
  completed: boolean;
  level: Level;
}

interface ClassProgress {
  id: string;
  completed: boolean;
}

const Class: React.FC = ({ children, ...rest }) => {
  const [content, setContent] = useState<Class>();
  const [nextContent, setNextContent] = useState<string>();

  const { user } = useAuthentication();
  const history = useHistory();

  const { pathname } = useLocation();
  const { t } = useTranslation();

  const formatLevelName = useCallback((name: string) => {
    return name.replaceAll(' ', '-');
  }, []);

  const reformatLevelName = useCallback((name: string) => {
    return name.replaceAll('-', ' ');
  }, []);

  useEffect(() => {
    async function loadContent(): Promise<void> {
      // check if user has level progress (if not - shouldn't be here)
      const courseName = pathname.split('/')[3];
      const areaName = pathname.split('/')[4];
      const levelName = pathname.split('/')[5];

      const responseLevel: Level = await (
        await api.get(
          `/courses/levels/${reformatLevelName(courseName)}/${reformatLevelName(
            areaName,
          )}/${reformatLevelName(levelName)}`,
        )
      ).data;

      const dbLevelProgress: LevelProgress = await (
        await api.get(
          `/learn/user-level-progress/${user.id}/${responseLevel?.id}`,
        )
      ).data;

      if (!dbLevelProgress) {
        // put message like "you should enroll in a course before..."
        history.push(`/levels/${responseLevel?.area_module_id}`);
      } else {
        // if user has level progress
        const contentName = pathname
          .substr(pathname.lastIndexOf('/') + 1)
          .replaceAll('-', ' ');

        const dbClass: Class = await (
          await api.get(`/courses/content/${contentName}/${responseLevel.id}`)
        ).data;

        setContent(dbClass);

        const dbClassProgress: ClassProgress = await (
          await api.get(`/learn/user-content-progress/${user.id}/${dbClass.id}`)
        ).data;

        if (!dbClassProgress) {
          await api.post('/learn/class-progress', {
            class_id: dbClass.id,
            level_progress_id: dbLevelProgress.id,
            user_id: user.id,
          });
        }

        const responseNext = await api.get(
          `/courses/next-content/${dbClass.module_level_id}/${dbClass.order}`,
        );

        if (responseNext.data) {
          const changePathname = pathname.replace(
            pathname.split('/')[2],
            responseNext.data.type,
          );

          const newPathname = changePathname.replace(
            pathname.substr(pathname.lastIndexOf('/') + 1),
            responseNext.data.title.replaceAll(' ', '-'),
          );

          setNextContent(newPathname);
        }
      }
    }

    loadContent();
  }, [history, pathname, reformatLevelName, user.id]);

  const handleNextContent = useCallback(async () => {
    const dbClassProgress: ClassProgress = await (
      await api.get(`/learn/user-content-progress/${user.id}/${content?.id}`)
    ).data;

    if (!dbClassProgress.completed) {
      await api.put('/courses/class/complete', {
        class_id: content?.id,
        user_id: user.id,
      });
    }

    const isLevelFinished = await (
      await api.get(
        `/courses/level-status/${content?.module_level_id}/${user.id}`,
      )
    ).data;

    if (isLevelFinished) {
      history.push('/finished-level');
    } else if (nextContent) {
      history.push(nextContent);
    } else {
      const courseName = pathname.split('/')[3];
      const areaName = pathname.split('/')[4];
      const levelName = pathname.split('/')[5];

      history.push(
        `/courses/${formatLevelName(courseName)}/${formatLevelName(
          areaName,
        )}/${formatLevelName(levelName)}`,
      );
    }
  }, [
    content?.id,
    content?.module_level_id,
    formatLevelName,
    history,
    nextContent,
    pathname,
    user.id,
  ]);

  return (
    <Screen>
      <Container {...rest}>
        {children}

        <Button
          style={{ padding: '0.5rem 1rem', width: 'auto', height: 'auto' }}
          onClick={handleNextContent}
          className="nextClassButton"
        >
          {t('Next')}
        </Button>
      </Container>
    </Screen>
  );
};

export default Class;
