interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mech/nut.png',
    title: 'Nuts with internal threads',
    text:
      'Nuts with threads are used to secure the screw in place on the surface.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mech/screw.png',
    title: 'Screw',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mech/rivet.png',
    title: 'Rivet',
    text: 'Used to fasten profiles and join parts.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mech/poly.png',
    title: 'Polycarbonate sheet',
    text:
      'Another commonly used material is polycarbonate. We can use it to support the collection of objects, organize electrical components, or provide lightweight protection.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mech/aluminum-sheet.png',
    title: 'Aluminum sheet',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mech/profile.png',
    title: 'Aluminum profile',
    text:
      'Aluminum profiles are commonly used to create the structures and mechanical systems of robots. There is a variety of types and sizes available.',
  },
];

export default Cards;
