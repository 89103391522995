import React from 'react';
import { useTranslation } from 'react-i18next';

import { ValueContainer, ClassContainer } from './styles';

const CoreValues: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>Core Values</h1>
      <p>
        {t(
          'There are some values that guide the competition and teams. They serve to stimulate creativity, respect, new skills, and fun. These principles make FIRST special; they are the Core Values and Gracious Professionalism. There are 6 values, namely:',
        )}
      </p>
      <h3 style={{ fontWeight: 'bold', color: '#051227' }}>{t('Discovery')}</h3>
      <p>{t('We explore new skills and ideas.')}</p>
      <h3 style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('Innovation')}
      </h3>
      <p>{t('We use creativity and persistence to solve problems.')}</p>
      <h3 style={{ fontWeight: 'bold', color: '#051227' }}>{t('Impact')}</h3>
      <p>{t("We apply what we've learned to improve our world.")}</p>
      <h3 style={{ fontWeight: 'bold', color: '#051227' }}>{t('Inclusion')}</h3>
      <p>{t('We respect each other and embrace our differences.')}</p>
      <h3 style={{ fontWeight: 'bold', color: '#051227' }}>{t('Teamwork')}</h3>
      <p>{t('We are stronger when we work together.')}</p>
      <h3 style={{ fontWeight: 'bold', color: '#051227' }}>{t('Fun')}</h3>
      <p>{t('We love and celebrate what we do!')}</p>
    </ClassContainer>
  );
};

export default CoreValues;
