import styled, { css } from 'styled-components';

interface ContainerProps {
  cardColor?: string;
  textColor?: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  padding: 1.5rem;
  border-radius: 1rem;

  background-color: ${props => (props.cardColor ? props.cardColor : '#fff')};
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */

  transition: 0.2s ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  ${props =>
    props.textColor &&
    css`
      > * {
        color: ${props.textColor};
      }
    `}
`;
