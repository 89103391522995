import styled from 'styled-components';

export const Screen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 4rem; //remove for smaller screens
  margin-left: 4rem; //remove for smaller screens */

  width: 100vw;
  height: 100%;
  overflow-y: scroll;

  @media (max-width: 800px) {
    align-items: flex-start;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;

  padding: 4rem 3rem;
  position: relative;

  & > h1 {
    margin-bottom: 5rem;

    @media (max-width: 800px) {
      margin-bottom: 3rem;
    }
  }

  h1 {
    color: #051227;

    font-size: 2rem;
    font-weight: 500;
  }

  @media (max-width: 800px) {
    padding: 2rem 1rem;
  }
`;

export const CoursesDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  button {
    width: 25%;
    aspect-ratio: 1 / 1;

    display: grid;
    grid-template: 80% 20% / 1fr;
    position: relative;
    text-decoration: none;
    background: #22272e;
    /* background: linear-gradient(
      90deg,
      rgba(61, 70, 82, 1) 0%,
      rgba(34, 39, 46, 1) 100%
    ); */
    border: 0;
    padding: 3rem;
    border-radius: 2rem;
    transition: 0.2s ease-in-out;

    /* svg {
      width: 250px;
      height: auto;
      margin-bottom: 2rem;
    } */

    h1 {
      color: white;
    }

    &:hover {
      box-shadow: 0 0 0 3px #f6ae2d;
    }

    @media (max-width: 1600px) {
      width: 30%;
      padding: 2rem;
    }

    @media (max-width: 1224px) {
      width: 40%;
      padding: 2rem;
    }

    @media (max-width: 800px) {
      width: 90%;
    }
  }

  > button + button {
    margin-left: 3rem;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    padding-bottom: 2rem;

    > button + button {
      margin-left: 0;
      margin-top: 3rem;
    }
  }
`;

export const CourseImage = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
`;

export const CourseTitle = styled.div`
  grid-area: 2 / 1 / 3 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  h1 {
    color: white;

    @media (max-width: 1200px) {
      font-size: 1.5rem;
    }
  }
`;
