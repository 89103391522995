/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Controls, Player } from '@lottiefiles/react-lottie-player';

import { RiOrganizationChart } from 'react-icons/ri';
import { ClassContainer } from './styles';
import CardsModal from '../../../../../../components/CardsModal';
import { useToast } from '../../../../../../hooks/toast';

import Cards from './dataMyBlocks';

const MyBlocks: React.FC = () => {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [showNextButton, setShowNextButton] = useState(false);

  useEffect(() => {
    if (showNextButton === true) {
      addToast({
        type: 'success',
        label: t("Now we're talking!"),
        description: t("Click on the 'next' button to continue!"),
      });
    }
  }, [addToast, showNextButton, t]);

  return (
    <ClassContainer showNextButton={showNextButton}>
      <h1 style={{ marginBottom: '0.5rem' }}>{t('My Blocks')}</h1>
      {/* <h2>{t('Basic Programming:')}</h2> */}
      <Player
        autoplay
        loop
        src="https://learnfirst-bucket.s3.us-east-2.amazonaws.com/myblock.json"
        style={{ height: '200px', width: '200px', margin: '1rem 0' }}
      >
        <Controls visible={false} />
      </Player>
      <p>
        {t(
          'The “My Blocks” category is a special area where you can create and save your own custom blocks. These custom blocks can consist of a sequence of actions, operators, and variables, allowing you to save a sequence of code and reuse it in other parts of the program.',
        )}
      </p>
      <p>
        {t(
          "Custom blocks can be created from a sequence of existing blocks, allowing you to create a function that performs a series of complex actions with a single click. For example, if you have a sequence of actions that performs a specific robot maneuver, you can group them into a custom block, which can be used multiple times in your programs. This way, you don't need to recreate the same sequence of actions multiple times.",
        )}
      </p>

      <p className="modalStartText">{t('Click the button below to start:')}</p>
      <CardsModal
        Icon={RiOrganizationChart}
        bgColor="#d7607e"
        propBorderColor="#f296ad"
        setShowNextButton={setShowNextButton}
        invert
        cards={Cards}
      />
    </ClassContainer>
  );
};

export default MyBlocks;
