import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { GiLotus } from 'react-icons/gi';
import { HiOutlinePuzzle } from 'react-icons/hi';
import { Link } from 'react-router-dom';

import SidebarItems from '../../data/dataSidebarItems';
import { useAuthentication } from '../../hooks/authentication';

import { Container, Logo, SidebarItem } from './styles';

interface SidebarProps {
  pathname: string;
}

const Sidebar: React.FC<SidebarProps> = ({ pathname }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const [activeIndex, setActiveIndex] = useState(0);

  const { user } = useAuthentication();

  const { t } = useTranslation();

  const mappedSideBarItems = SidebarItems.filter(item => {
    if (
      item.role &&
      !user.roles.some(userRole => userRole.name === item.role)
    ) {
      return false;
    }
    return true;
  });

  const getPath = useCallback(path => {
    if (path.charAt(0) !== '/') {
      return `/${path}`;
    }

    return path;
  }, []);

  useLayoutEffect(() => {
    const activeItem = mappedSideBarItems.findIndex(item =>
      item.routes.find(route => {
        return getPath(pathname).includes(getPath(route));
      }),
    );
    setActiveIndex(activeItem);
  }, [getPath, mappedSideBarItems, pathname]);

  return (
    <Container>
      <Logo>
        <div style={{ display: 'flex' }}>
          <HiOutlinePuzzle size={22} />
        </div>
        <p>LearnFirst</p>
      </Logo>
      {mappedSideBarItems
        .filter(item => isTabletOrMobile || !item.mobile)
        .map((item, index) => {
          return (
            <SidebarItem
              key={item.name}
              active={index === activeIndex}
              onClick={() => {
                setActiveIndex(index);
              }}
            >
              <Link key={item.name} to={item.mainRoute}>
                <div style={{ display: 'flex' }}>
                  <item.icon size={22} />
                </div>
                <p>{t(item.name)}</p>
              </Link>
            </SidebarItem>
          );
        })}
    </Container>
  );
};

export default Sidebar;
