interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'sensors/b14.png',
    title: 'Distance information',
    text:
      "This block provides the distance detected by the robot's distance sensor. It has two parameters: the first one is the port where the sensor is connected, and the second one is the unit of measurement for the distance.",
  },
  {
    image: 'sensors/b13.png',
    title: 'Color information',
    text:
      "This block provides the color detected by the robot's color sensor. It has one parameter indicating the port where the sensor is connected.",
  },
  {
    image: 'sensors/b12.png',
    title: 'Light information',
    text:
      "This block provides the intensity of light reflected by the robot's color sensor. It has one parameter indicating the port where the sensor is connected.",
  },
  {
    image: 'sensors/b11.png',
    title: 'Button condition',
    text:
      'This block informs whether a button on the robot has been pressed. It has one parameter indicating the button to be pressed.',
  },
  {
    image: 'sensors/b10.png',
    title: 'Distance condition',
    text:
      'This block informs whether the distance measured by the proximity sensor is greater, less than, or equal to the number provided by you. It has four parameters: the first one is the port where the sensor is connected, the second one is the distance condition, the third one is the number used to measure that distance, and the fourth one is the unit of measurement.',
  },
  {
    image: 'sensors/b9.png',
    title: 'Touch condition',
    text:
      'This block informs whether the touch sensor button has been pressed. It has one parameter indicating the port where the sensor is connected.',
  },
  {
    image: 'sensors/b8.png',
    title: 'Color condition',
    text:
      'This block informs whether the color detected by the sensor is equal to the color provided by you. It has two parameters: the first one is the port where the sensor is connected, and the second one is the color you want to detect.',
  },
  {
    image: 'sensors/b7.png',
    title: 'Light condition',
    text:
      'This block informs whether the intensity of light reflected by the color sensor is greater or less than the number provided by you. For example, if the intensity is less than 5%, the sensor is looking at the color black or very close to black. It has three parameters: the first one is the port where the sensor is connected, the second one is the reflection condition, and the third one is the number used to measure that reflection.',
  },
  {
    image: 'sensors/b6.png',
    title: 'Wait for button',
    text:
      'This block is used for the robot to wait until a button on the robot is pressed or released before continuing to execute the code. It has two parameters: the first one is the button to be used, and the second one is the button state.',
  },
  {
    image: 'sensors/b5.png',
    title: 'Wait for distance',
    text:
      'This block is used for the robot to wait until the proximity sensor detects an object before continuing to execute the code. It has four parameters: the first one is the port where the sensor is connected, the second one is the distance condition, the third one is the number used to measure that distance, and the fourth one is the unit of measurement.',
  },
  {
    image: 'sensors/b4.png',
    title: 'Wait for touch',
    text:
      'This block is used for the robot to wait until the touch sensor is pressed or released before continuing to execute the code. It has two parameters: the first one is the port where the sensor is connected, and the second one is the button state.',
  },
  {
    image: 'sensors/b3.png',
    title: 'Wait for color',
    text:
      'This block is used for the robot to wait until the color sensor detects the specified color before continuing to execute the code. It has two parameters: the first one is the port where the sensor is connected, and the second one is the reference color.',
  },
  {
    image: 'sensors/b2.png',
    title: 'Reset Calibration',
    text:
      'This block allows you to calibrate reset all color sensor calibrations.',
  },
  {
    image: 'sensors/b1.png',
    title: 'Calibrate Light',
    text:
      'This block allows you to calibrate the minimum and maximum light intensity of the color sensor, so that your robot can measure light more accurately under different lighting conditions. For example, if you want your robot to follow a black line on a white floor, you can calibrate the sensor to ensure that it correctly identifies the colors. In this case, the minimum reflected light would be black, and the maximum reflected light would be white (more details in the line-following robot lesson). It has two parameters: the reflected light state and the reference value.',
  },
];

export default Cards;
