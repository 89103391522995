import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    display: block;
    max-width: 100%;
  }

  .circleBg {
    fill: none;
  }

  .circleProgress {
    fill: none;
    transition: stroke-dashoffset 700ms ease-in-out;
  }

  p {
    position: absolute;

    font-size: 0.75rem;
  }
`;
