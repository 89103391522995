import React from 'react';
import { useTranslation } from 'react-i18next';

import { ClassContainer, BgImage } from './styles';

const Limelight: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('LEGO Showcase')}</h1>
      {/* <h2>{t('Basic Programming:')}</h2> */}
      <p>
        {t(
          "With LEGO Mindstorms, it is possible to explore a wide range of possibilities in creating robots, from the simplest to the most complex ones. You can build robots that perform simple tasks, such as following a black or white line, or more complex tasks, such as solving a Rubik's Cube or sort pieces on a factory conveyor belt.",
        )}
      </p>
      <p>
        {t(
          'The Mindstorms platform is based on a set of modular Lego pieces, sensors, and motors that can be assembled in countless combinations, allowing users to create custom and unique robots. Moreover, the platform is highly programmable, enabling users to create complex behaviors for their robots using an easy-to-use visual interface.',
        )}
      </p>
      <p>
        {t(
          'Within this world of possibilities, a few commonly used robots are:',
        )}
      </p>
      <h2>{t('Remote Control Robot')}</h2>
      <p>
        {t(
          'This robot can be controlled by a wireless remote control, allowing the user to move it forward, backward, left, and right. It is a simple robot to build and program, but it can be a lot of fun to use and explore.',
        )}
      </p>
      <BgImage>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/robot-base-bg.png"
          alt="robot base"
        />
      </BgImage>
      <h2>{t('Obstacle Avoiding Robot')}</h2>
      <p>
        {t(
          'This robot is equipped with sensors that detect obstacles in its path and adjust its route to avoid them. It can be used to explore an unknown environment or to perform tasks that require precise navigation in a limited space.',
        )}
      </p>
      <BgImage>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/obstacles-robot-bg.png"
          alt="robot base"
        />
      </BgImage>
      <h2>{t('Line Follower Robot')}</h2>
      <p>
        {t(
          'This robot is designed to follow a black or white line on a surface. It uses a color sensor to detect the line and adjusts its direction to continue following it.',
        )}
      </p>
      <BgImage>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/line-follower-bg.png"
          alt="robot base"
        />
      </BgImage>
      <h2>{t('Pick and Place Robot')}</h2>
      <p>
        {t(
          'The Pick and Place Robot is designed to simulate an autonomous robot that can pick up and deliver objects to different locations, using sensors and motors to navigate the environment and perform its tasks. This creation is similar to the robotic arms we see in large industries.',
        )}
      </p>
      <BgImage>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/robot-arm-bg.png"
          alt="robot base"
        />
      </BgImage>
      <h2>{t('Factory Conveyor Belt')}</h2>
      <p>
        {t(
          'This robot can be programmed to sort parts based on their shapes, sizes, or colors. By combining different sensors, the robot can move with precision and perform tasks autonomously. You can create different types of sorting machines according to your creativity and goals.',
        )}
      </p>
      <BgImage>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/color-sorter-bg.png"
          alt="robot base"
        />
      </BgImage>
    </ClassContainer>
  );
};

export default Limelight;
