import React from 'react';
import { useTranslation } from 'react-i18next';

import { CodeBlock } from '@atlaskit/code';

import { ClassContainer } from './styles';

const WhatIsLEGO: React.FC = () => {
  const { t } = useTranslation();

  const firstJavaCode = `class Main {\n\u0020\u0020public static void main (String [] args) {\n\u0020\u0020\u0020\u0020int counter = 0;\n\n\u0020\u0020\u0020\u0020while (counter < 5) {\n\u0020\u0020\u0020\u0020\u0020\u0020System.out.println("Number: " + counter);\n\u0020\u0020\u0020\u0020\u0020\u0020counter++;\n\u0020\u0020\u0020\u0020}\n\u0020\u0020}\n}`;

  const secondJavaCode = `class Main {\n\u0020\u0020public static void main (String [] args) {\n\u0020\u0020\u0020\u0020int counter = 0;\n\n\u0020\u0020\u0020\u0020do {\n\u0020\u0020\u0020\u0020\u0020\u0020System.out.println("Number: " + counter);\n\u0020\u0020\u0020\u0020\u0020\u0020counter++;\n\u0020\u0020\u0020\u0020} while (counter < 5);\n\u0020\u0020}\n}`;

  const thirdJavaCode = `class Main {\n\u0020\u0020public static void main (String [] args) {\n\u0020\u0020\u0020\u0020for (int i = 0; i < 5; i++) {\n\u0020\u0020\u0020\u0020\u0020\u0020System.out.println("Number: " + i);\n\u0020\u0020\u0020\u0020}\n\u0020\u0020}\n}`;

  return (
    <ClassContainer>
      <h1>{t('Repetition Structures')}</h1>
      <p>
        {t(
          'Loop structures, also known as loops, are used to repeatedly execute a statement or block of statements as long as a certain condition is continuously met.',
        )}
      </p>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>{t('WHILE')}</h2>
      <p>
        {t(
          'This loop structure is used when you want to execute a block of code while a specific condition is true. The condition is evaluated before the execution of the code block.',
        )}
      </p>
      <CodeBlock language="java" text={t(firstJavaCode)} shouldWrapLongLines />
      <p>
        {t(
          'In this example, the code will be executed as long as the counter is less than 5. The counter is incremented within the code block.',
        )}
      </p>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>{t('DO-WHILE')}</h2>
      <p>
        {t(
          "The do-while loop structure is a variation of the while structure. There is a subtle yet important difference between them. In a while loop, the condition is tested before the first execution of the statements that make up its body. Therefore, if the condition is false the first time it's evaluated, the statements in that loop will not be executed at all. In a do-while loop, on the other hand, the condition is only evaluated after its statements have been executed for the first time. Thus, even if the condition of this loop is false before it starts, its statements will be executed at least once.",
        )}
      </p>
      <CodeBlock language="java" text={t(secondJavaCode)} shouldWrapLongLines />
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>{t('FOR')}</h2>
      <p>
        {t(
          'The for loop structure is used when you know in advance how many times you want a block of code to be executed. It consists of three parts: initialization, condition, and counter update.',
        )}
      </p>
      <CodeBlock language="java" text={t(thirdJavaCode)} shouldWrapLongLines />
      <p>
        {t(
          "In this example, the code will be executed 5 times, starting with i equal to 0 and ending when i is equal to 4. The counter i is incremented with each iteration. It's worth noting that in programming, we typically start counting from 0, not 1.",
        )}
      </p>
    </ClassContainer>
  );
};

export default WhatIsLEGO;
