import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { BgImage, ClassContainer } from './styles';

const WhatIsLEGO: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Circuit Assembly II')}</h1>
      <p
        style={{
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('1. PWM Cables')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
        }}
      >
        {t('Option 1 (direct conection)')}
      </p>
      <p>
        {t(
          'Connect the PWM cables from each controller directly to the roboRIO. For Victor SPX and other PWM/CAN controllers, the green wire (black wire for non-integrated controllers) should be facing the outer edge of the roboRIO. For controllers without integrated wires, ensure that the side with the black wire aligns with the markings on the controller. It is recommended to connect the left side to PWM 0 and 1 and the right side to PWM 2 and 3 for the most straightforward programming experience, but any channel will work as long as you note which side goes to which channel and adjust the code accordingly.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
        }}
      >
        {t('Option 2 (Y cable)')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Connect one PWM Y-cable to the PWM cables of the controllers that control one side of the robot. The brown wire on the Y-cable should correspond to the green/black wire on the PWM cable.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Connect the PWM Y-cables to the PWM ports on the roboRIO. The brown wire should be facing the outer edge of the roboRIO. It is recommended to connect the left side to PWM 0 and the right side to PWM 1 for the most straightforward programming experience, but any channel will work as long as you note which side goes to which channel and adjust the code accordingly.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/electric/pwm.png"
          alt="pwm"
        />
      </BgImage>
      <p
        style={{
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('2. Robot Signal Light')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Cut one end of the 2-pin cable and strip the insulation from both wires.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Insert the black wire into the central "N" terminal and tighten the terminal.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Take the 18AWG red wire and insert it into the "La" terminal, then tighten the terminal.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Cut and strip the other end of the 18AWG wire to insert it into the "Lb" terminal.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Insert the red wire from the two-pin cable into the "Lb" terminal along with the 18AWG red wire and tighten the terminal.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Connect the two-pin connector to the RSL port on the roboRIO. The black wire should be closer to the outer edge of the roboRIO.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/electric/rsl.png"
          alt="rsl"
        />
      </BgImage>
      <p
        style={{
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('3. Fuses')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Insert the 40A fuses into the corresponding positions on the PDP for the Wago connectors to which the Victors are connected. Note that for all fuses, the input corresponds to the nearest positive (red) terminal, as shown in the image. All negative terminals on the board are internally connected.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/electric/40A-breaker.png"
          alt="40A-breaker"
        />
      </BgImage>
      <p
        style={{
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('3. Motor Power')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('To each CIM motor:')}
      </p>
      <p>{t('Strip the ends of the red and black wires from the CIM.')}</p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('To integrated wires controllers (including Victor SPX):')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('Strip the white and green wires from the controller.')}
      </p>
      <p>
        {t(
          'Connect the motor wires to the output wires of the controller (it is recommended to connect the red wire to the white M+ output). The images show examples using quick-connect terminals.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('For Sparks or other non-integrated wire controllers:')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('Attach a ring/fork terminal to each of the motor wires.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          "Connect the wires to the motor controller's output side (red wire to +, black wire to -).",
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/electric/motor-power.png"
          alt="motor-power"
        />
      </BgImage>
      <p style={{ color: '#051227', fontWeight: 'bold' }}>
        {t('Electric pattern and reference images from WPI Docs')}
      </p>
    </ClassContainer>
  );
};

export default WhatIsLEGO;
