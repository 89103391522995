interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'control/b8.png',
    title: 'Stop other code blocks',
    text:
      'This block is used to interrupt any other code block that is currently being executed. It is very useful to ensure that no other program is running at the same time.',
  },
  {
    image: 'control/b7.png',
    title: 'If, Then, Else',
    text:
      "This block is similar to the “If, Then” block, but it also allows you to add an action if the condition is NOT met, within the “Else” part. For example, if you want the robot to move forward if a color sensor detects the color green, and move backward if it doesn't detect the green color, you can use the “If, Then, Else” block. Inside the block, you specify the condition that the color sensor detects the green color, and then you specify the condition that the color sensor doesn't detect the green color, along with the corresponding movement actions.",
  },
  {
    image: 'control/b6.png',
    title: 'Until, Repeat',
    text:
      'This block allows you to execute a set of actions repeatedly until a certain condition, created by you, is met. In other words, while your condition is not satisfied, the code inside this block will continue to be executed. Your condition will be placed inside the hexagonal socket.',
  },
  {
    image: 'control/b5.png',
    title: 'Repeat Forever',
    text:
      'This block allows you to execute a set of actions repeatedly, infinitely, until the program is terminated.',
  },
  {
    image: 'control/b4.png',
    title: 'Repeats X times',
    text:
      'This block allows you to execute a set of actions multiple times, according to the number you specify. The actions you want to repeat will be placed inside this block. It has a parameter where you can indicate how many times this piece of code will be executed.',
  },
  {
    image: 'control/b3.png',
    title: 'Wait until',
    text:
      'With this block, you can add a timer in the middle of your code until your own condition is met, by adding a hexagon-shaped block inside this block.',
  },
  {
    image: 'control/b2.png',
    title: 'If, Then',
    text:
      'This block allows you to specify an action to be executed if, and only if, a certain condition is met. For example, if you want the robot to move forward only if a color sensor detects the color green, you can place the movement actions inside the “If, Then” block and specify the condition that the color sensor detects the green color. This way, the robot will move forward only if the condition is met.',
  },
  {
    image: 'control/b1.png',
    title: 'Wait',
    text:
      'This block adds a timer within the code, causing the robot to wait for a specified number of seconds before continuing with its instructions. It has a parameter where you can indicate how many seconds it should wait.',
  },
];

export default Cards;
