/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import {
  useSpring,
  useTrail,
  useChain,
  animated,
  useSpringRef,
  useTransition,
} from '@react-spring/web';

import { useTranslation, Trans } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Container, Content } from './styles';
import Button from '../../../../../components/Button';

interface ModalProps {
  modalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const InstructionsModal: React.FC<ModalProps> = ({
  modalVisible,
  setModalVisible,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' });

  const { t } = useTranslation();

  const modalRef = useSpringRef();
  const modalProps = useTransition(modalVisible, {
    ref: modalRef,
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  });

  const contentRef = useSpringRef();
  const contentProps = useSpring({
    ref: contentRef,
    config: {
      tension: 400,
      friction: 20,
    },
    from: {
      opacity: 0,
      transform: 'scale(0)',
    },
    to: {
      opacity: modalVisible ? 1 : 0,
      transform: modalVisible ? 'scale(1)' : 'scale(0)',
    },
  });

  const children = [
    {
      device: 'mobile',
      content: [
        <h1>{t('Using the Color Sensor')}</h1>,
        <p>
          {t(
            'Scroll down to see the code editor and click on the codes at the bottom of the page to add them to the program.',
          )}
        </p>,
        <p>
          <Trans t={t}>
            Your goal is to add a
            <span style={{ color: '#1dccf0', fontWeight: 600 }}>
              &nbsp;sensor block&nbsp;
            </span>
            in the parameter of a
            <span style={{ color: '#ffb515', fontWeight: 600 }}>
              &nbsp;control block&nbsp;
            </span>
            and then add a
            <span style={{ color: '#935df5', fontWeight: 600 }}>
              &nbsp;display block&nbsp;
            </span>
            inside the control block. Repeat this process with the colors red and green.
          </Trans>
        </p>,
        <p>
          {t('After finishing the code, test the robot by clicking on the colors below the sensor that will appear')}
        </p>,
        <Button
          type="button"
          className="modal-close-button"
          onClick={() => setModalVisible(false)}
        >
          {t('Got it')}
        </Button>,
      ],
    },
    {
      device: 'desktop',
      content: [
        <h1>{t('Using the Color Sensor')}</h1>,
        <p>
          {t('Click on the blocks on the right to add them to the program.')}
        </p>,
        <p>
          <Trans t={t}>
            Your goal is to add a
            <span style={{ color: '#1dccf0', fontWeight: 600 }}>
              &nbsp;sensor block&nbsp;
            </span>
            in the parameter of a
            <span style={{ color: '#ffb515', fontWeight: 600 }}>
              &nbsp;control block&nbsp;
            </span>
            and then add a
            <span style={{ color: '#935df5', fontWeight: 600 }}>
              &nbsp;display block&nbsp;
            </span>
            inside the control block. Repeat this process with the colors red and green.
          </Trans>
        </p>,
        <p>
          {t('After finishing the code, test the robot by clicking on the colors below the sensor that will appear')}
        </p>,
        <Button
          type="button"
          className="modal-close-button"
          onClick={() => setModalVisible(false)}
        >
          {t('Got it')}
        </Button>,
      ],
    },
  ];

  const childrenRef = useSpringRef();
  const childrenTrail = useTrail(
    isTabletOrMobile ? children[0].content.length : children[1].content.length,
    {
      ref: childrenRef,
      from: {
        opacity: 0,
        transform: 'translate3d(0, 10px, 0)',
      },
      to: {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
      },
    },
  );

  useChain([modalRef, contentRef, childrenRef], [0, 0.2, 0.6]);

  return (
    <>
      {modalProps(({ opacity }, item) => {
        return item ? (
          <Container style={{ opacity }}>
            <Content style={contentProps}>
              {childrenTrail.map((p, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <animated.div className="textContent" key={index} style={p}>
                    {isTabletOrMobile
                      ? children[0].content[index]
                      : children[1].content[index]}
                  </animated.div>
                );
              })}
            </Content>
          </Container>
        ) : null;
      })}
    </>
  );
};

export default InstructionsModal;
