/* eslint-disable import/no-duplicates */
import React, { useEffect, useMemo, useState } from 'react';
import { parseISO, formatDistance } from 'date-fns';
import socketClient from 'socket.io-client';
import { enUS, ptBR } from 'date-fns/locale';

import { useTranslation } from 'react-i18next';
import api from '../../services/api';
import { useAuthentication } from '../../hooks/authentication';

import {
  Screen,
  Container,
  Header,
  Scroll,
  Notification,
  NotificationIcon,
  NotificationContent,
  HeaderContent,
} from './styles';

interface Notification {
  id: string;
  content: string;
  addressee_id: string;
  achievement_id: string;
  read: boolean;
  created_at: string;
  timeDistance: string;
  badge_url: string;
  description: string;
}

interface Achievement {
  badge_url: string;
}

const UserNotifications: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>(
    [] as Notification[],
  );

  const { user } = useAuthentication();

  const { t, i18n } = useTranslation();

  const socket = useMemo(
    () =>
      socketClient(
        process.env.REACT_APP_API_URL || 'https://lfdeploy.learnfirst.com.br',
        {
          query: {
            user_id: user.id,
          },
        },
      ),
    [user.id],
  );

  const locales: { [key: string]: Locale } = useMemo(() => {
    return {
      'pt-BR': ptBR,
      'en-US': enUS,
    };
  }, []);

  useEffect(() => {
    socket.on('notification', async notification => {
      const achievement: Achievement = (
        await api.get(`/courses/achievement/${notification.achievement_id}`)
      ).data;

      const mappedNotification = {
        ...notification,
        timeDistance: formatDistance(
          parseISO(notification.created_at),
          new Date(),
          { addSuffix: true, locale: locales[i18n.language] },
        ),
        badge_url: achievement.badge_url,
      };

      setNotifications([mappedNotification, ...notifications]);
    });
  }, [i18n.language, locales, notifications, socket]);

  useEffect(() => {
    async function loadNotifications(): Promise<void> {
      const response = await api.get(`/notifications/addressee/${user.id}`);

      const mappedNotifications: Notification[] = await Promise.all(
        response.data.map(async (loadedNotification: Notification) => {
          const achievement: Achievement = (
            await api.get(
              `/courses/achievement/${loadedNotification.achievement_id}`,
            )
          ).data;

          return {
            ...loadedNotification,
            timeDistance: formatDistance(
              parseISO(loadedNotification.created_at),
              new Date(),
              { addSuffix: true, locale: locales[i18n.language] },
            ),
            badge_url: achievement.badge_url,
          };
        }),
      );

      setNotifications(mappedNotifications);
    }

    loadNotifications();
  }, [i18n.language, locales, user.id]);

  async function markAsRead(id: string) {
    await api.put(`notifications/${id}`);

    setNotifications(
      notifications.map(notification =>
        notification.id === id ? { ...notification, read: true } : notification,
      ),
    );
  }

  async function markAllAsRead() {
    const mappedNotifications = await Promise.all(
      notifications.map(async notification => {
        if (!notification.read) {
          await api.put(`notifications/${notification.id}`);

          return { ...notification, read: true };
        }

        return notification;
      }),
    );

    setNotifications(mappedNotifications);
  }

  return (
    <Screen>
      <Container>
        <Header>
          <HeaderContent>
            <h2>{t('Notifications')}</h2>
            <button type="button" onClick={() => markAllAsRead()}>
              {t('Mark all as read')}
            </button>
          </HeaderContent>
        </Header>
        <Scroll>
          {notifications.length > 0 ? (
            notifications
              .slice()
              .reverse()
              .map(notification => (
                <Notification key={notification.id} unread={!notification.read}>
                  <NotificationIcon unread={!notification.read}>
                    <img src={notification.badge_url} alt="" />
                  </NotificationIcon>
                  <NotificationContent>
                    <p>
                      {`${t('New Achievement!')} ${t(notification.content)}`}
                    </p>
                    <time>{t(notification.description)}</time>
                    <time>{notification.timeDistance}</time>
                    {!notification.read && (
                      <button
                        type="button"
                        onClick={() => markAsRead(notification.id)}
                      >
                        {t('MARK AS READ')}
                      </button>
                    )}
                  </NotificationContent>
                </Notification>
              ))
          ) : (
            <p style={{ marginTop: '2rem' }}>
              {t('You do not have any notifications at the moment')}
            </p>
          )}
        </Scroll>
      </Container>
    </Screen>
  );
};

export default UserNotifications;
