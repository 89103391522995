interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'operators/b7.png',
    title: 'Round',
    text:
      'This block is used to round a number to the nearest integer or to a number with a specific number of decimal places. It works as follows: you input the number you want to round and the precision (i.e., the number of decimal places to round to) inside the block. If you leave the precision as 0, the block will round the number to the nearest integer. If you set a precision other than 0, the block will round to the specified number of decimal places. It is useful in mathematical calculations and displaying numbers in a more readable format.',
  },
  {
    image: 'operators/b6.png',
    title: 'Negation Condition',
    text:
      'This block is used to negate a condition, that is, to check if a condition is false. It works as follows: when you add a condition inside the block, it will check if the condition is false. If the condition is false, the block will return “true”, indicating that the negation of the condition is true. If the condition is true, the block will return “false”. In other words, the block will return the opposite of the condition provided to it.',
  },
  {
    image: 'operators/b5.png',
    title: '“OR” Condition',
    text:
      'The “OR” block is another logical operator block used to test two conditions and check if AT LEAST one of them is true. In other words, when you add two conditions inside the “OR“ block, it will test both conditions simultaneously. If at least one of the conditions is true, the block will return “true”. Only if both conditions are false, the block will return “false”.',
  },
  {
    image: 'operators/b4.png',
    title: '“AND” Condition',
    text:
      'The “AND” operator block is a logical block used to test two conditions and check if both are true. Specifically, the “AND” block works as follows: when you add two conditions inside the block, it will test both conditions simultaneously. If both conditions are true, the block will return “true”. However, if one or both of the conditions are false, the block will return “false”. This way, you can make the robot take action based on this true or false response.',
  },
  {
    image: 'operators/b3.png',
    title: 'Comparisons',
    text:
      'These blocks inform whether the first input value is greater than, less than, or equal to the second input value. They have two parameters where you can input the values for the calculation.',
  },
  {
    image: 'operators/b2.png',
    title: 'Operators',
    text:
      'These blocks perform addition (+), subtraction (-), multiplication (*), and division (/) calculations. You can use sensor information blocks within these operation blocks. They have two parameters where you can input the values for the calculation.',
  },
  {
    image: 'operators/b1.png',
    title: 'Generate random number',
    text:
      'This block generates a random number within the provided range. It has two parameters indicating the start and end of the range.',
  },
];

export default Cards;
