import React, { useCallback, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { FiKey } from 'react-icons/fi';

import {
  HiOutlineLibrary,
  HiOutlineMail,
  HiOutlinePuzzle,
  HiOutlineUserAdd,
} from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import getValidationErrors from '../../utils/getValidationErrors';
import { useAuthentication } from '../../hooks/authentication';
import { useToast } from '../../hooks/toast';

import {
  Screen,
  Container,
  Content,
  SignUpContainer,
  ContainerRight,
  Logo,
} from './styles';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { ReactComponent as SignInGroup } from '../../assets/Illustrations/SignIn.svg';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuthentication();

  const { t } = useTranslation();
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required(t('Please, provide an email'))
            .email(t('Please, provide a valid email')),
          password: Yup.string().required(t('Please, provide a password')),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });

        history.push('/dashboard');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          label: t('Authentication error'),
          description: t('Check your credentials'),
        });
      }
    },
    [addToast, history, signIn, t],
  );

  return (
    <Screen>
      <Container>
        <Content>
          <Logo>
            <div style={{ display: 'flex' }}>
              <HiOutlinePuzzle size={32} />
            </div>
            <p>LearnFirst</p>
          </Logo>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>{t('Welcome back!')}</h1>
            <p>{t('It is so good to see you again!')}</p>

            <Input name="email" placeholder="E-mail" icon={HiOutlineMail} />

            <Input
              name="password"
              placeholder={t('Password')}
              icon={FiKey}
              endIconOn={RiEyeLine}
              endIconOff={RiEyeOffLine}
            />

            <Link to="/forgot-password">{t('Forgot your password?')}</Link>

            <Button type="submit">{t('Sign in')}</Button>
          </Form>

          <SignUpContainer>
            <p>{t("Don't have and account?")}</p>

            <Link to="/signup">
              {t('Create account')}
              <HiOutlineUserAdd size={18} />
            </Link>
          </SignUpContainer>
          <SignUpContainer>
            <Link to="/library">
              <HiOutlineLibrary
                size={18}
                style={{ marginLeft: '0', marginRight: '0.5rem' }}
              />
              {t('Access to the Library')}
            </Link>
          </SignUpContainer>
        </Content>
        <ContainerRight>
          <SignInGroup />
        </ContainerRight>
      </Container>
    </Screen>
  );
};

export default SignIn;
