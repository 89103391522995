/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { parseISO, subWeeks } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { FaArrowRight, FaRobot, FaUserAstronaut } from 'react-icons/fa';
import { IoCalendarSharp } from 'react-icons/io5';
import { IoIosArrowForward } from 'react-icons/io';

import { IconBaseProps } from 'react-icons';
import {
  HiCalendar,
  HiOutlineCalendar,
  HiOutlineTranslate,
  HiPencil,
} from 'react-icons/hi';
import {
  Screen,
  Main,
  Layout,
  Header,
  Profile,
  Me,
  Details,
  Levels,
  Level,
  LevelCat,
  Name,
  WelcomeCard,
  CardImage,
  CardContent,
  WeeklyCard,
  ChartContainer,
  ChartContent,
  Bars,
  LastWeek,
  ThisWeek,
  AwardsCard,
  Awards,
  Award,
  AwardContent,
  Classes,
  ClassHeader,
  Box,
  Class,
  ClassIcon,
  ClassName,
  Language,
  AwardsHeader,
  AchievementIcon,
  WeekHeader,
  LngList,
  ShimmerLevel,
  ShimmerClass,
  ShimmerAward,
  NewAvtButton,
} from './styles';

import { useAuthentication } from '../../hooks/authentication';

import api from '../../services/api';
// import { ReactComponent as WelcomeSVG } from '../../assets/Illustrations/welcome.svg';
import WelcomeSVG from '../../assets/Illustrations/welcome.svg';

import CircularProgressBar from '../../components/CircularProgressBar';

import getCourseProps from '../../utils/getCourseProps';
import Notification from '../../components/Notifications';
import Skeleton from '../../components/Skeleton';

interface Course {
  id: string;
  name: string;
}

interface Assignment {
  id: string;
  title: string;
  module_level_id: string;
  level?: Level;
  type?: string;
}

interface Class {
  id: string;
  title: string;
  module_level_id: string;
  level?: Level;
  type?: string;
}

interface AssignmentProgress {
  id: string;
  completed: boolean;
}

interface Level {
  id: string;
  name: string;
  area_module_id: string;
  area_name: string;
  course_name?: string;
  color: string;
  icon: React.ComponentType<IconBaseProps>;
  progress?: number;
  assignments: Assignment[];
  classes: Class[];
}

interface LevelProgress {
  completed: boolean;
  module_level_id: string;
  module_level: Level;
  class_progresses: ClassProgress[];
  assignment_progresses: AssignmentProgress[];
}

interface ClassProgress {
  id: string;
  completed: boolean;
}

interface Module {
  id: string;
  area_id: string;
  course_id: string;
  color?: string;
  icon?: React.ComponentType<IconBaseProps>;
  area: {
    name: string;
  };
}

interface Achievement {
  id: string;
  area_id: string;
  award_type_id: string;
  name: string;
  award_id: string;
  badge_url: string;
  description: string;
}

interface UserAchievement {
  user_id: string;
  achievement_id: string;
  created_at: string;
}

interface WeeklyData {
  lastDay: string;
  lastActivity: number;
  thisDay: string;
  thisActivity: number;
}

const Dashboard: React.FC = () => {
  const [levels, setLevels] = useState<Level[]>([] as Level[]);
  const [content, setContent] = useState<Assignment | Class>();
  const [achievements, setAchievements] = useState<Achievement[]>(
    [] as Achievement[],
  );
  const [weeklyData, setWeeklyData] = useState<WeeklyData[]>(
    [] as WeeklyData[],
  );
  const [lngIsOpen, setLngIsOpen] = useState(false);
  const [profileFetched, setProfileFetched] = useState(false);
  const [achievementsFetched, setAchievementsFetched] = useState(false);

  const { user } = useAuthentication();
  const history = useHistory();

  const { t, i18n } = useTranslation();

  const locales = ['pt-BR', 'en-US'];

  const formatLevelName = useCallback((name: string) => {
    return name.replaceAll(' ', '-');
  }, []);

  const getRoute = useCallback((route: string) => {
    return route.replaceAll(' ', '-');
  }, []);

  useEffect(() => {
    async function loadLevels(): Promise<void> {
      const dbLevels: Level[] = await (
        await api.get(`/courses/last-levels/${user.id}`)
      ).data;

      const propsLevels = await Promise.all(
        dbLevels.map(dbLevel => getCourseProps(dbLevel)),
      );

      const filteredLevels = propsLevels.filter(
        (mappedLevel): mappedLevel is Level => mappedLevel !== undefined,
      );

      setLevels(filteredLevels);

      const dbContent: Assignment | Class = await (
        await api.get(`/courses/last-activity/${user.id}`)
      ).data;

      if (dbContent) {
        const findLevel = filteredLevels.find(
          lv => lv.id === dbContent.module_level_id,
        );

        if (findLevel) {
          dbContent.level = await getCourseProps(findLevel);
        }

        setContent(dbContent);
      }

      setProfileFetched(true);
    }

    async function loadAchievements(): Promise<void> {
      const response = await api.get(`/courses/achievements/last/${user.id}`);

      const mappedAchievements: Achievement[] = await Promise.all(
        response.data.map(async (loadedUserAchievement: UserAchievement) => {
          const achievement: Achievement = (
            await api.get(
              `/courses/achievement/${loadedUserAchievement.achievement_id}`,
            )
          ).data;

          return {
            ...achievement,
            badge_url: achievement.badge_url,
          };
        }),
      );

      setAchievements(mappedAchievements);

      setAchievementsFetched(true);
    }

    async function loadWeeklyData(): Promise<void> {
      const response = await api.get(`/learn/week/${user.id}`);

      setWeeklyData(response.data);
    }

    loadLevels();
    loadAchievements();
    loadWeeklyData();
    // ntlf
  }, [user.id]);

  const getFirstName = useMemo(() => {
    const splitedName = user.name.split(' ');

    return splitedName[0];
  }, [user.name]);

  const contentFormatted = useCallback((title: String) => {
    return title.replace(/ /g, '-');
  }, []);

  const handleLanguageChange = useCallback(
    (language: string) => {
      i18n.changeLanguage(language);
    },
    [i18n],
  );

  const studentPerformance = useMemo(() => {
    let lastWeekClasses = 0;
    let thisWeekClasses = 0;

    weeklyData.forEach(data => {
      lastWeekClasses += data.lastActivity;
      thisWeekClasses += data.thisActivity;
    });

    if (lastWeekClasses === 0) {
      return `${t('Here you will se your weekly performance')}`;
    }

    if (lastWeekClasses > thisWeekClasses) {
      return `${Math.round(
        ((lastWeekClasses - thisWeekClasses) / lastWeekClasses) * 100,
      )}% ${t('Decrease')}`;
    }

    return `${Math.round(
      ((thisWeekClasses - lastWeekClasses) / lastWeekClasses) * 100,
    )}% ${t('Improvement')}`;
  }, [t, weeklyData]);

  const weeklyGoal = useMemo(() => {
    let thisWeekClasses = 0;

    weeklyData.forEach(data => {
      thisWeekClasses += data.thisActivity;
    });

    return Math.round((thisWeekClasses / user.goal) * 100);
  }, [user.goal, weeklyData]);

  const getType = useCallback((type: string | undefined) => {
    return type === 'assignment' ? 'assignment' : 'class';
  }, []);

  return (
    <Screen>
      <Main className="Main">
        <Header>
          <p>{`${t('Welcome Back')} ${getFirstName}!`}</p>
          <div>
            <HiCalendar size={18} />
            {t('Date', { date: new Date() })}
          </div>
        </Header>
        <WelcomeCard cardColor="#1d62d1" textColor="#fff">
          <CardImage style={{ backgroundImage: `url(${WelcomeSVG})` }} />
          <CardContent>
            <h1>{t('Strive for progress, not perfection')}</h1>
            <p>
              {Number.isNaN(weeklyGoal)
                ? t('Week percentage null')
                : t('Week percentage', { weeklyGoal })}
            </p>
          </CardContent>
        </WelcomeCard>
        <WeeklyCard>
          <WeekHeader>
            <h2>{t('Weekly Accomplishment')}</h2>
            <h3>{`${studentPerformance}!`}</h3>
          </WeekHeader>
          <ChartContainer>
            {weeklyData.map((data, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <ChartContent key={i}>
                <Bars>
                  <LastWeek activity={data.lastActivity} />
                  <ThisWeek activity={data.thisActivity} />
                </Bars>
                <p>
                  {t('DateDay', { date: parseISO(data.thisDay) })}
                  {/* {format(parseISO(data.thisDay), 'eee')} */}
                </p>
              </ChartContent>
            ))}
          </ChartContainer>
        </WeeklyCard>
        <AwardsCard textColor="#1d62d1">
          <AwardsHeader>
            <h1>{t('Last Achievements')}</h1>
            <Link to="/achievements" type="button">
              <FaArrowRight size={window.innerWidth >= 1501 ? 20 : 15} />
            </Link>
          </AwardsHeader>
          {achievementsFetched && achievements.length > 0 && (
            <Awards>
              {achievements
                .slice()
                .reverse()
                .map(achievement => (
                  <Award key={achievement.id}>
                    <AchievementIcon>
                      <img src={achievement.badge_url} alt="" />
                    </AchievementIcon>
                    <AwardContent>
                      <div>
                        <h3>{t(achievement.name)}</h3>
                        <h4>
                          {achievement.description.length > 60
                            ? `${t(achievement.description).substring(
                                0,
                                60,
                              )}...`
                            : t(achievement.description)}
                        </h4>
                      </div>
                    </AwardContent>
                  </Award>
                ))}
            </Awards>
          )}
          {achievementsFetched && achievements.length <= 0 && (
            <p style={{ marginTop: '1rem' }}>
              {t(
                "Here you'll see your last achievements acquired throughout the courses!",
              )}
            </p>
          )}
          {!achievementsFetched && (
            <Awards>
              <ShimmerAward>
                <Skeleton className="icon-skeleton blue" />
                <div>
                  <Skeleton className="row-skeleton blue" />
                  <Skeleton className="row-skeleton blue" />
                </div>
              </ShimmerAward>
            </Awards>
          )}
        </AwardsCard>
      </Main>
      <Profile>
        <Me>
          <Notification />
          <Details>
            <div>
              {user.avatar_url ? (
                <img src={user.avatar_url} alt="" />
              ) : (
                <>
                  <FaUserAstronaut size={100} />
                  <NewAvtButton onClick={() => history.push('/settings')}>
                    <HiPencil size={20} />
                  </NewAvtButton>
                </>
              )}
            </div>
            <p>{getFirstName}</p>
          </Details>
          <Language onClick={() => setLngIsOpen(prevState => !prevState)}>
            <HiOutlineTranslate size={24} />
          </Language>
          {lngIsOpen && (
            <LngList>
              {locales.map(locale => (
                <p key={locale} onClick={() => handleLanguageChange(locale)}>
                  {locale}
                </p>
              ))}
            </LngList>
          )}
        </Me>
        <Levels>
          <h4>{t('Last Levels')}</h4>
          {profileFetched &&
            levels.length > 0 &&
            levels.map(level => (
              <Link
                key={level.id}
                to={`/courses/${level.course_name}/${
                  level.area_name
                }/${formatLevelName(level.name)}`}
              >
                <Level key={level.id}>
                  <LevelCat>
                    <level.icon size={25} />
                  </LevelCat>
                  <Name>
                    <p>
                      {level.name.length > 20
                        ? `${t(level.name).substring(0, 20)}...`
                        : t(level.name)}
                    </p>
                  </Name>
                  <CircularProgressBar
                    inicialProgress={
                      level.progress !== undefined ? level.progress : 0
                    }
                    size={window.innerWidth >= 1501 ? 50 : 45}
                    strokeWidth={5}
                    circleBgStroke="rgba(204, 204, 204, 0.3)"
                    circleProgressStroke="#1d62d1"
                  />
                </Level>
              </Link>
            ))}

          {profileFetched && levels.length <= 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <p style={{ marginBottom: '1rem' }}>
                {t("Here you'll see your last visited levels")}
              </p>

              <Link to="/courses" type="button">
                <p>{t('Browse')}</p>
                <FaArrowRight size={12} />
              </Link>
            </div>
          )}
          {!profileFetched && (
            <ShimmerLevel>
              <Skeleton className="cat-skeleton" />
              <Skeleton className="row-skeleton" />
              <Skeleton className="progress-skeleton" />
            </ShimmerLevel>
          )}
        </Levels>
        <Classes>
          <ClassHeader>
            <h4>{t('Last Activity')}</h4>
          </ClassHeader>
          <Box>
            {profileFetched && content && (
              <>
                {content.level ? (
                  <Link
                    key={content.id}
                    to={`/course/${getType(content.type)}/${
                      content.level.course_name
                        ? formatLevelName(content.level.course_name)
                        : 'course'
                    }/${formatLevelName(
                      content.level.area_name,
                    )}/${formatLevelName(content.level.name)}/${getRoute(
                      content.title,
                    )}`}
                  >
                    <Class>
                      <ClassIcon>
                        <content.level.icon size={24} />
                      </ClassIcon>
                      <ClassName>
                        <p>{t(content.title)}</p>
                        <p>{t(content.level.area_name)}</p>
                      </ClassName>
                      <IoIosArrowForward size={24} />
                    </Class>
                  </Link>
                ) : (
                  <div />
                )}
              </>
            )}
            {profileFetched && !content && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <p style={{ marginBottom: '1rem' }}>
                  {t("Here you'll see your last visited activities")}
                </p>

                <Link to="/courses" type="button">
                  <p>{t('Browse')}</p>
                  <FaArrowRight size={12} />
                </Link>
              </div>
            )}
            {!profileFetched && (
              <ShimmerClass>
                <Skeleton className="cat-skeleton" />
                <Skeleton className="row-skeleton" />
              </ShimmerClass>
            )}
          </Box>
        </Classes>
      </Profile>
    </Screen>
  );
};

export default Dashboard;
