import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
  useRouteMatch,
  useHistory,
  matchPath,
} from 'react-router-dom';

import { useAuthentication } from '../hooks/authentication';

import Sidebar from '../components/Sidebar';
import UserGoal from '../pages/UserGoal';

interface RouteProps extends ReactDOMRouteProps {
  RouteNeedsSession?: boolean;
  NotFound?: boolean;
  routeRole?: string;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  routeRole,
  RouteNeedsSession = false,
  NotFound = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuthentication();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ history, location }) => {
        /* Redirecionar para o login quando a rota precisa
        de autenticação e o usuário não está logado. */
        if (RouteNeedsSession && !user) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          );
        }

        /* Acesso ao login ou cadastro sem estar logado */
        if (
          (history.location.pathname === '/' ||
            history.location.pathname === '/signup') &&
          !user
        ) {
          return <Component />;
        }

        /* Redirecionar para o dashboard quando o usuário tenta acessar
        o login ou cadastro já estando logado */
        if (
          history.location.pathname !== '/*' &&
          (history.location.pathname === '/' ||
            history.location.pathname === '/signup') &&
          user
        ) {
          return (
            <Redirect
              to={{
                pathname: '/dashboard',
                state: { from: location },
              }}
            />
          );
        }

        /* Redirecionar para o questionário de meta quando é a
        primeira vez do usuário na plataforma logado. */
        if (
          RouteNeedsSession &&
          user &&
          !user.flags.find(flag => flag.name === 'has-weekly-goal')
        ) {
          return <UserGoal />;
        }

        if (
          routeRole &&
          !user.roles.some(userRole =>
            routeRole.split(',').includes(userRole.name),
          )
        ) {
          return (
            <Redirect
              to={{
                pathname: '/dashboard',
                state: { from: location },
              }}
            />
          );
        }

        return (
          <>
            {user && !NotFound && (
              <Sidebar pathname={history.location.pathname} />
            )}
            <Component />
          </>
        );
      }}
    />
  );
};

export default Route;
