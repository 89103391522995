import React, { useCallback, useState } from 'react';

import {
  VscQuestion,
  VscNote,
  VscVerified,
  VscDiffRemoved,
} from 'react-icons/vsc';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import Input from '../Input';

import {
  Container,
  InputContainer,
  AnswerContainer,
  AnswerHeader,
  RadioContainer,
  Answers,
} from './styles';

const Question: React.FC = () => {
  const [elements, setElements] = useState([0] as number[]);
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const { t } = useTranslation();

  const handleAddAnswer = useCallback(() => {
    setElements(prevState => [...prevState, elements[elements.length - 1] + 1]);
  }, [elements]);

  const handleRemoveAnswer = useCallback(
    index => {
      if (elements.length > 1) {
        if (index === activeIndex) {
          setActiveIndex(-1);
        }

        setElements(prevState => prevState.filter((_, i) => i !== index));
      }
    },
    [activeIndex, elements.length],
  );

  return (
    <Container>
      <InputContainer>
        <Input
          name="question"
          type="text"
          placeholder={t('Type your question')}
          icon={VscQuestion}
        />
      </InputContainer>
      <AnswerHeader>
        <h3>{t('Answers')}</h3>
        {elements.length < 5 && (
          <Button
            type="button"
            onClick={handleAddAnswer}
            style={{ width: 'auto', marginTop: '0' }}
          >
            {t('Add answer')}
          </Button>
        )}
      </AnswerHeader>
      <Answers>
        <RadioContainer
          options={Array.from({ length: elements.length }, (_, i) => i + 0)}
          name="correct"
          icon={VscVerified}
        />
        <>
          {elements.map((element, index) => (
            <AnswerContainer
              gridIndex={index}
              key={element}
              correct={index === activeIndex}
            >
              {/* <VscVerified onClick={() => setActiveIndex(index)} size={30} /> */}
              <Input
                name={`answer[${index}]`}
                type="text"
                placeholder={t('Type an answer')}
                icon={VscNote}
              />
              <VscDiffRemoved
                onClick={() => handleRemoveAnswer(index)}
                size={30}
              />
            </AnswerContainer>
          ))}
        </>
      </Answers>
      <Button type="submit" style={{ width: 'auto' }}>
        {t('Save')}
      </Button>
    </Container>
  );
};

export default Question;
