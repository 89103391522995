interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'motors/b11.png',
    title: 'Speed',
    text:
      'This block is used to report the speed of the motor during its execution. It has one parameter: the port to which the motor you want to use is connected.',
  },
  {
    image: 'motors/b10.png',
    title: 'Degrees Counted',
    text:
      "Note that unlike the blocks mentioned so far, this one does not have a place to connect with other blocks. This is because this block is not used to set anything, but rather to inform something during the robot's movement. This block is used to report how many degrees the motor has rotated during its execution. It has one parameter: the port to which the motor you want to use is connected.",
  },
  {
    image: 'motors/b9.png',
    title: 'Restart Counted',
    text:
      'If you are using the degree unit to move your motor, this block is used to reset the degrees counted since your code was executed and the motor started moving.',
  },
  {
    image: 'motors/b8.png',
    title: 'Run Motor',
    text:
      "When setting the speed, you are specifying how many revolutions per minute (RPM) the motor will perform. The higher the speed, the faster the motor will rotate and the faster the robot will move. On the other hand, when setting the power, you are determining the amount of torque that the motor will produce, which is the motor's ability to move or rotate an object. The higher the power, the more weight or resistance the motor will be able to overcome. In other words, speed controls how fast it moves, while power controls the motor's ability to overcome obstacles or carry heavy objects. This block is used to turn on the motor and determine its power.",
  },
  {
    image: 'motors/b7.png',
    title: 'Run Motor',
    text:
      'This block is a combination of two other blocks. It is used to turn on the motor and set a speed for it at the same time. It has two parameters: the first one is the port where the motor you want to use is connected, and the second one is the motor speed.',
  },
  {
    image: 'motors/b6.png',
    title: 'Run Motor',
    text:
      'This block is a combination of two other blocks. It is used to turn on the motor until it completes a certain number of rotations, degrees, or seconds, while also setting a speed for the motor. It has four parameters: the first one is the port where the motor you want to use is connected, the second one is the number of the parameter that will be considered until the motor stops, the third one is the unit that will be used, and the fourth one is the motor speed.',
  },
  {
    image: 'motors/b5.png',
    title: 'Motor Position',
    text:
      "This block is used to determine what will happen to the motor after it has stopped. For example, when using a motor to lift an arm, you can set the arm to maintain its position and stay still at the top, or to 'relax' and return to the initial position. It has two parameters: the first one is the port where the motor you want to use is connected, and the second one is the motor state when stopped.",
  },
  {
    image: 'motors/b4.png',
    title: 'Set Speed',
    text:
      'This block is used to set the motor speed as a percentage. It is generally not necessary to use 100% in most tasks. It has two parameters: the first one is the port where the motor you want to use is connected, and the second one is the motor speed.',
  },
  {
    image: 'motors/b3.png',
    title: 'Stop Motor',
    text:
      'This block is used to turn off the motor. It has one parameter: the port where the motor you want to use is connected.',
  },
  {
    image: 'motors/b2.png',
    title: 'Run Motor',
    text:
      'This block is used to run the motor continuously until another block that stops the motor is added to your code. It has two parameters: the first one is the port where the motor you want to use is connected, and the second one is the direction of the movement.',
  },
  {
    image: 'motors/b1.png',
    title: 'Run Motor',
    text:
      'This first block is used to run the motor until it completes a certain number of rotations, degrees, or seconds. It has four parameters: the first one is the port where the motor you want to use is connected, the second one is the direction of the movement, the third one is the value of the next parameter to be considered until the motor stops, and the fourth one is the unit you have chosen (rotations, degrees, or seconds).',
  },
];

export default Cards;
