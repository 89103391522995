interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mechanic/gloves.png',
    title: 'Gloves',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mechanic/prot-ear-foam.png',
    title: 'Earplugs (Foam)',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mechanic/prot-ear-cord.png',
    title: 'Earplugs (Cord)',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mechanic/over-goggles.png',
    title: 'Overlapping glasses (Prescription glasses)',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mechanic/safety-goggles.png',
    title: 'Protective goggles',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mechanic/pants.png',
    title: 'Long pants',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/mechanic/shoes.png',
    title: 'Closed-toe shoes',
    text: '',
  },
];

export default Cards;
