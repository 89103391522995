import styled from 'styled-components';
import ScratchBlocks from 'scratchblocks-react';
import Class from '../../../../../Class';

export const ClassContainer = styled(Class)`
  & > h1,
  h2,
  & > p {
    margin-bottom: 2rem;
  }

  h1:first-child {
    margin-bottom: 3rem;

    color: #051227;

    font-size: 2rem;
    font-weight: 600;
  }

  h2 {
    color: #051227;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .lf-player-container {
    align-self: center;
  }

  .sb3-extension {
    fill: #f5c400;
    stroke: #b89a00;
  }

  .sb3-extension-alt {
    fill: #d9b100;
  }

  .sb3-extension-line {
    stroke: #d9b100;
  }
`;

export const BgImage = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  width: 100%;

  img {
    position: relative;
    width: 90%;
    height: auto;
  }
`;

export const ScratchBlocksContainer = styled(ScratchBlocks)`
  margin-bottom: 2rem;

  svg {
    width: 100%;
  }
`;
