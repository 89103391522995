import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import scratchblocks from 'scratchblocks';
import es from 'scratchblocks/locales/es.json';
import pt from 'scratchblocks/locales/pt-br.json';

import { ClassContainer, ScratchBlocksContainer } from './styles';

scratchblocks.loadLanguages({ es, pt });

const LineFollower: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Line Follower with PID')}</h1>
      <p>
        {t(
          "Welcome to the next level of line follower robotics! In our previous class, we explored the fundamentals of building and programming a basic line follower robot. You've learned how to create a simple code that enables your robot to follow a predefined path, utilizing its sensors to maintain a consistent trajectory.",
        )}
      </p>
      <p>
        {t(
          "But, what if we told you that there's a way to take your robot's performance to the next level? Meet the PID control.",
        )}
      </p>
      <p>
        {t(
          "PID control allows for dynamic, real-time adjustments in response to the robot's positional errors. The result? Unprecedented precision and adaptability, enabling these robots to tackle intricate courses, navigate uneven terrains, and handle unexpected disruptions.",
        )}
      </p>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('What is PID and how does it work?')}
      </h2>
      <p>
        {t(
          'The PID control is an algorithm used to control constant actions more smoothly, widely used in industries, for example, in robotic arms, air conditioning, hydraulic systems, etc.',
        )}
      </p>
      <p>
        {t(
          "For a simpler example, let's think about how an air conditioner works.",
        )}
      </p>
      <Player
        autoplay
        loop
        src="https://assets1.lottiefiles.com/packages/lf20_hnrtoafa.json"
        style={{ height: '250px', width: '250px', marginBottom: '1rem' }}
      >
        <Controls visible={false} />
      </Player>
      <p>
        {t(
          "First, think about the air conditioner you have at home. When you set a temperature, let's say 70 degrees Fahrenheit, to cool the room. It starts strong, cooling a lot at the beginning, and as the temperature gets close to 70 degrees, it gradually decreases in intensity. But even after reaching 25 degrees, it continues to cool a little before turning off, which causes the temperature to drop a bit further, making the room cooler than you intended.",
        )}
      </p>
      <p>
        {t(
          "This constant cooling is like a part of the air conditioner's job. We call this 'Proportional.' It starts strong and decreases proportionally until it gets close to the temperature you want.",
        )}
      </p>
      <p>
        {t(
          "But what if the temperature keeps fluctuating, going up and down? To prevent this, we use something called 'Integral.' This makes the air conditioner turn on and off smoothly and with low power to maintain a very constant temperature without constant changes.",
        )}
      </p>
      <p>
        {t(
          "Finally, we have 'Derivative.' It's like how the air conditioner reacts to external factors, such as the sun. If you turn on the air conditioner when it's very hot, like at noon, it will cool down significantly. But as the sun sets and the ambient temperature changes, the air conditioner adjusts its intensity to maintain the right room temperature, preventing it from becoming too cold.",
        )}
      </p>
      <p>
        {t(
          'So, to sum up, the air conditioner uses these three things - Proportional, Integral, and Derivative - to efficiently cool the room and keep the temperature as you like it, even when things around it change.',
        )}
      </p>
      <p>
        {t(
          "Now, adapting this to your robot, we can consider our desired temperature as the path the robot should follow, the air conditioner's power as the speed and direction of the robot's wheels, and the changing sun throughout the day as the variation in brightness from where you test the robot and where the competition will take place, for example.",
        )}
      </p>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('Creating our code with PID')}
      </h2>
      <p>
        <Trans t={t}>
          To begin, we should create 5 variables:
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;target, speed, kP, error, and proportional.&nbsp;
          </span>
          The
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;target&nbsp;
          </span>
          will be the value we want to achieve, where we set it to 50 to be the
          middle between the black line and the white ground. If desired, we can
          adjust this value, where 30% would bring it closer to the black line,
          and 70% would move it further away.
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;Speed&nbsp;
          </span>
          represents how fast we want our robot to move, but with high values,
          it may lose its way more easily. The variable
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;kP&nbsp;
          </span>
          will be our constant for calculating the
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;proportional
          </span>
          ,
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;error&nbsp;
          </span>
          is the difference between the
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;target&nbsp;
          </span>
          and the value read by the sensor, and
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;proportional&nbsp;
          </span>
          is the final value for the robot&apos;s motor power. After that, we
          define their values.
        </Trans>
      </p>
      <ScratchBlocksContainer
        blockStyle="scratch3"
        languages={['en', 'es', 'pt']}
      >
        {t(
          'when program starts::hat events\nset [target v] to (50)\nset [speed v] to (25)\nset [kP v] to (0.8)\nforever\nset [error v] to (((3 v) reflected light intensity :: sensing)-(target))\nset [proportional v] to ((error)*(kP))\nstart moving (proportional) at (speed) % speed::sound',
        )}
      </ScratchBlocksContainer>
      <p>
        {t(
          'With this, we will already have a robot that can follow the line, but using only the proportional calculation to the error. However, with lower errors, we may encounter oscillations, so we will start incorporating the other PID algorithm calculations into our program.',
        )}
      </p>
      <p>
        <Trans t={t}>
          The next step is to add
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;derivative&nbsp;
          </span>
          control to our code. Let&apos;s start by creating the necessary
          variables, which are:
          <span style={{ color: '#051227', fontWeight: 'bold' }}>&nbsp;kD</span>
          ,
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;derivative&nbsp;
          </span>
          ,
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;errorDifference&nbsp;
          </span>
          , and
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;lastError
          </span>
          . After that, we will set the value of
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;kD&nbsp;
          </span>
          , which we will adjust until we find a satisfactory value. Let&apos;s
          start with 2. Right after that, we&apos;ll set
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;lastError&nbsp;
          </span>
          to 0 to avoid issues in the code. For the calculations, we&apos;ll
          begin by defining
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;errorDifference&nbsp;
          </span>
          as the difference between the current error and the last error, and
          then update the lastError variable. Finally, for the
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;derivative&nbsp;
          </span>
          calculation, we will multiply
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;errorDifference&nbsp;
          </span>
          by our variable kD and add the value to the motor movement.
        </Trans>
      </p>
      <ScratchBlocksContainer
        blockStyle="scratch3"
        languages={['en', 'es', 'pt']}
      >
        {t(
          'when program starts::hat events\nset [target v] to (50)\nset [speed v] to (25)\nset [kP v] to (0.8)\nset [kD v] to (2)\nset [lastError v] to (0)\nforever\nset [error v] to (((3 v) reflected light intensity :: sensing)-(target))\nset [proportional v] to ((error)*(kP))\nset [errorDifference v] to ((error)-(lastError))\nset [lastError v] to (error)\nset [derivative v] to ((errorDifference)*(kD))\nstart moving ((proportional)+(derivative)) at (speed) % speed::sound',
        )}
      </ScratchBlocksContainer>
      <p>
        <Trans t={t}>
          For the implementation of the integral calculation, we need to create
          the variables:
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;kI&nbsp;
          </span>
          ,
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;sumError&nbsp;
          </span>
          , and
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;integral&nbsp;
          </span>
          . The calculation will be based on the sum of all previous errors, so
          the longer the robot moves, the higher this variable&apos;s value will
          be. Therefore, caution should be exercised when configuring its value.
          First, we should define the variables, where we will adjust
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;kI&nbsp;
          </span>
          until we find the best value, while setting
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;sumError&nbsp;
          </span>
          and
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;integral&nbsp;
          </span>
          to 0.
        </Trans>
      </p>
      <p>
        <Trans t={t}>
          After that, we will set
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;sumError&nbsp;
          </span>
          as
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;sumError&nbsp;
          </span>
          +
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;error&nbsp;
          </span>
          , and then define
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;integral&nbsp;
          </span>
          as the multiplication of
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;sumError&nbsp;
          </span>
          by
          <span style={{ color: '#051227', fontWeight: 'bold' }}>&nbsp;kI</span>
          . Finally, we will add
          <span style={{ color: '#051227', fontWeight: 'bold' }}>
            &nbsp;integral&nbsp;
          </span>
          to the sum for the robot&apos;s movement, resulting in code like the
          one below:
        </Trans>
      </p>
      <ScratchBlocksContainer
        blockStyle="scratch3"
        languages={['en', 'es', 'pt']}
      >
        {t(
          'when program starts::hat events\nset [target v] to (50)\nset [speed v] to (25)\nset [kP v] to (0.1)\nset [kI v] to (0.04)\nset [kD v] to (4)\nset [lastError v] to (0)\nset [sumError v] to (0)\nset [integral v] to (0)\nforever\nset [error v] to (((3 v) reflected light intensity :: sensing)-(target))\nset [proportional v] to ((error)*(kP))\nset [sumError v] to ((sumError)+(error))\nset [integral v] to ((sumError)*(kI))\nset [errorDifference v] to ((error)-(lastError))\nset [lastError v] to (error)\nset [derivative v] to ((errorDifference)*(kD))\nstart moving ((proportional)+((integral)+(derivative))) at (speed) % speed::sound',
        )}
      </ScratchBlocksContainer>
      <p>
        {t(
          "An important point to highlight is that we've set some values just for example purposes. You should run the code, analyze the robot's performance, and adjust the values until you achieve a good result. It can be a bit labor-intensive, but it's worth it in the end. Additionally, if you're having trouble using all three calculations, one solution would be to keep the values of kI and kD at 0 and work only with the P calculation, as it is the simplest to understand and refine.",
        )}
      </p>
      <p>
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('AND ATTENTION: ')}
        </span>
        {t(
          'The order of calculations is crucial for the code to work properly, so it is recommended to follow the same structure presented in the lesson and only modify the variable values.',
        )}
      </p>
    </ClassContainer>
  );
};

export default LineFollower;
