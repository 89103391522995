import styled from 'styled-components';
import { shade } from 'polished';

export const Screen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background: #1d62d1;
`;

export const Container = styled.div`
  border-radius: 2rem;

  background: #fff;
  border: 2px solid #f6ae2d;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;

  form {
    display: flex;
    flex-direction: column;

    width: 100%;

    text-align: center;

    h1 {
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }

    a {
      display: flex;
      align-self: flex-end;

      margin-top: 0.75rem;

      color: #58a4b0;

      text-decoration: none;

      transition: 0.2s ease-in-out;

      &:hover {
        color: ${shade(0.2, '#58a4b0')};
      }
    }
  }

  > a {
    display: flex;
    align-items: center;

    margin-top: 1rem;

    color: #58a4b0;

    text-decoration: none;

    transition: 0.2s ease-in-out;

    p {
      margin-right: 0.25rem;

      color: #6f7380;
    }

    svg {
      margin-right: 0.25rem;
    }

    &:hover {
      color: ${shade(0.2, '#58a4b0')};
    }
  }
`;
