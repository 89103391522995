import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { FaRobot, FaCamera } from 'react-icons/fa';

import { useTranslation } from 'react-i18next';
import { BiBot } from 'react-icons/bi';
import { HiOutlineCamera } from 'react-icons/hi';
import AvtEditor from '../../../AvatarEditor';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Input from '../../../Input';
import { useAuthentication } from '../../../../hooks/authentication';
import { EditContainer, FormContainer, Avatar, UpdateButton } from './styles';
import { useToast } from '../../../../hooks/toast';

const Profile: React.FC = () => {
  const nameFormRef = useRef<FormHandles>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState<File | undefined>();

  const { t } = useTranslation();

  const { user, updateUser } = useAuthentication();
  const { addToast } = useToast();

  const handleNameSubmission = useCallback(
    async (name: string) => {
      try {
        nameFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required(t('Please, provide your name')),
        });

        await schema.validate(name, {
          abortEarly: false,
        });

        const response = await api.put('/profile', name);

        updateUser(response.data);

        addToast({
          type: 'success',
          label: t('Name updated!'),
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          nameFormRef.current?.setErrors(errors);

          return;
        }

        const errorMessage = error.response.data.message as string;

        addToast({
          type: 'error',
          label: errorMessage,
          description: t('Please, try again.'),
        });
      }
    },
    [addToast, t, updateUser],
  );

  const handleOpenImageModal = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        setFile(e.target.files[0]);
        setIsOpen(true);
      }
    },
    [],
  );

  const handleChangeAvatar = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const maxSize = 1024 * 1024;

      if (e.target.files) {
        if (e.target.files[0].size > maxSize) {
          addToast({
            type: 'error',
            label: t('Max image size: 1MB'),
          });
        } else {
          // pass this code to AvatarEditor component
          const data = new FormData();

          data.append('avatar', e.target.files[0]);

          api.put('/profile', data).then(response => {
            updateUser(response.data);

            addToast({
              type: 'success',
              label: t('Avatar updated!'),
            });
          });
        }
      }
    },
    [addToast, t, updateUser],
  );

  return (
    <EditContainer>
      <Form
        ref={nameFormRef}
        initialData={{ name: user.name }}
        onSubmit={handleNameSubmission}
      >
        <FormContainer>
          {isOpen && <AvtEditor setIsOpen={setIsOpen} avatar={file} />}
          {!isOpen && (
            <Avatar hasPhoto={!!user.avatar_url}>
              {user.avatar_url ? (
                <>
                  <img src={user.avatar_url} alt={user.name} />
                  <label htmlFor="avatar">
                    <HiOutlineCamera />

                    <input
                      type="file"
                      id="avatar"
                      onChange={handleOpenImageModal}
                    />
                  </label>
                </>
              ) : (
                <label htmlFor="avatar">
                  <HiOutlineCamera />

                  <input
                    type="file"
                    id="avatar"
                    onChange={handleOpenImageModal}
                  />
                </label>
              )}
            </Avatar>
          )}
          <h4 style={{ marginBottom: '0.5rem' }}>{t('Name')}</h4>
          <Input id="name" name="name" placeholder={t('Name')} icon={BiBot} />
          <UpdateButton type="submit">{t('Update Name')}</UpdateButton>
        </FormContainer>
      </Form>
    </EditContainer>
  );
};

export default Profile;
