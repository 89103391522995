import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { ClassContainer } from './styles';

const ToolHandling: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Tool Handling')}</h1>
      <p>
        {t(
          "Now let's look at the best ways to use the electrical tools correctly. If you don't have any of the tools at home, don't worry; you can practice in your robotics lab!",
        )}
      </p>
      <h2>{t('Universal Pliers / Pressure Pliers')}</h2>
      <p>
        {t(
          'When using this type of pliers, position the workpiece between the jaws as close to the pivot point as possible to get the maximum force. Make sure to adjust the pressure according to the task and position the jaws evenly around the object to be held to prevent slipping.',
        )}
      </p>
      <h2>{t('Needle-nose Pliers / Cutting Pliers')}</h2>
      <p>
        {t(
          'When handling them, hold the handles firmly, keeping your fingers away from the cutting area. Apply firm and controlled pressure to ensure a clean cut.',
        )}
      </p>
      <h2>{t('Electrical Tape')}</h2>
      <p>
        {t(
          'Before applying the electrical tape, ensure that the surfaces to be worked on are clean and dry. The tape adheres better to clean, smooth surfaces, ensuring proper insulation. When applying the tape, stretch it slightly as you wrap it around the object. This will help the tape adhere well and prevent it from coming loose over time. Do not overstretch, as this can cause stress on the tape. Wrap the tape tightly and evenly, making sure it completely covers the area to be insulated. Avoid leaving gaps or excessive overlaps, as this can compromise the effectiveness of the insulation. In general, it is recommended to give at least two full wraps of electrical tape to ensure adequate protection.',
        )}
      </p>
      <h2>{t('Heat-Shrink Tubing')}</h2>
      <p>
        {t(
          "Before applying the heat shrink tubing, make sure the cable surfaces are clean and dry. Remove any residue or dirt that may interfere with the material's adhesion. Cut the heat shrink tubing into pieces that cover the area to be insulated. Remember that heat shrink tubing shrinks significantly when heated, so do not cut pieces that are too short. Slide the heat shrink tubing over the cable or wire to be insulated, ensuring it completely covers the area to be protected. If necessary, you can use multiple overlapping pieces to cover a longer area. Apply heat to the heat shrink tubing to make it shrink and conform around the cable. The most common way to do this is by using a heat gun or a lighter. Maintain a safe distance between the heat source and the tubing to avoid overheating or damage. After complete shrinkage, let the heat shrink tubing cool naturally. Avoid any sudden movement or manipulation during cooling to ensure proper sealing.",
        )}
      </p>
      <h2>{t('Multimeter')}</h2>
      <p style={{ marginBottom: '1rem' }}>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>
            Function selector:&nbsp;
          </span>
          The multimeter has a selector that allows you to choose the desired
          measurement function, such as direct current voltage (DCV),
          alternating current voltage (ACV), direct current (DCA), alternating
          current (ACA), resistance (Ohm), and more. Make sure to select the
          correct function before taking the measurement.
        </Trans>
      </p>
      <p style={{ marginBottom: '1rem' }}>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>
            Voltage:&nbsp;
          </span>
          To measure voltage, connect the multimeter&apos;s test leads to the
          terminals or connection points of the circuit where you want to take
          the measurement. To measure direct current voltage (DCV), select the
          DCV function, and for alternating current voltage (AC), select the ACV
          function.
        </Trans>
      </p>
      <p style={{ marginBottom: '1rem' }}>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>
            Current:&nbsp;
          </span>
          To measure current, break the circuit and connect the multimeter in
          series with the component through which the current flows. Select the
          DCA function to measure direct current (DC) and ACA for alternating
          current (AC).
        </Trans>
      </p>
      <p>
        {t(
          'Make sure to use the multimeter on the appropriate scales to prevent damage to the instrument and ensure your safety. Additionally, always disconnect circuits before taking voltage or current measurements to avoid accidents.',
        )}
      </p>
      <h2>{t('Soldering Iron with Solder')}</h2>
      <p style={{ marginBottom: '1rem' }}>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>
            Preparation:&nbsp;
          </span>
          Before using the soldering iron, make sure the tip is clean and shiny.
          Use a cleaning sponge or a specific metal sponge to clean the
          soldering iron tip, removing any residues or oxidation. A clean tip
          will ensure better heat transfer and help prevent soldering issues.
        </Trans>
      </p>
      <p style={{ marginBottom: '1rem' }}>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>
            Heating:&nbsp;
          </span>
          Connect the soldering iron to a power source and wait for the
          necessary time for it to reach the appropriate temperature for the
          type of work you will be doing. The ideal operating temperature varies
          depending on the type of solder and materials involved. Typically,
          soldering irons with solder have temperatures between 350°C and 400°C.
        </Trans>
      </p>
      <p style={{ marginBottom: '1rem' }}>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>
            Adding solder:&nbsp;
          </span>
          Before adding solder, make sure that the surface to be soldered is
          clean and free from residue. Apply the tip of the heated soldering
          iron to the junction between the materials to be joined. Then, gently
          touch the solder wire or rod to the junction and let it melt and
          spread evenly.
        </Trans>
      </p>
      <p style={{ marginBottom: '1rem' }}>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>
            Soldering:&nbsp;
          </span>
          Once the solder has melted and covered the joint, remove the soldering
          iron and allow the solder to cool and solidify. Avoid sudden movements
          or pulls during the cooling process to ensure that the solder sets
          firmly and securely.
        </Trans>
      </p>
      <p style={{ marginBottom: '1rem' }}>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>
            Final Cleaning:&nbsp;
          </span>
          After completing the soldering, clean the soldering iron tip again to
          remove any excess solder or residues.
        </Trans>
      </p>
      <h2>{t('Screwdriver or Philips')}</h2>
      <p>
        {t(
          "To use a screwdriver, simply insert the tool's tip into the screw's groove and apply torque by turning the screwdriver clockwise to tighten and counterclockwise to loosen the screw. Use the appropriate screwdriver for the size of the screw to avoid damaging the screw's groove and the screwdriver itself. When applying torque, keep the screwdriver well-aligned in the groove to prevent it from slipping and causing accidents or damaging materials.",
        )}
      </p>
      <h2>{t('Observations')}</h2>
      <p>
        {t(
          "What you, as a young robotics enthusiast in the field of electrical work, must not do with these tools to preserve them and avoid any accidents? We'll tell you here:",
        )}
      </p>
      <p style={{ marginBottom: '1rem' }}>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>
            Universal Pliers:&nbsp;
          </span>
          Never use the pliers as a hammer. They can crack or even break. Never
          use pliers on nuts and bolts. A wrench does the job better and reduces
          the risk of damaging their edges.
        </Trans>
      </p>
      <p style={{ marginBottom: '1rem' }}>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>
            Electrical Tape:&nbsp;
          </span>
          Never tear the tape with your hands, as the end will lose its
          adhesion, leaving not only an unpleasant appearance but also poorly
          done insulation. Do not cut the tape with your teeth.
        </Trans>
      </p>
      <p style={{ marginBottom: '1rem' }}>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>
            Multimeter:&nbsp;
          </span>
          If you are not sure that you are measuring correctly, ask a mentor in
          the field for help. Because it is an electronic device, it can be
          quite fragile, so always be careful not to drop it or subject it to
          impacts that could damage it.
        </Trans>
      </p>
      <p style={{ marginBottom: '1rem' }}>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>
            Soldering Iron:&nbsp;
          </span>
          Do not leave the iron heating on surfaces that could be damaged. Do
          not touch the iron&apos;s tip with your hand, as the tip is heated to
          very high temperatures and can cause severe burns. Do not leave the
          soldering iron on the robot. Do not leave the soldering iron plugged
          in after you finish using it (Wait for it to return to room
          temperature and store it in its proper place).
        </Trans>
      </p>
    </ClassContainer>
  );
};

export default ToolHandling;
