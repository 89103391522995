interface Case {
  name: string;
  text: string[];
  link: string;
}

const Cases: Case[] = [
  {
    name: 'EYES - NEUTRAL',
    text: [`when button (center v) pressed\ndisplay [Eyes/Neutral v]::looks`],
    link: 'https://makecode.mindstorms.com/---run?id=_4sCfbD9UFDsC',
  },
  {
    name: 'EYES - ANGRY',
    text: [`when button (center v) pressed\ndisplay [Eyes/Angry v]::looks`],
    link: 'https://makecode.mindstorms.com/---run?id=_i9c4Ykcm45iz',
  },
  {
    name: 'BLANK',
    text: [``, `when button (center v) pressed`],
    link: 'https://makecode.mindstorms.com/---run?id=_Rm4Afx74q0L8',
  },
];

export default Cases;
