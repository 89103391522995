import React, { useCallback, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { FiKey } from 'react-icons/fi';

import { HiOutlineLogin, HiOutlineMail, HiOutlinePuzzle } from 'react-icons/hi';
import { BiBot } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import api from '../../services/api';

import Input from '../../components/Input';
import Button from '../../components/Button';

import {
  Screen,
  Container,
  Content,
  SignInContainer,
  ContainerRight,
  Logo,
} from './styles';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';

import { ReactComponent as SignUpGroup } from '../../assets/Illustrations/SignUp.svg';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const history = useHistory();

  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required(t('Please, provide a name')),
          email: Yup.string()
            .required(t('Please, provide an email'))
            .email(t('Please, provide a valid email')),
          password: Yup.string().min(
            6,
            t('Your password must have at least 6 characters'),
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/users', data);

        history.push('/');

        addToast({
          type: 'success',
          label: t('Account created!'),
          description: t('You can already log in!'),
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          label: t('Error during Sign up'),
          description: t(
            'An error occured while creating your account, please try again',
          ),
        });
      }
    },
    [addToast, history, t],
  );

  return (
    <Screen>
      <Container>
        <Content>
          <Logo>
            <div style={{ display: 'flex' }}>
              <HiOutlinePuzzle size={32} />
            </div>
            <p>LearnFirst</p>
          </Logo>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>{t('Create an account')}</h1>
            <p>{t('We are glad you are joining us!')}</p>

            <Input name="name" placeholder={t('Name')} icon={BiBot} />
            <Input name="email" placeholder="E-mail" icon={HiOutlineMail} />

            <Input
              name="password"
              placeholder={t('Password')}
              icon={FiKey}
              endIconOn={RiEyeLine}
              endIconOff={RiEyeOffLine}
            />

            <Button type="submit">{t("Let's start your journey!")}</Button>
          </Form>

          <SignInContainer>
            <p>{t('Already have an account?')}</p>

            <Link to="/">
              {t('Sign in')}
              <HiOutlineLogin size={18} />
            </Link>
          </SignInContainer>
        </Content>
        <ContainerRight>
          <SignUpGroup />
        </ContainerRight>
      </Container>
    </Screen>
  );
};

export default SignUp;
