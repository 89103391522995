import React, { useCallback, useMemo, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';

import { FiChevronUp, FiChevronDown } from 'react-icons/fi';

import { useTranslation } from 'react-i18next';
import { IconBaseProps } from 'react-icons';
import { Container, MenuNav, Content } from './styles';
import api from '../../services/api';
import { useAuthentication } from '../../hooks/authentication';

interface LevelProgress {
  id: string;
  completed: boolean;
  level: Level;
}

interface Assignment {
  id: string;
  title: string;
  module_level_id: string;
  level?: Level;
  order: number;
  type?: string;
}

interface Class {
  id: string;
  title: string;
  module_level_id: string;
  level?: Level;
  order: number;
  type?: string;
}

interface Level {
  id: string;
  name: string;
  area_module_id: string;
  area_name: string;
  color: string;
  icon: React.ComponentType<IconBaseProps>;
  progress?: number;
  assignments: Assignment[];
  classes: Class[];
}

interface DropdownMenuProps {
  level: Level;
  areaName: string;
  courseName: string;
}

interface MappedContent {
  type: string;
  id: string;
  title: string;
  module_level_id: string;
  level?: Level | undefined;
  order: number;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  level,
  areaName,
  courseName,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { user } = useAuthentication();
  const history = useHistory();
  const { t } = useTranslation();

  const getRoute = useCallback((route: string) => {
    const newRoute = route.replaceAll(' ', '-');
    return newRoute;
  }, []);

  const formatLevelName = useMemo(() => {
    return level.name.replaceAll(' ', '-');
  }, [level.name]);

  const formatName = useCallback((name: string) => {
    return name.replaceAll(' ', '-');
  }, []);

  const mappedContents = useMemo(() => {
    const mappedAssignments = level.assignments.map(ass => {
      return { ...ass, type: 'assignment' };
    });

    const mappedClasses = level.classes.map(cl => {
      return { ...cl, type: 'class' };
    });

    const contents = mappedAssignments.concat(mappedClasses);

    contents.sort((a, b) => a.order - b.order);

    return contents;
  }, [level.assignments, level.classes]);

  const getType = useCallback((type: string) => {
    return type === 'assignment' ? 'assignment' : 'content';
  }, []);

  const handleMenuClick = useCallback(() => {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  }, [isOpen]);

  const createContentProgress = useCallback(
    async (content: MappedContent) => {
      const dbContentProgress: LevelProgress[] = await (
        await api.get(`/learn/user-content-progress/${user.id}/${content.id}`)
      ).data;

      if (dbContentProgress) {
        history.push(
          `/course/${content.type}/${formatName(courseName)}/${formatName(
            areaName,
          )}/${formatLevelName}/${getRoute(content.title)}`,
        );
      } else {
        const dbLevelProgress: LevelProgress = await (
          await api.get(`/learn/user-level-progress/${user.id}/${level.id}`)
        ).data;

        if (content.type === 'assignment') {
          await api.post('/learn/assignment-progress', {
            assignment_id: content.id,
            level_progress_id: dbLevelProgress.id,
            user_id: user.id,
          });

          history.push(
            `/course/${content.type}/${formatName(courseName)}/${formatName(
              areaName,
            )}/${formatLevelName}/${getRoute(content.title)}`,
          );
        }

        if (content.type === 'class') {
          await api.post('/learn/class-progress', {
            class_id: content.id,
            level_progress_id: dbLevelProgress.id,
            user_id: user.id,
          });

          history.push(
            `/course/${content.type}/${formatName(courseName)}/${formatName(
              areaName,
            )}/${formatLevelName}/${getRoute(content.title)}`,
          );
        }
      }
    },
    [
      areaName,
      courseName,
      formatLevelName,
      formatName,
      getRoute,
      history,
      level.id,
      user.id,
    ],
  );

  return (
    <Container>
      <MenuNav onClick={handleMenuClick} isOpen={isOpen}>
        <p>{t('Content')}</p>
        {isOpen ? <FiChevronUp size={24} /> : <FiChevronDown size={24} />}
      </MenuNav>
      {isOpen && (
        <Content>
          {mappedContents.length > 0 ? (
            mappedContents.map(content => (
              <button
                key={content.id}
                type="button"
                onClick={() => createContentProgress(content)}
              >
                {t(content.title)}
              </button>
            ))
          ) : (
            <p>{t('No classes available')}</p>
          )}
        </Content>
      )}
    </Container>
  );
};

export default DropdownMenu;
