/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Controls, Player } from '@lottiefiles/react-lottie-player';

import { RiOrganizationChart } from 'react-icons/ri';
import { ClassContainer } from './styles';
import CardsModal from '../../../../../../components/CardsModal';
import { useToast } from '../../../../../../hooks/toast';

import Cards from './dataSound';

const Sound: React.FC = () => {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [showNextButton, setShowNextButton] = useState(false);

  useEffect(() => {
    if (showNextButton === true) {
      addToast({
        type: 'success',
        label: t("Now we're talking!"),
        description: t("Click on the 'next' button to continue!"),
      });
    }
  }, [addToast, showNextButton, t]);

  return (
    <ClassContainer showNextButton={showNextButton}>
      <h1 style={{ marginBottom: '0.5rem' }}>{t('Sound')}</h1>
      {/* <h2>{t('Basic Programming:')}</h2> */}
      <Player
        autoplay
        loop
        src="https://learnfirst-bucket.s3.us-east-2.amazonaws.com/speakers.json"
        style={{ height: '250px', width: '250px', marginBottom: '1rem' }}
      >
        <Controls visible={false} />
      </Player>
      <p>
        {t(
          "The 'Sound' block category is responsible for controlling the sounds produced by the robot, whether it's for emitting audio signals like alerts and notifications, or for playing music or sound effects.",
        )}
      </p>
      <p>
        {t(
          'These blocks allow developers to program the robot to produce sounds in response to specific events, such as the activation of a sensor or as a form of feedback for the user.',
        )}
      </p>

      <p className="modalStartText">{t('Click the button below to start:')}</p>
      <CardsModal
        Icon={RiOrganizationChart}
        bgColor="#d7607e"
        propBorderColor="#f296ad"
        setShowNextButton={setShowNextButton}
        invert
        cards={Cards}
      />
    </ClassContainer>
  );
};

export default Sound;
