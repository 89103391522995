import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { BgImage, ClassContainer } from './styles';

const WireIdentification: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Wire Identification')}</h1>
      <p>{t('When it comes to cable sizing, we have two types to analyze:')}</p>
      <p>
        {t(
          'First, to check the cable diameter (which is often described on the cable itself in mm2), you should refer to an AWG table.',
        )}
      </p>
      <p>
        {t(
          'After selecting the cable with the correct diameter for use in the electrical components, you should measure its length. To do this, we "simulate" the distance from one component to another, taking care not to make it too long to avoid interfering with other robot systems, such as pneumatic tubes, which will also be dimensioned in the same way, and to maintain a pleasing aesthetic. It should also not be too short, as it could strain the component inputs and risk coming loose during a match or causing difficulties during maintenance.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/diagrams/label-diagram.png"
          alt="label-diagram"
        />
      </BgImage>
    </ClassContainer>
  );
};

export default WireIdentification;
