/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-duplicates */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FaArrowRight, FaRobot, FaUserAstronaut } from 'react-icons/fa';
import { HiOutlineTranslate, HiPencil } from 'react-icons/hi';
import { IoIosArrowForward } from 'react-icons/io';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconBaseProps } from 'react-icons';

import { useMediaQuery } from 'react-responsive';
import {
  Screen,
  Container,
  Box,
  Class,
  ClassHeader,
  ClassIcon,
  ClassName,
  Classes,
  Details,
  Language,
  Level,
  LevelCat,
  Levels,
  LngList,
  Me,
  Name,
  Profile,
  ShimmerLevel,
  ShimmerClass,
  NewAvtButton,
} from './styles';
import CircularProgressBar from '../../components/CircularProgressBar';
import Notification from '../../components/Notifications';
import { useAuthentication } from '../../hooks/authentication';

import api from '../../services/api';

import getCourseProps from '../../utils/getCourseProps';
import Skeleton from '../../components/Skeleton';

interface Course {
  id: string;
  name: string;
}

interface Assignment {
  id: string;
  title: string;
  module_level_id: string;
  level?: Level;
  type?: string;
}

interface Class {
  id: string;
  title: string;
  module_level_id: string;
  level?: Level;
  type?: string;
}

interface AssignmentProgress {
  id: string;
  completed: boolean;
}

interface Level {
  id: string;
  name: string;
  area_module_id: string;
  area_name: string;
  course_name?: string;
  color: string;
  icon: React.ComponentType<IconBaseProps>;
  progress?: number;
  assignments: Assignment[];
  classes: Class[];
}

interface LevelProgress {
  completed: boolean;
  module_level_id: string;
  module_level: Level;
  class_progresses: ClassProgress[];
  assignment_progresses: AssignmentProgress[];
}

interface ClassProgress {
  id: string;
  completed: boolean;
}

interface Module {
  id: string;
  area_id: string;
  course_id: string;
  color?: string;
  icon?: React.ComponentType<IconBaseProps>;
  area: {
    name: string;
  };
}

const MobileProfile: React.FC = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const [levels, setLevels] = useState<Level[]>([] as Level[]);
  const [content, setContent] = useState<Assignment | Class>();
  const [lngIsOpen, setLngIsOpen] = useState(false);
  const [profileFetched, setProfileFetched] = useState(false);

  const { t, i18n } = useTranslation();
  const history = useHistory();

  const locales = ['pt-BR', 'en-US'];

  const { user } = useAuthentication();

  useEffect(() => {
    async function loadLevels(): Promise<void> {
      const dbLevels: Level[] = await (
        await api.get(`/courses/last-levels/${user.id}`)
      ).data;

      const propsLevels = await Promise.all(
        dbLevels.map(dbLevel => getCourseProps(dbLevel)),
      );

      const filteredLevels = propsLevels.filter(
        (mappedLevel): mappedLevel is Level => mappedLevel !== undefined,
      );

      setLevels(filteredLevels);

      const dbContent: Assignment | Class = await (
        await api.get(`/courses/last-activity/${user.id}`)
      ).data;

      if (dbContent) {
        const findLevel = filteredLevels.find(
          lv => lv.id === dbContent.module_level_id,
        );

        if (findLevel) {
          dbContent.level = await getCourseProps(findLevel);
        }

        setContent(dbContent);
      }

      setProfileFetched(true);
    }

    loadLevels();
  }, [user.id]);

  const handleLanguageChange = useCallback(
    (language: string) => {
      i18n.changeLanguage(language);
    },
    [i18n],
  );

  const formatLevelName = useCallback((name: string) => {
    return name.replaceAll(' ', '-');
  }, []);

  const getRoute = useCallback((route: string) => {
    return route.replaceAll(' ', '-');
  }, []);

  const getFirstName = useMemo(() => {
    const splitedName = user.name.split(' ');

    return splitedName[0];
  }, [user.name]);

  const getType = useCallback((type: string | undefined) => {
    return type === 'assignment' ? 'assignment' : 'class';
  }, []);

  return (
    <Screen>
      <Container>
        {isTabletOrMobile ? (
          <Profile>
            <Me>
              <Notification />
              <Details>
                <div>
                  {user.avatar_url ? (
                    <img src={user.avatar_url} alt="" />
                  ) : (
                    <>
                      <FaUserAstronaut size={100} />
                      <NewAvtButton onClick={() => history.push('/settings')}>
                        <HiPencil size={20} />
                      </NewAvtButton>
                    </>
                  )}
                </div>
                <p>{getFirstName}</p>
              </Details>
              <Language onClick={() => setLngIsOpen(prevState => !prevState)}>
                <HiOutlineTranslate size={24} />
              </Language>
              {lngIsOpen && (
                <LngList>
                  {locales.map(locale => (
                    <p
                      key={locale}
                      onClick={() => handleLanguageChange(locale)}
                    >
                      {locale}
                    </p>
                  ))}
                </LngList>
              )}
            </Me>
            <Levels>
              <h4>{t('Last Levels')}</h4>
              {profileFetched &&
                levels.length > 0 &&
                levels.map(level => (
                  <Link
                    key={level.id}
                    to={`/courses/${level.course_name}/${
                      level.area_name
                    }/${formatLevelName(level.name)}`}
                  >
                    <Level key={level.id}>
                      <LevelCat>
                        <level.icon size={25} />
                      </LevelCat>
                      <Name>
                        <p>
                          {level.name.length > 20
                            ? `${t(level.name).substring(0, 20)}...`
                            : t(level.name)}
                        </p>
                      </Name>
                      <CircularProgressBar
                        inicialProgress={
                          level.progress !== undefined ? level.progress : 0
                        }
                        size={window.innerWidth >= 1501 ? 50 : 45}
                        strokeWidth={5}
                        circleBgStroke="rgba(204, 204, 204, 0.3)"
                        circleProgressStroke="#1d62d1"
                      />
                    </Level>
                  </Link>
                ))}
              {profileFetched && levels.length <= 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <p style={{ marginBottom: '1rem' }}>
                    {t("Here you'll see your last visited levels")}
                  </p>

                  <Link to="/courses" type="button">
                    <p>{t('Browse')}</p>
                    <FaArrowRight size={12} />
                  </Link>
                </div>
              )}
              {!profileFetched && (
                <ShimmerLevel>
                  <Skeleton className="cat-skeleton" />
                  <Skeleton className="row-skeleton" />
                  <Skeleton className="progress-skeleton" />
                </ShimmerLevel>
              )}
            </Levels>
            <Classes>
              <ClassHeader>
                <h4>{t('Last Activity')}</h4>
              </ClassHeader>
              <Box>
                {profileFetched && content && (
                  <>
                    {content.level ? (
                      <Link
                        key={content.id}
                        to={`/course/${getType(content.type)}/${
                          content.level.course_name
                            ? formatLevelName(content.level.course_name)
                            : 'course'
                        }/${content.level.area_name}/${formatLevelName(
                          content.level.name,
                        )}/${getRoute(content.title)}`}
                      >
                        <Class>
                          <ClassIcon>
                            <content.level.icon size={24} />
                          </ClassIcon>
                          <ClassName>
                            <p>{t(content.title)}</p>
                            <p>{t(content.level.area_name)}</p>
                          </ClassName>
                          <IoIosArrowForward size={24} />
                        </Class>
                      </Link>
                    ) : (
                      <div />
                    )}
                  </>
                )}
                {profileFetched && !content && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <p style={{ marginBottom: '1rem' }}>
                      {t("Here you'll see your last visited activities")}
                    </p>

                    <Link to="/courses" type="button">
                      <p>{t('Browse')}</p>
                      <FaArrowRight size={12} />
                    </Link>
                  </div>
                )}
                {!profileFetched && (
                  <ShimmerClass>
                    <Skeleton className="cat-skeleton" />
                    <Skeleton className="row-skeleton" />
                  </ShimmerClass>
                )}
              </Box>
            </Classes>
          </Profile>
        ) : (
          <h1>Desculpe, parece que não tem nada por aqui</h1>
        )}
      </Container>
    </Screen>
  );
};

export default MobileProfile;
