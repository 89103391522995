import styled, { css } from 'styled-components';

import { shade } from 'polished';
import Card from '../../components/Card';

interface WeekProps {
  activity: number;
}

export const Screen = styled.div`
  position: relative;
  display: grid;
  grid-template: 1fr / 4fr 1fr;

  width: 100%;
  max-width: 100vw;

  @media (max-width: 1200px) {
    grid-template: 1fr / 1fr;
    overflow-y: scroll;
  }

  @media (max-width: 800px) {
  }
`;

export const Main = styled.div`
  grid-area: 1 / 1 / 2 / 2;

  height: 100%;
  padding: 3rem 4rem;

  background: #fff;

  display: grid;
  row-gap: 3rem;
  column-gap: 2rem;
  max-width: 100vw;
  grid-template: 5% 25% auto / 45% auto;

  height: 100%;

  & > div:first-child {
    grid-area: 1 / 1 / 2 / 3;
  }

  /* @media only screen and (max-width: 1500px) {
    row-gap: 1.5rem;
    column-gap: 1.5rem;
    grid-template: 1fr 2fr 6fr / 40% 1fr;
  }
  @media only screen and (max-width: 1500px) {
    padding: 2.5rem 3rem;
  } */

  @media (max-width: 1600px) {
    padding: 2rem 3rem;
    row-gap: 2rem;
  }

  @media (max-width: 1400px) {
    padding: 2rem;
    row-gap: 1.5rem;
  }

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 800px) {
    padding: 2rem 1rem;

    & > div:first-child {
      grid-area: 1 / 1 / 2 / 2;
    }
  }
`;

export const Layout = styled.div``;

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: #051227;
    font-size: 1.5rem;
    font-weight: 500;
  }

  div {
    display: flex;
    align-items: center;
    color: #1d62d1;
    background: #e8effa;
    border-radius: 2rem;
    font-weight: 500;
    padding: 0.5rem 1rem;

    svg {
      margin-right: 0.5rem;
    }
  }

  @media only screen and (max-width: 1500px) {
    p {
      font-size: 1.2rem;
    }

    div {
      font-size: 0.85rem;
    }
  }
`;

interface CustomCardProps {
  textColor?: string;
}

export const WelcomeCard = styled(Card)<CustomCardProps>`
  ${props =>
    props.textColor &&
    css`
      color: ${props.textColor};
    `}

  grid-area: 2 / 1 / 3 / 3;
  display: grid;
  grid-template: 1fr / 35% 65%;
  padding: 0;

  width: 100% !important;
  border-radius: 2rem;

  @media only screen and (max-width: 1500px) {
    padding: 0 3rem;
  }

  @media (max-width: 800px) {
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
  }
`;

export const CardImage = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 1rem 0;
  background-origin: content-box;

  @media only screen and (max-width: 1600px) {
    padding: 0.5rem 0;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: 1 / 2 / 2 / 3;
  padding: 1.5rem 0;

  h1 {
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 1.75rem;
  }

  p {
    color: #f7fcf8;
  }

  @media only screen and (max-width: 1500px) {
    padding: 0.5rem 0;

    h1 {
      font-size: 1.5rem;
    }

    p {
      font-size: 0.85rem;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    padding: 0;
    /* margin-top: 1.5rem; */
  }
`;

export const WeeklyCard = styled(Card)<CustomCardProps>`
  ${props =>
    props.textColor &&
    css`
      color: ${props.textColor};
    `}

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  grid-area: 3 / 2 / 4 / 3;

  width: 100% !important;
  padding: 3rem;
  border: 2px solid #d2e0f6;
  border-radius: 2rem;

  background: transparent;

  @media only screen and (max-width: 1700px) {
    padding: 1.5rem;
  }

  @media (max-width: 800px) {
    grid-area: 3 / 1 / 4 / 2;
  }
`;

export const WeekHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-bottom: 1rem;

    color: #1d62d1;
  }

  h3 {
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: 1500px) {
    h2 {
      font-size: 1.2rem;
    }

    h3 {
      font-size: 1rem;
    }
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 100%;
  max-height: 100%;
`;

export const ChartContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  position: relative;
`;

export const Bars = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;

  margin-bottom: 1rem;
`;

export const LastWeek = styled.div<WeekProps>`
  width: 20px;
  border-radius: 0.25rem;
  margin-right: 5px;

  background: #f6ae2d;

  height: ${props => (props.activity ? `${props.activity * 5}%` : '10px')};

  max-height: 80%;

  @media only screen and (max-width: 1500px) {
    width: 10px;
    border-radius: 0.25rem;
  }

  @media (max-width: 1200px) {
    height: ${props => (props.activity ? `${props.activity * 15}px` : '10px')};
  }
`;

export const ThisWeek = styled.div<WeekProps>`
  width: 20px;
  border-radius: 0.25rem;

  background: #1d62d1;

  height: ${props => (props.activity ? `${props.activity * 5}%` : '10px')};

  max-height: 80%;

  @media only screen and (max-width: 1500px) {
    width: 10px;
    border-radius: 0.25rem;
  }

  @media (max-width: 1200px) {
    height: ${props => (props.activity ? `${props.activity * 15}px` : '10px')};
  }
`;

export const AwardsCard = styled(Card)<CustomCardProps>`
  ${props =>
    props.textColor &&
    css`
      color: ${props.textColor};
    `}

  display: flex;
  align-items: flex-start;
  align-items: center;
  grid-area: 3 / 1 / 4 / 2;

  width: 100% !important;
  padding: 3rem;
  border-radius: 2rem;

  background: #d2e0f6;
  position: relative;

  & > h3 {
    margin-bottom: 3rem;

    color: #888c99;

    font-weight: 400;
  }

  @media (max-width: 1700px) {
    padding: 1.5rem 2rem;
  }

  @media only screen and (max-width: 1500px) {
    padding: 1.5rem 2rem;

    & > h1 {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 800px) {
    grid-area: 4 / 1 / 5 / 2;
  }
`;

export const AwardsHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  & > h1 {
    font-size: 1.5rem;

    color: #1d62d1;
  }

  a {
    display: flex;
    align-items: center;
    background: transparent;
    border: 2px solid #1d62d1;
    border-radius: 1rem;
    text-decoration: none;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    padding: 0.25rem 1rem;

    color: #1d62d1;

    &:hover {
      color: #fff;
      background: #1d62d1;
    }

    & > svg {
      margin: 0;
    }
  }

  @media only screen and (max-width: 1500px) {
    & > h1 {
      font-size: 1.2rem;
      margin-right: 0.5rem;
    }

    a {
      padding: 0.1rem 0.5rem;
    }
  }
`;

export const Awards = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 3rem;

  @media only screen and (max-width: 1500px) {
    margin-top: 1.5rem;
    justify-content: space-around;
  }
`;

export const Award = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  & + & {
    margin-top: 2rem;
  }

  @media only screen and (max-width: 1500px) {
    align-items: flex-start;

    & + & {
      margin-top: 0.25rem;
    }
  }
`;

export const ShimmerAward = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  @media only screen and (max-width: 1500px) {
    align-items: flex-start;
  }

  .icon-skeleton {
    width: 70px;
    min-width: 70px;
    height: 70px;
    border-radius: 1.5rem;
    margin-right: 1rem;

    @media only screen and (max-width: 1500px) {
      width: 40px;
      height: 40px;
      border-radius: 1rem;
    }
  }

  div {
    width: 100%;

    .row-skeleton {
      display: flex;
      height: 16px;
      width: 50%;

      border-radius: 0.5rem;
    }

    .row-skeleton + .row-skeleton {
      width: 100%;
      margin-top: 0.5rem;
    }
  }
`;

export const AchievementIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 1.5rem;
  background: #0c2754;
  margin-right: 1rem;

  img {
    width: 60px;
    height: auto;

    -webkit-filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
  }

  @media only screen and (max-width: 1500px) {
    width: 40px;
    height: 40px;
    border-radius: 1rem;

    img {
      width: 40px;
      height: auto;
    }
  }
`;

export const AwardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 1;

  h3 {
    margin-bottom: 0.3rem;
    font-weight: 600;
  }

  h4 {
    margin-bottom: 0.5rem;

    font-size: 0.9rem;
    font-weight: normal;
    color: #4a81da;
  }

  @media only screen and (max-width: 1500px) {
    h3 {
      font-size: 1rem;
    }

    h4 {
      font-size: 0.85rem;
    }
  }
`;

export const Bar = styled.div`
  display: flex;

  width: 100%;
  height: 15px;
  border-radius: 16px;
  margin-right: 0.5rem;

  background: #6f738030;

  div {
    height: 15px;
    border-radius: 16px;

    background: #58a4b0;
  }
`;

export const Profile = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* width: 100%; */
  /* height: 100%; */
  max-height: 100%;
  padding: 3rem;
  border-radius: 2rem;
  /* border-left: 2px solid #e3e3e3; */

  background: #f6f7ff;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  margin: 1rem 0;
  margin-right: 1rem;

  @media only screen and (max-width: 1600px) {
    padding: 2rem 1.5rem;
  }

  @media only screen and (max-width: 1400px) {
    padding: 1.5rem;
    justify-content: space-between;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`;

export const Me = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  width: 100%;
  margin-bottom: 4rem;

  @media only screen and (max-width: 1500px) {
    margin-bottom: 2rem;
  }
`;

export const Language = styled.div`
  display: flex;
  align-items: flex-start;
  font-weight: bold;
  color: #051227;
  position: relative;
  cursor: pointer;
  z-index: 9999;
`;

export const LngList = styled.div`
  position: absolute;
  right: calc(0% + 1px);
  top: calc(20% + 5px);
  background: #fff;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;

  padding: 0.75rem 0.5rem;

  p {
    border-radius: 0.5rem;
    transition: 0.3s ease-in-out;
    padding: 0.25rem 1rem;
    color: #051227;
    cursor: pointer;
    font-weight: 500;
  }

  p + p {
    margin-top: 0.25rem;
  }

  p:hover {
    background: #e5e8ee;
  }
`;

export const Details = styled.div`
  text-align: center;

  margin: 0 0.5rem;

  & > div {
    display: inline-flex;
    position: relative;

    /* padding: 1rem; */
    outline: 5px solid #1d62d1;
    outline-offset: 0.5rem;
    border-radius: 50%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: center;

    & > svg {
      color: #6f7380;
      padding: 1rem;
    }

    img {
      height: 80px;
      width: 80px;
      border-radius: 50%;
    }

    @media only screen and (max-width: 1500px) {
      outline-offset: 0.25rem;

      img {
        height: 60px !important;
        width: 60px !important;
      }
    }
  }

  p {
    font-size: 1.1rem;
    font-weight: 500;
    color: #051227;
    margin-top: 1rem;

    @media only screen and (max-width: 1500px) {
      margin-top: 0.5rem;
    }
  }

  @media only screen and (max-width: 1500px) {
    p {
      font-size: 1rem;
    }
  }
`;

export const NewAvtButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #f6ae2d;
  color: #fff;
  padding: 0.25rem;
  right: 0;
  bottom: 0;

  transition: 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${shade(0.3, '#f6ae2d')};
  }
`;

export const Levels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  margin-bottom: 3.5rem;

  a {
    text-decoration: none;
  }

  > a {
    text-decoration: none;
    width: 100%;
  }

  & > h4 {
    padding-bottom: 1rem;
    /* padding-left: 0.5rem; */
    color: #051227;
    font-weight: 600;
  }

  * {
    color: #6f7380;
  }

  div {
    a {
      display: inline-flex;
      align-items: center;

      border-radius: 2rem;
      background: blue;
      padding: 0.25rem 1rem;

      * {
        color: #fff !important;
      }

      p {
        margin-right: 1rem;
        font-weight: 500;
      }
    }
  }

  @media only screen and (max-width: 1500px) {
    margin-bottom: 1rem;

    & > h4 {
      font-size: 0.9rem;
      padding-bottom: 0.75rem;
    }
  }
`;

export const Level = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  width: 100%;
  padding: 0.75rem 0.25rem;
  border-radius: 1rem;

  transition: 0.2s ease-in-out;

  &:hover {
    background-color: #e8effa;
  }

  @media (max-width: 1500px) {
    padding: 0.5rem 0.25rem;
  }
`;

export const ShimmerLevel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  width: 100%;
  padding: 0.75rem 0.25rem;
  border-radius: 1rem;

  transition: 0.2s ease-in-out;

  .cat-skeleton {
    width: 48px;
    height: 48px;
    border-radius: 1rem;
  }

  .row-skeleton {
    display: flex;
    width: 40%;
    height: 16px;

    border-radius: 0.5rem;
  }

  .progress-skeleton {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
`;

export const LevelCat = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;
  border-radius: 1rem;

  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  @media only screen and (max-width: 1500px) {
    font-size: 0.85rem;
    width: 38px;
    height: 38px;
    border-radius: 0.75rem;
  }

  * {
    color: #1d62d1;
  }
`;

export const Name = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 40%;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1e2a3d;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
  }

  @media only screen and (max-width: 1500px) {
    p {
      font-size: 0.75rem;
    }
  }
`;

export const Classes = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  & > h4 {
    padding-bottom: 1rem;
    padding-left: 0.5rem;
  }

  @media only screen and (max-width: 1500px) {
    & > h4 {
      padding-bottom: 0.75rem;
    }
  }
`;

export const ClassHeader = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 1.5rem;

  & > h4 {
    color: #051227;
    font-weight: 600;
  }

  @media only screen and (max-width: 1500px) {
    margin-bottom: 1rem;

    & > h4 {
      font-size: 0.9rem;
    }
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;

  width: 100%;

  a {
    text-decoration: none;
  }

  div {
    a {
      display: inline-flex;
      align-items: center;

      border-radius: 2rem;
      background: blue;
      padding: 0.25rem 1rem;

      * {
        color: #fff !important;
      }

      p {
        margin-right: 1rem;
        font-weight: 500;
      }
    }
  }
`;

export const Class = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 0.75rem 0.5rem;
  border-radius: 1rem;

  background: #fff;
  color: #6f7380;

  transition: 0.2s ease-in-out;

  &:hover {
    background: #e8effa;
  }

  & > svg {
    margin-right: 0.5rem;
  }
`;

export const ShimmerClass = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 0.75rem 0.5rem;
  border-radius: 1rem;

  background: #fff;
  position: relative;

  .cat-skeleton {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  .row-skeleton {
    display: flex;
    height: 16px;

    border-radius: 0.5rem;
    flex: 1;
    margin: 0 1rem;
  }
`;

export const ClassIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 1rem;

  background-color: #d2e0f6;

  svg {
    color: #1d62d1;
  }

  @media only screen and (max-width: 1500px) {
    min-width: 38px;
    width: 38px;
    height: 38px;
  }
`;

export const ClassName = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  * {
    color: #0f3169;
  }

  p:first-child {
    font-weight: 500;
  }

  p:nth-child(2) {
    font-weight: 400;
  }

  @media only screen and (max-width: 1500px) {
    p {
      font-size: 0.9rem;
    }
  }
`;
