import React from 'react';
import { useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { BgImage, ClassContainer } from './styles';

const DetectingObstacles: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Detecting Obstacles')}</h1>
      <p>
        {t(
          'Have you seen the vacuum robots that wander around the house cleaning the floor and avoiding all the furniture?',
        )}
      </p>
      <Player
        autoplay
        loop
        src="https://learnfirst-bucket.s3.us-east-2.amazonaws.com/stop.json"
        style={{ height: '250px', width: '250px' }}
      >
        <Controls visible={false} />
      </Player>
      <p>
        {t(
          'With LEGO pieces, it is also possible to create a robot that avoids obstacles similar to those small robots. It is a very interesting project to build and program using LEGO Mindstorms. The basic idea of the robot is that it should be able to detect obstacles in front of it and navigate around them without colliding.',
        )}
      </p>
      <p>
        {t(
          'This project is a good example of how LEGO robot programming can be used to solve real-world problems, such as autonomous navigation of robots in unknown environments.',
        )}
      </p>
      <p>
        {t(
          'To build the robot, you will need to use a distance sensor, also known as an infrared sensor or ultrasonic sensor. It is capable of measuring the distance between the robot and the nearest object. To achieve good results, it is important to position the sensor at the front of the robot, facing forward.',
        )}
      </p>
      <p>
        {t(
          'To program the robot, you can check if the distance measured by the sensor is less than a predefined threshold value. If the distance is less than the threshold, the robot should turn right or left to avoid the obstacle.',
        )}
      </p>
      <p>
        {t(
          'For example, if you want the robot to move forward until it encounters an obstacle, the code could be written as follows:',
        )}
      </p>
      <BgImage>
        <img src={`https://${t('obstacles/code1.png')}`} alt="code" />
      </BgImage>
      <p style={{ marginBottom: '0.5rem' }}>
        {t(
          'Here, we first use an event block so that this piece of code is executed as soon as you press the “up” button on the robot.',
        )}
      </p>
      <p style={{ marginBottom: '0.5rem' }}>
        {t(
          'Then, the “stop other stacks” block is used to ensure that no other piece of code will be executed at the same time (you can delete this block if you want).',
        )}
      </p>
      <p style={{ marginBottom: '0.5rem' }}>
        {t(
          'Then, the “start movement” block is added, which will make the motors that move the robot propel it forward.',
        )}
      </p>
      <p style={{ marginBottom: '0.5rem' }}>
        {t(
          'The next block, the motion sensor block, specifies that it should keep moving UNTIL the distance is less than 4 centimeters, meaning that when the sensor detects an object within this distance, it will execute the next block.',
        )}
      </p>
      <p>
        {t(
          "Finally, after going through the sensor block, the code reaches the “stop moving” block so that the robot doesn't continue moving towards the obstacle.",
        )}
      </p>
      <p>
        {t(
          'Now we can start making this code a bit more “robust” by adding more instructions for the robot',
        )}
      </p>
      <BgImage>
        <img src={`https://${t('obstacles/code2.png')}`} alt="code" />
      </BgImage>
      <p style={{ marginBottom: '0.5rem' }}>
        {t(
          "Here, first we define the motor speed to ensure that the robot doesn't move too fast.",
        )}
      </p>
      <p>
        {t(
          "The logic of the sensor here is the same, the only difference is that the sensor block has been placed inside the “repeat until” control block. This allows you to add blocks within it so that the robot can do something while it doesn't encounter an object. For example, you could add a beep to the robot until it encounters an object, indicating that the path is clear. At the end of this code, a block is added for the robot to move to the right as soon as it encounters the obstacle.",
        )}
      </p>
      <p>
        {t(
          'We can even imagine a possible scenario that your robot could face:',
        )}
      </p>
      <BgImage>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/obstacle-path.png"
          alt="code"
        />
      </BgImage>
      <p>
        {t(
          'If we wanted the robot to reach the end of this course, we could write a more complex code like this:',
        )}
      </p>
      <BgImage>
        <img src={`https://${t('obstacles/code3.png')}`} alt="code" />
      </BgImage>
    </ClassContainer>
  );
};

export default DetectingObstacles;
