import styled from 'styled-components';

import RadioButtons from '../RadioButtons';

interface AnswerProps {
  correct: boolean;
  gridIndex: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > button {
    margin-top: 2rem;
    align-self: flex-start;
  }
`;

export const InputContainer = styled.div`
  border-bottom: 2px solid #dadde4;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`;

export const AnswerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Answers = styled.div`
  display: grid;
  grid-template: 1fr / auto 1fr;
`;

export const AnswerContainer = styled.div<AnswerProps>`
  grid-row-start: ${props => `${props.gridIndex + 1}`};
  grid-row-end: ${props => `${props.gridIndex + 2}`};
  grid-column-start: 2;
  grid-column-end: 3;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0.5rem 0;

  > div:first-child {
    padding: 0.25rem 0.5rem !important;
    margin: 0 1rem;
  }

  > :nth-child(2) {
    cursor: pointer;
  }
`;

export const RadioContainer = styled(RadioButtons)`
  margin-right: 1rem !important;
`;
