import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { BsTextareaT } from 'react-icons/bs';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Question from '../../components/Question';

import { Screen, Container, Header } from './styles';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';
import Input from '../../components/Input';
import Button from '../../components/Button';

interface LevelParams {
  level_id: string;
}

interface AssignmentFormData {
  assignment: string;
  instructions: string;
}

const CreateAssignment: React.FC = () => {
  const { params } = useRouteMatch<LevelParams>();

  const questionFormRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { addToast } = useToast();
  const { t } = useTranslation();

  const handleQuestionSubmit = useCallback(
    async (data: AssignmentFormData) => {
      try {
        questionFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          assignment: Yup.string().required(t('Please, provide a name')),
          instructions: Yup.string().required(
            t('Please, provide instructions'),
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        history.push(`/teach/questions/${params.level_id}/${data.assignment}`, {
          params: data.instructions,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          questionFormRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          label: t('Authentication error'),
          description: t('Check your credentials'),
        });
      }
    },
    [addToast, history, params.level_id, t],
  );

  return (
    <Screen>
      <Container>
        <Header>
          <Form ref={questionFormRef} onSubmit={handleQuestionSubmit}>
            <h2>{t('Create Task')}</h2>
            <Input
              name="assignment"
              type="text"
              placeholder={t("Task's name")}
              icon={BsTextareaT}
            />
            <Input
              name="instructions"
              type="text"
              placeholder={t('Instructions')}
              icon={BsTextareaT}
            />
            <Button type="submit">{t('Next')}</Button>
          </Form>
        </Header>
      </Container>
    </Screen>
  );
};

export default CreateAssignment;
