import styled from 'styled-components';
import { lighten } from 'polished';

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  align-items: flex-start;
  justify-content: space-between;

  width: 100vw;
  height: 100%;
  max-height: 100%;

  padding: 5rem 5rem 0;

  @media (max-width: 1224px) {
    padding: 5rem 3rem;
  }

  @media (max-width: 800px) {
    padding: 2rem 1.5rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 50%;
  height: 100%;

  @media (max-width: 1500px) {
    width: 70%;
  }

  @media (max-width: 1224px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  border-bottom: 2px solid #f0f1f5;
`;

export const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  margin-bottom: 2rem;

  h2 {
    color: #051227;
  }

  button {
    background: transparent;
    border: 2px solid #f0f1f5;
    border-radius: 1rem;
    text-decoration: none;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    padding: 0.5rem 1rem;

    color: #387bd1;

    &:hover {
      color: #fff;
      border-color: #4a8ce0;
      background: #4a8ce0;
    }
  }
`;

export const Scroll = styled.div`
  /* max-height: 260px; */
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Achievement = styled.div`
  display: grid;
  grid-template: 1fr / 15% 70% 15%;
  align-items: center;

  padding: 1rem;
  transition: 0.2s ease-in-out;

  & + div {
    border-top: 2px solid #f0f1f5;
  }

  &:last-child {
    border-bottom: 2px solid #f0f1f5;
  }

  & > time {
    grid-area: 1 / 3 / 2 / 4;

    @media (max-width: 800px) {
      grid-area: 2 / 2 / 3 / 3;

      margin: 1rem 0 0 1.5rem;
    }
  }

  @media (max-width: 800px) {
    padding: 1rem 0;
    align-items: start;

    grid-template: 1fr auto / 15% 85%;
  }
`;

export const AchievementIcon = styled.div`
  grid-area: 1 / 1 / 3 / 2;

  aspect-ratio: 1 / 1;

  position: relative;
  border-radius: 1.5rem;
  background: #e8e8e8;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  /* @media (max-width: 1500px) {
    border-radius: 1.5rem;
  } */

  @media (max-width: 1224px) {
    border-radius: 1rem;
  }
`;

export const AchievementContent = styled.div`
  grid-area: 1 / 2 / 2 / 3;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 1.5rem;

  p {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0.75rem;
  }

  time {
    font-size: 0.95rem;
    opacity: 0.8;
  }

  button {
    font-size: 0.9rem;
    font-weight: 700;
    border: 0;
    background: ${lighten(0.4, '#387bd1')};
    color: #3854c2;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    margin-top: 0.75rem;
    transition: 0.2s ease-in-out;

    &:hover {
      background: ${lighten(0.3, '#387bd1')};
    }
  }

  @media (max-width: 800px) {
    margin: 0 0 0 1.5rem;
  }
`;
