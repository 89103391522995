import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { ClassContainer } from './styles';

const WhatIsLEGO: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Wires')}</h1>
      <p>
        {t(
          "Wonderful! In the past lessons, we learned a bit about each component and its connections. Now, let's dive into the world of wires!",
        )}
      </p>
      <p>
        {t(
          "A wire is composed of a metal that conducts a lot of energy, such as copper. Outside of this metal, there's a rubber coating for insulation, preventing, for example, electric shocks.",
        )}
      </p>
      <p>
        {t(
          "If the system isn't connected to power, there's no need to fear touching the wire. It will only give you a shock if the robot is powered on and you touch the copper.",
        )}
      </p>
      <p>
        {t(
          "Here's a little table illustrating the functions of each type of wire:",
        )}
      </p>
      <table style={{ marginBottom: '2rem' }}>
        <tr>
          <th> </th>
          <th>{t('Thin Wire')}</th>
          <th>{t('Thick Wire')}</th>
        </tr>
        <tr>
          <td>{t('Red Wire (Positive)')}</td>
          <td>{t('For most robot connections')}</td>
          <td>{t('Only for motors and their controllers')}</td>
        </tr>
        <tr>
          <td>{t('Black Wire (Negative)')}</td>
          <td>{t('For most robot connections')}</td>
          <td>{t('Only for motors and their controllers')}</td>
        </tr>
        <tr>
          <td>{t('Green Wire')}</td>
          <td>{t('Only for CAN')}</td>
          <td>-</td>
        </tr>
        <tr>
          <td>{t('Yellow Wire')}</td>
          <td>{t('Only for CAN')}</td>
          <td>-</td>
        </tr>
      </table>
      <p>
        {t(
          'Note: Thin wire and thick wire are informal terms. The correct terminology refers to the wire gauge (AWG).',
        )}
      </p>
      <p
        style={{ fontWeight: 'bold', color: '#051227', marginBottom: '0.5rem' }}
      >
        {t('Why is there a difference between thin wire and thick wire?')}
      </p>
      <p>
        {t(
          "If you use a thin wire for a component that requires thick wires, the thin wire will heat up excessively and may catch fire. That's why large components like motors, which need a lot of power, require thick wires.",
        )}
      </p>
      <p
        style={{ fontWeight: 'bold', color: '#051227', marginBottom: '0.5rem' }}
      >
        {t("What's the difference between red and black wires?")}
      </p>
      <p>
        {t(
          "At some point in our history, it was standardized that red wire represents positive and black wire represents negative. In practice, they don't have any inherent difference, but these colors help prevent us from making reversed connections (positive connected to negative and vice versa). If that happens, you might see some smoke coming from your electrical component.",
        )}
      </p>
      <p
        style={{ fontWeight: 'bold', color: '#051227', marginBottom: '0.5rem' }}
      >
        {t('How to connect wires to components:')}
      </p>
      <p style={{ marginBottom: '0.5rem' }}>
        {t(
          'To connect a wire to a component, you need to open a standardized little door on the components. This door is called a Weidmuller connector.',
        )}
      </p>
      {/* <a
        href="https://www.youtube.com/watch?v=Zsnro-9_ZfQ"
        style={{ color: '#1d62d1' }}
      >
        {t('Vídeo de demonstração')}
      </a> */}
    </ClassContainer>
  );
};

export default WhatIsLEGO;
