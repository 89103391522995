import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { lighten } from 'polished';

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  width: 100vw;
  height: 100%;
  max-height: 100%;

  padding: 5rem;

  @media (max-width: 800px) {
    padding: 2rem 1.5rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  margin-bottom: 2rem;

  h2 {
    color: #051227;
  }

  button {
    background: transparent;
    border: 2px solid #f0f1f5;
    border-radius: 1rem;
    text-decoration: none;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    padding: 0.5rem 1rem;

    color: #387bd1;

    &:hover {
      color: #fff;
      border-color: #4a8ce0;
      background: #4a8ce0;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;

  a {
    margin-top: 1rem;
    margin-right: 2rem;
  }

  @media (max-width: 1224px) {
    a {
      margin-right: 1.5rem;
    }
  }
`;

export const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  padding: 1rem;
  transition: 0.25s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

export const ImageIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eef0f3;
  border-radius: 1rem;
  height: 100px;
  width: 100px;

  img {
    width: 80%;
    height: auto;
  }
`;
