import styled from 'styled-components';
import Class from '../../../../../Class';

export const ClassContainer = styled(Class)`
  & > h1,
  h2,
  & > p {
    margin-bottom: 2rem;
  }

  h1:first-child {
    margin-bottom: 3rem;

    color: #051227;

    font-size: 2rem;
    font-weight: 600;
  }

  h2 {
    color: #051227;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .lf-player-container {
    align-self: center;
  }

  ul {
    li {
      list-style-position: inside;
    }
  }
`;

export const BgImage = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;

  img {
    position: relative;
    width: 100%;
    height: auto;

    @media (max-width: 1200px) {
      width: 90%;
    }
  }
`;
