/* eslint-disable import/no-duplicates */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { format as formatDate, isDate } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';

import PTBR from './locales/pt/pt-br.json';
import ENUS from './locales/en/en-us.json';

const locales: { [key: string]: Locale } = {
  'pt-BR': ptBR,
  'en-US': enUS,
};

const resources = {
  'pt-BR': PTBR,
  'en-US': ENUS,
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,

    resources,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format(value, format, lng) {
        if (!format || !lng) {
          return value;
        }

        if (isDate(value)) {
          const locale = locales[lng];
          return formatDate(value, format, { locale });
        }

        return value;
      },
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
