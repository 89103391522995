import styled from 'styled-components';
import { shade } from 'polished';

import signInBackgroundImg from '../../assets/background.svg';

export const Screen = styled.div`
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  display: grid;
  grid-template: 1fr / 1fr 1fr;

  width: 100%;
  height: 100%;

  @media (max-width: 1365px) {
    grid-template: 1fr / 1fr;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-area: 1 / 1 / 2 / 2;

  form {
    display: flex;
    flex-direction: column;

    width: 50%;

    text-align: start;

    h1 {
      margin-bottom: 2rem;
      color: #051227;
    }

    p {
      color: #888d96;
      margin-bottom: 2rem;
    }

    @media (max-width: 1365px) {
      width: 70%;
    }

    @media (max-width: 800px) {
      width: 85%;
    }
  }

  a {
    display: flex;
    align-items: center;

    margin-top: 1.2rem;
    font-weight: 500;

    color: #1d62d1;

    text-decoration: none;

    transition: 0.2s ease-in-out;

    &:hover {
      color: ${shade(0.3, '#1d62d1')};
    }

    svg {
      margin-right: 0.25rem;
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  * {
    color: #1d62d1;
  }

  p {
    font-size: 1.5rem;
    font-weight: 600;
    margin-left: 0.5rem;
  }
`;

export const SignInContainer = styled.div`
  display: flex;
  margin-top: 2rem;

  p {
    margin-right: 0.5rem;
  }

  a {
    display: flex;
    align-items: center;

    font-weight: 500;

    color: #1d62d1;

    text-decoration: none;

    transition: 0.2s ease-in-out;

    p {
      color: #6f7380;
    }

    svg {
      margin-left: 0.5rem;
    }

    &:hover {
      color: ${shade(0.3, '#1d62d1')};
    }
  }
`;

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-area: 1 / 2 / 2 / 3;

  background-color: #1d62d1;
  background-image: url(${signInBackgroundImg});
  background-size: cover;
  background-repeat: no-repeat;

  svg {
    width: 80%;
  }

  @media (max-width: 1365px) {
    display: none;
  }
`;
