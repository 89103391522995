import ScratchBlocks from 'scratchblocks-react';
import styled from 'styled-components';
import Button from '../../../../components/Button';

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;

  background: #fff;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  .sb3-extension {
    fill: #f5c400;
    stroke: #b89a00;
  }

  .sb3-extension-alt {
    fill: #d9b100;
  }

  .sb3-extension-line {
    stroke: #d9b100;
  }
`;

export const Container = styled.div`
  display: grid;
  /* grid-template: 1fr / 1fr 1fr 1fr; */
  grid-template: 1fr / 1fr 1fr 1fr;
  position: relative;
  width: 100%;

  @media (min-width: 1000px) {
    min-width: 100%;
    min-height: 100%;
  }

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
  }
`;

export const EV3Container = styled.div`
  grid-area: 1 / 1 / 2 / 2;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  height: 100%;

  iframe {
    height: 100%;
    width: 70%;
  }

  @media (max-width: 1000px) {
    position: relative;
    height: 0;
    padding-bottom: 200%;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

export const BlocksContainer = styled.div`
  margin: 2rem 1rem;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-area: 1 / 2 / 2 / 3;
  position: relative;

  background-color: #fbfbfb;
  background-size: 40px 40px;
  background-image: radial-gradient(circle, #d2e0f6 1px, rgba(0, 0, 0, 0) 1px);

  border-radius: 1rem;

  @media (max-width: 1224px) {
    padding: 2rem 1rem;
  }

  @media (max-width: 1000px) {
    grid-area: 2 / 1 / 3 / 2;

    min-height: 80vh;
  }

  @media (max-width: 800px) {
    margin: 2rem 0;
    margin-top: 0;
  }
`;

export const OptionsContainer = styled.div`
  margin: 3rem 1rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-area: 1 / 3 / 2 / 4;

  & > div + div {
    margin-top: 2rem;
  }

  @media (max-width: 1000px) {
    grid-area: 3 / 1 / 4 / 2;
  }

  @media (max-width: 800px) {
    margin: 3rem 0;
  }
`;

export const ScratchBlocksContainer = styled(ScratchBlocks)`
  svg {
    width: 100%;
  }
`;

export const ClearButton = styled(Button)`
  position: absolute;
  left: 20px;
  bottom: 20px;
`;

export const RunButton = styled(Button)`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

export const PathButton = styled(Button)`
  @media (min-width: 1000px) {
    position: absolute;
    bottom: 50px;
    right: 50px;
  }

  @media (max-width: 1000px) {
    margin-top: 0 !important;
    margin-bottom: 2rem;
  }
`;
