interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/tools/soldering-iron.png',
    title: 'Soldering Iron',
    text:
      'The soldering iron is an essential tool for making electrical connections using solder. Thanks to the intense heating at its tip, the soldering iron can reach the necessary temperature to melt solder wire. This allows for efficient and reliable bonding of electrical components.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/tools/screwdriver.png',
    title: 'Flathead and Phillips Screwdrivers',
    text:
      "The flathead screwdriver has a flat and narrow tip. This tool is inserted into the slot of a screw (flathead type) to turn it and tighten or loosen it. The Phillips screwdriver has a thin cone-shaped tip and is used to tighten or loosen Phillips head screws, which have a cross-shaped 'X' recess.",
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/tools/multimeter.png',
    title: 'Multimeter',
    text:
      'A digital multimeter has basic functions such as measuring direct current voltage, alternating current voltage, electrical resistance, and direct current.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/tools/tube.png',
    title: 'Heat-Shrink Tubing',
    text:
      'This type of tubing is used for insulation, stress relief, bundling, and any other function that requires a professional finish.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/tools/tape.png',
    title: 'Electrical Tape',
    text:
      'The use of electrical tape prevents the possibility of a direct electrical connection between two or more circuits, reducing the risk of short circuits and other electrical problems. It is applied around wires to provide insulation and prevent the flow of harmful electrical currents from other circuits.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/tools/cutpliers.png',
    title: 'Wire Cutter',
    text:
      'This tool has a side-cutting or diagonal-cutting edge, perfect for cutting thicker wires and cables with ease.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/tools/plierd.png',
    title: 'Wire Stripper',
    text:
      'This tool is ideal for removing the insulation from electrical wires and network cables, making the removal process easy, fast, and safe. Additionally, this tool also has the function of cutting wires and crimping.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/tools/bigpliers.png',
    title: 'Locking Pliers',
    text:
      'As the name suggests, it is used to grip and apply pressure to parts and surfaces, crimp terminals, and also for loosening screws and nuts.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/tools/slimpliers.png',
    title: 'Needle-Nose Pliers',
    text:
      'Commonly used in mechanical and electrical work, they are perfect for cutting, twisting, and handling cables, wires, and small components.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/frc/tools/uni-plier.png',
    title: 'Universal Pliers',
    text:
      'This tool is very popular and used in both professional and residential environments. It combines the functionalities of needle-nose pliers and wire cutters, allowing for both cutting and gripping objects.',
  },
];

export default Cards;
