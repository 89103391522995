import React from 'react';
import { useTranslation } from 'react-i18next';

import { CodeBlock } from '@atlaskit/code';

import { ClassContainer } from './styles';

const WhatIsLEGO: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('What is Programming')}</h1>
      <p>
        {t(
          'Welcome to our introductory programming class! Today, we will learn how code and algorithms come to life to perform amazing tasks.',
        )}
      </p>
      <p>
        {t(
          'In essence, programming is the act of giving instructions to a computer to perform specific tasks. Imagine programming as a language that computers understand and obediently follow. With traditional programming, you have the power to create your own customized instructions to solve a wide range of problems.',
        )}
      </p>
      <p>
        {t(
          'To create these instructions, you need a Programming Language. Just as there are many human languages, there are also various programming languages. Each of these languages has its own structures and rules, suitable for different types of tasks. Some popular examples are Python, JavaScript, Java, C++, C#, among others.',
        )}
      </p>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('Changing a Light Bulb: An Example of Programming Logic')}
      </h2>
      <p>
        {t(
          "Before we start programming, it's important to learn the logic behind creating algorithms. To do this, let's imagine you have a light bulb that needs to be replaced. We'll use programming logic to break down this process into logical steps:",
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('1. Analyze the environment')}
      </p>
      <p>
        {t(
          'Before any electrical intervention, analyze the environment. Check if you have enough space to perform the task, verify the voltage of the bulb being replaced, and ensure you have the correct type of bulb for replacement.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('2. Gather materials')}
      </p>
      <p>
        {t(
          "Now, it's time to gather the necessary materials. You'll need to get a new light bulb, a ladder to reach the burned-out bulb, and possibly a screwdriver or a suitable tool to open the lamp fixture.",
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('3. Turn Off the Power')}
      </p>
      <p>
        {t(
          'After preparing the environment, turn off the power. This prevents electrical accidents. You may need to either turn off the circuit breaker or simply flip the light switch off.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('4. Access the Bulb')}
      </p>
      <p>
        {t(
          'Climb the ladder carefully to access the burnt-out bulb. Make sure that the ladder is stable.',
        )}
      </p>
      <p
        style={{
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('5. Remove the burnt-out bulb')}
      </p>
      <p
        style={{
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('6. Install the new bulb')}
      </p>
      <p
        style={{
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('7. Restore the power')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('8. Test the light bulb')}
      </p>
      <p>
        {t(
          'Finally, test the new lamp to ensure it is working correctly. Turn on the light and check if the new bulb illuminates the area properly.',
        )}
      </p>
      <p>
        {t(
          "In summary, changing a light bulb is a simple yet effective example of how programming logic is similar to solving real-world problems. It involves breaking tasks into steps, making decisions, and following a logical sequence of actions. By understanding these concepts, you'll be better equipped to tackle complex issues in computer programming.",
        )}
      </p>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('And how to code?')}
      </h2>
      <p>
        {t(
          'To get started, you\'ll need a development environment. This can be a specialized program called an Integrated Development Environment (IDE) or a simple text editor. Within the program, you can begin by displaying a simple and well-known message in the programming world: "Hello, World!"',
        )}
      </p>
      <CodeBlock language="python" text={`print("${t('Hello, world!')}")`} />
      <p>
        {t(
          'You will see that syntax, which is the way instructions are written, is crucial in programming. Small syntax errors can cause significant issues in the code.',
        )}
      </p>
    </ClassContainer>
  );
};

export default WhatIsLEGO;
