import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { CodeBlock } from '@atlaskit/code';
import { AtlaskitThemeProvider } from '@atlaskit/theme';

import { BgImage, ClassContainer } from './styles';

const WhatIsLEGO: React.FC = () => {
  const { t } = useTranslation();

  const firstCode = `a = 5\nb = 3\n\nsum = a + b  # sum is equal to 8\nsubtraction = a - b  # subtraction is equal to 2\nmultiplication = a * b  # multiplication is equal to 15\ndivision = a / b  # division is equal to 1.666...\nmodulus = a % b  # modulus is equal to 2`;

  return (
    <ClassContainer>
      <h1>{t('Operators')}</h1>
      <p>
        {t(
          'Operators are essential building blocks in programming. They allow you to perform mathematical calculations, make decisions based on conditions, and control the flow of your code. In this lesson, we will explore what operators are, how to use them, and why they are fundamental in programming.',
        )}
      </p>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('Arithmetic Operators')}
      </h2>
      <p>
        {t(
          'Everyone has already used arithmetic operators in school. In the early years of study, we learned how to do simple calculations like addition, subtraction, multiplication, and division. In algorithms, they are also straightforward and have the same symbols in all programming languages.',
        )}
      </p>
      <p>{t('They inlcude:')}</p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('Addition (+): ')}
        </span>
        {t('Used to add two values.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('Subtraction (-): ')}
        </span>
        {t('Used to subtract the right-hand value from the left-hand value.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('Multiplication (*): ')}
        </span>
        {t('Used to multiply two values.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('Division (/): ')}
        </span>
        {t('Used to divide the left-hand value by the right-hand value.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('Exponentiation (^): ')}
        </span>
        {t(
          'Performs the exponentiation operation, raising one number to the power of another number.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('Modulus (%): ')}
        </span>
        {t(
          'Returns the remainder of the division between the left-hand value and the right-hand value.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('Increment (++): ')}
        </span>
        {t('Increases the value of a variable by 1.')}
      </p>
      <p>
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('Decrement (--): ')}
        </span>
        {t('Decreases the value of a variable by 1.')}
      </p>
      <p
        style={{
          marginBottom: '1rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('Example of using arithmetic operators in Python')}
      </p>
      <CodeBlock language="python" text={t(firstCode)} shouldWrapLongLines />
      <h2 style={{ fontWeight: 'bold', color: '#051227', marginTop: '2rem' }}>
        {t('Logical Operators')}
      </h2>
      <p>
        {t(
          'Logical operators are used to perform logical evaluation operations in conditional structures (we will see more in the next lesson). They include:',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('AND logical (&&): ')}
        </span>
        {t('Returns true if both conditions are true.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('OR logical (||): ')}
        </span>
        {t('Returns true if at least one of the conditions is true.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('NOT logical (!): ')}
        </span>
        {t('Inverts the value, true becomes false, and vice versa.')}
      </p>
      <p>
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('Equality (==): ')}
        </span>
        {t('Returns true if the compared values are equal.')}
      </p>
      <p>
        {t(
          'But before applying these logical operators, we need to move on to the next lesson to learn about the ',
        )}

        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('conditional structures.')}
        </span>
      </p>
    </ClassContainer>
  );
};

export default WhatIsLEGO;
