import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { HiOutlineLightningBolt } from 'react-icons/hi';
import { ClassContainer } from './styles';
import CardsModal from '../../../../../../components/CardsModal';
import Cards from './dataTools';
import { useToast } from '../../../../../../hooks/toast';

const WhatIsLEGO: React.FC = () => {
  const { t } = useTranslation();

  const { addToast } = useToast();

  const [showNextButton, setShowNextButton] = useState(false);

  useEffect(() => {
    if (showNextButton === true) {
      addToast({
        type: 'success',
        label: t("Now we're talking!"),
        description: t("Click on the 'next' button to continue!"),
      });
    }
  }, [addToast, showNextButton, t]);

  return (
    <ClassContainer showNextButton={showNextButton}>
      <h1>{t('Tools')}</h1>
      <Player
        autoplay
        loop
        src="https://learnfirst-bucket.s3.amazonaws.com/frc/plug.json"
        style={{ height: '250px', width: '250px', margin: '1rem 0' }}
      >
        <Controls visible={false} />
      </Player>
      <p>
        {t(
          "There are hundreds of tools that can assist in the development of an electrical circuit. As a student, it's always important to treat all of them with care, as it can be difficult to acquire them again.",
        )}
      </p>
      <p className="modalStartText">{t('Click the button below to start:')}</p>
      <CardsModal
        Icon={HiOutlineLightningBolt}
        bgColor="#ffb129"
        propBorderColor="#f2d4a0"
        setShowNextButton={setShowNextButton}
        cards={Cards}
      />
    </ClassContainer>
  );
};

export default WhatIsLEGO;
