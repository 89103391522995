import React from 'react';
import { useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { BgImage, ClassContainer } from './styles';

const EV3Simulator: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Detecting Obstacles')}</h1>
      <p>
        {t(
          'Você já viu os robôs aspiradores que passeiam pela casa limpando o chão e desviando de todos os móveis?',
        )}
      </p>
      <div
        style={{
          position: 'relative',
          height: '0',
          paddingBottom: '200%',
          overflow: 'hidden',
          width: '60%',
        }}
      >
        <iframe
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
          }}
          src="https://makecode.mindstorms.com/---run?id=_U7s4pT2j8iYq"
          sandbox="allow-popups allow-forms allow-scripts allow-same-origin"
          frameBorder="0"
          title="simu"
        />
      </div>
    </ClassContainer>
  );
};

export default EV3Simulator;
