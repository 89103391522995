import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface TabPanelProps {
  active: boolean;
}

export const Tabs = styled.div`
  display: flex;

  margin-bottom: 2rem;
  border-bottom: 2px solid #e3e3e3;
  width: 85%;
  padding-bottom: 1.2rem;

  @media (max-width: 1224px) {
    width: 100%;
  }
`;

export const TabItem = styled.button<TabPanelProps>`
  transition: 0.2s ease-in-out;

  border: 0;
  border-radius: 0.75rem;
  padding: 0.5rem 1rem;

  background: transparent;

  color: #939998;

  & + & {
    margin-left: 1rem;
  }

  ${props =>
    !props.active &&
    css`
      &:hover {
        color: ${shade(0.2, '#0f3169')};
      }
    `}

  ${props =>
    props.active &&
    css`
      color: #1d62d1;
      background: #e8effa;
    `}
`;
