/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClear } from 'react-icons/ai';
import { useHistory, useLocation } from 'react-router-dom';
import { Xwrapper } from 'react-xarrows';
import Button from '../../../../components/Button';

import dataInterfaces from '../../../../data/dataInterfaces';
import { useAuthentication } from '../../../../hooks/authentication';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';

import {
  Screen,
  Container,
  WiresContainer,
  CenterContainer,
  ItemsContainer,
  RoboRIO,
  CenterPorts,
  Ports,
  Category,
  Unselect,
  Port,
} from './styles';

interface Level {
  id: string;
  name: string;
  area_module_id: string;
  area_name: string;
  color: string;
  progress?: number;
  assignments: Assignment[];
}

interface Assignment {
  id: string;
  title: string;
  instructions: string;
  module_level_id: string;
  module_level?: Level;
  questions: Question[];
  order: number;
}

interface Answer {
  id: string;
  placeholder: string;
  correct: boolean;
}

interface Question {
  id: string;
  placeholder: string;
  answers: Answer[];
}

interface Connection {
  catId: string;
  id: string;
  correct: boolean;
}

interface AssignmentProgress {
  id: string;
  completed: boolean;
}

interface InteractiveProps {
  content: Assignment;
  nextContent?: string;
}

const RoboRIOActivity: React.FC<InteractiveProps> = ({
  content,
  nextContent,
}) => {
  const [selectMode, setSelectMode] = useState(false);
  const [verifyMode, setVerifyMode] = useState(false);
  const [connections, setConnections] = useState<Connection[]>(
    [] as Connection[],
  );
  const [activeCat, setActiveCat] = useState<string>('');
  const [activePorts, setActivePorts] = useState<string[]>([]);

  const { addToast } = useToast();

  const { user } = useAuthentication();
  const history = useHistory();
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const handleSelectPort = useCallback(
    (categoryId: string) => {
      if (connections.length === 4) {
        setConnections([] as Connection[]);
        setActivePorts([]);
        setSelectMode(true);
      }

      if (!connections.some(conn => conn.catId === categoryId)) {
        setSelectMode(true);
      } else if (
        connections.some(conn => conn.catId === categoryId) &&
        connections.length < 4
      ) {
        setSelectMode(false);
      }

      setVerifyMode(false);
    },
    [connections],
  );

  const handlePortChoice = useCallback(
    (cat: any, port: any) => {
      if (connections.some(conn => conn.id === port.id)) {
        addToast({
          type: 'error',
          label: t('Port already selected'),
        });
      } else {
        setActivePorts(prevState => [...prevState, port.id]);

        const result =
          port.matches === cat.id
            ? { catId: cat.id, id: port.id, correct: true }
            : { catId: cat.id, id: port.id, correct: false };

        setConnections(prevState => [...prevState, result]);

        setSelectMode(false);
      }
    },
    [addToast, connections, t],
  );

  const handleVerifyAssignment = useCallback(() => {
    if (connections.length !== 4) {
      addToast({
        type: 'error',
        label: t('Not all ports are selected'),
        description: t('Connect all ports'),
      });

      setActivePorts([]);
      setConnections([] as Connection[]);
      setActiveCat('');
      setSelectMode(false);
    } else {
      setVerifyMode(true);
      setActiveCat('');
      if (!connections.every(conn => conn.correct)) {
        addToast({
          type: 'error',
          label: t('Not all ports are correct'),
          description: t('Try again!'),
        });

        setActivePorts([]);
      } else if (
        connections.length === 4 &&
        connections.every(conn => conn.correct)
      ) {
        addToast({
          type: 'success',
          label: t('Congratz!'),
          description: t('Click on the button to go to the next assignment!'),
        });
      }
    }
  }, [addToast, connections, t]);

  const formatLevelName = useCallback((name: string) => {
    return name.replaceAll(' ', '-');
  }, []);

  const handleNextContent = useCallback(async () => {
    const dbAssignmentProgress: AssignmentProgress = await (
      await api.get(`/learn/user-content-progress/${user.id}/${content?.id}`)
    ).data;

    if (!dbAssignmentProgress.completed) {
      await api.put('/courses/assignment/complete', {
        assignment_id: content?.id,
        user_id: user.id,
      });
    }

    const isLevelFinished = await (
      await api.get(
        `/courses/level-status/${content?.module_level_id}/${user.id}`,
      )
    ).data;

    if (isLevelFinished) {
      history.push('/finished-level');
    } else if (nextContent) {
      history.push(nextContent);
    } else {
      const courseName = pathname.split('/')[3];
      const areaName = pathname.split('/')[4];
      const levelName = pathname.split('/')[5];

      history.push(
        `/courses/${courseName}/${areaName}/${formatLevelName(levelName)}`,
      );
    }
  }, [
    content?.id,
    content?.module_level_id,
    formatLevelName,
    history,
    nextContent,
    pathname,
    user.id,
  ]);

  return (
    <Screen>
      <Xwrapper>
        <Container>
          <WiresContainer>
            <ItemsContainer>
              {dataInterfaces
                .filter(pt => pt.type === 'cable')
                .reverse()
                .map((itr, index) => (
                  <Category
                    key={itr.id}
                    active={itr.id === activeCat}
                    onClick={() => {
                      setActiveCat(itr.id);
                      handleSelectPort(itr.id);
                    }}
                  >
                    <p>{itr.name && t(itr.name)}</p>

                    {itr.icon && <itr.icon size={30} />}
                  </Category>
                ))}
              <Unselect
                onClick={() => {
                  setActiveCat('');
                  setSelectMode(false);
                  setConnections([]);
                  setActivePorts([]);
                  setVerifyMode(false);
                }}
              >
                <AiOutlineClear size={24} />
              </Unselect>
            </ItemsContainer>
          </WiresContainer>
          <CenterContainer>
            <RoboRIO>
              <Ports>
                <CenterPorts $on={selectMode}>
                  {dataInterfaces
                    .filter(itr => itr.type === 'port')
                    .map(itr => (
                      <Port
                        key={itr.id}
                        id={itr.id}
                        active={activePorts.some(id => id === itr.id)}
                        correct={connections.some(
                          conn => conn.correct && conn.id === itr.id,
                        )}
                        verifyMode={verifyMode}
                        {...(selectMode && {
                          onClick: () =>
                            handlePortChoice(
                              dataInterfaces.find(int => int.id === activeCat),
                              itr,
                            ),
                        })}
                      >
                        {itr.src && (
                          <img src={itr.src} alt="img" className="img" />
                        )}
                      </Port>
                    ))}
                </CenterPorts>
              </Ports>
              <img
                src="https://learnfirst-bucket.s3.us-east-2.amazonaws.com/ni-logo.svg"
                alt="logo"
                className="logo"
              />
            </RoboRIO>
            {connections.length === 4 &&
            connections.every(conn => conn.correct) &&
            verifyMode ? (
              <Button
                type="button"
                onClick={handleNextContent}
                style={{ marginTop: '2rem', width: 'auto', padding: '0 1rem' }}
              >
                {t('Next')}
              </Button>
            ) : (
              <Button
                type="button"
                onClick={handleVerifyAssignment}
                style={{ width: 'auto', padding: '0 1rem' }}
              >
                {t('Verify')}
              </Button>
            )}
          </CenterContainer>
        </Container>
      </Xwrapper>
    </Screen>
  );
};

export default RoboRIOActivity;
