import Roborio from '../pages/ComponentsDetail/Controllers/Roborio';
import TalonSRX from '../pages/ComponentsDetail/Controllers/TalonSRX';
import NotYet from '../pages/ComponentsDetail/Controllers/NotYet';

const Components = [
  {
    path: '/library/NI-roboRIO-2.0',
    component: Roborio,
  },
  {
    path: '/library/Talon-SRX',
    component: TalonSRX,
  },
  {
    path: '/library/*',
    component: NotYet,
  },
];

export default Components;
