import styled from 'styled-components';
import Class from '../../../../../Class';

export const ClassContainer = styled(Class)`
  & > h1,
  & > p {
    margin-bottom: 2rem;
  }

  h1:first-child {
    margin-bottom: 3rem;

    color: #051227;

    font-size: 2rem;
    font-weight: 600;
  }

  h2 {
    color: #051227;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  h3 {
    margin-bottom: 1rem;
  }

  .lf-player-container {
    align-self: center;
  }

  table {
    border-collapse: collapse;
  }

  table,
  td {
    border: 1px solid #1d62d1;
  }

  td {
    padding: 0.5rem;
  }

  a {
    color: #1d62d1;
  }

  .prismjs {
    margin-bottom: 2rem;
  }
`;

export const BgImage = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;

  img {
    position: relative;
    width: 50%;
    height: auto;

    @media (max-width: 1200px) {
      width: 70%;
    }
  }
`;
