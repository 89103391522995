import React from 'react';
import { useTranslation } from 'react-i18next';

import { CodeBlock } from '@atlaskit/code';

import { ClassContainer } from './styles';

const WhatIsLEGO: React.FC = () => {
  const { t } = useTranslation();

  const firstCode = `If (condition is true) Then\n\u0020\u0020Execute this code block\nElse\n\u0020\u0020Execute this other code block\nEnd;`;

  const firstJavaCode = `class Main {\n\u0020\u0020public static void main (String [] args) {\n\u0020\u0020\u0020\u0020int age = 18;\n\n\u0020\u0020\u0020\u0020if (age >= 18) {\n\u0020\u0020\u0020\u0020\u0020\u0020System.out.println("You are of legal age.");\n\u0020\u0020\u0020\u0020} else {\n\u0020\u0020\u0020\u0020\u0020\u0020System.out.println("You are a minor.");\n\u0020\u0020\u0020\u0020}\n\u0020\u0020}\n}`;

  const secondJavaCode = `class Main {\n\u0020\u0020public static void main (String [] args) {\n\u0020\u0020\u0020\u0020String dayOfWeek = "Wednesday";\n\n\u0020\u0020\u0020\u0020switch (dayOfWeek) {\n\u0020\u0020\u0020\u0020\u0020\u0020case "Monday":\n\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020System.out.println("Today is Monday.");\n\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020break;\n\u0020\u0020\u0020\u0020\u0020\u0020case "Wednesday":\n\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020System.out.println("Today is Wednesday.");\n\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020break;\n\u0020\u0020\u0020\u0020\u0020\u0020default:\n\u0020\u0020\u0020\u0020\u0020\u0020\u0020\u0020System.out.println("Unrecognized day.");\n\u0020\u0020\u0020\u0020}\n\u0020\u0020}\n}`;

  const thirdJavaCode = `class Main {\n\u0020\u0020public static void main (String [] args) {\n\u0020\u0020\u0020\u0020int age = 20;\n\n\u0020\u0020\u0020\u0020String status = age >= 18 ? "Legal age" : "Minor";\n\u0020\u0020\u0020\u0020System.out.println(status);\n\u0020\u0020}\n}`;

  return (
    <ClassContainer>
      <h1>{t('Conditional Statements')}</h1>
      <p>
        {t(
          'Conditional structures allow programs to make decisions based on specific conditions and execute different code blocks depending on these conditions. They are used to control the flow of execution, enabling specific parts of the code to be executed only if a condition is met, and they are essential for creating dynamic and adaptable programs.',
        )}
      </p>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('Elements of a Conditional')}
      </h2>
      <p>{t('A conditional structure typically includes three main parts:')}</p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('1. ')}
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('Condition: ')}
        </span>
        {t(
          'It is an expression that is evaluated as true or false. The condition determines which code block will be executed.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('2. ')}
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('True Code Block: ')}
        </span>
        {t('It is the code that will be executed if the condition is true.')}
      </p>
      <p>
        {t('3. ')}
        <span style={{ color: '#051227', fontWeight: 'bold' }}>
          {t('False Code Block (optional): ')}
        </span>
        {t(
          'It is the code that will be executed if the condition is false. This block is optional in some programming languages.',
        )}
      </p>
      <CodeBlock
        language="python"
        text={t(firstCode)}
        shouldWrapLongLines
        showLineNumbers={false}
      />
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>{t('IF-ELSE')}</h2>
      <p>
        {t(
          'The if-else conditional structure is used to make simple decisions based on a condition. It allows you to execute one code block if the condition is true [if] and another code block if the condition is false [else].',
        )}
      </p>
      <CodeBlock language="java" text={t(firstJavaCode)} shouldWrapLongLines />
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('SWITCH-CASE')}
      </h2>
      <p>
        {t(
          "The switch conditional statement tests a condition and, depending on the value, allows you to execute one of multiple choice actions. This way, it's possible to replace multiple nested 'if' statements with code that is easier to create, understand, and modify in the future.",
        )}
      </p>
      <p>
        {t(
          "The comparison of values provided in each case is represented by the reserved word 'case.' We can have as many cases as necessary in the structure, and when one of the values matches the test variable, the code in that case will be executed. Finally, to prevent comparisons from continuing to be executed after a matching case has been found, we add the 'break' statement at the end of each code block. The 'break' statement, when executed, terminates the execution of the structure it is within.",
        )}
      </p>
      <CodeBlock language="java" text={t(secondJavaCode)} shouldWrapLongLines />
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>{t('TERNARY')}</h2>
      <p>
        {t(
          'The ternary operator in Java is a concise way of writing conditional structures. You should think of a ternary condition as if it were a normal IF condition, but in a way that its entire structure is written on a single line. It is often used when you want to assign a value to a variable based on a condition.',
        )}
      </p>
      <p style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('<variable> = (condition) ? <value1> : <value2>;')}
      </p>
      <p>
        {t(
          '<variable> is the one that will receive the value chosen from the two options. (condition) is the condition we are setting for the first value to be assigned; otherwise, the second value will be returned if the condition is NOT true.',
        )}
      </p>
      <CodeBlock language="java" text={t(thirdJavaCode)} shouldWrapLongLines />
      <p>
        {t(
          'The choice between if-else, switch-case, and the ternary operator in Java depends on the complexity of the condition, the number of cases you are evaluating, and code clarity. if-else is widely used for simple decisions, switch-case is more suitable for situations where you are evaluating multiple options based on a single value, and the ternary operator is useful when you want to assign values based on a single condition in a concise manner.',
        )}
      </p>
    </ClassContainer>
  );
};

export default WhatIsLEGO;
