import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ClassContainer, MenuImages, PortsImages } from './styles';

const ExploringBrick: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Exploring the Main Brick')}</h1>
      {/* <h2>{t('Basic Programming:')}</h2> */}
      <p>
        {t(
          "First, let's learn about the different ports available in the LEGO Mindstorms EV3 kit and how we can use them to interact with sensors and other components.",
        )}
      </p>
      <p>
        {t(
          "The EV3 ports are physical connectors located on the top and bottom of the robot's central brick.",
        )}
      </p>
      <h2>{t('Types of ports')}</h2>
      <p>{t('There are three main types of ports in the EV3')}</p>
      <p style={{ marginBottom: '0.5rem' }}>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>
            Motor Ports:&nbsp;
          </span>
          Located on the top, ports A, B, C, and D are designed to connect the
          motors to the EV3
        </Trans>
      </p>
      <p style={{ marginBottom: '0.5rem' }}>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>
            Sensor Ports:&nbsp;
          </span>
          Located on the bottom, ports 1, 2, 3, and 4 are designed to connect
          the sensors to the EV3
        </Trans>
      </p>
      <p>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>
            Data Transfer Ports:&nbsp;
          </span>
          The port identified as &apos;PC&apos; on the top of the brick is used
          for transferring the code created on the computer to the robot
        </Trans>
      </p>
      <PortsImages>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/motor-ports.png"
          alt="robot base"
        />
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/sensor-ports.png"
          alt="robot base"
        />
      </PortsImages>
      <p>
        {t(
          'To connect sensors and motors to the EV3 ports, it is necessary to use the appropriate cables, connecting the ports on the sensors and motors to the EV3 ports.',
        )}
      </p>
      <h2>{t('Setting up the ports in programming')}</h2>
      <p>
        {t(
          "Before using the ports, it is necessary to configure them in the EV3 software. When creating a program, you can choose the ports that will be used by specific sensors or motors. Make sure to correctly configure the ports to ensure proper communication between the central brick and the devices. A common mistake while writing code is when the robot doesn't perform any action. This usually happens when the ports indicated in the program are different from the ports connected to the robot",
        )}
      </p>
      <p>
        {t(
          'After properly configuring the ports, you can start interacting with the connected sensors and motors.',
        )}
      </p>
      <p>{t("Now, let's explore the different menus of the EV3.")}</p>
      <p>
        {t(
          'The central brick of the robot has a screen that displays a series of menus. These menus are the main interface for accessing the different functionalities and settings of the robot.',
        )}
      </p>
      <h2>{t('EV3 Menus')}</h2>
      <p style={{ marginBottom: '0.5rem' }}>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>
            Recent Programs:&nbsp;
          </span>
          It is the main menu that appears when you turn on the EV3 robot. It
          shows the most recent programs executed on the robot. The most recent
          program is listed first.
        </Trans>
      </p>
      <p style={{ marginBottom: '0.5rem' }}>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>
            File Explorer:&nbsp;
          </span>
          In this menu, you can find all the programming projects transferred to
          the robot, listed in alphabetical order.
        </Trans>
      </p>
      <p style={{ marginBottom: '0.5rem' }}>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>Apps:&nbsp;</span>
          This menu contains several pre-built programs to test the robot&apos;s
          components, such as which sensors are connected, the remote control,
          among others.
        </Trans>
      </p>
      <p>
        <Trans t={t}>
          <span style={{ color: '#051227', fontWeight: 600 }}>
            Config:&nbsp;
          </span>
          The last menu contains various settings and configurations of the EV3.
        </Trans>
      </p>
      <PortsImages style={{ marginBottom: '1rem' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/menu1.png"
          alt="robot base"
        />
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/menu2.png"
          alt="robot base"
        />
      </PortsImages>
      <PortsImages>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/menu3.png"
          alt="robot base"
        />
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/menu4.png"
          alt="robot base"
        />
      </PortsImages>
      <p>
        {t(
          'To navigate through the menus of the EV3, you can use the buttons located on the central brick. The center button is used to select an option or confirm a choice. The navigation buttons (up, down, left, and right) are used to scroll through the menu options.',
        )}
      </p>
    </ClassContainer>
  );
};

export default ExploringBrick;
