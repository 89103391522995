import styled from 'styled-components';
import { animated } from '@react-spring/web';

export const Container = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);

  @media (max-width: 1000px) {
    align-items: flex-start;
    padding-top: 2rem;
  }
`;

export const Content = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4rem;
  background-color: #fff;
  border-radius: 1rem;
  max-width: 50%;

  .textContent {
    h1 {
      color: #051227;
      margin-bottom: 1.5rem;
    }

    p {
      margin-bottom: 0.5rem;
    }

    button {
      margin-top: 2rem;
      padding: 0 2rem;
    }
  }

  @media (max-width: 1000px) {
    max-width: 90%;
  }

  @media (max-width: 800px) {
    padding: 2rem;
  }
`;
