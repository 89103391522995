import styled from 'styled-components';

interface ArrowProps {
  disabled: boolean;
}

export const Container = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-grow: 0;
  align-items: center;
  background: #d2e0f6;
  z-index: 9999;
  padding: 3rem 0.25rem;
  border-radius: 2rem 0 0 2rem;
`;

export const Button = styled.button<ArrowProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 0;

  svg {
    color: #144592;
    display: ${props => (props.disabled ? 'none' : 'block')};
  }
`;
