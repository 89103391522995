import React from 'react';
import { useTranslation } from 'react-i18next';

import { Screen, Container } from './styles';

const NotYet: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Screen>
      <Container>
        <h1>Desculpe, ainda não criamos essa página</h1>
      </Container>
    </Screen>
  );
};

export default NotYet;
