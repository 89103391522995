import styled from 'styled-components';
import Class from '../../../../../Class';

export const ClassContainer = styled(Class)`
  & > h1,
  h2,
  & > p {
    margin-bottom: 2rem;
  }

  h1:first-child {
    margin-bottom: 3rem;

    color: #051227;

    font-size: 2rem;
    font-weight: 600;
  }

  .lf-player-container {
    align-self: center;
  }
`;
