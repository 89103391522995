import styled, { css } from 'styled-components';

interface ContainerProps {
  bgColor: string;
  invert?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template: 1fr / 1fr 1.5fr;
  justify-content: center;
  align-items: center;
  /* background: lightblue; */
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  .cardContainer {
    position: absolute;
    grid-area: 1 / 1 / 2 / 2;
    width: 100%;
    height: 100%;
    will-change: transform;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: none;
    /* margin: 0 2rem; */

    @media (max-width: 1000px) {
      grid-area: 1 / 1 / 2 / 2;
    }
  }

  div > div {
    padding: 0.75rem;
    background: ${props => props.bgColor};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: ${props => (props.invert ? '280px' : '200px')};
    max-width: 300px;
    height: ${props => (props.invert ? '200px' : '280px')};
    max-height: 500px;
    will-change: transform;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    margin: 0 0.5rem;
    background-origin: content-box;
  }

  @media (max-width: 1400px) {
    grid-template: 1fr / 45% 55%;
  }

  @media (max-width: 1000px) {
    grid-template: ${props =>
      props.invert ? '50% auto / 1fr' : '60% auto / 1fr'};
    /* grid-template: 50% auto / 1fr; */

    overflow-y: scroll;
  }
`;

export const CardText = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  margin: 0 5rem 0 1rem;
  display: flex;
  flex-direction: column;
  max-width: 100%;

  h1 {
    @media (max-width: 1400px) {
      font-size: 1.5rem !important;
    }
  }

  @media (max-width: 1400px) {
    margin: 0 1rem 0 1rem;
  }

  @media (max-width: 1000px) {
    grid-area: 2 / 1 / 3 / 2;

    p {
      margin-bottom: 2rem;
    }
  }
`;
