import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import api from '../../services/api';
import { useAuthentication } from '../../hooks/authentication';

import {
  Container,
  CourseImage,
  CourseTitle,
  CoursesDiv,
  Screen,
} from './styles';

import dataCourses from '../../data/dataCourses';
import { useToast } from '../../hooks/toast';

interface Course {
  id: string;
  image?: string;
  name: string;
}

const Courses: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([] as Course[]);

  const { user, updateUser } = useAuthentication();
  const history = useHistory();
  const { addToast } = useToast();

  const { t } = useTranslation();

  useLayoutEffect(() => {
    async function loadCourses(): Promise<void> {
      const dbCourses: Course[] = await (await api.get('/courses')).data;

      const mappedCourses = dbCourses.map(course => {
        const matchCourse = dataCourses.find(
          dataCourse => dataCourse.name === course.name,
        );

        return matchCourse ? { ...course, image: matchCourse.image } : course;
      });

      setCourses(mappedCourses);
    }

    loadCourses();
  }, []);

  const handleCourseEnrollment = useCallback(
    async course_id => {
      const isEnrolled = user.enrollments.some(
        enrollment => enrollment.course_id === course_id,
      );

      if (isEnrolled) {
        const dbModules: [] = await (
          await api.get(`/courses/course/modules/${course_id}`)
        ).data;

        if (!dbModules.length) {
          addToast({
            type: 'info',
            label: t('Course under construction'),
            description: t('Soon we will release this amazing content!'),
          });
        }

        history.push(`/course/modules/${course_id}`);
      } else {
        try {
          const response = await api.post('/learn', {
            course_id,
          });

          delete response.data.user;
          delete response.data.course;

          user.enrollments.push(response.data);

          updateUser(user);

          history.push(`/course/modules/${course_id}`);
        } catch (error) {
          addToast({
            type: 'info',
            label: t('Course under construction'),
            description: t('Soon we will release this amazing content!'),
          });
        }
      }
    },
    [addToast, history, t, updateUser, user],
  );

  return (
    <Screen>
      <Container>
        <h1>{t('Learning')}</h1>
        <h3>{t('Choose your course and start your journey!')}</h3>
        <CoursesDiv>
          {courses.slice().map(course => (
            <button
              key={course.id}
              type="button"
              onClick={() => handleCourseEnrollment(course.id)}
            >
              {course.image && (
                <CourseImage
                  style={{ backgroundImage: `url(${course.image})` }}
                />
              )}
              <CourseTitle>
                <h1>{t(course.name)}</h1>
              </CourseTitle>
            </button>
          ))}
        </CoursesDiv>
      </Container>
    </Screen>
  );
};

export default Courses;
