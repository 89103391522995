import styled, { css } from 'styled-components';

interface SidebarItemProps {
  active: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* display: none; */

  /* min-width: 280px;
  width: 280px; */
  height: 100%;
  padding: 2rem 1rem;

  background: #f8f9fd;

  transition: 0.2s ease-in-out;

  > div:last-child {
    margin-top: auto;
  }

  @media only screen and (max-width: 1400px) {
    /* min-width: 220px; */
  }

  @media (max-width: 800px) {
    width: 100%;
    height: auto;
    z-index: 9999;
    flex-direction: row;
    padding: 0.5rem 1rem;
    justify-content: space-around;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;

  * {
    color: #1d62d1;
  }

  p {
    font-size: 1.3rem;
    font-weight: 600;
    margin-left: 0.5rem;
  }

  @media only screen and (max-width: 1360px) {
    p {
      display: none;
    }
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const SidebarItem = styled.div<SidebarItemProps>`
  display: flex;
  justify-content: center;

  width: 100%;
  box-sizing: border-box;
  border-radius: 1rem;

  transition: 0.3s ease-in-out;
  margin: 0.25rem 0;

  ${props =>
    props.active &&
    css`
      /* border-right: 3px solid #58a4b0;
       */
      background: #1d62d1;

      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    `}

  ${props =>
    !props.active &&
    css`
      &:hover {
        background: #d2e0f6;

        svg,
        p {
          color: #fff;
        }
      }
    `}

  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    padding: 1rem;

    color: ${props => (props.active ? '#fff' : '#abb1c2')};

    text-decoration: none;

    p {
      font-weight: 500;
      margin-left: 0.75rem;
    }

    @media only screen and (max-width: 1360px) {
      p {
        display: none;
      }
    }

    @media (max-width: 800px) {
      padding: 0.75rem;
    }
  }

  & + svg {
    margin-top: 1rem;
  }

  @media (max-width: 800px) {
    width: auto;
  }
`;
