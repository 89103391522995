interface Case {
  name: string;
  text: string[];
  link: string;
}

const Cases: Case[] = [
  {
    name: 'UP - (A)',
    text: [
      `when button (up v) pressed\n(A v) start motor at (25)% speed::motion`,
    ],
    link: 'https://makecode.mindstorms.com/---run?id=_ToUT17C5WRTp',
  },
  {
    name: 'UP - (D)',
    text: [
      `when button (up v) pressed\n(D v) start motor at (50)% speed::motion`,
    ],
    link: 'https://makecode.mindstorms.com/---run?id=_4EdExq1AWFPm',
  },
  {
    name: 'UP - (A + D)',
    text: [
      `when button (up v) pressed\n(A v) start motor at (25)% speed::motion\n(D v) start motor at (50)% speed::motion`,
      `when button (up v) pressed\n(D v) start motor at (50)% speed::motion\n(A v) start motor at (25)% speed::motion`,
    ],
    link: 'https://makecode.mindstorms.com/---run?id=_LqJ86ze4fWRU',
  },
  {
    name: 'DOWN - (A)',
    text: [
      `when button (down v) pressed\n(A v) start motor at (25)% speed::motion`,
    ],
    link: 'https://makecode.mindstorms.com/---run?id=_FefJYY04tYuH',
  },
  {
    name: 'DOWN - (D)',
    text: [
      `when button (down v) pressed\n(D v) start motor at (50)% speed::motion`,
    ],
    link: 'https://makecode.mindstorms.com/---run?id=_P2dXC5YPC7z8',
  },
  {
    name: 'DOWN - (A + D)',
    text: [
      `when button (down v) pressed\n(A v) start motor at (25)% speed::motion\n(D v) start motor at (50)% speed::motion`,
      `when button (down v) pressed\n(D v) start motor at (50)% speed::motion\n(A v) start motor at (25)% speed::motion`,
    ],
    link: 'https://makecode.mindstorms.com/---run?id=_4ss0ew5cCV1y',
  },
  {
    name: 'UP (A) - DOWN(D)',
    text: [
      `when button (up v) pressed\n(A v) start motor at (25)% speed::motion\nwhen button (down v) pressed\n(D v) start motor at (50)% speed::motion`,
    ],
    link: 'https://makecode.mindstorms.com/---run?id=_7qvUMLgYM1x4',
  },
  {
    name: 'UP (D) - DOWN(A)',
    text: [
      `when button (up v) pressed\n(D v) start motor at (50)% speed::motion\nwhen button (down v) pressed\n(A v) start motor at (25)% speed::motion`,
    ],
    link: 'https://makecode.mindstorms.com/---run?id=_bCjfmF6azVeY',
  },
  {
    name: 'DOWN (A) - UP(D)',
    text: [
      `when button (down v) pressed\n(A v) start motor at (25)% speed::motion\nwhen button (up v) pressed\n(D v) start motor at (50)% speed::motion`,
    ],
    link: 'https://makecode.mindstorms.com/---run?id=_DMzW6vRbg0P1',
  },
  {
    name: 'DOWN (D) - UP(A)',
    text: [
      `when button (down v) pressed\n(D v) start motor at (50)% speed::motion\nwhen button (up v) pressed\n(A v) start motor at (25)% speed::motion`,
    ],
    link: 'https://makecode.mindstorms.com/---run?id=_KWWbpaWemRgR',
  },
  {
    name: 'BLANK',
    text: [``, `when button (up v) pressed`, `when button (down v) pressed`],
    link: 'https://makecode.mindstorms.com/---run?id=_Rm4Afx74q0L8',
  },
];

export default Cases;
