import styled from 'styled-components';
import { lighten, shade } from 'polished';

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  width: 100vw;
  height: 100%;
  max-height: 100%;
  background: #d2e0f6;

  padding: 5rem;

  @media (max-width: 1224px) {
    padding: 5rem 3rem;
  }

  @media (max-width: 800px) {
    padding: 2rem 1.5rem;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 1.5rem;
  padding: 2rem 3rem;

  h2 {
    font-size: 2rem;
    font-weight: 500;
    color: #051227;
    margin-bottom: 2rem;
  }

  form {
    width: 100%;
  }

  button {
    margin-top: 1rem;
  }

  @media (min-width: 800px) {
    min-width: 560px;
  }

  @media (max-width: 800px) {
    width: 100%;

    padding: 2rem 1.5rem;
  }
`;
