interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'myblocks/b2.png',
    title: 'Reusable Block',
    text:
      "Now, when you call the custom block you created called “My Code”, the robot will first perform the movements and then make the beep sound. This is a great way to reuse your code in multiple parts of your program without having to write them again. Just call your custom block, and you're good to go!",
  },
  {
    image: 'myblocks/b1.png',
    title: 'Code Block',
    text:
      'In this case, a sequence of movements was added for the robot to move forward, turn right, and move forward again.',
  },
];

export default Cards;
