import styled, { css } from 'styled-components';
import Class from '../../../../../Class';

interface ContainerProps {
  showNextButton?: boolean;
}

export const ClassContainer = styled(Class)<ContainerProps>`
  & > h1,
  & > p {
    margin-bottom: 2rem;
  }
  position: relative;

  h1:first-child {
    margin-bottom: 3rem;

    color: #051227;

    font-size: 2rem;
    font-weight: 600;
  }

  h2 {
    color: #051227;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .lf-player-container {
    align-self: center;
  }

  .modalStartText {
    font-weight: 600;
    color: #051227;
    margin-bottom: 80px;
    align-self: center;
  }

  ${props =>
    !props.showNextButton &&
    css`
      .nextClassButton {
        visibility: hidden;
      }
    `}

  ${props =>
    props.showNextButton &&
    css`
      .modalStartText {
        visibility: hidden;
      }
    `}

  table {
    border-collapse: collapse;
  }

  table,
  td,
  th {
    border: 1px solid #1d62d1;
  }

  td,
  th {
    padding: 0.5rem;
  }
`;
