interface CardInfo {
  image: string;
  title: string;
  text: string;
}

const Cards: CardInfo[] = [
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/color-sensor-spike.png',
    title: 'Color Sensor (SPIKE)',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/ultrasonic-spike.png',
    title: 'Ultrasonic Sensor (SPIKE)',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/touch-sensor-spike.png',
    title: 'Touch Sensor (SPIKE)',
    text: '',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/color-sensor.png',
    title: 'Color Sensor',
    text:
      'Another common sensor is the color sensor, which is used to detect the colors of objects on its surroundings. This allows the robot to perform specific actions based on the colors it detects. For example, the robot can be programmed to follow a black line on the floor or identify different colors of objects and sort them accordingly.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/ir-sensor.png',
    title: 'Ultrasonic Sensor',
    text:
      'The ultrasonic sensor, or motion sensor, allows the robot to detect objects on its surroundings. This is very useful for robots to avoid obstacles or approach specific objects in an environment.',
  },
  {
    image: 'learnfirst-bucket.s3.amazonaws.com/touch-sensor.png',
    title: 'Touch Sensor',
    text:
      'One of the most common sensors is the touch sensor, which is used to detect when the robot comes into contact with an object. The touch sensor consists of a button that is pressed when the robot touches an object, allowing the robot to register this event and perform an action in response.',
  },
];

export default Cards;
