import { shade } from 'polished';
import styled from 'styled-components';

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  align-items: flex-start;
  justify-content: space-between;

  width: 100vw;
  height: 100%;
  max-height: 100%;

  padding: 4rem;

  @media (max-width: 1224px) {
    padding: 3rem 2rem;
  }

  @media (max-width: 800px) {
    padding: 2rem 1.5rem;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template: 1fr / 40% 60%;

  width: 100%;
  height: 100%;

  @media (max-width: 1600px) {
    grid-template: 1fr / 60% 40%;
  }

  @media (max-width: 1224px) {
    grid-template: 1fr / 70% 30%;
  }

  @media (max-width: 800px) {
    grid-template: 1fr / 100%;
  }
`;

export const Info = styled.div`
  grid-area: 1 / 1 / 2 / 2;
`;

export const HeaderCustomCard = styled.div`
  width: 100%;
  margin-bottom: 5rem;

  transition: 0.2s ease-in-out;

  @media (max-width: 1400px) {
    margin-bottom: 2rem;
  }
`;

export const Content = styled.div``;

export const HeaderTopContent = styled.div`
  padding-bottom: 1.5rem;
  border-bottom: 2px solid #c2cad6;
  margin-bottom: 1.5rem;

  h1 {
    margin-bottom: 0.5rem;
    color: #051227;
  }

  @media (max-width: 1224px) {
    padding-bottom: 0;
  }
`;

export const HeaderBottomContent = styled.div`
  display: flex;
  align-items: center;
  color: #051227;
`;

export const LittleInfo = styled.div`
  display: flex;
  align-items: center;

  & + div {
    margin: 0 2rem;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

export const Bottom = styled.div`
  display: flex;

  width: 100%;
`;

export const ContentCustomCard = styled.div`
  width: 100%;

  transition: 0.2s ease-in-out;

  & > div {
    width: 100%;
    height: 100%;

    h3 {
      margin-bottom: 1.5rem;
      color: #051227;
    }
  }
`;

export const BackButton = styled.div`
  display: inline-flex;
  margin-bottom: 0.5rem;
  cursor: pointer;
  color: #051227;

  &:hover {
    color: ${shade(0.3, '#051227')};
  }
`;
