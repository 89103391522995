import React from 'react';

import { useTranslation } from 'react-i18next';
import { HiOutlineViewGridAdd } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { Screen, Container, Text, SVGContainer } from './styles';

import Problem from '../../assets/Illustrations/Problem.svg';
import Button from '../../components/Button';

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Screen>
      <Container>
        <SVGContainer style={{ backgroundImage: `url(${Problem})` }} />
        <Text>
          <h1>{t('Hey, where are we?')}</h1>
          <p>
            {t('It seems like this page does not exist or has been removed.')}
          </p>
          <p>{t("Let's go back to our home!")}</p>
        </Text>
        <Link to="/dashboard" style={{ marginTop: '0.5rem' }}>
          <Button>
            {t('Back to dashboard')}
            <HiOutlineViewGridAdd size={20} style={{ marginLeft: '0.5rem' }} />
          </Button>
        </Link>
      </Container>
    </Screen>
  );
};

export default NotFound;
