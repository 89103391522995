import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { CodeBlock } from '@atlaskit/code';
import { AtlaskitThemeProvider } from '@atlaskit/theme';

import { BgImage, ClassContainer } from './styles';

const WhatIsLEGO: React.FC = () => {
  const { t } = useTranslation();

  const firstJavaCode = `class Main {\n\u0020\u0020public static void main (String [] args) {\n\u0020\u0020\u0020\u0020double student1 = 8.2; // Grade of the first student\n\u0020\u0020\u0020\u0020double student2 = 7.6; // Grade of the second student\n\u0020\u0020\u0020\u0020double student3 = 9.5; // Grade of the third student\n\u0020\u0020\u0020\u0020// and so on\n\u0020\u0020}\n}`;

  const secondJavaCode = `class Main {\n\u0020\u0020public static void main (String [] args) {\n\u0020\u0020\u0020\u0020double grades[] = new double[100]; // Creates the array\n\n\u0020\u0020\u0020\u0020// Add values dynamically\n\n\u0020\u0020\u0020\u0020System.out.println(grades[2]);\n\u0020\u0020}\n}`;

  return (
    <ClassContainer>
      <h1>{t('Arrays')}</h1>
      <p>
        {t(
          'During the development of computer programs, there is often a need to group variables of the same type. For example, when you are creating a program that needs to store the grades of students in a class, one possible approach is the following:',
        )}
      </p>
      <CodeBlock language="java" text={t(firstJavaCode)} shouldWrapLongLines />
      <p>
        {t(
          'As you may have noticed, the approach involves creating a variable of type double (accepts decimal numbers) for each student.',
        )}
      </p>
      <p>{t('However, this approach presents the following problems: ')}</p>
      <ul>
        <li>
          {t(
            'If the class has 100 students, you will have to create 100 different variables (and give each one a different name).',
          )}
        </li>
        <li>
          {t(
            "It's not the most efficient approach to solving the problem because the code becomes very lengthy and repetitive.",
          )}
        </li>
      </ul>
      <p>
        {t(
          "That's why there is a much simpler approach to solving this problem: arrays!",
        )}
      </p>
      <CodeBlock
        language="java"
        text={t('double grades[] = new double[100]')}
        shouldWrapLongLines
      />
      <p>
        {t(
          "The line of code above creates an array (also called an array) named 'grades' capable of storing 100 elements of type double. What happens internally is that 100 memory spaces will be reserved to store these elements. Each of these spaces can be accessed through a number (called an index).",
        )}
      </p>
      <p>
        {t(
          'To store numbers inside arrays and to retrieve them again, the programmer must use indices, which range from 0 (first space) to the size of the array - 1 (last space).',
        )}
      </p>
      <CodeBlock
        language="java"
        text={t(secondJavaCode)}
        shouldWrapLongLines
        highlight="5"
      />
    </ClassContainer>
  );
};

export default WhatIsLEGO;
