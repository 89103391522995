/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiCheckCircle, HiOutlineInformationCircle } from 'react-icons/hi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Button from '../../components/Button';
import { useAuthentication } from '../../hooks/authentication';
import api from '../../services/api';

import {
  Screen,
  Container,
  QuestionContainer,
  Answer,
  AssignmentHeader,
  QuestionImage,
  AnswerImage,
  AnswerContent,
} from './styles';

interface Level {
  id: string;
  name: string;
  area_module_id: string;
  area_name: string;
  color: string;
  progress?: number;
  assignments: Assignment[];
}

interface Answer {
  id: string;
  placeholder: string;
  correct: boolean;
  image?: string;
}

interface Question {
  id: string;
  placeholder: string;
  image?: string;
  answers: Answer[];
}

interface Assignment {
  id: string;
  title: string;
  instructions: string;
  module_level_id: string;
  module_level?: Level;
  questions: Question[];
  order: number;
}

interface QuestionData {
  question: Question;
  answer: Answer;
}

interface LevelProgress {
  id: string;
  completed: boolean;
  level: Level;
}

interface AssignmentProgress {
  id: string;
  completed: boolean;
}

const SimpleAssignment: React.FC = () => {
  const [content, setContent] = useState<Assignment>();
  const [activeElements, setActiveElements] = useState<string[]>([]);
  const [nextContent, setNextContent] = useState<string>();
  const [data, setData] = useState<QuestionData[]>([] as QuestionData[]);

  const { user } = useAuthentication();
  const history = useHistory();

  const { pathname } = useLocation();
  const { t } = useTranslation();

  const reformatLevelName = useCallback((name: string) => {
    return name.replaceAll('-', ' ');
  }, []);

  useEffect(() => {
    async function loadAssignment(): Promise<void> {
      // check if user has level progress (if not - shouldn't be here)
      const courseName = pathname.split('/')[3];
      const areaName = pathname.split('/')[4];
      const levelName = pathname.split('/')[5];

      const responseLevel: Level = await (
        await api.get(
          `/courses/levels/${reformatLevelName(courseName)}/${reformatLevelName(
            areaName,
          )}/${reformatLevelName(levelName)}`,
        )
      ).data;

      const dbLevelProgress: LevelProgress = await (
        await api.get(
          `/learn/user-level-progress/${user.id}/${responseLevel?.id}`,
        )
      ).data;

      if (!dbLevelProgress) {
        // put message like "you should enroll in a course before..."
        history.push(`/levels/${responseLevel?.area_module_id}`);
      } else {
        const contentName = pathname
          .substr(pathname.lastIndexOf('/') + 1)
          .replaceAll('-', ' ');
        const dbAssignment: Assignment = await (
          await api.get(`/courses/content/${contentName}/${responseLevel.id}`)
        ).data;
        setContent(dbAssignment);
        const dbAssignmentProgress: AssignmentProgress = await (
          await api.get(
            `/learn/user-content-progress/${user.id}/${dbAssignment.id}`,
          )
        ).data;
        if (!dbAssignmentProgress) {
          await api.post('/learn/assignment-progress', {
            assignment_id: dbAssignment.id,
            level_progress_id: dbLevelProgress.id,
            user_id: user.id,
          });
        }
        const responseNext = await api.get(
          `/courses/next-content/${dbAssignment.module_level_id}/${dbAssignment.order}`,
        );
        if (responseNext.data) {
          const changePathname = pathname.replace(
            pathname.split('/')[2],
            responseNext.data.type,
          );

          const newPathname = changePathname.replace(
            pathname.substr(pathname.lastIndexOf('/') + 1),
            responseNext.data.title.replaceAll(' ', '-'),
          );

          setNextContent(newPathname);
        }
      }
    }

    loadAssignment();
  }, [history, pathname, reformatLevelName, user.id]);

  const handleCorrectAnswer = useCallback(
    (questionId: string, answerId: string, questionData: QuestionData) => {
      const newActive = activeElements.filter(el => !el.includes(questionId));

      setActiveElements([...newActive, `${questionId}-${answerId}`]);

      const newData = data.filter(
        el => el.question.placeholder !== questionData.question.placeholder,
      );

      setData([...newData, questionData]);
    },
    [activeElements, data],
  );

  return (
    <Screen>
      <Container>
        <AssignmentHeader>
          {content && (
            <>
              <h1>{t(content.title)}</h1>
              <h3>{t(content.instructions)}</h3>
            </>
          )}
        </AssignmentHeader>
        {content?.questions.map(question => (
          <QuestionContainer key={question.id}>
            <h2>{t(question.placeholder)}</h2>
            {question.image && (
              <QuestionImage>
                <img src={`https://${t(question.image)}`} alt="img" />
              </QuestionImage>
            )}

            {question.answers.map(answer => (
              <Answer
                checked={activeElements.some(el => el.includes(answer.id))}
                key={answer.id}
                onClick={() =>
                  handleCorrectAnswer(question.id, answer.id, {
                    question,
                    answer,
                  })
                }
              >
                {activeElements.some(el => el.includes(answer.id)) ? (
                  <HiCheckCircle size={24} />
                ) : (
                  <HiOutlineInformationCircle size={24} />
                )}
                <AnswerContent>
                  {answer.image && (
                    <AnswerImage>
                      <img src={`https://${t(answer.image)}`} alt="img" />
                    </AnswerImage>
                  )}
                  {answer.placeholder && <p>{t(answer.placeholder)}</p>}
                </AnswerContent>
              </Answer>
            ))}
          </QuestionContainer>
        ))}

        {data.length === content?.questions.length && (
          <Link
            to={{
              pathname: `/course/assignment-review/${pathname.split('/')[3]}/${
                pathname.split('/')[4]
              }/${pathname.split('/')[5]}/${pathname.split('/')[6]}`,
              state: {
                content,
                data,
                nextContent,
              },
            }}
            style={{ marginTop: '0.5rem', textDecoration: 'none' }}
          >
            <Button style={{ width: 'auto', padding: '0.5rem 1rem' }}>
              {t('Finish')}
            </Button>
          </Link>
        )}
      </Container>
    </Screen>
  );
};

export default SimpleAssignment;
