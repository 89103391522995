import styled from 'styled-components';
import { lighten, shade } from 'polished';
import Button from '../../components/Button';

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  width: 100vw;
  height: 100%;
  max-height: 100%;
  background: #e8effa;

  padding: 5rem;

  @media (max-width: 1224px) {
    padding: 5rem 3rem;
  }

  @media (max-width: 800px) {
    padding: 2rem 1.5rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 55%;
  height: 100%;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  form {
    background: #fff;
    border-radius: 1.5rem;
    padding: 2rem 3rem;
  }

  @media (max-width: 1500px) {
    width: 60%;
  }

  @media (max-width: 1224px) {
    width: 80%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5rem;
  background: #fff;
  border-radius: 1.5rem;
  padding: 1rem 2rem;
`;

export const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  h2 {
    font-size: 2rem;
    font-weight: 500;
    color: #051227;
    margin-right: 4rem;
  }
`;

export const AddQuestion = styled.button`
  display: flex;
  padding: 0.5rem;
  border: 0;
  border-radius: 1rem;
  color: #0c2754;
  background: ${lighten(0.45, '#1d62d1')};
  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${shade(0.1, '#e2eff2')};
  }
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 2rem;
  background: #fff;
  border-radius: 1.5rem;
  padding: 1rem 2rem;

  h2 {
    width: 100%;
    border-bottom: 2px solid #dadde4;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  > div {
    display: flex;

    svg {
      margin-right: 1rem;
    }
  }
`;

export const CreateButton = styled(Button)`
  margin-top: 2rem;
`;
