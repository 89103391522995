import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { ClassContainer } from './styles';

const WhatIsLEGO: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('FIRST Communication Principles')}</h1>
      <p>
        {t(
          'The goal of the communication area is to spread the values of FIRST through the most significant sources of information exchange, "selling" the team on social media as a brand.',
        )}
      </p>
      <Player
        autoplay
        loop
        src="https://learnfirst-bucket.s3.us-east-2.amazonaws.com/camera.json"
        style={{ height: '300px', width: '300px' }}
      >
        <Controls visible={false} />
      </Player>
      <p style={{ fontWeight: 'bold' }}>{t('And how do we do that?')}</p>
      <ul style={{ marginBottom: '2rem' }}>
        <li>
          {t(
            "By focusing on communicating the team's activities, both in their daily routine and at special events.",
          )}
        </li>
        <li>{t('Keeping social media active and engaging followers.')}</li>
        <li>{t('Inspiring people through weekly posts.')}</li>
        <li>
          <Trans t={t}>
            Sharing our version of&nbsp;
            <i>trends</i>
          </Trans>
        </li>
        <li>
          {t('Positioning FIRST and educational robotics as a life style.')}
        </li>
      </ul>
      <p>
        {t(
          'In addition to social media posts, photos, videos, and records, in general, are extremely important and connect all areas of the team, helping to organize and document our accomplishments (very important for award documentation!).',
        )}
      </p>
    </ClassContainer>
  );
};

export default WhatIsLEGO;
