import React from 'react';
import { useTranslation } from 'react-i18next';

import { Screen, Container } from './styles';

const TalonSRX: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Screen>
      <Container>
        <h1>Talon SRX</h1>
        <p>
          {t(
            'The Talon SRX is a speed controller designed through collaboration between VEX Robotics (VEX.com) & Cross the Road Electronics (crosstheroadelectronics.com) that allows fine control and high performance of brushed DC motors in competition robotics applications. The Talon SRX handles high current loads with minimal voltage drop and heat generation. Its linear output and Brake/Coast options allow for precise motor control, making it well suited for everything from drivetrains to shooter wheels.',
          )}
        </p>
        <p>
          {t(
            'The Talon SRX’s Data Port and CAN bus features make it the most capable motor controller available for competition robotics. The Data Port allows Quadrature Encoders, Limit Switches and Analog Sensors to be connected directly to the motor controller. Sensors that are connected through the Data Port are directly processed by the Talon SRX, freeing up resources in the primary robot controller for more complex user code. When using CAN bus, up to 63 Talon SRXs can communicate with the primary robot controller for tasks like field-upgrade, Data Port Configuration, dynamic Brake/Coast toggling, and motor control.',
          )}
        </p>
        <p>
          {t(
            'To power the Talon SRX, connect the positive input (red) wire to the selected positive terminal of the Power Distribution Panel (PDP), and the input ground (black) wire to the corresponding ground terminal. In many cases, the Talon SRX may be wired directly to the robot’s PDP without extension cables – this is preferable since it reduces the number of electrical connections. If the robot’s design does not allow for a direct connection to the PDP, extension cables may be used.',
          )}
        </p>
        <p>
          {t(
            "Always keep good electrical practices in mind when designing a robot's electrical system. The Talon SRX’s source voltage should never exceed 28V. In addition, a 40A or smaller breaker should be used in series with the Talon SRX’s positive input.",
          )}
        </p>
      </Container>
    </Screen>
  );
};

export default TalonSRX;
