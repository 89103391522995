import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import { BgImage, ClassContainer } from './styles';

const WhatIsLEGO: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ClassContainer>
      <h1>{t('Circuit Assembly')}</h1>
      <p>
        {t(
          'Electrical circuits are connections of elements such as generators, receivers, and capacitors, made through conducting wires, allowing the flow of electric current. They are used for distributing electrical energy in homes, industries, and, of course, for our lovely robot as well!',
        )}
      </p>
      <p>
        {t(
          "To help you understand better, here's a diagram of a circuit set up in real life for an FRC robot:",
        )}
      </p>
      <BgImage style={{ justifyContent: 'center', marginBottom: '1rem' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/diagrams/frc-control-system-layout.svg"
          alt="control-system"
        />
      </BgImage>
      <p style={{ color: '#051227', alignSelf: 'center', fontWeight: 'bold' }}>
        {t('Diagram courtesy of FRC® Team 3161 and Stefen Acepcion')}
      </p>
      <p style={{ fontWeight: 'bold', color: '#051227', marginBottom: '1rem' }}>
        {t('TO KNOW')}
      </p>
      <p>
        {t(
          'The difference between alternating current (AC) and direct current (DC) is that in DC, electrons follow a one-way flow, while in AC, they change direction. In AC circuits, less energy is lost, so they are used in higher-power applications. In our robot, we use only DC circuits, with a maximum voltage of 24V.',
        )}
      </p>
      <h2 style={{ fontWeight: 'bold', color: '#051227' }}>
        {t('How to Safely and Practically Assemble a Circuit?')}
      </h2>
      <p>{t('There are no strict rules, but FIRST provides the recipe!')}</p>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t(
          '1. Create a base for circuit assembly and organize it functionally.',
        )}
      </p>
      <p>
        {t(
          'For a base/plate, choose a lightweight material that can be easily removed, something that can be attached to the robot chassis with Velcro or zip ties.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t("2. Now, it's time to secure the components to the plate.")}
      </p>
      <p>
        {t(
          'To secure the components to the plate, you have numerous options: you can use Velcro, double-sided tape, or, if you prefer, zip ties, which can be placed in the nut and bolt fasteners (as shown in the image below).',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/electric/ziptie.png"
          alt="ziptie-example"
        />
      </BgImage>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('3. Connect the battery connector to the PDP.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Using a 1/16" Allen wrench, remove the two screws that secure the cover to the PDP terminal.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'With a 5mm (or 3/16") Allen wrench, remove the negative (-) screw and washer from the PDP. Place the negative terminal of the battery connector and tighten it.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Finally, using a 7/16" open-end wrench, remove the nut from the "bat" side of the circuit breaker and secure the positive terminal of the battery connector.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/electric/terminal-lug.png"
          alt="terminal-lug"
        />
      </BgImage>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('4. Connect the Circuit Breaker to the PDP.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'First, attach a terminal to the end of the 6AWG red wire. Remove the nut from the "AUX" side of the circuit breaker and place the terminal on the pin. Secure it with the nut, and that side is connected!',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Cut, strip, and attach a terminal to the 2nd end of the red 6AWG wire.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Using the 5mm Allen wrench, attach the other end of the same wire to the positive terminal side of the PDP.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/electric/circuit-breaker.png"
          alt="circuit-breaker"
        />
      </BgImage>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('5. Insulate the PDP connections.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Use electrical tape to insulate the two connections to the circuit breaker. Also, insulate any parts of the PDP terminals that will be exposed when the cover is reattached.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('Using the 1/16" Allen wrench, reattach the terminal cover.')}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/electric/insulate.png"
          alt="insulate"
        />
      </BgImage>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('6. Wago Connectors.')}
      </p>
      <p>
        {t(
          'The next step involves using Wago connectors on the PDP. To use the Wago connectors, insert a small flat-head screwdriver into the rectangular hole at a shallow angle and then tilt the screwdriver upward while continuing to press to activate the lever, opening the terminal.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('7. Distribution of Power for Motor Controllers.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('For Victor SPX or other integrated wire controllers:')}
      </p>
      <p>
        {t(
          'Cut and strip the red and black power input wires and insert them into one of the larger 40A Wago pairs.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/electric/pdp-power.png"
          alt="pdp-power"
        />
      </BgImage>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('For other motor controllers:')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Cut the red and black wires to the appropriate length to reach one of the Wago 40A input terminals on the motor controller (with a little extra for the length that will be inserted into the terminals at each end).',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Strip one end of each of the wires and insert them into the Wago terminals.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Strip the other end of each wire and attach a fork or ring terminal.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Connect the terminal to the input terminals of the speed controller (red to +, black to -).',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/electric/spark-power.png"
          alt="spark-power"
        />
      </BgImage>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('8. Weidmuller Connectors.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Various CAN and system power connectors use a Weidmuller LSF series wire-to-board connector. Remember a few things when using this connector for the best results:',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('The wire should be 16AWG to 24AWG.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('Strip the wire ends approximately 5/16".')}
      </p>
      <p>
        {t(
          'To insert or remove the wire, press the button corresponding to the terminal you want to open.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t("After making the connection, ensure it's clean and secure:")}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Check for any "whiskers" sticking out of the connector that could cause a short circuit.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          "Pull the wire to ensure it's seated properly. If the wire comes out and is the correct length, it needs to be reinserted or stripped a bit more.",
        )}
      </p>
      <a
        href="https://youtu.be/Zsnro-9_ZfQ"
        rel="noreferrer"
        target="_blank"
        style={{ marginBottom: '2rem' }}
      >
        {t('Reference Video.')}
      </a>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('9. RoboRIO Power')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Insert the 10A and 20A mini fuses into the PDP in the locations shown in the image below.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Strip approximately 5/16" from the red and black 18AWG wires and connect them to the "Vbat Controller PWR" terminals on the PDP.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          "Measure the necessary length to reach the power input on the roboRIO. Make sure to leave enough length to route the wires around other components. Don't forget about the part that will be stripped and inserted into the roboRIO connector.",
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('Cut and strip the wire.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Using a small flat-head screwdriver, connect the wires to the power input connector on the roboRIO (red to V, black to C). Also, make sure the power connector is securely screwed into the roboRIO.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/electric/roborio-power.png"
          alt="roborio-power"
        />
      </BgImage>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('10. Voltage Regulator Module Power')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('Strip about 5/16" from the ends of the red and black 18AWG wires.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Connect the wire to one of the two pairs of terminals labeled "Vbat VRM PCM PWR" on the PDP.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Measure the necessary length to reach the "12Vin" terminals on the VRM. Make sure to leave enough length to route the wires around other components. Don\'t forget about the part that will be stripped and inserted into the VRM connector.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('Cut and strip 5/16" from the ends of the wires.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('Connect the wires to the VRM 12Vin terminals.')}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/electric/vrm-power.png"
          alt="vrm-power"
        />
      </BgImage>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('11. Pneumatics Control Module Power')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
          fontStyle: 'italic',
        }}
      >
        {t(
          'The PCM is an optional component, as it is used to control pneumatics on the robot.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('Strip about 5/16" from the ends of the red and black 18AWG wires.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Connect the wires to one of the pairs of terminals labeled "Vbat VRM PCM PWR" on the PDP.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Measure the necessary length to reach the "Vin" terminals on the PCM. Make sure to leave enough length to route the wires around other components. Don\'t forget about the part that will be stripped and inserted into the PCM connector.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('Cut and strip 5/16" from the ends of the wires.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('Connect the wires to the PCM Vin terminals.')}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/electric/pcm-power.png"
          alt="pcm-power"
        />
      </BgImage>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('12. Radio Power')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Insert the tips of the passive PoE injector cable into the corresponding colored terminals in the 12V/2A section of the VRM.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Connect the male RJ45 (Ethernet) end of the cable to the Ethernet port on the radio nearest the connector (labeled as POE 18-24v).',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/electric/radio-power.png"
          alt="radio-power"
        />
      </BgImage>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('13. roboRIO to Radio Ethernet.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Connect an Ethernet cable from the female RJ45 (Ethernet) port of the Rev Passive PoE cable to the RJ45 (Ethernet) port on the roboRIO.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/electric/radio-ethernet.png"
          alt="radio-ethernet"
        />
      </BgImage>
      <p
        style={{
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('14. CAN Devices')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('RoboRIO to PCM')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
          fontStyle: 'italic',
        }}
      >
        {t(
          'The PCM is an optional component used to control pneumatics on the robot. If you are not going to use the PCM, connect the CAN connection directly from the roboRIO to the PDP.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('Strip approximately 5/16" from each of the CAN wires.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Insert the wires into the appropriate CAN terminals on the roboRIO (yellow for YEL, green for GRN).',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Measure the necessary length to reach the CAN terminals on the PCM (one of the two available pairs), cut, and remove approximately 5/16" from the end of the wires.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Insert the wires into the appropriate color-coded terminals on the PCM. You can use any of the yellow/green terminal pairs on the PCM because there are no predefined input and output terminals.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/electric/pcm-can.png"
          alt="pcm-can"
        />
      </BgImage>
      <p
        style={{
          color: '#051227',
          fontWeight: 'bold',
        }}
      >
        {t('PCM to PDP')}
      </p>
      <p
        style={{
          fontStyle: 'italic',
        }}
      >
        {t(
          "The PCM is an optional component used for controlling pneumatics on the robot. If you're not using the PCM, connect the CAN connection directly from the roboRIO (as shown in the previous step) to the PDP (as shown in this step).",
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('Remove approximately 5/16" from each of the CAN wires.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t('Insert the wires into the appropriate CAN terminals on the PCM.')}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Measure the necessary length to reach the CAN terminals on the PDP (one of the two available pairs). Cut and remove 5/16" from the end of the wires.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Insert the wires into the appropriate color-coded terminals on the PDP. You can use either of the yellow/green terminal pairs on the PDP because there are no predefined input and output terminals.',
        )}
      </p>
      <p
        style={{
          marginBottom: '0.5rem',
        }}
      >
        {t(
          'Note: The PDP is shipped with the CAN bus termination resistor jumper in the PCM to PDP CAN position.',
        )}
      </p>
      <BgImage style={{ justifyContent: 'center', marginBottom: '2rem' }}>
        <img
          src="https://learnfirst-bucket.s3.amazonaws.com/frc/electric/pdp-can.png"
          alt="pdp-can"
        />
      </BgImage>
      <p style={{ color: '#051227', fontWeight: 'bold' }}>
        {t('Electric pattern and reference images from WPI Docs')}
      </p>
    </ClassContainer>
  );
};

export default WhatIsLEGO;
