/* eslint-disable import/no-duplicates */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useMemo, useState } from 'react';
import { parseISO, formatDistance } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';
import socketClient from 'socket.io-client';
import { MdNotifications } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HiOutlineBell } from 'react-icons/hi';
import { useAuthentication } from '../../hooks/authentication';

import api from '../../services/api';

import {
  Container,
  Bell,
  NotificationList,
  Scroll,
  Notification,
  NotificationsHeader,
  NotificationIcon,
  NotificationContent,
  NotificationsFooter,
} from './styles';

interface Notification {
  id: string;
  content: string;
  addressee_id: string;
  achievement_id: string;
  read: boolean;
  created_at: string;
  timeDistance: string;
  badge_url: string;
}

interface Achievement {
  badge_url: string;
}

const Notifications: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>(
    [] as Notification[],
  );

  const { user } = useAuthentication();

  const { t, i18n } = useTranslation();

  const hasNotRead = useMemo(
    () =>
      !!notifications.find(
        (notification: Notification) => notification.read === false,
      ),
    [notifications],
  );

  const socket = useMemo(
    () =>
      socketClient(
        process.env.REACT_APP_API_URL || 'https://lfdeploy.learnfirst.com.br',
        {
          query: {
            user_id: user.id,
          },
        },
      ),
    [user.id],
  );

  const locales: { [key: string]: Locale } = useMemo(() => {
    return {
      'pt-BR': ptBR,
      'en-US': enUS,
    };
  }, []);

  useEffect(() => {
    socket.on('notification', async notification => {
      const achievement: Achievement = (
        await api.get(`/courses/achievement/${notification.achievement_id}`)
      ).data;

      const mappedNotification = {
        ...notification,
        timeDistance: formatDistance(
          parseISO(notification.created_at),
          new Date(),
          { addSuffix: true, locale: locales[i18n.language] },
        ),
        badge_url: achievement.badge_url,
      };

      setNotifications([mappedNotification, ...notifications]);
    });
  }, [i18n.language, locales, notifications, socket]);

  useEffect(() => {
    async function loadNotifications(): Promise<void> {
      const response = await api.get(`/notifications/addressee/${user.id}`);

      const mappedNotifications: Notification[] = await Promise.all(
        response.data.map(async (loadedNotification: Notification) => {
          const achievement: Achievement = (
            await api.get(
              `/courses/achievement/${loadedNotification.achievement_id}`,
            )
          ).data;

          return {
            ...loadedNotification,
            timeDistance: formatDistance(
              parseISO(loadedNotification.created_at),
              new Date(),
              { addSuffix: true, locale: locales[i18n.language] },
            ),
            badge_url: achievement.badge_url,
          };
        }),
      );

      setNotifications(mappedNotifications);
    }

    loadNotifications();
  }, [i18n.language, locales, user.id]);

  function toggleVisibility() {
    setVisible(!visible);
  }

  async function markAsRead(id: string) {
    const notificationAlreadyRead = notifications.find(
      notification => notification.id === id && notification.read,
    );

    if (notificationAlreadyRead) {
      return;
    }

    await api.put(`notifications/${id}`);

    setNotifications(
      notifications.map(notification =>
        notification.id === id ? { ...notification, read: true } : notification,
      ),
    );
  }

  async function markAllAsRead() {
    const mappedNotifications = await Promise.all(
      notifications.map(async notification => {
        if (!notification.read) {
          await api.put(`notifications/${notification.id}`);

          return { ...notification, read: true };
        }

        return notification;
      }),
    );

    setNotifications(mappedNotifications);
  }

  return (
    <Container>
      <Bell onClick={toggleVisibility} hasNotRead={hasNotRead}>
        <HiOutlineBell color="#051227" size={24} />
      </Bell>

      <NotificationList visible={visible}>
        <NotificationsHeader>
          <p>{t('Notifications')}</p>
          <button type="button" onClick={() => markAllAsRead()}>
            {t('Mark all as read')}
          </button>
        </NotificationsHeader>
        <Scroll>
          {notifications.length > 0 ? (
            notifications
              .slice()
              .reverse()
              .slice(0, 4)
              .map(notification => (
                <Notification
                  key={notification.id}
                  unread={!notification.read}
                  onMouseEnter={() => markAsRead(notification.id)}
                >
                  <NotificationIcon unread={!notification.read}>
                    <img src={notification.badge_url} alt="" />
                  </NotificationIcon>
                  <NotificationContent>
                    <p>
                      {`${t('New Achievement!')} ${t(notification.content)}`}
                    </p>
                    <time>{notification.timeDistance}</time>
                  </NotificationContent>
                  {/* {!notification.read && (
                <button
                  type="button"
                  onClick={() => markAsRead(notification.id)}
                >
                  Mark as read
                </button>
              )} */}
                </Notification>
              ))
          ) : (
            <p style={{ textAlign: 'center' }}>
              {t('You have 0 notifications')}
            </p>
          )}
        </Scroll>
        <NotificationsFooter>
          <Link to="/notifications" type="button">
            {t('View all notifications')}
          </Link>
        </NotificationsFooter>
      </NotificationList>
    </Container>
  );
};

export default Notifications;
