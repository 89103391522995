import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

interface ContainerProps {
  $propBorderColor: string;
  open: boolean;
}

export const Container = styled(animated.div)<ContainerProps>`
  position: absolute;
  bottom: 50px;
  border-radius: 0.5rem;
  align-self: center;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  border: 3px solid ${props => props.$propBorderColor};
  /* cursor: pointer; */
  display: flex;
  justify-content: center;
  align-items: center;

  .openModalContainer {
    height: 100%;
  }

  ${props =>
    props.open &&
    css`
      .openModalContainer {
        width: 100%;
      }
    `}

  button {
    position: absolute;
    display: flex;
    bottom: 10px;
    right: 10px;
    margin-bottom: 0;
    border: 2px solid #2e76e8;
    border-radius: 1.25rem;
    background-color: #1d62d1;
    color: white;
    padding: 1rem;
    cursor: pointer;
    z-index: 1000;

    @media (max-width: 1000px) {
      padding: 0.5rem;
      border-radius: 1rem;
    }
  }

  svg {
    color: white;
  }

  .modalDirections {
    position: absolute;
    bottom: 20px;
    left: 20px;
    font-weight: 600;
    display: flex;
    align-items: flex-end;
    color: #051227;

    svg {
      color: #051227;
      margin-left: 0.5rem;
      align-self: flex-start;
    }

    @media (max-width: 1000px) {
      display: none;
    }
  }

  .nextClassButton {
    display: none;
  }
`;
