import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';

interface CategoryProps {
  active: boolean;
  itemId: string;
}

export const Screen = styled.div`
  height: 100%;
  max-height: 100%;
  width: 100%;
  position: relative;

  overflow-x: hidden;

  background: #1d62d1;

  .react-horizontal-scrolling-menu--wrapper,
  .react-horizontal-scrolling-menu--scroll-container {
    height: 100%;
  }

  div + div {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  div + div::-webkit-scrollbar {
    display: none;
  }
`;

export const FavoritesContainer = styled.div`
  display: flex;
  position: absolute;
  margin: 2rem;
  padding: 1rem;
  background: #fff;
  border-radius: 50%;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  transition: all 0.3s ease-in-out;

  z-index: 999999;

  svg {
    color: #1d62d1;
  }

  &:hover {
    background: #e8effa;
  }

  @media (max-width: 800px) {
    margin: 1rem 2rem;
  }
`;

export const MobileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CategoryContainer = styled(Link)<CategoryProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  border-left: 3px solid #6191df;
  border-top: 15px solid #113b7d;

  width: 300px;
  height: 100%;

  padding: 3rem;

  transition: all 0.5s ease-in-out;

  //----Background----

  background: transparent;
  background-repeat: no-repeat;
  background: linear-gradient(
    0deg,
    #1d62d1,
    #8eb1e8,
    rgba(34, 34, 34, 0),
    rgba(34, 34, 34, 0)
  );
  background-position: 1% 125%;
  background-size: 500% 500%;

  text-decoration: none;

  svg {
    color: #fff;
    margin-bottom: 3rem;
  }

  p {
    font-size: 1.5rem;
    font-weight: 600;
    color: #f0f1f5;
  }

  ${props =>
    props.active &&
    css`
      background-position: 1% 100%;
      border-top: 15px solid #174ea7;
    `}

  @media (max-width: 800px) {
    width: 100%;
    align-items: center;

    border-top: 0;
    border-bottom: 3px solid #6191df;
    border-left: 15px solid #113b7d;
  }
`;
