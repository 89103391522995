/* eslint-disable prettier/prettier */
import React, { useCallback } from 'react';
import ScratchBlocks from 'scratchblocks-react';
import scratchblocks from 'scratchblocks';
import es from 'scratchblocks/locales/es.json';
import pt from 'scratchblocks/locales/pt-br.json';

import { useTranslation } from 'react-i18next';
import { EditContainer, ScratchBlocksContainer } from './styles';
import { useToast } from '../../../../../../../hooks/toast';

scratchblocks.loadLanguages({ es, pt });

interface TabsProps {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
}

const Motors: React.FC<TabsProps> = ({ code, setCode }) => {
  const { addToast } = useToast();

  const { t } = useTranslation();

  const setNewText = useCallback(
    (text: string) => {
      const textColor = text.includes('red') ? 'red' : 'green';

      if (!code.includes(textColor))
      {
        addToast({
          type: 'error',
          label: t('Not Yet'),
          description: t('Add a sensor block first'),
        });
      } else if (code.includes(text)) {
          addToast({
            type: 'error',
            label: t('Block already added'),
            description: t(''),
          });
      } else if (code.includes('if <') && code.includes('set status light') && code.lastIndexOf('if <') > 50) {
        const newText = `${code.slice(0, code.lastIndexOf("then") + 4)}\n${text}\n${code.slice(code.lastIndexOf("end"))}`
        setCode(newText);
      } else {
        const newText = `${code.slice(0, code.indexOf("then") + 4)}\n${text}\n${code.slice(code.indexOf("end"))}`
        setCode(newText);
      }
    },
    [addToast, code, setCode, t],
  );

  return (
    <EditContainer>
      <ScratchBlocksContainer
        blockStyle="scratch3"
        languages={['en', 'es', 'pt']}
        onClick={() =>
          setNewText('set status light to [red v]::looks')}
      >
        {`
              ${t('set status light to [red v]::looks')}
            `}
      </ScratchBlocksContainer>
      <ScratchBlocksContainer
        blockStyle="scratch3"
        languages={['en', 'es', 'pt']}
        onClick={() =>
          setNewText('set status light to [green v]::looks')}
      >
        {`
              ${t('set status light to [green v]::looks')}
            `}
      </ScratchBlocksContainer>
    </EditContainer>
  );
};

export default Motors;
