import styled, { css } from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { lighten } from 'polished';

interface NotificationProps {
  hasNotRead?: boolean;
  unread?: boolean;
  visible?: boolean;
}

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  align-items: flex-start;
  justify-content: space-between;

  width: 100vw;
  height: 100%;
  max-height: 100%;

  padding: 5rem 5rem 0;

  @media (max-width: 1224px) {
    padding: 5rem 3rem;
  }

  @media (max-width: 800px) {
    padding: 2rem 1.5rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 45%;
  height: 100%;

  @media (max-width: 1500px) {
    width: 70%;
  }

  @media (max-width: 1224px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  border-bottom: 2px solid #f0f1f5;
`;

export const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  margin-bottom: 2rem;

  h2 {
    color: #051227;

    @media (max-width: 1224px) {
      margin-bottom: 2rem;
    }
  }

  button {
    background: transparent;
    border: 2px solid #f0f1f5;
    border-radius: 1rem;
    text-decoration: none;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    padding: 0.5rem 1rem;

    color: #387bd1;

    &:hover {
      color: #fff;
      border-color: #4a8ce0;
      background: #4a8ce0;
    }
  }

  @media (max-width: 1224px) {
    flex-direction: column;
  }
`;

export const Scroll = styled.div`
  /* max-height: 260px; */
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Notification = styled.div<NotificationProps>`
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  transition: 0.2s ease-in-out;

  & + div {
    border-top: 2px solid #f0f1f5;
  }

  &:last-child {
    border-bottom: 2px solid #f0f1f5;
  }
`;

export const NotificationIcon = styled.div<NotificationProps>`
  position: relative;
  min-width: 60px;
  min-height: 60px;
  border-radius: 1rem;
  background: #e8e8e8;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 50px;
    height: auto;
  }

  ${props =>
    props.unread &&
    css`
      &::before {
        content: '';
        position: absolute;
        left: -3px;
        top: 0;
        width: 12px;
        height: 12px;
        background: #387bd1;
        border: 3px solid #fff;
        border-radius: 50%;
      }
    `}
`;

export const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0.75rem;
  }

  time {
    font-size: 0.95rem;
    opacity: 0.8;
    margin-bottom: 0.5rem;
  }

  button {
    font-size: 0.9rem;
    font-weight: 600;
    border: 0;
    background: ${lighten(0.4, '#387bd1')};
    color: #3854c2;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    margin-top: 0.75rem;
    transition: 0.2s ease-in-out;

    &:hover {
      background: ${lighten(0.3, '#387bd1')};
    }
  }
`;
