import styled from 'styled-components';

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-between; */

  width: 100vw;
  height: 100%;
  max-height: 100%;

  padding: 7rem 5rem 0;
  overflow-y: scroll;

  @media (max-width: 1224px) {
    padding: 5rem 3rem;
  }

  @media (max-width: 800px) {
    padding: 2rem 1.5rem;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 45%;

  margin: 0 auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1500px) {
    width: 90%;
  }

  @media (max-width: 1224px) {
    width: 100%;
  }

  button {
    margin-bottom: 2rem;
  }
`;
